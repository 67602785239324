/*jshint esversion: 6*/
define([], function () {
    /*
     * functionCall
     * ============
     * evt - [required] - object - object event
     * funcName - [required] - string - name of the function to be called
     * args - array - arguments that will be passed to the function
     */
    return (evt, funcName, args, settings) => {
        // Shift variables just in case the event object is not included
        if (typeof evt === 'string' && (Array.isArray(funcName) || funcName === undefined) ) {
            // Shift all the variables
            // console.log('Shift all the variables');
            settings = args;
            args = funcName;
            funcName = evt;
            evt = false;
        }

        const callFunction = (evt, funcName, args , settings, remainingSteps) => {

            // Check to see if the function is in a namespace
            if (funcName.indexOf('.') === -1) {
                // No namespace assume this is a global (window) function
                // Check to make sure we are calling a real function first.
                if (typeof(window[funcName]) === 'function') {
                    return window[funcName].apply(this, args);
                }
                else {
                    console.error('window[' + funcName + '] is not a function');
                }
            }
            else {
                let context = window;
                const namespace = funcName.split('.');
                const firstPart = namespace.shift();

                if (context[firstPart]) {
                    let textContext = firstPart;

                    context = context[firstPart];

                    // Loop through remaining spaces
                    for (let i = 0, len = namespace.length; i < len; i++) {
                        const testSpace = context[namespace[i]];

                        if (testSpace) {
                            // Update context and text name
                            context = testSpace;
                            textContext += '.' + namespace[i];
                        }
                        else {
                            journal.log({type: 'error', owner: 'UI', module: 'feta', submodule: 'callFunction'}, 'Namespace breaks down at depth: "', textContext + '.' + namespace[i], '"');

                            return false;
                        }
                    }

                    // We reached the end make sure the namespace is a function
                    if (typeof context === 'function') {
                        switch (funcName) {

                            case "feta.confirm.create":

                                // add on the original event object
                                // args.unshift(evt);
                                // args.push(remainingSteps);

                                break;

                            case "feta.form.submit":
                            case "feta.uploadModal.create":
                                args.unshift(evt);
                                break;
                        }

                        if (typeof args == "string") {
                            journal.log({type: 'error', owner: 'UI', module: 'feta', submodule: 'callFunction'}, 'args should be passed as an array');
                            args = [args];
                        }

                        return context.apply(this, args);
                    }
                    else {
                        journal.log({type: 'error', owner: 'UI', module: 'feta', submodule: 'callFunction'}, 'Window namespace is not a function: ', textContext);
                    }
                }
                else {
                    journal.log({type: 'error', owner: 'UI', module: 'feta', submodule: 'callFunction'}, 'Window namespace does not exist');
                }
            }
        };

        // Check to see what the function call type is
        if (typeof funcName === 'string') {
            // console.info('funcName is a string');
            return callFunction(evt, funcName, args, settings);
        }
        else {
            if (typeof funcName === 'object') {
                // console.info('funcName is an object: ', funcName);
                // Make a copy of the object of functions
                const functionCalls = $.extend({}, funcName);
                // console.log('functionCalls: ', functionCalls);
                const functionKeys = [];


                if(funcName.function && funcName.function!== undefined){
                    callFunction(evt, funcName.function, funcName.args);
                }
                else{

                    for (var func in functionCalls) {
                        functionKeys.push(func);
                    }
                    // console.log('functionKeys: ', functionKeys);

                    let priorReturn;
                    const priorReturns = [];
                    let currentIndex = 0;
                    (function callFunctions (keys) {

                        // Get a copy of the function object
                        const funcObj = functionCalls[keys.shift()];
                        // const funcObj = functionCalls;
                        // console.log('funcObj: ', funcObj);

                        const remainingSteps = {};

                        for (let i = 0, len = keys.length; i < len; i++) {
                            remainingSteps[i] = functionCalls[keys[i]];
                        }

                        // Check to see if we can include the prior return
                        if (priorReturn !== undefined) {
                            if (Array.isArray(funcObj.args)) {
                                funcObj.args.push();
                            }
                            else if (typeof funcObj.args === 'object') {
                                funcObj.args.priorReturn = priorReturn;
                            }
                            else {
                                funcObj.args = [priorReturn];
                            }
                        }

                        try {

                            if(funcObj.function === undefined){
                                return;
                            }

                            // Call the function and get its return type
                            var funcReturn = callFunction(evt, funcObj.function, funcObj.args, settings, remainingSteps);

                            priorReturn = funcReturn;
                            priorReturns.push(priorReturn);

                            // Check to see if the return failed.
                            if (funcReturn === false) {
                                // Override the array and make it blank to force the function execution to stop
                                keys = [];

                                journal.log({type: 'error', owner: 'UI', module: 'feta', submodule: 'functionCall'}, 'EventScript failed - Function ', funcObj.function, ' returned a failure code: ', funcReturn, '. Stoping eventScript execution loop.');
                            }
                            else if (funcReturn === 'stop') {
                                keys = [];
                            }
                        }
                        catch (e) {
                            keys = [];

                            journal.log({type: 'error', owner: 'UI', module: 'feta', submodule: 'functionCall'}, 'EventScript failed - Function ', funcObj.function, ' execution failed.', e);
                        }

                        if (keys.length) {
                            // Update the index
                            currentIndex += 1;

                            //TODO: This line creates an infinite loop
                            callFunctions(keys);
                        }
                        else {
                            return priorReturns;
                        }
                    })(functionKeys);
                }
            }
        }
    };
});
