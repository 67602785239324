define(['react'], function (React) {
    function RenderIconMessage (/* props */) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" height="24" width="24">
                <defs>
                    <linearGradient id="a">
                        <stop offset="0" stopColor="#e4e4e4" />
                        <stop offset="1" stopColor="#f1f1f1" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient xlinkHref="#a" id="b" x1="11.708" y1="1047.554" x2="11.708" y2="1032.214" gradientUnits="userSpaceOnUse" />
                </defs>
                <g transform="translate(0 -1028.362)">
                    <rect width="20.999" height="15.999" x="1.5" y="1031.862" ry=".746" rx=".665" fill="url(#b)" stroke="#505050" strokeWidth="1.001" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2.502 1046.865h18.996" fill="none" stroke="#ccc" strokeWidth=".995" strokeLinecap="round" strokeLinejoin="round" />
                    <path fill="#4a0" d="M16 1033.862h4v5h-4z" />
                    <path d="M11 1041.862h6.675m-6.675 2h5.216m-11.716-9h3.87" fill="none" stroke="#757575" strokeLinecap="square" />
                    <path d="M4.5 1036.862h2.71" fill="none" stroke="#666" strokeLinecap="square" />
                </g>
            </svg>
        );
    }

    return RenderIconMessage;
});
