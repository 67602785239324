/*jshint esversion: 6 */
/**
 * feta clear methods module
 *
 * @description          Contains methods for clearing fields
 * @version              0.1.0
 *
 * @return {object}      Public API for module
 */

define(['fieldMethods'], function(fieldMethods) {

	const NAMESPACE = 'fieldMethods';
    const VERSION = '1.0.0';
	const SUFFIX = "fieldMethods";

    const CLASS_NAMES = {
        clearButton: "clearButton",
        clearField: "clearField",
        copyButton: "copyValue",
        setup:"field-method-setup",
        copyData: "copydata",
    };

    const COPY_SUFFIXES = {
        copyAll : "all",
        copySource: "source",
        copyDestination: "dest"
    }

	let hasInitalized = false;

	//Public funcions
	const init = () => {
        if(hasInitalized){
			return;
		}    	
    	setupContainer(document);    	
    	hasInitalized = true;
    };

    const setupContainer = (container) =>{
        let clearButtons = container.querySelectorAll("."+CLASS_NAMES.clearButton);
        if(clearButtons.length > 0){
            for(let i=0;i<clearButtons.length;i++){
                let clearButton = clearButtons[i];
                 setupClearButton(clearButton);
            }
        }

        let copyButtons = container.querySelectorAll("."+CLASS_NAMES.copyButton);
        if(copyButtons.length > 0){
            for(let i=0; i<copyButtons.length; i++){
                let copyButton = copyButtons[i];
                setupCopyButton(copyButton);
            }
        }

        let copyDataControls = container.querySelectorAll("."+CLASS_NAMES.copyData);
        if(copyDataControls.length > 0){
            for(let i=0; i<copyDataControls.length; i++){
                let copyDataControl = copyDataControls[i];
                setupCopyDataControl(copyDataControl);
            }
        }
    }

    const setupClearButton = (elem) =>{
        if(!elem.classList.contains(CLASS_NAMES.setup)){
            elem.addEventListener("click", _events.clearAllFields);  
            elem.classList.add(CLASS_NAMES.setup);
        }          
    };

    const clearField = (elem) =>{
        
        if(!elem || elem.nodeType != Node.ELEMENT_NODE){
            return;
        }

        //Check if label. 
        if (elem.nodeName == "LABEL"){
            if(elem.for != ""){
                let forElem = document.getElementById(elem.for);
                if(forElem){
                    elem = forElem;
                }
            }
        }
        else if(elem.nodeName == "DIV"){
            let childElem = elem.querySelector('input, select, textarea');

            if(childElem){
                elem = childElem;
            }          
        }       

        switch(elem.tagName){
            case 'INPUT':{
                switch(elem.type){
                    
                    case 'checkbox':{
                        elem.checked = false;
                    } break;                    

                    case 'radio':{
                        elem.selected = false;
                    } break;

                    case 'text':{  
                        elem.value = "";
                    } break;

                    default:
                }

                //Handle fields managed by react. 
                var event;
                if(typeof(Event) === 'function') {
                    event = new Event('input', { bubbles: true});
                }else{
                    event = document.createEvent('Event');
                    event.initEvent('input', true, true);
                }

                event.simulated = true;
                elem.dispatchEvent(event);    

            } break;


            case 'SELECT':{
                if(elem.options.length > 0){
                    elem.options[0].selected = true;
                    elem.selectedIndex = 0;
                }
            } break;


            case 'TEXTAREA':{
                elem.value = "";
            } break;

            default:
        }              
    }

    const setupCopyButton = (elem) =>{
        if(!elem.classList.contains(CLASS_NAMES.setup)){
            elem.addEventListener("click", _events.copyButton);  
            elem.classList.add(CLASS_NAMES.setup);
        }
    }

    const setupCopyDataControl = (elem) =>{
        if(!elem.classList.contains(CLASS_NAMES.setup)){
            switch(elem.nodeName){
                case 'INPUT':
                    elem.addEventListener("change", _events.copyDataControlChange);  

                    let destElem = document.getElementById(elem.id + "_dest");
                    if(destElem && !destElem.classList.contains(CLASS_NAMES.setup)){
                        destElem.addEventListener("change", _events.copyDataCheckIfSelfPopulated);    
                        destElem.classList.add(CLASS_NAMES.setup)
                    }                    
                break;

                case 'BUTTON':
                    elem.addEventListener("click", _events.copyDataControlClick);  
                break;
            }
            elem.classList.add(CLASS_NAMES.setup);
        }
    }

    const copyAllInTable = (idPrefix) =>{
        // This function assumes that the element IDs will be an unbroken sequence (1,2,3,4 and not 1,2,4). Normally the loop will quit checking for elements when it fails to find the next item in the sequence.
        // However, in a table some rows might be skipped yet the JSPs `rowIndex` will continue to increment, leaving gaps in the sequence.
        // Therefore, if the element is in a table we'll keep looping until we've searched once for each row in the table.

        // If the first element is in a table, make sure we search enough times to hit every row's index

        let firstCopySource = document.getElementById(idPrefix + '_source_0');
        let searchLimit = 0;
        let parentTbody = uiUtil.getParentElem(firstCopySource, 'tbody');

        if(parentTbody){
            searchLimit = parentTbody.rows.length
        }

        for (let i=0; i < searchLimit; i++){
            let sourceElem = document.getElementById(idPrefix + "_" +COPY_SUFFIXES.copySource + "_" + i);
            let destElem = document.getElementById(idPrefix + "_" +COPY_SUFFIXES.copyDestination + "_" + i);

            if(sourceElem && destElem){
                copyValue(sourceElem, destElem);
            }
        }
    }

    const copySingle = (copyId) =>{
        if(!copyId){
            return;
        }

        let copyIndex = copyId.lastIndexOf('_');

        if(copyIndex){
            let sourceElemId = copyId.substr(0, copyIndex + 1) + COPY_SUFFIXES.copySource + copyId.substr(copyIndex);
            let destElemId = copyId.substr(0, copyIndex + 1) + COPY_SUFFIXES.copyDestination + copyId.substr(copyIndex);

            let sourceElem = document.getElementById(sourceElemId);
            let destElem = document.getElementById(destElemId);

            if(sourceElem && destElem){
                copyValue(sourceElem, destElem);
            }
        }
    }

    const copyValue = (srcElem, destElem) =>{
        let srcValue;

        if(srcElem.nodeType == 1){
            switch(srcElem.nodeName.toUpperCase()){
                case "SPAN":
                    srcValue = srcElem.textContent;
                break;
                case "TD":
                    srcValue = srcElem.textContent;
                break;

                case "INPUT":
                    srcValue = srcElem.value;
                break;

                case "DIV":
                    if(srcElem.classList.contains("feta-table-elem-td")){
                        let valueWrapper = srcElem.querySelector('.feta-data');
                        let childNodes = valueWrapper.childNodes;

                        for(let i=0; i<childNodes.length; i++){
                            if(!srcValue && childNodes[i].nodeName.toUpperCase() == "#TEXT"){
                                if(childNodes[i].textContent.trim() != ""){
                                    srcValue = childNodes[i].textContent;
                                }                                
                            }
                        }
                    }
                break;

                default:
            }                    
        }

        if(srcValue && destElem){
            if(destElem.nodeType == 1){
                switch(destElem.nodeName.toUpperCase()){
                    case "SPAN":
                        destElem.textContent = srcValue;
                    break;

                    case "INPUT":
                        //Only copy value if input is empty or is some form of 0.00.
                        if(destElem.value == "" || (/^0+(\.0+)?$/.test(destElem.value))){
                            destElem.value = srcValue;

                            //Handle fields managed by react. 
                            var event;
                            if(typeof(Event) === 'function') {
                                event = new Event('input', { bubbles: true});
                            }else{
                                event = document.createEvent('Event');
                                event.initEvent('input', true, true);
                            }

                            event.simulated = true;
                            destElem.dispatchEvent(event);    
                        }                                
                    break;

                    default:
                }                    
            }
        }
    }
    
    //Private functions
    const _events = {};

    _events.copyButton = (evt) =>{
        const copyAllSuffixPattern = /_all$/i;
        const trigger = evt.target;
        let id;

        if(trigger && trigger.id){

            id = trigger.id;

            if(copyAllSuffixPattern.test(id)){
                id = id.replace(copyAllSuffixPattern, '');
                copyAllInTable(id);
            }
            else{
                copySingle(id);
            }
        }
    }

    _events.clearAllFields = () =>{
        const clearFields = document.querySelectorAll("."+CLASS_NAMES.clearField);

        if(clearFields.length > 0){
            for(let i=0;i<clearFields.length;i++){
                let field = clearFields[i];
                clearField(field);
            }
        }
    }  

    _events.copyDataControlChange = (evt) =>{
        if(!evt && !evt.target){
            return;
        }

        let controlElement = evt.target;

        if(controlElement){
            //Get source and dest elements
            let sourceElem = document.getElementById(controlElement.id+'_source');
            let destElem =  document.getElementById(controlElement.id+'_dest');

            if(controlElement.checked == true){
                //Need to use a timeout to give react time to process checkbox toggle in IE.
                setTimeout(function(){ copyValue(sourceElem, destElem)}, 100);
            }
            else if(controlElement.checked == false){                
                //Need to use a timeout to give react time to process checkbox toggle in IE.
                 setTimeout(function(){ clearField(destElem)}, 100);   
            }
        }
    }

    _events.copyDataCheckIfSelfPopulated = (evt) =>{
           if(!evt && !evt.target){
            return;
        }

        let inputElement = evt.target;
        let controlElem = document.getElementById(inputElement.id.split('_dest')[0]);

        uiMethods.copyMethods.checkIfSelfPopulated({'controlId':controlElem.id,'srcId':inputElement.id});
    }

    _events.copyDataControlClick = () =>{
        
    }

    return {
        init: init,
        setupContainer:setupContainer      
    };
});
