/*global feta */
define(['react', 'reactproptypes', 'RenderIconPointerRight', 'RenderIconPointerLeft', 'RenderIconEnvelopeOpen', 'RenderIconTrashCan'], function (React, ReactPropTypes, RenderIconPointerRight, RenderIconPointerLeft, RenderIconEnvelopeOpen, RenderIconTrashCan) {
    const InboxModalFooter = (props) => {
        const listOfMessages = props.listOfMessages;
        const message = listOfMessages[props.currentIndex];
        const buttons = {};
        let deleteButtonElem;

        if (!message || message.key === 'empty-message') {
            return null;
        }

        // These variables are unnecessary (i.e. we should just call `props.XXXX` directly), however by creating them we prevent a false positive for the ESLint rule `react/no-unused-prop-types` which seems to have a bug
        const onNavClick = props.onNavClick;
        const onCloseModal = props.onCloseModal;
        const onDeleteClick = props.onDeleteClick;

        // Add applicable buttons:

        // Delete
        if (message.delete.url) {
            const onClick = (...eventArgs) => {
                onDeleteClick(props.currentIndex, ...eventArgs);
                onCloseModal();
            };

            buttons.delete = (
                <button
                    type="button"
                    className="cui-col feta-inbox-list-footer-button-delete"
                    title="Remove message"
                    ref={(el) => deleteButtonElem = el}
                >
                    {RenderIconTrashCan()}
                    <span>Remove message</span>
                </button>
            );

            setTimeout(() => {
                feta.confirm.setup(deleteButtonElem, {callback: onClick, args: [props.currentIndex]});
            }, 100);
        }
        else {
            buttons.delete = (
                <div className="cui-col" />
            );
        }

        // Prev
        if (props.currentIndex > 0) {
            const onClick = (...eventArgs) => {
                onNavClick(message, 'previous', ...eventArgs);
            };

            buttons.prev = (
                <button
                    type="button"
                    className="cui-col feta-inbox-list-footer-button-prev"
                    onClick={onClick}
                    title="Previous message"
                >
                    {RenderIconPointerLeft()}
                    <span>Previous message</span>
                </button>
            );
        }
        else {
            // No button because this is the first message, but insert a dummy column so this message's buttons will line up with other messages'
            buttons.prev = (
                <div className="cui-col" />
            );
        }

        // Next
        if (props.currentIndex < props.listOfMessages.length - 1) {
            const onClick = (...eventArgs) => {
                onNavClick(message, 'next', ...eventArgs);
            };

            const onKeyPress = (...eventArgs) => {
                let evt = eventArgs[0];

                if(evt.which == 13 || evt.which == 32){

                    evt.preventDefault();
                    // this._onHeaderClick(data, evt);
                    onNavClick(message, 'next', ...eventArgs);
                }
            };

            buttons.next = (
                <button
                    type="button"
                    className="cui-col feta-inbox-list-footer-button-next"
                    onClick={onClick}
                    onKeyPress={onKeyPress}
                    title="Next message"
                >
                    {RenderIconPointerRight()}
                    <span>Next message</span>
                </button>
            );
        }
        else {
            // No button because this is the last message, but insert a dummy column so this message's buttons will line up with other messages'
            buttons.next = (
                <div className="cui-col" />
            );
        }

        return (
            <div className="cui-modal-footer cui-uiBox-footer">
                <div className="cui-modal-footer-content">
                    <div className="cui-row">
                        {buttons.prev}
                        {buttons.delete}
                        {buttons.next}
                    </div>
                </div>
            </div>
        );
    };

    InboxModalFooter.propTypes = {
        listOfMessages: ReactPropTypes.array,
        currentIndex: ReactPropTypes.number,
        onCloseModal: ReactPropTypes.func,
        onNavClick: ReactPropTypes.func,
        onDeleteClick: ReactPropTypes.func,
    };

    InboxModalFooter.defaultProps = {
        listOfMessages: [],
        currentIndex: 0,
        onCloseModal: () => {},
        onNavClick: () => {},
        onDeleteClick: () => {},
    };

    return InboxModalFooter;
});
