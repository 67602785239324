/*jshint esversion: 6 */
/*global feta */
define(['clickBlocker'], function (clickBlocker) {
    // Public entry point which kicks off the request
    const init = () =>{
        uiMethods.sampleCheck.pageSetup();
        uiMethods.preloader.setup();
        uiMethods.buttons.pageSetup();
        uiMethods.iFlowMessages.display();
    };

    let uiMethods = {};

    //If uiMethods object is not present on the page, bind the uiMethods object.
    if(!window.uiMethods){
        window.uiMethods = uiMethods;
    }

    // Button click methods.
    uiMethods.buttonClick = {

        /**
         * Submits a form with additional parameters
         *
         * @param   {Element}  target   The sender DOM element (e.g. a button that was clicked)
         * @param   {Object}   json     JSON defining additional fields and their values
         */
        submitForm: function _buttonClick_submitForm (target, json) {
            let formElem;
            let numParams;
            let paramName;
            let paramValue;
            let hiddenInput;

            // Check object is not undefined
            if (typeof target === 'undefined') {
                return false;
            }

            // Check JSON is not undefined
            if (typeof json === 'undefined') {
                return false;
            }

            // If JSON is a string object, parse it
            if (typeof json === 'string') {
                if (json.length === 0) {
                    return false;
                }

                // Use native JSON parsing (if supported by browser) or JavaScript json parser (json.org)
                json = JSON.parse(json);
            }

            // Get `<form>` element

            if(json.Form){
                // Use the form name sent inside JSON
                if (json.Form.name.length > 0) {
                    formElem = document.forms[json.Form.name];
                }
                // Use the target element
                else {
                    formElem = target.form;
                }

                // Standardize the `<form>` element
                // Set action from JSON
                if (json.Form.action.length > 0) {
                    formElem.action = json.Form.action;
                }

                // Set target from JSON
                if (json.Form.target.length > 0) {
                    formElem.target = json.Form.target;
                }

                if (typeof json.Form.autoComplete !== 'undefined') {
                // Overwrite autocomplete value if passed inside JSON
                    if (json.Form.autoComplete.length > 0) {
                        formElem.setAttribute('autocomplete', json.Form.autoComplete);
                    }
                }
                else {
                    formElem.setAttribute('autocomplete', 'off');
                }
            }
            else{
                formElem = target.form;
                formElem.setAttribute('autocomplete', 'off');
            }

            // Method (enforce to POST)
            formElem.method = 'post';

            // Set or create hidden inputs
            numParams = (json.Parameters) ? json.Parameters.length : 0;
            while (numParams--) {
                // Get param name and value
                paramName = json.Parameters[numParams].name;
                paramValue = json.Parameters[numParams].value;

                // Check if the hidden field already exists (e.g. in case the same button was clicked twice)

                // In this condition, the `typeof` expression works in all browsers except IE7 (IE7 will always return `undefined`). The second expression works in all browsers including IE7, however it is much slower. Therefore we're using both expressions so that modern browsers will have better performance since they only need to evaluate the first expression.
                // Note that we're using our custom `uiUtil.query()` function because unfortunately `getElementsByName()` only works on `document` and not on other nodes. In other words, we can't search for the `<input>` by doing a native lookup on the `<form>` itself.
                if (typeof formElem[paramName] !== 'undefined') {
                    // Set value to existing input:

                    // Modern browsers
                    if (formElem[paramName]) {
                        formElem[paramName].value = paramValue;
                    }
                }
                // Hidden `<input>` does not exist yet
                else {
                    // Create new hidden input
                    hiddenInput = document.createElement('input');
                    hiddenInput.setAttribute('type', 'hidden');
                    hiddenInput.name = paramName;
                    hiddenInput.value = paramValue;

                    // Append the new input to the form
                    formElem.appendChild(hiddenInput);
                }
            }

            // Submit the form
            formElem.submit();
        }
    };

    //Image swapping for sample check image on Pay from Bank Account pages
    uiMethods.sampleCheck = {
        aImagePaths: {},        // Enumeration for each type of check image
        oFocusedElem: null,     // Most recently focused element
        bTooltipOpen: false,    // Track tooltip dispay
        bLabelMouseDown: false, // Track mousedown on labels
        revertTimeout: 0,       // Delay for setTimeout()

        ///<summary>Sets up check image path enumeration</summary>
        pageSetup: function _sampleCheck_pageSetup() {
            try {
                // Setup check image path enumeration
                var aTypes;
                var sType;
                var i;

                // Quit immediately if there are no sample check images
                if (!uiUtil.queryOne('.feta-change-sample-check')) { return false; }

                aTypes = ['routing', 'account', 'both'];
                i = aTypes.length;
                while (i--) {
                    sType = aTypes[i];

                    // Create object with the image's file source and alt text
                    uiMethods.sampleCheck.aImagePaths[sType] = {
                        src: uiGlobal.sIFLOWImagesPath + 'sample-check-' + sType + '.png',
                        alt: 'Sample check with the ' + (sType === 'both' ? 'routing and account numbers' : sType + ' number') + ' highlighted'
                    };

                    // Preload image to prevent flashing
                    uiMethods.preloader.addAsset('<img src="' + uiMethods.sampleCheck.aImagePaths[sType].src + '" alt="" />');
                }

                uiMethods.sampleCheck.events.pageSetup();
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        },

        events: {
            ///<summary>Adds event listeners to the relevant elements</summary>
            pageSetup: function _sampleCheck_events_pageSetup() {
                try {
                    // Find the check-related elements
                    var aElems = uiUtil.query('.feta-change-sample-check');
                    var oElem;
                    var oRelatedElem;
                    var i;

                    // Apply event listeners
                    i = aElems.length;
                    while (i--) {
                        oElem = aElems[i];

                        // Track focus and blur
                        uiUtil.attachEvent(oElem, 'focus', uiMethods.sampleCheck.events.onFocus);
                        uiUtil.attachEvent(oElem, 'click', uiMethods.sampleCheck.events.onFocus);
                        uiUtil.attachEvent(oElem, 'blur', uiMethods.sampleCheck.events.onBlur);

                        // Track mouse actions on <label>s to avoid flashing the image between mousedown and mouseup
                        oRelatedElem = uiUtil.queryOne('label', uiUtil.getParentElem(oElem, '.cui-field'));
                        uiUtil.css.addClass(oRelatedElem, 'feta-change-sample-check');
                        uiUtil.attachEvent(oRelatedElem, 'mousedown', uiMethods.sampleCheck.events.onFocus);
                        uiUtil.attachEvent(oRelatedElem, 'mousedown', uiMethods.sampleCheck.events.onLabelMouseDown);
                        uiUtil.attachEvent(oRelatedElem, 'mouseup', uiMethods.sampleCheck.events.onLabelMouseUp);

                        // Need to watch tooltips as well
                        if (uiUtil.css.hasClass(oElem, 'help')) {
                            oRelatedElem = document.getElementById(oElem.id + '_tooltip');

                            if (oRelatedElem) {
                                // Add data- attribute and event listeners
                                uiUtil.attachEvent(oRelatedElem, 'tooltiphide', uiMethods.sampleCheck.events.onToolTipHide, true);

                                // IE7 only: watch the div.tooltipBody instead
                                if (/internet\sexplorer/i.test(uiUtil.browser.sName) && uiUtil.browser.iVersion < 8) {
                                    oRelatedElem = uiUtil.queryOne('div.tooltipBody', oRelatedElem);
                                }

                                uiUtil.css.addClass(oRelatedElem, 'feta-change-sample-check');
                                oRelatedElem.setAttribute('data-samplechecktype', oElem.getAttribute('data-samplechecktype'));
                                uiUtil.attachEvent(oRelatedElem, 'focus', uiMethods.sampleCheck.events.onFocus);
                            }
                        }
                    }

                    uiUtil.attachEvent(document, 'click', uiMethods.sampleCheck.events.onOtherElementClick);

                    // Need to watch for clicks on other help icons since uiMethods.tooltip.display() will stop the event from bubbling up to the document
                    uiUtil.query('button.feta-icon-help').forEach(function (oElem) {
                        uiUtil.attachEvent(oElem, 'click', uiMethods.sampleCheck.events.onOtherElementClick);
                    });
                }
                catch (e) {
                    uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                }
            },

            ///<summary>Tracks element focus</summary>
            ///<param name='eEvt' type='DOM Event'>The event that was fired</param>
            onFocus: function _sampleCheck_events_onFocus(eEvt) {
                try {
                    var oSender = uiUtil.getHTMLObjFromEvent(eEvt);

                    uiMethods.sampleCheck.oFocusedElem = oSender;

                    //if parent element is tooltip
                    if (uiUtil.getParentElem(oSender, '.feta-tooltip')) {
                        uiMethods.sampleCheck.bTooltipOpen = true;
                    }
                    else if(uiUtil.css.hasClass(oSender, 'feta-tooltip')){
                        //focused element is tooltip
                        uiMethods.sampleCheck.bTooltipOpen = true;
                    }
                    else if(uiUtil.css.hasClass(oSender, 'feta-icon-help')){
                        //focused element is tooltip
                        uiMethods.sampleCheck.bTooltipOpen = true;
                    }

                    uiMethods.sampleCheck.changeImage(eEvt);
                }
                catch (e) {
                    uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                }
            },

            ///<summary>Tracks element focus</summary>
            ///<param name='eEvt' type='DOM Event'>The event that was fired</param>
            onBlur: function _sampleCheck_events_onBlur(eEvt) {
                try {
                    var oSender = uiUtil.getHTMLObjFromEvent(eEvt);

                    if (uiUtil.getParentElem(oSender, '.feta-change-sample-check')) {
                        uiMethods.sampleCheck.oFocusedElem = null;

                        if (uiUtil.getParentElem(oSender, '.feta-tooltip')) {
                            uiMethods.sampleCheck.bTooltipOpen = false;
                        }

                        // Revert image to the default
                        // Use a setTimeout to ensure that it runs after all other events have fired (eg, focus on another element)
                        setTimeout(uiMethods.sampleCheck.revertImages, uiMethods.sampleCheck.revertTimeout);
                    }
                }
                catch (e) {
                    uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                }
            },

            ///<summary>Tracks clicks on labels</summary>
            onLabelMouseDown: function _sampleCheck_events_onLabelMouseDown() {
                try {
                    uiMethods.sampleCheck.bLabelMouseDown = true;
                }
                catch (e) {
                    uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                }
            },

            ///<summary>Tracks clicks on labels</summary>
            onLabelMouseUp: function _sampleCheck_events_onLabelMouseUp() {
                try {
                    uiMethods.sampleCheck.bLabelMouseDown = false;
                }
                catch (e) {
                    uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                }
            },

            ///<summary>Tracks tooltip display</summary>
            onToolTipHide: function _sampleCheck_events_onToolTipHide() {
                try {
                    uiMethods.sampleCheck.bTooltipOpen = false;
                }
                catch (e) {
                    uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                }
            },

            ///<summary>Tracks clicks on other, non-check-related elements on the page</summary>
            ///<param name="eEvt" type="DOM Event">The event that was fired</param>
            onOtherElementClick: function _sampleCheck_events_onOtherElementClick(eEvt) {
                try {
                    var oSender = uiUtil.getHTMLObjFromEvent(eEvt);

                    if (!uiUtil.getParentElem(oSender, '.feta-tooltip .feta-change-sample-check')) {
                        uiMethods.sampleCheck.bTooltipOpen = false;
                        uiMethods.sampleCheck.oFocusedElem = null;
                        // Revert image to the default, but use setTimeout to wait for other events from this click to fire
                        setTimeout(uiMethods.sampleCheck.revertImages, uiMethods.sampleCheck.revertTimeout);
                    }
                }
                catch (e) {
                    uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                }
            }
        },

        ///<summary>Determines which image should be displayed and passes it to setImage()</summary>
        ///<param name='eEvt' type='DOM Event'>The event that was fired</param>
        ///<remarks>Only affects the check image within the event target's parent div.group</remarks>
        changeImage: function _sampleCheck_changeImage(eEvt) {
            try {
                var oSender = uiUtil.getHTMLObjFromEvent(eEvt);
                var oCheckImg = null;
                var oParent;
                var sId;

                // Find the check image that we're changing
                if (uiUtil.css.hasClass(oSender, 'tooltip') || uiUtil.css.hasClass(oSender, 'tooltipBody')) {
                    // The focused item is a tooltip, so we need to search within its help icon's div.group
                    oCheckImg = uiUtil.queryOne('.iflow-sample-check-image',
                                                uiUtil.getParentElem(document.getElementById(oSender.id.replace('_tooltip', '')), 'section')
                                                );
                }
                else {
                    // Search within the sender element's div.group
                    oParent = uiUtil.getParentElem(oSender, 'section');
                    oCheckImg = uiUtil.queryOne('.iflow-sample-check-image', oParent);

                    // Determine whether oSender is something other than a help icon
                    if (!uiUtil.getParentElem(oSender, '.help')) {
                        if (/label/i.test(oSender.nodeName)) {
                            sId = oSender.getAttribute('for') || oSender.htmlFor;
                            oSender = document.getElementById(sId);
                        }
                    }
                    else {
                        // Make sure the rest of the function refers to the help icon's anchor, not its image
                        oSender = uiUtil.getParentElem(oSender, '.help');
                    }
                }

                // Set image source
                uiMethods.sampleCheck.setImage(oCheckImg, oSender.getAttribute('data-samplechecktype'));
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        },

        ///<summary>Revert images to the default check graphic</summary>
        ///<remarks>Will quit if any relevant element has focus. Reverts all check images on the page, not just ones within a particular div.group</remarks>
        revertImages: function _sampleCheck_revertImages() {
            try {
                if (uiMethods.sampleCheck.oFocusedElem !== null || uiMethods.sampleCheck.bLabelMouseDown || uiMethods.sampleCheck.bTooltipOpen) {
                    return false;
                }

                // Revert all checks back to the 'both' image
                uiUtil.query('.iflow-sample-check-image').forEach(uiMethods.sampleCheck.setImage);
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        },

        ///<summary>Sets the specified check graphic to display a particular image</summary>
        ///<param name="oCheckImg' type='DOM Object' required='true'>The <img> to be set</param>
        ///<param name='sType' type='String'>The type of image to show. If not specified, the default image will be shown.</param>
        setImage: function _sampleCheck_setImage(oCheckImg, sType) {
            try {
                if (!oCheckImg || typeof oCheckImg !== 'object') { return false; }

                if (!sType || typeof sType !== 'string') {
                    sType = 'both';
                }

                oCheckImg.setAttribute('src', uiMethods.sampleCheck.aImagePaths[sType].src);
                oCheckImg.setAttribute('alt', uiMethods.sampleCheck.aImagePaths[sType].alt);
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        }
    };
     ///<summary>Creates a loading message and displays it over the page</summary>
    ///<param name="oOptions" type="Object">Optional settings</param>
    uiMethods.insertLoadingMessage = function _insertLoadingMessage(oOptions) {
        try {
            clickBlocker.createPageLoadingMessage();
            // var oDefaults;
            // var oDiv;
            // var oSettings;

            // if (!PLUGIN.spinwheel) {
            //     Modernizr.load({
            //         load: uiGlobal.assets.urls.spinwheel,
            //         callback: function () {
            //             uiMethods.insertLoadingMessage(oOptions);
            //         }
            //     });

            //     return false;
            // }

            // oDefaults = {
            //     bClearBackground: true,
            //     bShowPageOverlay: true,
            //     sMessage: 'Please wait'
            // };
            // oDiv = document.createElement('div');
            // oSettings = uiUtil.extend(true, {}, oDefaults, oOptions);

            // // Add message div to the page
            // oDiv.className = 'loadingMessageCentered';
            // oDiv.setAttribute('tabIndex', '1');
            // oDiv.innerHTML = '<div class="spinnerHolder"></div><p>' + oSettings.sMessage + '</p>';
            // document.body.appendChild(oDiv);
            // // Set focus on it for accessibility purposes
            // oDiv.focus();

            // // Start spinner
            // PLUGIN.spinwheel.start(uiUtil.queryOne('.spinnerHolder'));

            // // Clear background gradient
            // if (oSettings.bClearBackground) {
            //     document.body.style.background = '#fff';
            // }

            // if (oSettings.bShowPageOverlay) {
            //     // Create overlay div
            //     oDiv = document.createElement('div');
            //     oDiv.className = 'loadingMessagePageOverlay';
            //     oDiv.setAttribute('tabIndex', '1');
            //     oDiv.innerHTML = '&nbsp;';
            //     // Add overlay div to page and
            //     document.body.appendChild(oDiv);
            //     oDiv.style.display = 'block';
            // }
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
    }; // end uiMethods.insertLoadingMessage()

    uiMethods.preloader = {
        oContainer: null, // The container <div>
        aAssets: [],      // Assets to be preloaded; this can be prepopulated

        ///<summary>Creates an off-screen container for preloaded assets and adds any assets present in the queue</summary>
        ///<remarks>Should be called at window.load</remarks>
        setup: function _preloader_setup() {
            try {
                var i = uiMethods.preloader.aAssets.length;
                // Create container
                uiMethods.preloader.oContainer = document.createElement('div');
                uiMethods.preloader.oContainer.className = 'cui-hide-from-screen';
                document.body.appendChild(uiMethods.preloader.oContainer);

                // Load anything that's already in the queue
                while (i--) {
                    uiMethods.preloader.addAsset(uiMethods.preloader.aAssets[i]);
                }

                // Empty the queue
                uiMethods.preloader.aAssets = [];
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        },

        ///<summary>Add an asset to the preload container</summary>
        ///<param name='sHTML' type='string' required='true'>An HTML string to be added to the page</param>
        ///<remarks>May be called before or after preload.setup() has run</remarks>
        addAsset: function _preloader_addAsset(sHTML) {
            try {
                if (typeof sHTML !== 'string' || !sHTML) { return false; }

                // If the container has already been set up, add this asset immediately
                if (uiMethods.preloader.oContainer) {
                    uiMethods.preloader.oContainer.innerHTML += sHTML;
                    // uiMethods.preloader.hasRun++;
                }
                // Otherwise, queue it to load when setup() is run
                else {
                    uiMethods.preloader.aAssets.push(sHTML);
                }
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        }
    };

    uiMethods.buttons = {
        pageSetup: function _buttons_pageSetup(){
            try {
                // uiMethods.buttons.mutex.pageSetup();
                // uiMethods.buttons.enabledByValue.pageSetup();
                // uiMethods.buttons.clear.pageSetup();
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        },
        // Mutually-exclusive button handling
        mutex: {
            aButtons: [], // Array of all mutually exclusive buttons and links

            pageSetup: function _buttons_mutex_pageSetup () {
                // // try {
                //     // Collect elements with the appropriate classes. Add to the existing array in case this function is called twice.
                //     uiMethods.buttons.mutex.aButtons = uiMethods.buttons.mutex.aButtons.concat(uiUtil.query('.mutexElement, .hasOverlay'));

                //     if (uiMethods.buttons.mutex.aButtons.length) {
                //         // Setup button event listeners
                //         uiMethods.buttons.mutex.events.pageSetup();

                //         // Load the spinner plugin
                //         if (PLUGIN && !PLUGIN.spinwheel) {
                //             Modernizr.load({
                //                 load: uiGlobal.assets.urls.spinwheel
                //             });
                //         }
                //     }
                // // }
                // // catch (e) {
                //     // uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                // // }
            },
            disableAll: function _disableAll(){

            },
            enableAll: function _disableAll(){

            },
        }, // end uiMethods.buttons.mutex
    };

    uiMethods.iFlowMessages = {
        oJSON: null, // JSON object for iflow messages
        sMsgTypeEnum: {error:'Error', warning:'Warning', info:'Informational', success:'Success'}, // Enumeration for message type
        sDefaultFormErrorMsg: 'Please correct the highlighted errors shown below before continuing.', // Default form errors page message
        sCustomPageMsgClass: 'customPageMsg', // Default class for custom message container

        ///<summary>Displays page and form messages</summary>
        ///<param name="oOptions" type="object" required="true">Settings object to configure how to handle method's functionality</param>
        display: function _iFlowMessages_display (oOptions) {
            try {
                // Check oJSON and settings first
                if (this.oJSON === null) { return false; }
                if (typeof oOptions === 'undefined') { oOptions = {}; }

                // Local variables
                var oSettings = {
                      bAppendPageMsgs: oOptions.bAppendPageMsgs || false, // Appends/overwrites page level messages
                      bAppendFormMsgs: oOptions.bAppendFormMsgs || false, // Appends/overwrites form level messages
                      oFocus: oOptions.oFocus || null // Set focus on specific element by id or dom element
                    };
                var iCounter = 0;
                var jCounter = 0;
                var iPageMsgCnt = 0;
                var iFormMsgCnt = 0;
                var iFormMsgMessagesCnt = 0;
                var sHTML = '';
                var oElem = null;
                var oPageMsgList = null;
                var oFormMsgList = null;
                var aFormMsgs = null;
                var iFormMsgs = 0;
                var oErrorMessages = null;
                var oErrorMessagesUL = null;
                var oFormWrapper = null;
                var oContainer = null;
                var oMessage = null;
                var bNoPageMessages = false;
                var iFormMessagesDisplayed = 0;
                var bLabelFound = false;
                var msgListParent;
                var msgType;
                var msgTypes = [];
                var iconElem;

                let fetaMessageJSON = {
                    "template": "userMessages",
                    "parameters": {
                        "page": [],
                        "field":[]
                    }
                };

                // If JSON is a string object, parse it
                if (typeof this.oJSON === 'string') {
                    if (this.oJSON.length === 0) { return false; }
                    this.oJSON = JSON.parse(this.oJSON); // Use native JSON parsing (if supported by browser) or JavaScript json parser (json.org)
                }

                if (this.oJSON.iFlowMessages === undefined) {
                    return false;
                }

                // -------------
                // PAGE Messages
                // -------------
                if (typeof this.oJSON.iFlowMessages.pageMessages !== 'undefined') {
                    this.oJSON.iFlowMessages.pageMessages.forEach((iflowMessage) => {

                        if(iflowMessage.type && iflowMessage.description){
                            fetaMessageJSON.parameters.page.push(
                                {
                                    "template": "message",
                                    "type": iflowMessage.type,
                                    "text": iflowMessage.description
                                }
                            );
                        }
                    });
                }

                // -------------
                // Field Messages
                // -------------
                if (typeof this.oJSON.iFlowMessages.formMessages !== 'undefined') {
                    //Process field messages

                    this.oJSON.iFlowMessages.formMessages.forEach((iflowMessage) => {
                        let fieldJSON = {
                            "elem":'#'+ iflowMessage.elementId,
                            "messages":[]
                        };

                        if(typeof iflowMessage.messages !== 'undefined'){
                            let validMessage = false;
                            iflowMessage.messages.forEach((fieldMessage) => {

                                if(fieldMessage.type && fieldMessage.description){
                                    fieldJSON.messages.push({
                                        "template": "message",
                                        "type": fieldMessage.type,
                                        "text": fieldMessage.description
                                    });
                                    validMessage = true;
                                }
                            });

                            if(validMessage){
                                fetaMessageJSON.parameters.field.push(fieldJSON);
                            }
                        }
                    });

                }

               if(fetaMessageJSON.parameters.page.length > 0 || fetaMessageJSON.parameters.field.length > 0){
                    feta.processUserMessages(fetaMessageJSON);
                }

                // Set focus
                // if (oSettings.oFocus) {
                //     uiUtil.setFocus(oSettings.oFocus);
                // }
            }
            catch (e) {
            //     journal.log({type: 'error', owner: 'UI', module: 'iflow', submodule: 'uiMethods.iFlowMessages'}, 'Error adding user message');
            //     uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        },

        remove: {
            ///<summary>Removes page messages containing specific text</summary>
            ///<param name="sMessageText" type="String" required="true">Text of the message that will be removed/param>
            pageMessages: {
                withText: function _iFlowMessages_remove_pageMessages_withText(sMessageText) {
                    try {
                        if (!sMessageText || typeof sMessageText !== 'string') { return false; }

                        var oMessagesList = document.getElementById('errorMessagesList');

                        if (!oMessagesList) { return false; }

                        sMessageText = sMessageText.toLowerCase();

                        // Find matching items in the list
                        uiUtil.query('li', oMessagesList).forEach(function (msg) {
                            if (msg.innerHTML.toLowerCase().indexOf(sMessageText) > -1) {
                                msg.parentNode.removeChild(msg);
                            }
                        });

                        // Remove the list itself if it's empty
                        if (!uiUtil.queryOne('li', oMessagesList)) {
                            oMessagesList.parentNode.removeChild(oMessagesList);
                        }
                    }
                    catch (e) {
                        uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                    }
                }
            }
        }
    };

    uiMethods.confirmRemove = function _confirmRemove (sItemName, sCustomText, isSingleItem, callback, callbackArgs) {
        // try {
            let confirmSettings = {};

            if (typeof sItemName === 'string' && sItemName.match(/\w/)) {
                // PLUGIN.tracking.trackEvent('RemoveItem', 'Confirm dialog display', uiGlobal.page.code + '|' + sItemName.replace(/\W/g, ''));
                if (isSingleItem) {
                    confirmSettings.confirmMessage = 'Are you sure you want to remove this ' + sItemName + '?';
                }
                else {
                    confirmSettings.confirmMessage = 'Are you sure you want to remove the selected ' + sItemName + '(s)?';
                }
            }
            else if (typeof sCustomText === 'string' && sCustomText.match(/\w/)) {
                // PLUGIN.tracking.trackEvent('RemoveItem', 'Confirm dialog display', uiGlobal.page.code + '|' + sCustomText.replace(/\W/g, ''));
                confirmSettings.confirmMessage = sCustomText;
            }

            if(confirmSettings.confirmMessage && callback){

                confirmSettings.callback = callback;
                if(callbackArgs){
                    confirmSettings.callbackArgs = callbackArgs;
                }

                feta.confirm.create(confirmSettings);
            }

            return false;
        // }
        // catch (e) {
            // uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            // return false;
        // }
    };

     ///<summary>Handles the removal of an item</summary>
    ///<param name='oItemKey' type='DOM Object'>Data to identify what row (or rows) needs to be deleted. Can be string, integer, etc.</param>
    ///<param name='sItemName' type='string'>The name of the item which will be appended to the standard confimation message</param>
    ///<param name='sCustomText' type='string'>Optional alternative text to display in the confirm() popup rather than the default text</param>
    ///<param name='oFunction' type='Function Object'>Function to call after user approves the removal</param>
    ///<param name='oDeclineCallback' type='Function Object'>Function to call after user declines the removal</param>
    uiMethods.removeItem = function _removeItem (oItemKey, sItemName, sCustomText, oFunction, oDeclineCallback) {
        // try {

            if(oDeclineCallback){
                journal.log({type: 'warning', owner: 'UI', module: 'iflow', submodule: 'uiMethods.removeItem'}, 'Declination callback used.');
            }

            uiMethods.confirmRemove(sItemName, sCustomText, true, oFunction, oItemKey);


            // // User affirmed the action
            // if () {

            //     // If the callback is a string, check if it's the name of a global function
            //     if (typeof oFunction === 'string' && typeof window[oFunction] === 'function') {
            //         oFunction = window[oFunction];
            //     }

            //     if (typeof oFunction === 'function') {
            //         oFunction(oItemKey);
            //     }
            //     else{
            //         journal.log({type: 'warning', owner: 'UI', module: 'iflow', submodule: 'uiMethods.removeItem'}, 'Callback function passed not a function:' + oFunction);
            //     }
            // }
            // // User denied the action
            // else {

            //     uiMethods.buttons.mutex.enableAll();
            //     // Prevent the button from taking action
            //     return false;
            // }
        // }
        // catch (e) {
            // uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            // return false;
        // }
    };



    // Ajax functionality
    // ------------------
    // Version 3.1
    uiMethods.ajax = {
        sMethodEnum: {get : 'get', post : 'post'}, // Enumeration for the type of http request
        sSpinImageEnum: { sizeM : 'ajaxLoaderM.gif', sizeS : 'ajaxLoaderS.gif'}, // Enumeration for ajax spinning wheel image
        aXmlHTTP: [], // Array for xmlHTTP requests for handling concurrent calls
        aTimeout: [], // Array for xmlHTTP requests' timeout functions
        oDefaultSettings: {
            sUrl: '', // The URL the request will be made to
            iTimeout: 1200000, // How long to wait before considering the request to be a timeout (20-minute default)
            sMethod: 'post', // Method value, defaulted to POST
            oParams: null, // Parameters sent to call (can be passed as string or form objects)
            sSpinElemId: '', // The container's id to fill with ajax overlay
            sOverlayClass: 'ajaxOverlay', // Class for the spinning wheel
            sSpinImage: 'sizeM', // Spinning wheel image
            sFocusElemId: '', // The id for the html container to set focus to if success
            sErrorMessage: 'Our system can\'t display the requested information at this time. Please try again later.', // Displays custom/default error message
            onComplete: function (){ uiMethods.ajax.onComplete(arguments); }, // Callback pattern for a completed request
            onSuccess: function (){ uiMethods.ajax.onSuccess(arguments); }, // Callback pattern for a successful request
            onError: function (){ uiMethods.ajax.onError(arguments); }, // Callback pattern for an errored request
            onTimeout: function (){ uiMethods.ajax.onTimeout(arguments); }, // Callback pattern for a timed-out request
            onSuccessXtra: function (){ uiMethods.ajax.onSuccessXtra(arguments); } // Callback pattern for extra custom code
        },

        ///<summary>Sends XMLHttpRequest to the server and updates page with response when applicable</summary>
        ///<param name="oOptions" type="object" required="true">Settings object to configure how to handle both the request/response.</param>
        execute: function _ajax_execute(oOptions) {
            try {
                var oSettings = uiUtil.extend(true, {}, uiMethods.ajax.oDefaultSettings, oOptions);
                var oUpdateElem = null; // Container to add ajax overlay object to
                var oOverlay = null; // Overlay object for ajax spinning wheel
                var sParams = ''; // Parameters string to be built and passed along with request
                var iIndex = 0; // Index for xmlHTTP array

                // Get index for this XMLHttpRequest call
                while (uiMethods.ajax.aXmlHTTP[iIndex]) {
                    iIndex++;
                }

                // Check if ajax overlay needs to be added
                if (typeof oSettings.sSpinElemId === 'string' && oSettings.sSpinElemId.length > 0)  {
                    // Get overlay's element container and set spinning wheel
                    oUpdateElem = document.getElementById(oSettings.sSpinElemId);

                    if (oUpdateElem) {
                        if (oUpdateElem.style.position === '' || oUpdateElem.style.position === 'static') {
                            oUpdateElem.style.position = 'relative';
                        }

                        oOverlay = document.createElement('div');
                        oOverlay.setAttribute('id', 'ajaxOverlay_' + iIndex);
                        oOverlay.innerHTML = '<img src="' + uiGlobal.sIFLOWImagesPath + uiMethods.ajax.sSpinImageEnum[oSettings.sSpinImage] + '" alt="Loading...please wait."/>';
                        oUpdateElem.appendChild(oOverlay);
                        // Add class this way for IE7 compatibility
                        oOverlay.className = oSettings.sOverlayClass;
                    }
                }

                // Append additional parameters either from form object or string
                sParams = uiMethods.ajax.getFormParams(oSettings);

                // Get XMLHttpRequest
                uiMethods.ajax.aXmlHTTP[iIndex] = (window.XMLHttpRequest) ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP'); // XMLHttpRequest object
                uiMethods.ajax.aTimeout[iIndex] = setTimeout(function () { oSettings.onTimeout(uiMethods.ajax.aXmlHTTP[iIndex], oSettings); uiMethods.ajax.aXmlHTTP[iIndex].abort(); }, oSettings.iTimeout);

                // Open asynchronous request and establish a connection to the server
                if (oSettings.sMethod === this.sMethodEnum.post) {
                    uiMethods.ajax.aXmlHTTP[iIndex].open(oSettings.sMethod, oSettings.sUrl, true);
                    /// Send the proper header information along with the request
                    uiMethods.ajax.aXmlHTTP[iIndex].setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                    // uiMethods.ajax.aXmlHTTP[iIndex].setRequestHeader('Content-length', sParams.length);
                    // uiMethods.ajax.aXmlHTTP[iIndex].setRequestHeader('Connection', 'close');
                    uiMethods.ajax.aXmlHTTP[iIndex].send(sParams);
                }
                else {
                    uiMethods.ajax.aXmlHTTP[iIndex].open(oSettings.sMethod, oSettings.sUrl + '?' + sParams, true);
                    uiMethods.ajax.aXmlHTTP[iIndex].send(null);
                }

                // Check for the state once request is executed
                if (uiMethods.ajax.aXmlHTTP[iIndex]) {
                    uiMethods.ajax.aXmlHTTP[iIndex].onreadystatechange = function () {
                        try {
                            var iXmlHTTPLength = uiMethods.ajax.aXmlHTTP.length;

                            // Find whether xmlRequest is ready or not
                            while (iXmlHTTPLength--) {
                                  if (uiMethods.ajax.aXmlHTTP[iXmlHTTPLength] && uiMethods.ajax.aXmlHTTP[iXmlHTTPLength].readyState === 4) {
                                    // Request didn't time out
                                    clearTimeout(uiMethods.ajax.aTimeout[iXmlHTTPLength]);

                                    // Check for request status and call callback methods
                                      if (uiMethods.ajax.aXmlHTTP[iXmlHTTPLength].status >= 200 && uiMethods.ajax.aXmlHTTP[iXmlHTTPLength].status < 300) {
                                        oSettings.onSuccess(uiMethods.ajax.aXmlHTTP[iXmlHTTPLength], oSettings);
                                    }
                                    else {
                                        oSettings.onError(uiMethods.ajax.aXmlHTTP[iXmlHTTPLength], oSettings);
                                    }

                                    // Remove ajax overlay, if any
                                    if (oUpdateElem) {
                                        oUpdateElem.removeChild(oOverlay);
                                    }

                                    oSettings.onComplete(uiMethods.ajax.aXmlHTTP[iXmlHTTPLength], oSettings);
                                    // Avoid memory leaks
                                    uiMethods.ajax.aXmlHTTP[iXmlHTTPLength] = null;
                                }
                            }
                        }
                        catch (e) {
                            uiMethods.ajax.aXmlHTTP[iIndex].abort();
                        }
                    };
                }
            }
            catch (e) {
                // Don't write to the error log here until the log server is set up in all environments.
                //   If it is called and there is no servlet to receive the call, it will create an
                //   infinite loop of `uiMethods.ajax.execute` calls that all fail.
            }
        },

        // Callbacks (default; can be overriden)
        ///<summary>Default code to run when ajax call is completed</summary>
        ///<param name="oArguments" required="true">Parameters needed to run this method</param>
        onComplete: function _ajax_onComplete( /*oArguments*/ ) {
            try {
                // Default code to run when ajax call is completed
            }
            catch (e) { }
        },

        ///<summary>Default code to run when ajax call is successful</summary>
        ///<param name="oArguments" required="true">Parameters needed to run this method</param>
        ///<remarks>For JSON responses samples please contact UISupport</remarks>
        onSuccess: function _ajax_onSuccess(oArguments) {
            try {
                var xmlHTTP = oArguments[0];
                var oSettings = oArguments[1];
                var oJSON = null;
                var oHtmlContainer = null;
                var iHtmlContainers = 0;
                var bHtmlChanged = false;

                try {
                    // Response is a JSON object
                    oJSON = JSON.parse(xmlHTTP.responseText);

                    // If html is returned, display it in appropriate containers
                    if (uiUtil.typeOf(oJSON.htmlContent) === 'array') {
                        iHtmlContainers = oJSON.htmlContent.length;
                        while (iHtmlContainers--) {
                            oHtmlContainer = document.getElementById(oJSON.htmlContent[iHtmlContainers].elementId);
                            if (oHtmlContainer) {
                                try {
                                    if (bHtmlChanged === false) {
                                        bHtmlChanged = true;
                                    }

                                    oHtmlContainer.innerHTML = oJSON.htmlContent[iHtmlContainers].html;
                                }
                                catch (eee) {
                                    // Handle innerHTML for table elements in IE:  http://www.ericvasilik.com/2006/07/code-karma.html
                                    uiUtil.modifyTableInnerHtml(oHtmlContainer, oJSON.htmlContent[iHtmlContainers].html);
                                }
                            }
                        }
                    }

                    // If user messages are included in JSON response, display them
                    if (typeof oJSON.userMessages !== 'undefined') {
                        uiMethods.iFlowMessages.oJSON = oJSON.userMessages;
                        uiMethods.iFlowMessages.display();
                    }
                }
                catch (e) { }
                finally {
                    // Set focus if applicable
                    uiUtil.setFocus(oSettings.sFocusElemId);
                    // Re-register events and such on the new html responsed
                    if (bHtmlChanged === true) {
                        uiMethods.pageSetup();
                    }
                    // Run post successful code if requested
                    oSettings.onSuccessXtra(xmlHTTP, oSettings);
                }
            }
            catch (e) { }
        },

        ///<summary>Default code to run when ajax call errored</summary>
        ///<param name="oArguments" required="true">Parameters needed to run this method</param>
        onError: function _ajax_onError( /*oArguments*/ ) {
            // Default code to run when ajax call errored
        },

        ///<summary>Default code to run when ajax call timed out</summary>
        ///<param name="oArguments" required="true">Parameters needed to run this method</param>
        onTimeout: function _ajax_onTimeout( /*oArguments*/ ) {
            // Default code to run when ajax call timed out
        },

        ///<summary>Default code to run when ajax call is successful and extra custom code needs to run</summary>
        ///<param name="oArguments" required="true">Parameters needed to run this method</param>
        onSuccessXtra: function _ajax_onSuccessXtra( /*oArguments*/ ) {
            // Default code to run when ajax call is successful and extra functionality needs to run
        },

        ///<summary>Gets parameters from form object</summary>
        ///<param name="oSettings" required="true">Settings object from ajax call</param>
        getFormParams: function _ajax_getFormParams(oSettings) {
            var sParams = ''; // Parameters string

            try {
                // Local variables
                var iElems = 0;          // Form's elements
                var iSelectMultiple = 0; // Multi-select control length
                var reRegExp = null;     // Regular expression object

                if (typeof oSettings.oParams !== 'undefined' && oSettings.oParams !== null) {
                    if (/^form$/i.test(oSettings.oParams.nodeName)) {
                        // Loop through form elements and append to query string (sParams)
                        iElems = oSettings.oParams.elements.length;
                        while (iElems--) {
                            switch (oSettings.oParams.elements[iElems].type) {
                                case 'select-one':
                                    sParams += '&' + oSettings.oParams.elements[iElems].id + '=' + oSettings.oParams.elements[iElems].options[oSettings.oParams.elements[iElems].selectedIndex].value;
                                    break;

                                case 'select-multiple':
                                    sParams += '&' + oSettings.oParams.elements[iElems].id + '=';
                                    iSelectMultiple = oSettings.oParams.elements[iElems].options.length;
                                    // Loop and concatenate multiple selected values using a pipe '|'
                                    while (iSelectMultiple--) {
                                        if (oSettings.oParams.elements[iElems].options[iSelectMultiple].selected) {
                                            sParams += oSettings.oParams.elements[iElems].options[iSelectMultiple].value + '|';
                                        }
                                    }

                                    if (sParams.substring(sParams.length-1) === '|') {
                                        sParams = sParams.substring(0, sParams.length-1);
                                    }

                                    break;

                                case 'checkbox':
                                    sParams += '&' + oSettings.oParams.elements[iElems].id + '=';
                                    if (oSettings.oParams.elements[iElems].checked) {
                                      sParams += oSettings.oParams.elements[iElems].value;
                                    }
                                    else {
                                      sParams += '';
                                    }

                                    break;

                                case 'radio':
                                    if (oSettings.oParams.elements[iElems].checked) {
                                      sParams += '&' + oSettings.oParams.elements[iElems].name + '=' + oSettings.oParams.elements[iElems].value;
                                    }

                                    break;

                                default:
                                    sParams += '&' + oSettings.oParams.elements[iElems].id + '=' + oSettings.oParams.elements[iElems].value;
                                    break;
                            }
                        }
                    }
                    else {
                        // Append params sent as string object (i.e. &a=1&b=2)
                        if (typeof oSettings.oParams === 'string') {
                            sParams = '&' + oSettings.oParams;
                        }
                    }
                }

                // Append timestamp to query string for caching purposes
                sParams += '&Ts=' + (new Date().getTime()).toString();

                // Format sParams to end up with a well-formed query string
                if (sParams.substring(0, 1) === '&') {
                    sParams = sParams.substring(1);
                }

                // Clean just in case adding params in multiple ways
                reRegExp = new RegExp('&&', 'g'); // Regular expression
                sParams = sParams.replace(reRegExp, '&');
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
            finally {
                return sParams;
            }
        }
    }; // end uiMethods.ajax


    //Copy functionality
    uiMethods.copyMethods = {
        copyValue: function _copyMethods_copyValue (oOptions) {          
            if(!oOptions || !oOptions.srcId || !oOptions.destId){
                return;
            }

            let srcId = oOptions.srcId;
            let destId = oOptions.destId;

            let srcElement = document.getElementById(srcId);
            let destElement = document.getElementById(destId);            
            
            if(srcElement){                
                let srcValue;

                if(srcElement.nodeType == 1){
                    switch(srcElement.nodeName.toUpperCase()){
                        case "SPAN":
                            srcValue = srcElement.textContent;
                        break;

                        case "INPUT":
                            srcValue = srcElement.value;
                        break;

                        default:
                    }                    
                }

                if(srcValue && destElement){
                    if(destElement.nodeType == 1){
                        switch(destElement.nodeName.toUpperCase()){
                            case "SPAN":
                                destElement.textContent = srcValue;
                            break;

                            case "INPUT":
                                //Only copy value if input is empty.
                                if(destElement.value == ""){
                                    destElement.value = srcValue;

                                    //Handle fields managed by react. 
                                    var event;
                                    if(typeof(Event) === 'function') {
                                        event = new Event('input', { bubbles: true});
                                    }else{
                                        event = document.createEvent('Event');
                                        event.initEvent('input', true, true);
                                    }

                                    event.simulated = true;
                                    destElement.dispatchEvent(event);    
                                }                                
                            break;

                            default:
                        }                    
                    }
                }
                else{
                    //invalid value or no dest element
                }
            }
            else{
                //No src element
            }

        },
        clearValue: function _copyMethods_clearValue(oOptions){
            if(!oOptions || !oOptions.destId){
                return;
            }

            let destId = oOptions.destId;            
            let destElement = document.getElementById(destId);

            if(destElement.nodeType == 1){
                switch(destElement.nodeName.toUpperCase()){
                    case "SPAN":
                        destElement.textContent = "";
                    break;

                    case "INPUT":
                        destElement.value = "";

                        //Handle fields managed by react. 
                        var event;
                        if(typeof(Event) === 'function') {
                            event = new Event('input', { bubbles: true});
                        }else{
                            event = document.createEvent('Event');
                            event.initEvent('input', true, true);
                        }
                        
                        event.simulated = true;
                        destElement.dispatchEvent(event);    
                    break;

                    default:
                }                    
            }
            else{
                //invalid dest element
            }

        },
        checkIfSelfPopulated: function _copyMethods_checkIfSelfPopulated(oOptions){
            if(!oOptions || !oOptions.controlId || !oOptions.srcId){
                return;
            }
            let controlElem = document.getElementById(oOptions.controlId);
            let srcElem = document.getElementById(oOptions.srcId);
            
            if(controlElem && srcElem){
                //Use click to trigger any react render hooks that are not triggered when updating checked property.
                if(srcElem.value == ""){
                    if(controlElem.checked != false){
                        controlElem.click();   
                    }
                }
                else if(srcElem.value != ""){
                    if(controlElem.checked != true){
                        controlElem.click();   
                    }
                }
            }
        },        
        copyOnCheck: function _copyMethods_copyOnCheck (oOptions){
            if(oOptions.controlId){
                let controlElement = document.getElementById(oOptions.controlId);
                
                if(controlElement){
                    if(controlElement.checked == true){
                        uiMethods.copyMethods.copyValue(oOptions);
                    }
                    else if(controlElement.checked == false){
                        uiMethods.copyMethods.clearValue(oOptions);    
                    }
                }
            }            
        }

    };

    uiMethods.fieldValues = {
        events:{
            pageSetup: function _fieldValues_pageSetup(){

            },
            sectionSetup: function _fieldValues_sectionSetup(){

            }
        },

        clear: function _fieldValues_clear(items, isContainer){
            try {
                var itemsType = uiUtil.typeOf(items);
                var elems = [];

                // DOM element
                if (itemsType === 'element') {
                    if (isContainer === true) {
                        // It's a container, so find all input fields inside it
                        elems = uiUtil.query('input, select, textarea', items);
                    }
                    else {
                        // Just this element
                        elems.push(items);
                    }
                }
                // Array of elements or selectors
                else if (itemsType === 'array') {
                    items.forEach(function (i) {
                        // Weed out empty strings, null, etc
                        if (!i) { return false; }

                        // Selector
                        if (typeof i === 'string') {
                            elems = elems.concat(uiUtil.query(i));
                        }
                        // Element
                        else if (typeof i === 'object') {
                            elems.push(i);
                        }
                    });
                }
                // Node list
                else if (itemsType === 'nodelist') {
                    elems = uiUtil.toArray(items);
                }
                // Selector
                else if (itemsType === 'string') {
                    elems = uiUtil.query(items);
                }
                else {
                    return -1;
                }

                // Clear all fields
                elems.forEach(function (elem) {
                    // If the element is a <label>, find the associated input
                    // This is used in iflow1 when the input custom tag does not allow adding arbitrary classes (i.e., 'clearField')
                    if (/^label$/i.test(elem.nodeName)) {
                        elem = document.getElementById(elem.getAttribute('for') || elem.attributes['for'].nodeValue); // Second method is for IE7
                    }

                    uiUtil.val(elem, '');
                });

                return elems.length;
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        },

        copy: function _fieldValues_copy(){

        },

        copySingle: function _fieldValues_copySingle(){

        },

        copyMultiple: function _fieldValues_copyMultiple(){

        }


    };

    /**
     * @public
     *
     * Public API
    */
    return {
        init,
    };
});


