/*jshint esversion: 6 */
/**
 * feta mutex module
 *
 * @description          Contains methods for handling mutex elements
 * @version              0.1.0
 *
 * @return {object}      Public API for module
 */

define(['clickBlocker'], function(clickBlocker) {

	const NAMESPACE = 'mutex';
    const VERSION = '1.0.0';
	const SUFFIX = "mutex";

    const CLASS_NAMES = {
        mutexElem: "iflow-mutex-element",        
        mutexSetup: "iflow-mutex-setup",
        mutexActive: "iflow-mutex-active",
        pageBlocker: "iflow-mutex-page-blocker"
    };

	const mutexReferences = [];	
	const pageBlockerTimeout = 60000;

	let hasInitalized = false;
	let pageBlocker = null;

	//Public funcions
	const init = () => {
		if(hasInitalized){
			return;
		}    	

    	let mutexElements = document.querySelectorAll("."+CLASS_NAMES.mutexElem);
    	
    	if(mutexElements.length > 0){
    		for(let i=0;i<mutexElements.length;i++){
    			let mutexElem = mutexElements[i];
    			addMutex(mutexElem);
    		}
    	}

    	hasInitalized = true;
    };

    const addMutex = (elem) =>{
    	mutexReferences.push(elem);
		elem.addEventListener("click", _events.triggerMutex);
		elem.classList.add(CLASS_NAMES.mutexSetup);
    };

    const disableBlocker = (elem) =>{
    	clickBlocker.removeClickBlocker(elem);
    }

    const disableAll = () =>{

    };

    const enableAll = () =>{

    };

    //Private functions

    const _blockPageFor = (triggerElem) =>{
    	
    	if(pageBlocker){
    		//Page blocker is already created. Check if active.

    		let onPage = document.querySelector("."+CLASS_NAMES.pageBlocker);

    		if(onPage){
    			return;	
    		}
    		else{
    			document.body.appendChild(pageBlocker);

		    	setTimeout(function(){
		    		document.body.removeChild(pageBlocker);
		    		disableBlocker(triggerElem);
		    	}, pageBlockerTimeout);
    		}    		
    	}
    	else{
    		pageBlocker = document.createElement('div');
	    	pageBlocker.classList.add(CLASS_NAMES.pageBlocker);
	    	pageBlocker.style.position = "absolute";
	    	pageBlocker.style.top = "0";
	    	pageBlocker.style.bottom = "0";
	    	pageBlocker.style.left = "0";
	    	pageBlocker.style.right = "0";
	    	pageBlocker.style.zIndex = 100;
	    	pageBlocker.addEventListener("click",function(evt){
	    		evt.stopPropagation();
	    		evt.preventDefault();
	    	});
	    	document.body.appendChild(pageBlocker);

	    	setTimeout(function(){
	    		document.body.removeChild(pageBlocker);
	    		disableBlocker(triggerElem);
	    	}, pageBlockerTimeout);
    	}    	
    };

    const _removeFromPage = () =>{

    };

    const _events = {};

    _events.pageSetup = () =>{

    };

    _events.elemSetup = (elem) =>{

    };

    _events.triggerMutex = (evt) =>{
    	let mutexElem = evt.target;
    	clickBlocker.addClickBlocker(mutexElem);
    	_blockPageFor(mutexElem);
    }

    return {
        init: init,
        addMutex: addMutex,
        enableAll: enableAll,
        disableAll: disableAll
    };

});
