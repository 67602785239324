var uiUtil = {

    // Functionality related to iTags (help icons)
    helpIcons: {
        oReturnTo: null,

        /**
         * Scrolls the page to a particular element (caller referenced by this.oReturnTo)
         * Used when the same instruction is linked to by multiple help icons
         */
        returnToElement: function () {
            try {
                var pos;

                // Make sure local variable has a value
                if (!this.oReturnTo) { return true; }

                // If oToScroll is an ID, we can just just set the URL's hash, which has other benefits
                if (typeof this.oReturnTo === 'string') {
                    window.location.hash = this.oReturnTo;

                    return false;
                }
                // If it's an object, we need to scroll to it
                else if (typeof this.oReturnTo === 'object') {
                    pos = this.getElementPosition(this.oReturnTo);

                    // Need to use a timeout, otherwise the scrolling never happens, not sure why
                    setTimeout(function () { window.scrollTo(pos[0], pos[1]-10); }, 0);

                    return false;
                }
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
            finally {
                this.oReturnTo = '';
            }
        }
    },

    // CSS-related methods
    css: {
        oStyleSheet: null, // In-page `<style>` block

        /**
         * Add CSS rule(s) to the current page
         * @param  {String}  sRule  CSS rules, written as "p { color:red; }"
         */
        addRule: function _addRule(sRule) {
            try {
                var oStyleSheet;

                if (!document.styleSheets || typeof sRule !== 'string') { return false; }

                // The first time this function is called, a new <style> block must be created
                if (!uiUtil.oStyleSheet) {
                    oStyleSheet = document.createElement('style');
                    oStyleSheet.type = 'text/css';
                    oStyleSheet.id = 'ui-style-sheet';
                    uiUtil.queryOne('head').appendChild(oStyleSheet);
                    uiUtil.oStyleSheet = oStyleSheet;
                }

                // Add rules to the style sheet
                if (uiUtil.oStyleSheet.sheet) { // Modern browsers
                    uiUtil.oStyleSheet.appendChild(document.createTextNode(' ' + sRule));
                }
                else if (uiUtil.oStyleSheet.styleSheet) { // IE8-
                    uiUtil.oStyleSheet.styleSheet.cssText += ' ' + sRule;
                }
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        },

        // These functions are defined at the end of `uiUtil`
        hasClass: null,
        addClass: null,
        removeClass: null,

        ///<summary>Simultaneously add/remove classnames from an element</summary>
        ///<param name="oSender" type="DOM Object">The DOM element to be changed</param>
        ///<param name="sClasses" type="string">Space-separated class names</param>
        ///<returns>Updated class list, or false upon failure</returns>
        ///<remark>Useful with CSS transitions and to prevent FOUC</remark>
        toggleClass: function _toggleClass(oSender, sClasses) {
            try {
                var aClasses;
                var sClassName;
                var sClass;
                var i;
                var cleanClassNames = function (sClassNames) {
                        try {
                            return sClassNames.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' ');
                        } catch (e) { }
                    };

                // Array of the given classes
                aClasses = cleanClassNames(sClasses).split(' ');

                // The element's current class list
                sClassName = ' ' + cleanClassNames(oSender.className) + ' ';

                // Add and remove classes from the class list
                i = aClasses.length;
                while (i--) {
                    sClass = ' ' + aClasses[i] + ' ';

                    if (sClassName.indexOf(sClass) < 0) {
                        sClassName += sClass;
                    }
                    else {
                        sClassName = sClassName.replace(sClass, ' ');
                    }
                }

                // Apply all changes at once
                oSender.className = cleanClassNames(sClassName);

                return oSender.className;
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                return false;
            }
        },

        ///<summary>Returns the computed style for a given property</summary>
        ///<param name="oSender" type="DOM Object">The DOM element to be tested</param>
        ///<param name="sProperty" type="string">Hyphen-separated property name</param>
        ///<returns>String; will be "auto" if the property was not set</returns>
        ///<remark>Adapted from jQuery 1.7.1</remark>
        getStyleValue: function _getStyleValue(oSender, sProperty) {
            try {
                if (!oSender || !sProperty) { return ''; }

                var sReturnValue;
                var oStyle;
                var oDefaultView;
                var oComputedStyle;
                var sLeftValue;
                var sLeftRuntimeStyle;

                // Modern browsers
                if ( document.defaultView && document.defaultView.getComputedStyle ) {
                    if ( (oDefaultView = oSender.ownerDocument.defaultView) &&
                        (oComputedStyle = oDefaultView.getComputedStyle(oSender, null)) ) {
                      sReturnValue = oComputedStyle.getPropertyValue(sProperty);

                      if (sReturnValue === '' && !uiUtil.contains(oSender.ownerDocument.documentElement, oSender) ) {
                          sReturnValue = oSender.style ? oSender.style[sProperty] : '';
                      }
                    }
                }
                // IE 8-
                else if (document.documentElement.currentStyle) {
                    sProperty = uiUtil.hyphenToCamelCase(sProperty);
                    sReturnValue = oSender.currentStyle && oSender.currentStyle[sProperty];
                    oStyle = oSender.style;

                    // Avoid setting sReturnValue to empty string here so we don't default to auto
                    if (sReturnValue === null && oStyle) {
                        sReturnValue = oStyle[sProperty];
                    }

                    // From the awesome hack by Dean Edwards
                    // http://erik.eae.net/archives/2007/07/27/18.54.15/#comment-102291

                    // If we're not dealing with a regular pixel number
                    // but a number that has a weird ending, we need to convert it to pixels
                    if ( !/^-?\d+(?:px)?$/i.test(sReturnValue) && /^-?\d/.test(sReturnValue) ) {
                        // Remember the original values
                        sLeftValue = oStyle.left;
                        sLeftRuntimeStyle = oSender.runtimeStyle && oSender.runtimeStyle.left;

                        // Put in the new values to get a computed value out
                        if (sLeftRuntimeStyle) {
                            oSender.runtimeStyle.left = oSender.currentStyle.left;
                        }

                        oStyle.left = sProperty === 'fontSize' ? '1em' : ( sReturnValue || 0 );
                        sReturnValue = oStyle.pixelLeft + 'px';

                        // Revert the changed values
                        oStyle.left = sLeftValue;

                        if (sLeftRuntimeStyle) {
                            oSender.runtimeStyle.left = sLeftRuntimeStyle;
                        }
                    }

                    if (sReturnValue === '') {
                        sReturnValue = 'auto';
                    }
                }

                return sReturnValue;
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                return '';
            }
        } // end uiUtil.css.getStyleValue
    }, // end uiUtil.css

    // Basic query selector, defined after `uiUtil`
    query: null,

    /**
     * Converts a node list or element list into a proper array
     *
     * @param   {Nodelist}  aList  The list to be converted
     *
     * @return  {Array}            The same list as an array
     */
    toArray: function _toArray(aList) {
        try {
            var aArray = [];
            var i;

            try {
                // This method will unpredictably fail on some types of lists
                aArray = Array.prototype.slice.call(aList);
            } catch (err) { }

            // Manually copy the contents to a new array
            if (!aArray.length && aList.length) {
                i = 0;

                while (i < aList.length) {
                    aArray.push(aList[i]);
                    i++;
                }
            }

            return aArray;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return [];
        }
    },

    ///<summary>Object extender</summary>
    ///<param name="oTarget" type="Object or Boolean">If Boolean and true, a deep copy will be performed</param>
    ///<remark>Any number of objects are accepted as arguments; they will be copied into each other from last to first</remark>
    ///<remark>Adapted from jQuery, via https://github.com/mythz/jquip/blob/master/src/jquip.js</remark>
    extend: function _extend() {
        var oTarget = {};

        try {
            var opt;
            var name;
            var oSource;
            var copy;
            var copyIsArr;
            var clone;
            var aArgs = arguments;
            var iArgs = aArgs.length;
            var i = 1;
            var deep = false;

            oTarget = aArgs[0] || {};

            if (uiUtil.typeOf(oTarget) === 'boolean') {
                deep = oTarget;
                oTarget = aArgs[1] || {};
                i = 2;
            }

            if (uiUtil.typeOf(oTarget) !== 'object' && uiUtil.typeOf(oTarget) !== 'function') {
                if (uiUtil.typeOf(oTarget) === 'array') {
                    oTarget = [];
                }
                else {
                    oTarget = {};
                }
            }

            if (iArgs === i) {
                oTarget = this;
                i--;
            }

            for (; i < iArgs; i++) {
                if ((opt = aArgs[i]) !== null) {
                    for (name in opt) {
                        oSource = oTarget[name];
                        copy = opt[name];
                        if (oTarget === copy) { continue; }
                        if (deep && copy && (uiUtil.isPlainObject(copy) || (copyIsArr = uiUtil.typeOf(copy) === 'array'))) {
                            if (copyIsArr) {
                                copyIsArr = false;
                                clone = oSource && uiUtil.typeOf(oSource) === 'array' ? oSource : [];
                            }
                            else {
                                clone = oSource && uiUtil.isPlainObject(oSource) ? oSource : {};
                            }

                            oTarget[name] = uiUtil.extend(deep, clone, copy);
                        }
                        else if (copy !== undefined) {
                            // Don't let an empty string override a truthy non-string value (e.g. `abc:""` won't replace `abc:123`)
                            if ( !(typeof copy === 'string' && copy === '' && typeof oTarget[name] !== 'string' && !!oTarget[name]) ) {
                                oTarget[name] = copy;
                            }
                        }
                    }
                }
            }
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
        finally {
            return oTarget;
        }
    },

    toTitleCase: function _toTitleCase (str) {
        try {
            var _convert = function _toTitleCase_convert (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            };

            return str.replace(/\w\S*/g, _convert);
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return str;
        }
    },

    url: {
        sGenericAuthErrorUrl: 'genericAuthError.jsp',
        rAppBaseUrl: /(http[s]?\:\/\/[\w+\.]+\.gov\/\w+\/).*/i,

        ///<summary>Returns the current app's base URL, eg https://www.tax.gov/iflow/ABCD/ </summary>
        getAppBaseUrl: function () {
            try {
                if (uiUtil.url.rAppBaseUrl.test(window.location.href)) {
                    return uiUtil.url.rAppBaseUrl.exec(window.location.href)[1];
                }

                return '';
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                return '';
            }
        }
    },

    /**
     * Gets the ancestor element that matches the provided selector(s)
     *
     * Note that it may match the given element itself. This function works like `jQuery.closest()`.
     *
     * @param   {DOM Object}   oSender       The element whose ancestors will be searched
     * @param   {String}       targetValue   Simple selector(s) (tag name, class names, ID, etc), space-separated
     */
    getParentElem: function _getParentElem (oSender, targetValue) {
        try {
            var aTargetValues = [];
            var oNode;
            var i;
            // Comparison function
            var elemTest =
                    uiUtil.matchesSelector ||
                    function _getParentElem_matches(oElem, sValue) {
                        if (sValue.indexOf('.') === 0) {
                            return uiUtil.css.hasClass(oElem,sValue.substr(1));
                        }
                        else if (sValue.indexOf('#') === 0) {
                            return (oElem.id && oElem.id === sValue.substr(1));
                        }
                        else {
                            return oElem.nodeName.toLowerCase() === sValue;
                        }
                    };

            // Normalize input values and place them into an array so we can loop through them
            aTargetValues = targetValue.replace(/^\s+|\s+$/g,'').replace(/\s+/g,' ').split(' ');

            // Loop through each ancestor node, beginning with oSender
            oNode = oSender;
            while (oNode && !/^body$|^html$/i.test(oNode.nodeName)) {
                // Check current node against each target value
                i = aTargetValues.length;
                while (i--) {
                    if (elemTest(oNode, aTargetValues[i])) {
                        return oNode;
                    }
                }

                oNode = oNode.parentNode;
            }

            return null;
        }
        catch (e) {
          uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
    },

    // Defined after `uiUtil`
    matchesSelector: null,

    ///<summary>Determines whether an element matches any of the given selecors</summary>
    ///<param name="oElem" type="DOM Element">The element to inspect</param>
    ///<param name="sSelectors" type="String">Comma-separated list of tag-, class-, and/or id-based selectors (no attributes or pseudo-classes)</param>
    ///<returns>Boolean</returns>
    ///<remarks>If a selector includes an ID, only the ID is evaluated; any tag or class name is ignored</remarks>
    matches: function _matches (oElem, sSelectors) {
        try {
            var wasMatchFound = false;
            // Tests whether an element has all specified classes
            var _hasAllClasses = function __hasAllClasses(aClasses) {
                    var iMatches;

                    if (!aClasses.length) { return false; }

                    iMatches = 0;
                    aClasses.forEach(function (sClass) {
                        if (uiUtil.css.hasClass(oElem, sClass)) {
                            iMatches++;
                        }
                    });

                    return iMatches === aClasses.length;
                };

            if (uiUtil.typeOf(oElem) !== 'element' || typeof sSelectors !== 'string') { return false; }

            // Trim spaces in and around each selector
            sSelectors = uiUtil.trim(sSelectors, ',');

            if (!sSelectors.length) { return false; }

            // Test each selector against the element
            sSelectors.split(',').forEach(function _matches_loop(sSelector) {
                var tagName;
                var classNames;

                // Don't bother testing this selector if a match was already found
                if (wasMatchFound) { return false; }

                tagName = '';
                classNames = [];

                // ID
                if (sSelector.indexOf('#') > -1) {
                    // Strip out tag name and trailing class names
                    sSelector = sSelector.split('#')[1].split('.')[0];

                    if (uiUtil.establishElementId(oElem) === sSelector) {
                        wasMatchFound = true;
                    }
                }
                // Class name(s)
                else if (sSelector.indexOf('.') === 0) {
                    // Trim leading and trailing dots to avoid having empty class names when calling .split()
                    classNames = sSelector.replace(/^\.+|\.+$/g, '').split('.');

                    if (_hasAllClasses(classNames)) {
                        wasMatchFound = true;
                    }
                }
                // Class names(s) with a tag name
                else if (sSelector.indexOf('.') > 0) {
                    // Get class name(s) and trim trailing dot
                    classNames = sSelector.replace(/\.+$/g, '').split('.');

                    // Separate tag name from classes
                    tagName = classNames.splice(0, 1)[0].toLowerCase();

                    // Test tag name and class name(s)
                    if (oElem.nodeName.toLowerCase() === tagName && _hasAllClasses(classNames)) {
                        wasMatchFound = true;
                    }
                }
                // Tag name, no class
                else if (/^\w+$/.test(sSelector)) {
                    if (oElem.nodeName.toLowerCase() === sSelector.toLowerCase()) {
                        wasMatchFound = true;
                    }
                }
            });

            return wasMatchFound;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return false;
        }
    },

    ///<summary>Set an element's ID to either its existing ID or a random, unique one</summary>
    ///<param name="oElem" type="DOM Object">Optional element that needs an ID</summary>
    ///<returns>String: the element's current or new ID</returns>
    establishElementId: function _establishElementId (oElem, opts) {
        try {
            var id = '';
            var label;

            // See if the element already has an ID
            if (oElem && typeof oElem === 'object') {
                if (oElem.id && typeof oElem.id === 'string') {
                    return oElem.id;
                }
            }

            // Attempt to use a label-type element associated with the given element
            if (opts && oElem && typeof opts === 'object' && opts.useLabel === true) {
                label = uiUtil.getParentElem(oElem, '.line .selDepFields .linePn');

                // In a line
                if (label) {
                    label = uiUtil.queryOne('.label, .labelWrap, .rBtnWrapper', label);
                    // Try to find a label
                    label = uiUtil.queryOne('label, legend, .visibleLabel', label) || label;
                }
                // In a table cell, probably
                else {
                    label = uiUtil.getParentElem(oElem, 'th td');
                }

                id = uiUtil.getValueAsText(label)
                                // Remove spaces, non-word characters, and accessibility text
                                .replace(/\s+/g, '')
                                .replace(/\W/g, '')
                                .replace(/requiredfields?/gi, '')
                                .replace(/moreinformation/gi, '');

                // Trim down to 50 chars
                id = id.substr(0, 50);
            }

            // Randomly generate an ID
            if (!id) {
                id += uiUtil.getRandomString();
            }

            // Try again if this ID is already in use
            if (document.getElementById(id)) {
                id = uiUtil.establishElementId(null);
            }

            // Set elements's ID
            if (oElem && typeof oElem === 'object') {
                oElem.id = id;
            }

            return id;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
    },

    getRandomString: function _getRandomString() {
        try {
            var letterPool = 'abcdefghijklmnopqrstuvwxyz';
            var i = 4;
            var id = '';

            // Get four random letters
            while (i--) {
                id += letterPool.charAt(Math.floor(Math.random() * letterPool.length));
            }

            // Add four digits based on the current time (milliseconds)
            id += (new Date()).getTime().toString().substr(9);

            return id;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
    },

    /**
     * Get text nodes for a given element
     * @param   {Element}  parent   DOM element to search within
     * @param   {Object}   options  Optional settings. `recursive`: boolean, whether to search through all child elements
     * @return  {Array}             All text nodes
     */
    getTextNodes: function _getTextNodes (parent, options) {
        try {
            var results = [];
            var settings = {
                    recursive: true // Recursively search through all child nodes and get their text nodes as well
                };

            // Default to `document` if nothing was passed
            if (typeof parent === 'undefined') {
                parent = document;
            }

            // Make sure the argument is an element
            if (!parent || uiUtil.typeOf(parent) !== 'element') {
                return [];
            }

            // Apply settings
            if (uiUtil.typeOf(options) === 'object') {
                // Since there is only one option at the moment, check it manually for performance reasons. With many options, use `uiUtil.extend` instead.

                if (typeof options.recursive === 'boolean') {
                    settings.recursive = options.recursive;
                }
            }

            // Loop through child nodes
            for (parent = parent.firstChild; parent; parent = parent.nextSibling) {
                // Check the node type

                // Text node
                if (parent.nodeType === 3) {
                    results.push(parent);
                }
                // Other types of nodes may contain their own text nodes, so search recursively
                else {
                    results = results.concat(uiUtil.getTextNodes(parent));
                }
            }

            return results;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return [];
        }
    },

    ///<summary>Sets focus on particular element</summary>
    ///<param name='oElem' type='DOM Object' required='true'>Element object or element's id to set focus to</param>
    setFocus: function _setFocus(oElem) {
        try {
            var oElement;

            // Check if sId exists
            if (typeof oElem === 'undefined') { return false; }

            oElement = oElem;

            if (typeof oElement === 'string') {
                oElement = document.getElementById(oElement);
            }

            // Get element to set focus to
            if (oElement) {
                oElement.focus();
            }
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
    },

    /**
     * Adds an event listener to an element
     * @param   {Element}  elem          DOM element
     * @param   {String}   eventName     Event type (may be custom, see `isCustom` argument)
     * @param   {String}   functionName  Name of callback function
     * @param   {Boolean}  isCustom      Whether the event is custom
     * @return  {Boolean}                Success/failure
     */
    attachEvent: function _attachEvent (elem, eventName, functionName, isCustom) {
        try {
            var currentEventHandler;

            // This argument seems to be missing often in apps that are actively developed and it fills the error log with entries that aren't very helpful at tracking down the problem. So instead we do nothing here so that the dev/testers will notice the page not working (i.e. not responding to click events). (CP 10/19/2015)
            if (!elem) {
                return false;
            }

            if (elem.addEventListener) { // Modern browsers, plus IE 9
                elem.addEventListener(eventName, functionName, false);
            }
            else if (elem.attachEvent) { // IE 6-8
                if (!isCustom) {
                    elem.attachEvent('on' + eventName, functionName);
                }
                else {
                    // IE can't handle custom events, so instead we must watch the element's
                    //     `onpropertychange` event. When that event's `propertyName` matches `functionName`,
                    //     `functionName` will be called.
                    if (!elem[eventName]) {
                        elem[eventName] = 0;
                    }

                    elem.attachEvent('onpropertychange', function (evt) {
                        if (evt.propertyName === eventName) {
                            functionName(evt);
                        }
                    });
                }
            }
            else { // Older browsers
                currentEventHandler = elem['on' + eventName];

                if (!currentEventHandler) {
                    elem['on' + eventName] = functionName;
                }
                else {
                    elem['on' + eventName] = function (e) { currentEventHandler(e); functionName(e); };
                }
            }

            return true;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return false;
        }
    },

    /**
     * Removes an event listener from an element
     * @param   {Element}  elem          DOM element
     * @param   {String}   eventName     Event type (may be custom, see `isCustom` argument)
     * @param   {String}   functionName  Name of callback function
     * @param   {Boolean}  isCustom      Whether the event is custom
     * @return  {Boolean}                Success/failure
     */
    detachEvent: function _detachEvent (elem, eventName, functionName, isCustom) {
        try {
            var currentEventHandler;

            // See explanation in `uiUtil.attachEvent()`. (CP 10/19/2015)
            if (!elem) {
                return false;
            }

            if (elem.removeEventListener) { // Modern browsers, plus IE 9
                elem.removeEventListener(eventName, functionName, false);
            }
            else if (elem.detachEvent) { // IE 6-8
                if (!isCustom) {
                    elem.detachEvent('on' + eventName, functionName);
                }
                else {
                    // IE can't handle custom events. See explanation in `uiUtil.attachEvent()`
                    elem.detachEvent('onpropertychange', function (evt) {
                        if (evt.propertyName === eventName) {
                            functionName(evt);
                        }
                    });
                }
            }
            else { // Older browsers, inline event handlers
                currentEventHandler = elem['on' + eventName];

                if (currentEventHandler) {
                    elem.removeAttribute('on' + eventName);
                }
            }

            return true;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
    },

    ///<summary>Stops event bubbling and prevents default action on caller object from running</summary>
    ///<param name="eEvt" type="Event object">The event object</param>
    stopEvent: function _stopEvent (eEvt) {
        try {
            if (eEvt) {
                uiUtil.preventDefault(eEvt);
                uiUtil.stopPropagation(eEvt);
            }
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
    },

    preventDefault: function (eEvt) {
        if (typeof eEvt.preventDefault === 'function') {
            eEvt.preventDefault();
        }
        else {
            eEvt.returnValue = false; // IE6 - IE8
        }
    },

    stopPropagation: function (eEvt) {
        if (typeof eEvt.stopPropagation === 'function') {
            eEvt.stopPropagation();
        }
        else {
            eEvt.cancelBubble = true; // IE6 - IE8
        }
    },

    ///<summary>Returns event's target HTML object</summary>
    ///<param name="eEvt" type="DOMEvent">The event object</param>
    ///<returns>HTML object</returns>
    getHTMLObjFromEvent: function _getHTMLObjFromEvent(eEvt) {
        var oTarg;

        try {
            // Create cross browser event (if null)
            if (!eEvt) { eEvt = window.event; }

            // Get HTML object
            if (eEvt.target) {  // Non IE
                oTarg = eEvt.target;
            }
            else {
                if (eEvt.srcElement) { // IE
                    oTarg = eEvt.srcElement;
                }
            }

            if (oTarg.nodeType === 3) { oTarg = oTarg.parentNode; } // defeat Safari bug
        }
        catch (e) {
            oTarg = null;
        }
        finally {
            return oTarg;
        }
    },

    ///<summary>Determines if an object is a plain Javascript object (no DOM elements, constructors, etc)</summary>
    ///<param name="oObject" type="Object" required="true">An object</param>
    ///<returns>Boolean</returns>
    ///<remark>From jQuery, via https://github.com/mythz/jquip/blob/master/src/jquip.js</remark>
    isPlainObject: function _isPlainObject(oObject) {
        try {
            var oHasOwnProp = Object.prototype.hasOwnProperty;
            var sKey;
            var fIsWindow = function (oObj) {
                    return oObj && uiUtil.typeOf(oObj) === 'object' && 'setInterval' in oObj;
                };

            if (!oObject || uiUtil.typeOf(oObject) !== 'object' || oObject.nodeType || fIsWindow(oObject)) {
                return false;
            }

            try {
                if (oObject.constructor && !oHasOwnProp.call(oObject, 'constructor') &&
                    !oHasOwnProp.call(oObject.constructor.prototype, 'isPrototypeOf')) {
                    return false;
                }
            }
            catch(ee) {
                return false;
            }

            for (sKey in oObject) { }

            return sKey === undefined || oHasOwnProp.call(oObject, sKey);
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return false;
        }
    },

    /**
     * Identifies a variable's actual type, digging deeper into special object types
     * This acts the same as the native `typeof` operator except it digs deeper into objects to look for special types. For example, objects such as null, array, and date are identified accordingly. DOM objects will return 'element', 'node', or 'nodelist'. Only plain objects, or objects that we're not testing for, will return 'object'.
     * @param   {Mixed}   thing  Variable to test
     * @return  {String}         Lowercase name of the variable's type
     */
    typeOf: function _typeOf(thing) {
        try {
            var nativeType = typeof thing;
            var objProtoType;
            var objTypes;
            var i;

            // Basic, non-object types (string, function, undefined, etc)
            if (nativeType !== 'object') {
                return nativeType;
            }

            // Objects

            // Check for `null`
            if (thing === null) {
                return 'null';
            }

            objProtoType = Object.prototype.toString.call(thing);

            // Special JavaScript types that inherit from Object
            objTypes = ['Array', 'Date', 'RegExp'];
            i = 3;
            while (i--) {
                if (objProtoType === '[object ' + objTypes[i] + ']') {
                    return objTypes[i].toLowerCase();
                }
            }

            // DOM element
            if (typeof HTMLElement === 'object' && thing instanceof HTMLElement) { // DOM Level 2
                return 'element';
            }
            if (typeof thing.nodeName === 'string' && thing.nodeType === 1) {
                return 'element';
            }

            // DOM node
            if (typeof Node === 'object' && thing instanceof Node) { // DOM Level 2
                return 'node';
            }
            if (typeof thing.nodeType === 'number' && typeof thing.nodeName === 'string') {
                return 'node';
            }

            // Node list
            if (/^\[object (HTMLCollection|NodeList|Object)\]$/.test(objProtoType) &&
                typeof thing.length === 'number' &&
                typeof thing.item !== 'undefined' &&
                (thing.length === 0 || (typeof thing[0] === 'object' && thing[0].nodeType > 0))) {
                return 'nodelist';
            }

            if (objProtoType === '[object Object]') {
                return 'object';
            }

            // Some other type of object, or a plain object
            return 'object';
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return '';
        }
    },

    ///<summary>Get or set the value of an updatable field</summary>
    ///<param name="oElem" type="DOM element" required="true">An input field, select, or textarea</param>
    ///<param name="mvalue" type="DOM node">Optional value, which the input will be set to</param>
    ///<returns>The element's value if getting (see remark); Boolean (success/failure) if setting; null if the operation failed</returns>
    ///<remark>When getting the value, it will return an empty string for value-less input fields, and a Boolean for check boxes and radio buttons.</remark>
    val: function _val (oElem, mValue) {
        try {
            var bSetValue = false;
            var i;

            if (!oElem || uiUtil.typeOf(oElem) !== 'element') { return null; }

            // If setting a value, convert a null or numeric value to a string
            if (typeof mValue !== 'undefined') {
                // Convert to string
                mValue += '';
                bSetValue = true;
            }

            // Inputs and textareas
            if (/^input$|^textarea$|^option$/i.test(oElem.nodeName)) {
                // Checkable types
                if (/checkbox|radio/i.test(oElem.type)) {
                    if (bSetValue) {
                        oElem.checked = !!mValue;
                        return true;
                    }
                    else {
                        return !!oElem.checked || false;
                    }
                }
                // Other inputs
                else {
                    if (bSetValue) {
                        oElem.value = mValue;
                        return true;
                    }
                    else {
                        return oElem.value || '';
                    }
                }
            }
            // Selects
            else if (/^select$/i.test(oElem.nodeName)) {
                if (bSetValue) {
                    i = 0;
                    while (i < oElem.options.length) {
                        if (oElem.options[i].value === mValue) {
                            oElem.options[i].selected = true;
                            return true;
                        }

                        i++;
                    }

                    if(oElem.options.length>0){
                        oElem.options[0].selected = true;
                        oElem.selectedIndex = 0;
                    }

                }
                else {
                    return oElem.options[oElem.selectedIndex].value || '';
                }
            }
            // Not an input field
            // Note: certain functions rely on `uiUtil.val` returning `null` for non-input fields, so for example don't change this to return the element's innerHTML
            else {
                return null;
            }
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return null;
        }
    },

    isUpdatable: function (elem) {
        try {
            if (!elem || uiUtil.typeOf(elem) !== 'element') { return null; }

            // Elements with values
            if (/^input$|^textarea$|^option$|^select$/i.test(elem.nodeName)) {
                return true;
            }
            else {
                return false;
            }
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return null;
        }
    },

    ///<summary>Determines whether one node is contained within another node</summary>
    ///<param name="a" type="DOM node">The node which will be tested as the outer/parent node</param>
    ///<param name="b" type="DOM node">The node which will be tested as the inner/child node</param>
    ///<returns>Boolean; true if a contains b</returns>
    ///<remark>Adapted from jQuery 1.7.1</remark>
    contains: function (a, b) {
        var bReturnValue = false;

        try {
            if (document.documentElement.contains) {
                bReturnValue = a !== b && (a.contains ? a.contains(b) : true);
            }
            else if (document.documentElement.compareDocumentPosition) {
                bReturnValue = !!(a.compareDocumentPosition(b) & 16);
            }
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
        finally {
            return bReturnValue;
        }
    },

    ///<summary>Converts a dash-separated string to camelCase</summary>
    ///<param name="sString" type="String">A string, usually a CSS property</param>
    ///<returns>Camel case string</returns>
    ///<remark>Adapted from jQuery 1.7.1</remark>
    hyphenToCamelCase: function (sString) {
        var sReturnValue = '';

        try {
            var fCamelCase = function (aAll, sLetter) {
                  return (sLetter + '').toUpperCase();
                };

            // Remove ms prefixes, which don't have a leading dash
            sReturnValue = sString.replace(/^-ms-/, 'ms-').replace(/-([a-z]|[0-9])/ig, fCamelCase);
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
        finally {
            return sReturnValue;
        }
    },

    ///<summary>Returns element's (x,y) position in pixels with respect to the page's viewport</summary>
    ///<param name="oSender" type="DOM Object">The sender object</param>
    ///<returns>Array with x=[0] and y=[1]</returns>
    getElementPosition: function _getElementPosition(oSender) {
        try {
            var left = 0;
            var top = 0;

            if (oSender.offsetParent) {
                left = oSender.offsetLeft;
                top = oSender.offsetTop;
                oSender = oSender.offsetParent;

                while (oSender) {
                    left += oSender.offsetLeft;
                    top += oSender.offsetTop;

                    oSender = oSender.offsetParent;
                }
            }

            return [left,top];
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
    },

    ///<summary>Returns an array with the [horizontal,vertical] size of the viewport in pixels</summary>
    getViewPort: function () {
        try {
            var x = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            var y = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

            return [x, y];
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
    },

    ///<summary>Returns the distance in pixels that the page has been scrolled down (i.e., size of the area that's out of view)</summary>
    getScrollTop: function () {
        try {
            var iScrollTop = document.body.scrollTop;

            if (!iScrollTop) {
                if (window.pageYOffset) {
                    iScrollTop = window.pageYOffset;
                }
                else {
                    iScrollTop = (document.body.parentElement) ? document.body.parentElement.scrollTop : 0;
                }
            }

            return iScrollTop;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
    },

    ///<summary>Returns array of elements matching the specified CSS class(es)</summary>
    ///<param name="oNode" type="DOM Object">The sender object to search within</param>
    ///<param name="sTag" type="string">The type of element to search within (optional)</param>
    ///<param name="sClassName" type="string">The class name(s) to look for (one or more)</param>
    ///<returns>Returns an array of elements with the specified class name(s)</returns>
    ///<remarks>Temporary until fmk enhances custom tags</remarks>
    getElementsByClassName: function _getElementsByClassName(oNode, sTag, sClassNames) {
        try {
            var i;
            var j;
            var aReturnElements = [];
            var aClasses;
            var aElements;

            if (!sClassNames || typeof sClassNames !== 'string') { return false; }

            // Set defaults if these parameters weren't specified
            if (typeof oNode !== 'object') { oNode = document; }

            if (!sTag) { sTag = '*'; }

            // Trim class list, reduce whitespace to single spaces, then split on spaces
            aClasses = sClassNames.replace(/^\s+|\s+$/g,'').replace(/\s+/g,' ').split(' ');

            // Get all elements to search through
            aElements = oNode.getElementsByTagName(sTag);

            // Loop through elements and check each one's classes against the list
            // The outer loop cannot be 'reversed' like while(i--), causes problems with clickCheckbox()
            i = 0;
            while (i < aElements.length) {
                j = aClasses.length;
                while (j--) {
                    if (uiUtil.css.hasClass(aElements[i],aClasses[j])) {
                        aReturnElements.push(aElements[i]);
                        // Quit the classes loop so this element doesn't get added again if it matches another class
                        break;
                    }
                }

                i++;
            }

            return aReturnElements;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
    },

    /**
     * Finds elements matching the specified attribute name or attribute selector
     * Search string may be given as `foo`, `[foo]`, `[foo="bar"]`, etc
     * @param   {String}  sQueryString  Attribute selector or attribute name
     * @param   {Element} oNode         Optional node to search within
     * @return  {Array}                 Elements that match the query
     */
    getElementsByAttribute: function _getElementsByAttribute(sQueryString, oNode) {
        try {
            var aReturnElements = [];
            var aSelectors;
            var rBracketsLeft;
            var rBracketsRight;
            var rTagName;
            var rAttrNameOnly;
            var rWithValue;

            if (typeof sQueryString !== 'string' || !sQueryString) {
                return aReturnElements;
            }

            oNode = oNode || document;

            // Native query
            if (oNode.querySelectorAll) {
                // Check if a simple attribute name was passed
                if (/^[\w\-]+$/.test(sQueryString)) {
                    // Convert to a CSS selector (`abc` --> `[abc]`)
                    sQueryString = '[' + sQueryString + ']';
                }

                return uiUtil.toArray(oNode.querySelectorAll(sQueryString));
            }

            // Define regular expressions
            rBracketsLeft = /^([\w\-]+)?\[/;
            rBracketsRight = /\]$/;
            rTagName = /^([\w\-]+)\[/;
            rAttrNameOnly = /^([\w\-]+)((\W?)\=[\"\'][\"\'])?$/; // With or without empty value string
            rWithValue = /^([\w\-]+)(\W?)\=\"(.+)\"$/;

            // Separate selectors
            aSelectors = uiUtil.trim(sQueryString, ',').split(',');

            aSelectors.forEach(function _getElementsByAttribute_forEach(sSelector) {
                var sTagName = '*';
                var sAttrName;
                var sAttrValue;
                var aResults;
                var numResults;
                var elemVal;
                var i;

                // Tag name
                if (rTagName.test(sSelector)) {
                    sTagName = rTagName.exec(sSelector)[1];
                }

                // Remove brackets
                sSelector = sSelector.replace(rBracketsLeft, '').replace(rBracketsRight, '');

                // Attribute name only
                if (rAttrNameOnly.test(sSelector)) {
                    sAttrName = rAttrNameOnly.exec(sSelector)[1];
                }
                else if (rWithValue.test(sSelector)) {
                    aResults = rWithValue.exec(sSelector);
                    sAttrName = aResults[1];
                    sAttrValue = aResults[3];
                }
                else {
                    // Nothing to go by, skip to the next selector
                    return false;
                }

                // Get all elements to search through
                aResults = oNode.getElementsByTagName(sTagName);

                i = 0;
                numResults = aResults.length;
                while (i < numResults) {
                    // Check for attribute existence
                    if (uiUtil.hasAttribute(aResults[i], sAttrName)) {
                        // Check for value if specified
                        if (sAttrValue) {
                            elemVal = aResults[i].getAttribute(sAttrName);

                            // IE7 has trouble with `label[for]`
                            if (elemVal === null && sAttrName === 'for' && aResults[i].htmlFor) {
                                elemVal = aResults[i].htmlFor;
                            }
                            // IE7 returns numeric values as numbers but the search term is a string
                            else if (typeof elemVal === 'number') {
                                elemVal = elemVal.toString();
                            }

                            if (sAttrValue === elemVal) {
                                // Found a match
                                aReturnElements.push(aResults[i]);
                            }
                        }
                        // No value necessary
                        else {
                            aReturnElements.push(aResults[i]);
                        }
                    }

                    i++;
                }
            });

            return aReturnElements;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return [];
        }
    },

    // Defined after `uiUtil`
    hasAttribute: null,

    ///<summary>Trims leading and trailing spaces from a string</summary>
    ///<param name="string" type="string" required="true">The string to be trimmed</param>
    ///<param name="separator" type="string" required="false">Character(s) that occurs within the string which will also be trimmed</param>
    ///<remarks>Example, using a comma separator: " a, b ,, c ," ==> "a,b,c"</remarks>
    trim: function _trim(string, separator) {
        try {
            var rSeparatorInner;
            var rSeparatorBegin;
            var rSeparatorEnd;

            // Default case, just trim leading and ending spaces
            if (!separator || typeof separator !== 'string') {
                return string.replace(/^\s+|\s+$/g, '');
            }

            // Also trim around the specified separator
            rSeparatorInner = new RegExp('\\s*' + separator + '+\\s*', 'g');
            rSeparatorBegin = new RegExp('^\\s*' + separator + '+\\s*', 'g');
            rSeparatorEnd = new RegExp('\\s*' + separator + '+\\s*$', 'g');

            return string.replace(/^\s+|\s+$/g, '')
                         .replace(rSeparatorInner, separator)
                         .replace(rSeparatorBegin, '')
                         .replace(rSeparatorEnd, '');
        }
        catch (e) {
            return string;
        }
    },

    ///<summary>Creates and returns a JSON object based of a delimited key/value pairs string</summary>
    ///<param name="sKeyValueString" type="string" required="true">The string containing the key/value pairs data</param>
    ///<param name="sDelimiter" type="string" required="true">String delimiter to use as key/value pairs separator</param>
    ///<returns>JSON object with "key" and "value" objects</remarks>
    ///<remarks>String must be something like: a=1|jadeAction=SOME_ACTION|fieldName=fieldValue</remarks>
    getJSONFromDelimitedKeyValueString: function _getJSONFromDelimitedKeyValueString(sKeyValueString, sDelimiter) {
        var oJSON = null;
        try {
            // Validations
            if (typeof sKeyValueString !== 'string' || typeof sDelimiter !== 'string') { return false; }
            if (sKeyValueString.length === 0 || sDelimiter.length === 0) { return false; }

            // Local variables
            var aKeyValue = sKeyValueString.split(sDelimiter); // Split string to array
            var iKeyValue = aKeyValue.length;
            var iCnt = 0;
            var sJSON = '{ "keyValuePairs" : [ ';
            var sEqualSign;
            var sKeyValuePair;

            // Loop through key/value pairs (a=1)
            while(iCnt < iKeyValue) {
                sKeyValuePair = aKeyValue[iCnt];
                sEqualSign = sKeyValuePair.indexOf('=');

                if (sEqualSign > -1) {
                    // If key and value are delimited by an equal sign, add it to string
                    sJSON += '{ "key" : "' + sKeyValuePair.substring(0, sEqualSign) + '", "value" : "' + sKeyValuePair.substring(sEqualSign+1) + '" },';
                }

                iCnt++;
            }

            sJSON = sJSON.substring(0, sJSON.length-1) + ' ] }';

            // Parse string to JSON object
            oJSON = JSON.parse(sJSON);
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
        finally {
            // Return JSON object
            return oJSON;
        }
    },

    ///<summary>Modifies the contents of a table using innerHTML on IE, especially for ajax responses</summary>
    ///<param name="oElem" type="DOM Object" required="true">The DOM element that needs to be updated</param>
    ///<param name="sNewHtml" type="string" required="true">The HTML code to update element with</param>
    ///<remarks>Handle table elements in IE issues:  http://www.ericvasilik.com/2006/07/code-karma.html</remarks>
    modifyTableInnerHtml: function (oElem, sNewHtml) {
        var oSpan = null;

        try {
            // Local variables
            var sTag = '';
            var sWrapperHtml = '';
            var sWrapperHtmlEnum = {
                    table:'<table>',
                    tbody:'<table><tbody>',
                    thead:'<table><thead>',
                    tfoot:'<table><tfoot>',
                    tr:'<table><tbody><tr>',
                    th:'<table><tbody><tr><th>',
                    td:'<table><tbody><tr><td>'
                };
            var iAttributes;
            var oElemAttributes;
            var i;

            if (typeof oElem === 'undefined' || oElem === null) { return false; }
            if (typeof sNewHtml !== 'string') { return false; }

            sTag = oElem.nodeName.toLowerCase();
            sWrapperHtml = sWrapperHtmlEnum[sTag];

            // Create span to hold new html temporarily
            oSpan = document.createElement('span');
            oSpan.setAttribute('class', 'hidden');
            // Add proper table structure to the html so that IE will accept the replacement
            oSpan.innerHTML = sWrapperHtml + sNewHtml;
            document.body.appendChild(oSpan);

            // Before replacing the contents, make sure the container element keeps its attributes
            try {
                if (oElem.hasAttributes()) { // IE7 doesn't support `.hasAttributes` (note the "S" on the end)
                    oElemAttributes = oSpan.getElementsByTagName(sTag)[0];

                    if (oElemAttributes) {
                        iAttributes = oElem.attributes.length;
                        i = 0;
                        while(i < iAttributes) {
                            oElemAttributes.setAttribute(oElem.attributes[i].name, oElem.attributes[i].value);
                            i++;
                        }
                    }
                }
            }
            catch (e) {
            }

            // Replace appropriate child by using the properly constructed oSpan contents
            if (oElem.parentNode) {
                oElem.parentNode.replaceChild(oSpan.getElementsByTagName(sTag)[0], oElem);
            }
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
        finally {
            // Destroy span
            document.body.removeChild(oSpan);
        }
    },

    errorHandling: {
        ///<summary>JavaScript log object</summary>
        jsLog: {
            ///<summary>Adds entry to JavaScript log</summary>
            ///<param name='oError' type='Error object'>The error object</param>
            ///<param name='oArguments' type='Array'>Array of arguments from failing callee function</param>
            addEntry: function (oError, oArguments) {
                try {
                    var sDomain = document.domain;
                    var sXML;
                    var iArgsCnt;
                    var iArguments;
                    var oArgument;
                    var oH1;
                    var oH2;
                    var oDivTPInfo;
                    var sLocationHref;
                    var sLocationHostname;
                    var sSubdomain;
                    var sIflowAppId;
                    var sFunction;

                    // Validations
                    if (typeof oError === 'undefined') { return false; }
                    if (typeof oArguments === 'undefined') { return false; }

                    // Quit if we're not on a server
                    if (sDomain.indexOf('.gov') === -1) {
                        return false;
                    }

                    // Initialize variables
                    oArgument = null;
                    sSubdomain = '';
                    sIflowAppId = '';

                    // Wrapper
                    sXML  = '<?xml version="1.0" encoding="utf-8"?><ExceptionContainer>';
                    sXML += '<Exception date="' + (new Date()) + '">';

                    // <Error> segment
                    // ---------------------
                    sXML += '<Error>';

                    // <ErrorObject> segment
                    sXML += '<ErrorObject>';
                    sXML += '<Message>' + oError.message + '</Message>';
                    sXML += '<Description>' + oError.description + '</Description>';
                    sXML += '<Number>' + oError.number + '</Number>';
                    sXML += '<Name>' + oError.name + '</Name>';
                    sXML += '<FileName>' + oError.fileName + '</FileName>';
                    sXML += '<LineNumber>' + oError.lineNumber + '</LineNumber>';

                    // <ErrorObject><StackTrace> segment
                    sXML += '<StackTrace>';
                    sXML += '<Raw>' + oError.stack + '</Raw>';
                    sXML += '<Formatted>' + this.getStackTrace(oError, oArguments) + '</Formatted>';
                    sXML += '</StackTrace>';
                    sXML += '</ErrorObject>';

                    // <Callee> segment
                    sXML += '<Callee>';
                    sXML += '<FunctionName>' + oArguments.callee.caller + '</FunctionName>';

                    // <Callee><Arguments> segment
                    sXML += '<Arguments>';
                    iArguments = oArguments.length;
                    iArgsCnt = 0;
                    while (iArgsCnt < iArguments) {
                        oArgument = oArguments[iArgsCnt++];
                        sXML += '<Argument>';

                        // Array
                        if (Object.prototype.toString.call(oArgument) === '[object Array]') {
                            sXML += '<Type>array</Type><Properties><Id></Id><Name></Name><Value></Value><Tag></Tag><Type></Type><Length>' + oArgument.length + '</Length><FunctionName></FunctionName></Properties>';
                        }
                        // Null
                        else if (oArgument === null) {
                            sXML += '<Type>null</Type><Properties><Id></Id><Name></Name><Value></Value><Tag></Tag><Type></Type><Length></Length><FunctionName></FunctionName></Properties>';
                        }
                        // Object, Function or Primitive
                        else {
                            sXML += '<Type>' + typeof oArgument + '</Type>';

                            if (typeof oArgument === 'object') {
                                sXML += '<Properties><Id>' + oArgument.id + '</Id><Name>' + oArgument.name + '</Name><Value>' + oArgument.value + '</Value><Tag>' + oArgument.tagName + '</Tag><Type>' + oArgument.type + '</Type><Length></Length><FunctionName></FunctionName></Properties>';
                            }
                            else if (typeof oArgument === 'function') {
                                sFunction = oArgument.toString();
                                sXML += '<Properties><Id></Id><Name></Name><Value></Value><Tag></Tag><Type></Type><Length>' + oArgument.length + '</Length><FunctionName><![CDATA[' + sFunction.substring(sFunction.indexOf('function'), sFunction.indexOf(')') + 1) + ']]></FunctionName></Properties>';
                            }
                            else {
                                sXML += '<Properties><Id></Id><Name></Name><Value>' + oArgument.toString() + '</Value><Tag></Tag><Type></Type><Length></Length><FunctionName></FunctionName></Properties>';
                            }
                        }
                        sXML += '</Argument>';
                    }

                    sXML += '</Arguments>';
                    sXML += '</Callee>';
                    sXML += '</Error>';

                    // <Environment> segment
                    // ---------------------
                    sXML += '<Environment scriptVersion="' + uiGlobal.sVersion + '">';

                    // <Environment><Browser> segment
                    sXML += '<Browser>';
                    sXML += '<Name>' + uiUtil.browser.sName + '</Name>';
                    sXML += '<Version>' + uiUtil.browser.iVersion + '</Version>';
                    sXML += '<OS>' + uiUtil.browser.sOS + '</OS>';
                    sXML += '<UserAgent>' + navigator.userAgent + '</UserAgent>';
                    sXML += '</Browser>';

                    // <Environment><Application> segment
                    sLocationHref = window.location.href;
                    sXML += '<Application><URL>' + sLocationHref + '</URL>';

                    sLocationHostname = window.location.hostname;
                    if (sLocationHostname.length > 0) {
                        // Subdomain
                        if (sLocationHostname.indexOf('.') > -1) {
                            sSubdomain = sLocationHostname.substring(0, sLocationHostname.indexOf('.'));
                        }
                        else {
                            sSubdomain = sLocationHostname;
                        }

                        // iFlow app id
                        if (sLocationHref.length > sLocationHostname.length) {
                            sIflowAppId = sLocationHref.substring(sLocationHref.indexOf(sLocationHostname) + sLocationHostname.length + 1);

                            if (sIflowAppId.indexOf('/') > -1) {
                                sIflowAppId = sIflowAppId.substring(0, sIflowAppId.indexOf('/'));
                            }
                        }
                    }

                    sXML += '<Subdomain>' + sSubdomain + '</Subdomain><Id>' + sIflowAppId + '</Id><Name>';
                    oH1 = document.getElementsByTagName('h1');

                    if (oH1.length > 0) {
                        sXML += oH1[0].innerHTML;
                    }

                    sXML +=  '</Name><PageTitle>';
                    oH2 = document.getElementsByTagName('h2');

                    if (oH2.length > 0) {
                        sXML += oH2[0].innerHTML;
                    }

                    sXML += '</PageTitle><TaxpayerInfo>';
                    oDivTPInfo = document.getElementById('taxpayer-info');

                    if (oDivTPInfo) {
                        sXML += '<![CDATA[' + oDivTPInfo.innerHTML + ']]>';
                    }
                    sXML += '</TaxpayerInfo></Application></Environment></Exception></ExceptionContainer>';

                    // Replace certain characters for readability
                    sXML = sXML.replace(/>undefined</g, '><').replace(/\n/g, ' ').replace(/\t/g, ' ').replace(/\s+/g, ' ');

                    // Call server to save log entry
                    // [Test] uiMethods.ajax.execute({sUrl:'http://dvm.int.nystax.gov/UILG/UILGLogMessage.jsp', oParams:'MESSAGE=' + uiUtil.base64.encode(sXML) + '&BASE=64'});

                    uiMethods.ajax.execute({
                        sUrl: '//' + sDomain + '/UILG/UILGLogMessage.jsp',
                        oParams:'MESSAGE=' + uiUtil.base64.encode(sXML) + '&BASE=64'
                    });

                    // feta.fetch({
                    //     url: '//' + sDomain + '/UILG/UILGLogMessage.jsp',
                    //     params:'MESSAGE=' + uiUtil.base64.encode(sXML) + '&BASE=64'
                    // });


                }
                catch (e) {
                    /*DEBUG - START*/
                    console.log(e);
                    /*DEBUG - END*/
                }
            },

            ///<summary>Returns string with stack trace</summary>
            ///<param name="oError" type="Error object">The error object</param>
            ///<param name="oArguments" type="Array">Array of arguments passed to failing callee function</param>
            getStackTrace: function (oError, oArguments) {
                var sStack = '';

                try {
                    // Local variables
                    var oFunction = oArguments.callee.caller;
                    var sFunction = '';

                    // Browsers that support stack
                    if (oError.stack) {
                        sStack = oError.stack;
                    }
                    else {
                        // Other browsers
                        while (oFunction) {
                            sFunction = oFunction.toString();
                            sStack += sFunction.substring(sFunction.indexOf('function'), sFunction.indexOf(')') + 1) + ' >> ' || 'anonymous >> ';
                            oFunction = oFunction.caller;
                        }

                        if (sStack.length > 4) {
                            sStack = sStack.substring(0, sStack.length-4);
                        }
                    }
                }
                catch (e) { }
                finally {
                    return sStack;
                }
            }
        },

        getErrorObj: function (sMsg, sName) {
            var err = new Error();

            try {
                err.message = sMsg;
                err.name = sName;
            }
            catch (e) { }
            finally {
                return err;
            }
        }
    },

    // http://www.quirksmode.org/js/detect.html (modified version by UI team)
    browser: {
        sName: '',
        iVersion: 0,
        sOS: '',
        sVersionSearchString: '',

        // Browsers
        isOldIE:   false,
        isNewIE:   false,
        isIE7:     false,
        isFirefox: false,
        isChrome:  false,
        isIOS:     false,
        isAndroidBrowser: false,

        // Device characteristics
        isResponsive:     false,
        isSmallScreen:    false,

        // Browser support
        supports: {
            animation:   false,
            // notSelector: false,
            sticky:      false
        },

        eventNames: {
            transitionEnd: 'transitionend'
        },

        init: function _browser_init() {
            try {
                var ua = navigator.userAgent;

                this.sName = this.searchString(this.oBrowserData) || 'Unknown browser';
                this.iVersion = this.searchVersion(ua) || this.searchVersion(navigator.appVersion) || -1;
                this.sOS = this.searchString(this.oOSData) || 'Unknown OS';

                // Browser tests

                // Chrome on Android
                if (/Chrome/.test(ua) && /Android\s(\d[\.\d]*)/.test(ua)) {
                    this.sName = 'Chrome';
                }

                // Chrome
                if (this.sName === 'Chrome') {
                    this.isChrome = true;
                }
                // IE
                else if (this.sName === 'Internet Explorer') {
                    if (this.iVersion < 10) {
                        this.isOldIE = true;

                        // Make note of IE8 and IE9 for CSS purposes
                        if (this.iVersion === 9) {
                            uiUtil.css.addClass(document.documentElement, 'ie9');
                        }
                        else if (this.iVersion === 8) {
                            uiUtil.css.addClass(document.documentElement, 'ie8');
                        }
                        else if (this.iVersion === 7) {
                            this.isIE7 = true;
                        }
                    }
                    else {
                        this.isNewIE = true;
                        uiUtil.css.addClass(document.documentElement, 'new-ie');
                    }
                }
                // Firefox
                else if (this.sName === 'Firefox') {
                    this.isFirefox = true;
                }
                // iOS 7+
                else if (this.sOS === 'iOS' && this.iVersion >= 6) {
                    this.isIOS = true;
                }
                // Android stock browser
                else if (this.sName === 'Android') {
                    this.isAndroidBrowser = true;
                    uiUtil.css.addClass(document.documentElement, 'android');
                }
                // Amazon Silk (e.g. Kindle with color screen)
                else if (this.sName === 'Amazon Silk') {
                    // Playstation Vita masquerades as Amazon Silk
                    // http://docs.aws.amazon.com/silk/latest/developerguide/user-agent.html
                    if (/Playstation/.test(ua)) {
                        this.sName = 'Playstation Vita';
                    }

                    // Get correct version number because `parseFloat` can't handle the multiple dots and underscore
                    // E.g. `1.0.13.81_10003810` -> `1.0`
                    if (this.iVersion < 1 && /Silk\/(\d+\.\d+)/.test(ua)) {
                        this.iVersion = parseFloat(/Silk\/(\d+\.\d+)/.exec(ua)[1]);
                    }
                }

                // Differentiate IE7 for printing
                if (this.isOldIE && this.iVersion === 7) {
                    uiUtil.css.addClass(document.documentElement, 'ie7');
                }
                else {
                    uiUtil.css.addClass(document.documentElement, 'no-ie7');
                }

                // Windows Phone 8.1 (and higher? Added 3/18/15 [CP])
                // Example: Mozilla/5.0 (Windows Phone 8.1; ARM; Trident/7.0; Touch; rv:11.0; IEMobile/11.0; NOKIA; Lumia 635) like Gecko
                if (!Modernizr.touch && /Trident\/\d+\.\d+\;/.test(ua) && /\sTouch\;/.test(ua) && /\sIEMobile\/(\d+\.\d+)/.test(ua)) {
                    // This is the main reason for the check; Modernizr 2.7 does not always detect these devices properly
                    Modernizr.touch = true;

                    // Might as well set these too since we've already checked for them
                    this.sName = 'Internet Explorer';
                    this.sOS = 'Windows';
                    this.iVersion = parseInt(/\sIEMobile\/(\d+\.\d+)/.exec(ua)[1], 10);

                    // Remove Modernizr's false flag; we'll add the `touch` class in `responsiveInit()`
                    uiUtil.css.removeClass(document.documentElement, 'no-touch');
                }

                // Nicer text on all but Android 2 (e.g. it can crash if `text-rendering` is used in CSS)
                // For best performance this needs to be added immediately, before the page starts to paint any text
                if (!this.isAndroidBrowser || this.iVersion >= 3) {
                    uiUtil.css.addClass(document.documentElement, 'optimize-text');
                }

                // Browser capabilities are detected by `responsiveInit()` which needs to be called after `uiResponsive` is defined (so that `uiResponsive.getBreakpoints()` will work)
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        },

        responsiveInit: function _browser_responsiveInit() {
            try {
                var docElem = document.documentElement;
                var elem;
                var breakpoints;

                // Responsive capability
                if (typeof uiResponsive === 'undefined') {
                    // Cannot move forward because this is not a responsive-enabled app
                    return;
                }

                // Get breakpoint definitions
                breakpoints = uiResponsive.getBreakpoints();

                // Small screen
                // Note that we make sure `screen.width` reports a number greater than 1 to ensure it's giving us a real value. Some browsers return `0` or do not define it.
                this.isSmallScreen = (screen.width > 1 && screen.width < breakpoints.main.maxWidth);

                // Touch screen detection
                if (Modernizr.touch) {
                    uiUtil.css.addClass(docElem, 'touch');
                }
                else {
                    uiUtil.css.addClass(docElem, 'no-touch');
                }

                // Cut-the-mustard test
                // Determines whether the browser will get responsive treatment, animation, etc
                // Test page: https://codepen.io/patik/pen/VYqZYP
                if (window.matchMedia && window.matchMedia('all').addListener && document.head && document.querySelectorAll && 'classList' in docElem) {
                    this.isResponsive = true;

                    // Check for animation characteristics
                    // Avoid Android stock browser and iOS < 7 because of edge case issues that aren't easy/possible to detect (e.g. not firing the `transitionEnd` event properly, low framerates, etc)
                    this.supports.animation = (!this.isAndroidBrowser && !(this.isIOS && this.iVersion < 7) && typeof getComputedStyle === 'function' && docElem && (docElem.style && docElem.style.animationName !== undefined || docElem.style.WebkitAnimationName !== undefined));

                    if (this.supports.animation) {
                        // Check for prefixed event names (realistically only applies to Safari 6-6.1 and Android 4.2+, as of 10/2013)
                        if (docElem.style.transition === undefined && docElem.style.webkitTransition !== undefined) {
                            this.eventNames.transitionEnd = 'webkitTransitionEnd';
                        }
                    }

                    // Check support for `position:sticky`
                    // Adapted from Modernizr https://github.com/phistuck/Modernizr/commit/3fb7217f5f8274e2f11fe6cfeda7cfaf9948a1f5
                    elem = document.createElement('test');
                    elem.style.cssText = 'position: -webkit-sticky; position: sticky;';
                    this.supports.sticky = (elem.style.position.indexOf('sticky') !== -1);

                    if (this.supports.sticky) {
                        docElem.classList.add('position-sticky');
                    }

                    // // Check for support of the CSS `:not()` selector
                    // this.supports.notSelector = (function () {
                    //     try {
                    //         // This query should always return null
                    //         return !!document.querySelector('head:not(div)');
                    //     }
                    //     catch(ee) {
                    //         return false;
                    //     }
                    // }());

                    // Disable automatic telephone number linking on phones since we display a lot of long numbers
                    if (this.isIOS || this.sOS === 'Android' || (Modernizr.touch && this.isSmallScreen)) {
                        elem = document.createElement('meta');
                        elem.setAttribute('name', 'format-detection');
                        elem.setAttribute('content', 'telephone=no');
                        document.head.appendChild(elem);
                    }

                    if (Modernizr.touch && this.isSmallScreen) {
                        PLUGIN.tracking.trackEvent('client', 'Responsive, small, touch', navigator.userAgent, screen.width, true);
                    }
                    else if (Modernizr.touch) {
                        PLUGIN.tracking.trackEvent('client', 'Responsive, large, touch', navigator.userAgent, screen.width, true);
                    }
                    else if (this.isSmallScreen) {
                        PLUGIN.tracking.trackEvent('client', 'Responsive, small, no touch', navigator.userAgent, screen.width, true);
                    }
                    else {
                        PLUGIN.tracking.trackEvent('client', 'Responsive, large, no touch', navigator.userAgent, screen.width, true);
                    }
                }
                // Not responsive: keep track of (potentially mobile) browsers that we are NOT giving the responsive treatment
                else {
                    if (Modernizr.touch && this.isSmallScreen) {
                        PLUGIN.tracking.trackEvent('client', 'Non-responsive, small, touch', navigator.userAgent, screen.width, true);
                    }
                    else if (Modernizr.touch) {
                        PLUGIN.tracking.trackEvent('client', 'Non-responsive, large, touch', navigator.userAgent, screen.width, true);
                    }
                    else if (this.isSmallScreen) {
                        PLUGIN.tracking.trackEvent('client', 'Non-responsive, small, no touch', navigator.userAgent, screen.width, true);
                    }
                    else {
                        PLUGIN.tracking.trackEvent('client', 'Non-responsive, large, no touch', navigator.userAgent, screen.width, true);
                    }
                }
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        },

        searchString: function _browser_searchString(oData) {
            try {
                if (typeof oData === 'undefined') { return false; }

                var iLength = oData.length;
                var sDataString = '';
                var sDataProperty = '';
                var iCnt = 0;

                while(iCnt < iLength) {
                    sDataString = oData[iCnt].string;
                    sDataProperty = oData[iCnt].property;
                    this.sVersionSearchString = oData[iCnt].versionSearch || oData[iCnt].identity;

                    if (sDataString) {
                        if (sDataString.indexOf(oData[iCnt].subString) !== -1) {
                            return oData[iCnt].identity;
                        }
                    }
                    else if (sDataProperty) {
                        return oData[iCnt].identity;
                    }

                    iCnt++;
                }
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        },

        searchVersion: function _browser_searchVersion(sDataString) {
            try {
                var iIndex = sDataString.indexOf(this.sVersionSearchString);

                if (iIndex > -1) {
                    return parseFloat(sDataString.substring(iIndex + this.sVersionSearchString.length + 1));
                }
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        },

        // Note that as of April 2014 this incorrectly identifies the newer, Blink-based Opera as Chrome.
        // Sample user agent for Opera 18 on Windows: Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/31.0.1650.63 Safari/537.36 OPR/18.0.1284.68
        oBrowserData: [
            { string: navigator.userAgent, subString: 'Chrome', identity: 'Chrome' },
            { string: navigator.userAgent, subString: 'OmniWeb', versionSearch: 'OmniWeb/', identity: 'OmniWeb' },
            { string: navigator.vendor, subString: 'Apple', identity: 'Safari', versionSearch: 'Version' },
            { string: navigator.userAgent, subString: 'Presto/', identity: 'Opera', versionSearch: 'Version' },
            { string: navigator.vendor, subString: 'iCab', identity: 'iCab' },
            { string: navigator.vendor, subString: 'KDE', identity: 'Konqueror' },
            { string: navigator.userAgent, subString: 'Firefox', identity: 'Firefox' },
            { string: navigator.vendor, subString: 'Camino', identity: 'Camino' },
            { string: navigator.userAgent, subString: 'Netscape', identity: 'Netscape' },
            { string: navigator.userAgent, subString: 'MSIE', identity: 'Internet Explorer', versionSearch: 'MSIE' },
            { string: navigator.userAgent, subString: 'Trident', identity: 'Internet Explorer', versionSearch: 'rv' },
            { string: navigator.userAgent, subString: 'Android', identity: 'Android', versionSearch: 'Android' },
            { string: navigator.userAgent, subString: 'Silk', identity: 'Amazon Silk', versionSearch: 'Silk/' },

            // Consider both iPhone and iPod to be 'iPhone'
            { string: navigator.userAgent, subString: 'iPhone', identity: 'iPhone', versionSearch: 'iPhone OS' },
            { string: navigator.userAgent, subString: 'iPod', identity: 'iPhone', versionSearch: 'iPhone OS' },
            { string: navigator.userAgent, subString: 'iPad', identity: 'iPad', versionSearch: ' OS' },

            // These must be last since nearly all user agent strings contain 'Mozilla' and/or 'Gecko'
            { string: navigator.userAgent, subString: 'Gecko', identity: 'Mozilla', versionSearch: 'rv' },
            { string: navigator.userAgent, subString: 'Mozilla', identity: 'Netscape', versionSearch: 'Mozilla' }
        ],

        oOSData: [
            { string: navigator.userAgent, subString: 'like Mac OS X', identity: 'iOS' },
            { string: navigator.userAgent, subString: 'Android', identity: 'Android' },

            // Kindle Fire - even though it's not a typical Android, it would be more misleading to call it 'Linux' (which appears in the UA)
            { string: navigator.userAgent, subString: 'Silk/', identity: 'Android' },
            { string: navigator.platform, subString: 'Win', identity: 'Windows' },
            { string: navigator.platform, subString: 'Mac', identity: 'Mac' },
            { string: navigator.platform, subString: 'Linux', identity: 'Linux' },
            { string: navigator.userAgent, subString: 'Blackberry', identity: 'Blackberry' }
        ],

        // End of user agent sniffing

        /**
         * Rudimentary check for support of `nth-child` selectors
         * Note that this is purposely a quick and dirty test. A true test would create a style rule
         *     test elements for the application of those styles, a la Modernizr. This is meant to
         *     determine whether `uiUtil.table.stripes.pageSetup()` should run (which
         *     overrides the CSS rules).
         * Also note that this is purposely written as a function instead of a boolean because it
         *     doesn't need to be run on every page, and even then it is only needed once.
         *
         * @return  {Boolean}  Whether the browser supports it
         */
        checkNthChildSupport: function _browser_checkNthChildSupport() {
            try {
                return !!document.querySelector('div:nth-child(odd)');
            }
            catch (e) {
                return false;
            }
        }
    },

    // http://rumkin.com/tools/compression/base64.php (modified version by UI team)
    base64: {
        sKeyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

        encode: function (sInput) {
            try {
                var sResult = '';
                var sChr1 = '';
                var sChr2 = '';
                var sChr3 = '';
                var sEnc1 = '';
                var sEnc2 = '';
                var sEnc3 = '';
                var sEnc4 = '';
                var iCnt = 0;

                while (iCnt < sInput.length) {
                    sChr1 = sInput.charCodeAt(iCnt++);
                    sChr2 = sInput.charCodeAt(iCnt++);
                    sChr3 = sInput.charCodeAt(iCnt++);

                    sEnc1 = sChr1 >> 2;
                    sEnc2 = ((sChr1 & 3) << 4) | (sChr2 >> 4);
                    sEnc3 = ((sChr2 & 15) << 2) | (sChr3 >> 6);
                    sEnc4 = sChr3 & 63;

                    if (isNaN(sChr2)) {
                        sEnc3 = sEnc4 = 64;
                    }
                    else if (isNaN(sChr3)) {
                        sEnc4 = 64;
                    }

                    sResult += this.sKeyStr.charAt(sEnc1) +
                               this.sKeyStr.charAt(sEnc2) +
                               this.sKeyStr.charAt(sEnc3) +
                               this.sKeyStr.charAt(sEnc4);
                }

                return sResult;
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        },

        decode: function (sInput) {
            try {
                var sResult = '';
                var sChr1 = '';
                var sChr2 = '';
                var sChr3 = '';
                var sEnc1 = '';
                var sEnc2 = '';
                var sEnc3 = '';
                var sEnc4 = '';
                var iCnt = 0;

                // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
                sInput = sInput.replace(/[^A-Za-z0-9\+\/\=]/g, '');

                while (iCnt < sInput.length) {
                    sEnc1 = this.sKeyStr.indexOf(sInput.charAt(iCnt++));
                    sEnc2 = this.sKeyStr.indexOf(sInput.charAt(iCnt++));
                    sEnc3 = this.sKeyStr.indexOf(sInput.charAt(iCnt++));
                    sEnc4 = this.sKeyStr.indexOf(sInput.charAt(iCnt++));

                    sChr1 = (sEnc1 << 2) | (sEnc2 >> 4);
                    sChr2 = ((sEnc2 & 15) << 4) | (sEnc3 >> 2);
                    sChr3 = ((sEnc3 & 3) << 6) | sEnc4;

                    sResult += String.fromCharCode(sChr1);

                    if (sEnc3 !== 64) {
                        sResult += String.fromCharCode(sChr2);
                    }

                    if (sEnc4 !== 64) {
                        sResult += String.fromCharCode(sChr3);
                    }
                }

                return sResult;
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        }
    },

    getFunctionFromName: function _getFunctionFromName (str) {
        var func = window[str];
        var pieces;
        var i;

        if (typeof func === 'function') {
            return func;
        }

        pieces = str.split('.');

        func = window[pieces[0]];

        i = 1;
        while (func && i < pieces.length) {
            func = func[pieces[i]];

            i++;
        }

        if (typeof func !== 'function') {
            console.error('[uiUtil.getFunctionFromName] Could not get function from the name ', str);
        }

        return func;
    }
}; // end of uiUtil

//////////////////
// Query method //
//////////////////

// Native `querySelector` support
// // Exclude IE 8 because its implementation cannot handle some more advanced selectors
// if (document.querySelector && document.addEventListener) {
if (document.querySelector) {
    /**
     * Search the DOM using the native `querySelectorAll` and convert results to an array
     * If node is defined but invalid (e.g. `null`) then no search will be performed
     *
     * @param   {String}   selector  CSS-style selector
     * @param   {Element}  node      Optional node to search within. Will default to `document` if undefined
     * @param   {Object}   options   Optional settings: "single: true" returns only one element (a la `querySelector`)
     * @return  {Array}              Matching DOM elements
     */
    uiUtil.query = function _query(selector, node, options) {
        var settings = null;

        try {
            if (options && typeof options === 'object') {
                settings = options;
            }
            else {
                settings = {};
            }

            // Set property defaults
            if (typeof settings.single !== 'boolean') {
                settings.single = false;
            }

            // Node is optional, but only use `document` if it was **undefined**, not for any other falsy value.
            // Sometimes `null` or another unexpected value may be passed for the node. Querying the
            // entire document in those cases would return inaccurate results if the caller assumed `node`
            // represented an actual element.
            if (typeof node === 'undefined') {
                node = document;
            }
            else if (!node) {
                if (settings.single) {
                    return null;
                }
                else {
                    return [];
                }
            }

            if (settings.single) {
                return node.querySelector(selector);
            }
            else {
                return uiUtil.toArray(node.querySelectorAll(selector));
            }
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            // Return the correct type of non-result if we at least managed to get the settings
            if (settings && settings.single === true) {
                return null;
            }
            else {
                return [];
            }
        }
    };
}
// Use custom query function
else {
    /**
     * Finds elements using a series of non-native methods
     * Only supports basic selectors: #id, .class, [attribute], [attribute="value"], and tag name.
     *
     * @param   {String}  sSelector  Comma-separated list of CSS-style selectors
     * @param   {Element}  oNode     Optional node to search within; if not provided, will search within `document`
     * @param   {Object}  oOptions   Options. `single`: whether to return a single element instead of an array
     *
     * @return  {Array}              The matching elements, or an empty array if none were found
     */
    uiUtil.query = function _queryCustom(sSelector, oNode, oOptions) {
        try {
            var aElements = [];
            var oSettings;
            var aSelectors;
            var sTagName;
            var sProperty;
            var oElem;
            var i;
            var uniqueItems = function _uniqueItems(value, index, self) {
                    return self.indexOf(value) === index;
                };

            if (!sSelector || typeof sSelector !== 'string') { return null; }

            aSelectors = sSelector.replace(/\s*\,\s*/, ',').split(',');

            // If no node was given, search the entire document
            oNode = oNode || document;

            // Check settings
            oSettings = oOptions || {single: false};

            // Loop through each selector
            i = 0;
            while (i < aSelectors.length) {
                sSelector = aSelectors[i];

                // By class name
                if (sSelector.indexOf('.') > -1) {
                    sTagName = sSelector.split('.')[0] || '*';
                    sProperty = sSelector.split('.')[1] || '';

                    aElements = aElements.concat(uiUtil.getElementsByClassName(oNode, sTagName, sProperty));
                }
                // By element ID
                else if (sSelector.indexOf('#') > -1) {
                    // Get the ID
                    sProperty = sSelector.substr(sSelector.indexOf('#') + 1);
                    oElem = document.getElementById(sProperty);

                    if (oElem) {
                        aElements.push(oElem);
                    }
                }
                // By attribute
                else if (/^([\w\-]+)?\[[\w\-]+(\W?\=\".*\")?\]$/.test(sSelector)) {
                    aElements = aElements.concat(uiUtil.getElementsByAttribute(sSelector, oNode));
                }
                // By tag name
                else {
                    aElements = aElements.concat(uiUtil.toArray(oNode.getElementsByTagName(sSelector)));
                }

                i++;
            } // end while loop

            // Remove duplicates
            aElements = aElements.filter(uniqueItems);

            if (oSettings.single === true) {
                if (aElements.length) {
                    return aElements[0];
                }
                else {
                    return null;
                }
            }

            return aElements;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            // Return the correct type of non-result if we at least managed to get the settings
            if (oOptions && oOptions.single === true) {
                return null;
            }
            else {
                return [];
            }
        }
    };
}

/**
 * Search the DOM for a single element
 * This function is shorthand for `uiUtil.query(selector)[0]` or `uiUtil.query(selector, node, {single: true})`
 *
 * @param   {String}   selector  CSS-style selector
 * @param   {Element}  node      Optional node to search within. Will default to `document` if undefined
 * @param   {Object}   options   Optional settings object; its `single` property will be overwritten
 * @return  {Object}             Matching DOM element, or `null` if none were found
 */
uiUtil.queryOne = function _queryOne(selector, node, options) {
    try {
        var result;

        if (!options) {
            options = {};
        }

        // Will use native method
        if (document.querySelectorAll) {
            options.single = true;

            return uiUtil.query(selector, node, options);
        }
        // Will use custom function
        else {
            result = uiUtil.query(selector, node, options);

            return (result && result.length) ? result[0] : null;
        }
    }
    catch (e) {
        uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        return null;
    }
};

/**
 * Match `elem` to `selector`
 * Uses native methods when available
 * Adapted from https://github.com/component/matches-selector/blob/master/index.js
 *
 * @param  {Element}  elem
 * @param  {String}   selector
 * @return {Boolean}
 */
(function _matchesSetup() {
    var proto;
    var nativeMethod;

    // Check for native support
    try {
        // IE7 chokes on `Element`
        proto = Element.prototype;

        // Look for vendor-prefixed methods
        nativeMethod = proto.matches ||
                       proto.webkitMatchesSelector ||
                       proto.mozMatchesSelector ||
                       proto.msMatchesSelector ||
                       proto.oMatchesSelector;
    }
    catch (e) {
    }

    // Use native method
    if (nativeMethod) {
        uiUtil.matchesSelector = function _matchesSelector(elem, selector) {
            return nativeMethod.call(elem, selector);
        };
    }
    // Fallback to a custom function that matches the element manually
    else {
        uiUtil.matchesSelector = function _matchesSelectorCustom(elem, selector) {
            var nodes;
            var i;

            // If we've arrived at this point the browser will likely be using the older, custom
            // `uiUtil.query` which will search the entire document if `elem.parentNode` is not properly
            // defined, so just return `false` to avoid finding a false positive
            if (!elem || !elem.parentNode) {
                return false;
            }

            nodes = uiUtil.query(selector, elem.parentNode);

            for (i = 0; i < nodes.length; i++) {
                if (nodes[i] === elem) {
                    return true;
                }
            }

            return false;
        };
    }
}());

// Define element class handlers
// Use native `classList` if available for better performance
if (typeof document !== 'undefined' && document.documentElement && 'classList' in document.documentElement) {
    uiUtil.css.addClass = function _css_addClass(oSender, sClassName) {
        try {
            return !oSender.classList.add(sClassName);
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return false;
        }
    };

    uiUtil.css.removeClass = function _css_removeClass(oSender, sClassName) {
        try {
            return !oSender.classList.remove(sClassName);
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return false;
        }
    };

    uiUtil.css.hasClass = function _css_hasClass(oSender, sClassName) {
        try {
            return oSender.classList.contains(sClassName);
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return false;
        }
    };
}
// Custom element class handlers
else {
    ///<summary>Returns true if the element now has the specified class, otherwise returns false</summary>
    ///<param name="oSender" type="DOM Object">The DOM element to check</param>
    ///<param name="sClassName" type="string">The class name to add</param>
    ///<returns>True if sClassName is present on oSender</returns>
    uiUtil.css.addClass = function _css_addClassNonNative(oSender, sClassName) {
        try {
            // Fix oSender if 'this' was passed in IE
            if (!oSender && window.event) { oSender = window.event.srcElement; }
            if (!oSender || typeof sClassName !== 'string') { return false; }

            if (!oSender.className) {
              oSender.className = sClassName;
              return true;
            }

            // Check if the element already has this class
            if (uiUtil.css.hasClass(oSender, sClassName)) { return true; }

            // Add class name and clean up extraneous spaces
            oSender.className = oSender.className.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '') +
                                ' ' + sClassName;

            return true;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return false;
        }
    };

    ///<summary>Returns true if the element no longer has the specified class, otherwise returns false</summary>
    ///<param name="oSender" type="DOM Object">The DOM element to check</param>
    ///<param name="sClassName" type="string">The class name to remove</param>
    ///<returns>True if sClassName wasn't present on oSender</returns>
    uiUtil.css.removeClass = function _css_removeClassNonNative(oSender, sClassName) {
        try {
            var oRegExp;

            // Fix oSender if 'this' was passed in IE
            if (!oSender && window.event) { oSender = window.event.srcElement; }

            // Check parameters
            if (!oSender) { return false; }
            if (!oSender.className || typeof sClassName !== 'string') { return false; }

            // Make sure the class exists
            if (!uiUtil.css.hasClass(oSender, sClassName)) { return false; }

            // Remove class name and clean up extraneous spaces
            oRegExp = new RegExp('(^|\\s)' + sClassName + '(\\s|$)', 'g');
            oSender.className = oSender.className.replace(oRegExp, ' ').replace(/\s+/g, ' ').replace(/^\s|\s$/g, '');

            return true;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return false;
        }
    };

    ///<summary>Returns true if the element has the specified class, otherwise returns false</summary>
    ///<param name="oElem" type="DOM Object">The DOM element to check</param>
    ///<param name="sClassName" type="string">The class name to check for</param>
    ///<returns>True if sClassName is present on oElem</returns>
    uiUtil.css.hasClass = function _css_hasClassNonNative(oSender, sClassName) {
        try {
            // Fix oSender if 'this' was passed in IE
            if (!oSender && window.event) { oSender = window.event.srcElement; }

            // Check parameters
            if (!oSender.className || typeof sClassName !== 'string') { return null; }

            // Test for the class
            return (' ' + oSender.className + ' ').indexOf(' ' + sClassName + ' ') > -1;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return false;
        }
    };
}

// Define safe `hasAttribute` function (the native method is not supported in IE7)
if (typeof document !== 'undefined' && document.documentElement && typeof document.documentElement.hasAttribute === 'function') {
    // Use native function
    uiUtil.hasAttribute = function _hasAttribute(elem, attr) {
        try {
            return elem.hasAttribute(attr);
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return false;
        }
    };
}
else {
    // Use custom function
    uiUtil.hasAttribute = function _hasAttributeNonNative(elem, attr) {
        try {
            // IE7 handles `label[for]` differently
            if (/^for$/i.test(attr) && elem.nodeName === 'LABEL' && elem.getAttribute(attr) === null) {
                return elem.htmlFor.length > 0;
            }
            else {
                // IE7 returns function for inline event handlers (`onclick`, etc)
                // Non-existent attributes return `null`
                return (/^string$|^function$|^number$/.test(typeof elem.getAttribute(attr)));
            }
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return false;
        }
    };
}

// Define `uiUtil.getValueAsText`
// Using an enclosure so we can define the default settings just once but without leaking them globally
(function () {
    // Patterns for matching tag names
    var NODE_NAME_PATTERNS = {
            input: /^input$|^textarea$/i,
            checkableInput: /^checkbox$|^radio$/i,
            select: /^select$/i,
            button: /^button$/i
        };

    // Property for getting the textual content of an element
    // Newer browsers support `elem.textContent` while oldIE only supports `elem.innerText`
    var TEXT_PROPERTY = (typeof document.documentElement.textContent === 'string') ? 'textContent' : 'innerText';

    // Default settings
    var DEFAULT_SETTINGS = {
            // Create a string equivalent for booleans because the calling function will always receive a string
            text: {
                truthy: 'Yes',
                falsy: 'No'
            },

            // Define regular expressions for patterns we want to ignore
            // If a value matches the regexp we assume the element has no actual value
            ignorePattern: {
                // Tag name and pattern
                select: /^Select\s.+$/ // Ignore the default "Select foo" option used in iflow
            }
        };

    /**
     * Retrieves an element's value as plain text
     * Converts booleans (including checked/unchecked) to "True" and "False", may be overridden with settings
     * Returns a `<select>`s visible text value, not the `<option value>`
     * @param   {Element}  elem     Input field, select, or textarea
     * @param   {Object}   options  Optional settings
     * @return  {String}            Input's trimmed value
     */
    uiUtil.getValueAsText = function _val(elem, options) {
        var returnValue = '';

        try {
            var settings;

            if (!elem || uiUtil.typeOf(elem) !== 'element') {
                return '';
            }

            settings = uiUtil.extend(true, {}, DEFAULT_SETTINGS, options);

            // Inputs and textareas
            if (NODE_NAME_PATTERNS.input.test(elem.nodeName)) {
                if (NODE_NAME_PATTERNS.checkableInput.test(elem.type)) {
                    if (elem.checked) {
                        returnValue = settings.text.truthy;
                    }
                    else {
                        returnValue = settings.text.falsy;
                    }
                }
                // Text field, etc
                else {
                    returnValue = uiUtil.trim(elem.value);
                }
            }
            // Select/dropdown
            else if (NODE_NAME_PATTERNS.select.test(elem.nodeName)) {
                // Get visible text from `<option>` element
                returnValue = uiUtil.trim(elem.options[elem.selectedIndex].innerHTML);

                // Make sure this isn't a value that should be considered empty/blank
                if (settings.ignorePattern.select.test(returnValue)) {
                    returnValue = '';
                }
            }
            // Button tag (but not `input[type=button]`)
            else if (NODE_NAME_PATTERNS.button.test(elem.nodeName)) {
                // Get visible text from within the element
                returnValue = uiUtil.trim(elem.options[elem.selectedIndex][TEXT_PROPERTY]);
            }
            // Plain text element, not an input field
            else {
                returnValue = uiUtil.trim(elem[TEXT_PROPERTY]
                                                .replace(/&nbsp;/g, ' ')
                                                .replace(/<\/?br\/?\s*>/gi, '\n')
                                        );
            }
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            returnValue = '';
        }
        finally {
            return returnValue;
        }
    };
}());

////////////
// Cookie //
////////////

// Adapted from https://developer.mozilla.org/en-US/docs/DOM/document.cookie
uiUtil.cookie = {};

/**
 * Retrieves the value of a key from the cookie
 *
 * @param   {String}  sName  Key name
 *
 * @return  {String}         The value if the key exists; otherwise an empty string
 */
uiUtil.cookie.get = function _cookie_get(key) {
    try {
        return decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(key).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null;
    }
    catch (e) {
        uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        return '';
    }
};

/**
 * Stores a key and value in the cookie
 *
 * @param  {String}  key    Key name
 * @param  {String}  value  Value
 *
 * @return {Boolean}        The updated cookie string, or empty string if it failed
 */
uiUtil.cookie.set = function _cookie_set(key, value) {
    try {
        if (!key || /^(?:expires|max\-age|path|domain|secure)$/i.test(key)) {
            return false;
        }

        document.cookie = encodeURIComponent(key) + '=' + encodeURIComponent(value);

        return true;
    }
    catch (e) {
        uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        return '';
    }
};

// `List` constructor (associative array)
if (typeof List === 'undefined') {
    var List = function _list() {
        try {
            var aArgs = arguments;
            var iArgs = aArgs.length;
            var i;

            // Private properties & methods
            this.length = 0;

            // Only allow key names that are strings or numbers, and avoid reserved words
            this.isSafeKeyName = function _list_isSafeKeyName(sKey) {
                var reservedWords;

                if (/string|number/i.test(typeof sKey)) {
                    sKey += ''; // Convert to string

                    if (!sKey.length) { return false; }

                    if (this[sKey]) {
                        reservedWords = ['get', 'set', 'push', 'isSet', 'forEach', 'remove',
                                         'length', 'getLength', 'indexOf', 'isSafeKeyName'];

                        // Any property aside from reserved words and methods are okay
                        return (reservedWords.indexOf(sKey) < 0);
                    }

                    return true;
                }

                return false;
            };

            // Add intial keys/values, if provided
            if (iArgs > 1 && iArgs%2 === 0) {
                i = 0;
                while (i < iArgs) {
                    this.set(aArgs[i], aArgs[i + 1]);
                    i += 2;
                }

                this.length = i;
            }
            // Convert an object to a List
            else if (iArgs === 1 && uiUtil.typeOf(aArgs[0]) === 'object') {
                i = uiUtil.extend(true, {}, new List(), aArgs[0]);
                i.length = i.getLength() - 1;

                return i;
            }

            return this;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);

            return null;
        }
    };

    List.prototype = {
        // Returns the value for a particular index, or undefined if it's not found
        get: function _list_get(sKey) {
            var mReturnVal;

            try {
                if (sKey) {
                    if (this.isSafeKeyName(sKey) && this.hasOwnProperty(sKey)) {
                        mReturnVal = this[sKey];
                    }
                }
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
            finally {
                return mReturnVal;
            }
        },

        // Adds an item and returns the new list length, or -1 on failure
        set: function _list_set(sKey, mValue) {
            var iReturnVal = -1;

            try {
                if (this.isSafeKeyName(sKey)) {
                    if (!this.hasOwnProperty(sKey)) {
                        this.length++; // Only increase for new items
                    }

                    this[sKey] = mValue;
                    iReturnVal = this.length;
                }
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
            finally {
                return iReturnVal;
            }
        },

        // Alias for set()
        push: function _list_push(sKey, mValue) {
            var iReturnVal = -1;

            try {
                iReturnVal = this.set(sKey, mValue);
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
            finally {
                return iReturnVal;
            }
        },

        isSet: function _list_isSet(sKey) {
            try {
                return (this.get(sKey) !== undefined);
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                return false;
            }
        },

        forEach: function _list_forEach(fFunction) {
            try {
                var sKey;

                if (typeof fFunction !== 'function' || this.length < 1) { return false; }

                for (sKey in this) {
                    if (this.isSafeKeyName(sKey) && this.hasOwnProperty(sKey)) {
                        // Passes these arguments: value, key, List
                        (function (f,v,k,a){f(v,k,a);})(fFunction, this[sKey], sKey, this);
                        //FIXME: This fails JSHint's "don't create functions within a loop" test
                    }
                }

                return true;
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                return false;
            }
        },

        remove: function _list_remove(sKey) {
            try {
                if (sKey && typeof sKey === 'string') {
                    if (this.isSafeKeyName(sKey) && this.hasOwnProperty(sKey)) {
                        delete this[sKey];
                        this.length--;

                        return this.length;
                    }

                    return -1;
                }

                return -1;
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
                return -1;
            }
        },

        // Returns the first index of the value, or empty string if it's not found
        indexOf: function _list_indexOf(mValue) {
            var mReturnVal = '';

            try {
                var sKey;

                if (typeof mValue !== 'undefined') {
                    for (sKey in this) {
                        if (this.isSafeKeyName(sKey) && this.hasOwnProperty(sKey) && this[sKey] === mValue) {
                            mReturnVal = sKey;
                            break;
                        }
                    }
                }
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
            finally {
                return mReturnVal;
            }
        },

        // Re-determine the array's length (eg, after artificially creating a List instance using uiUtil.extend)
        getLength: function _list_getLength() {
            var iReturnVal = -1;

            try {
                var iTotal = 0;
                var sKey;

                for (sKey in this) {
                    if (this.isSafeKeyName(sKey) && this.hasOwnProperty(sKey)) {
                        iTotal++;
                    }
                }

                this.length = iTotal;
                iReturnVal = this.length;
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
            finally {
                return iReturnVal;
            }
        }
    };
} // end List

/**
 * Returns the selector for an element
 * Not guaranteed to be unique. Meant for debugging in browsers with unhelpful consoles.
 *
 * @param   {[type]}  elem  [description]
 * @return  {[type]}        [description]
 */
uiUtil.getSelector = function _util_getSelector (elem) {
    var selector;

    // Make sure it's a DOM element
    if (!elem || elem.nodeType !== 1) {
        return '';
    }

    // Start with tag name
    selector = elem.nodeName.toLowerCase();

    // Add ID
    if (elem.id) {
        selector += '#' + elem.id;
    }

    // Add classes
    if (elem.className) {
         uiUtil.trim(elem.className, ' ').split(' ').forEach(function (c) {
            selector += '.' + c;
        });
    }

    // Add value (helpful for buttons)
    if (elem.value) {
        selector += '[value="' + uiUtil.trim(elem.value) + '"]';
    }

    return selector;
};
