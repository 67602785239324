define(['react', 'reactdom', 'guid', 'Inbox', 'modal', 'dateutil'], function (React, ReactDOM, guid, Inbox, modal, dateutil) {
    ////////////////////
    // Public methods //
    ////////////////////

    const init = (sourceObject, mountNode) => {
        if (!sourceObject) {
            journal.log({type: 'error', owner: 'app', module: 'inbox', submodule: 'init'}, 'No source list was provided');

            return false;
        }

        if (!mountNode && sourceObject.container) {
            mountNode = document.querySelector(sourceObject.container);
        }

        if (!mountNode) {
            journal.log({type: 'error', owner: 'app', module: 'inbox', submodule: 'init'}, 'Could not get mount node');

            return false;
        }

        sourceObject = _normalize(sourceObject);

        if (!sourceObject) {
            journal.log({type: 'error', owner: 'app', module: 'inbox', submodule: 'init'}, 'Source object failed normalization ', JSON.parse(JSON.stringify(sourceObject)));

            return false;
        }

        const MyMessages = React.createFactory(Inbox);

        // Mount the JSX component
        ReactDOM.render(
            MyMessages({source: sourceObject}),
            mountNode
        );

        return MyMessages;
    };


    /////////////////////
    // Private methods //
    /////////////////////

    const _normalize = (sourceObject) => {
        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const __addContentsKeys = (content) => {
            // Ensure it has a unique key
            if (!content.key) {
                content.key = guid();
            }

            // Check children
            if (content.contents) {
                content.contents.map(__addContentsKeys);
            }

            return content;
        };

        const __normalizeList = (list) => {
            // Ensure it has an ID
            if (!list.id) {
                list.id = guid();

                // Re-use the ID as a key so we don't need to make another call to `guid()`
                if (!list.key) {
                    list.key = list.id;
                }
            }

            // Ensure it has a unique key
            if (!list.key) {
                list.key = guid();
            }

            // Navigation link uses ajax
            if (typeof list.navLink === 'object') {
                if (!list.navLink.url || typeof list.navLink.url !== 'string') {
                    journal.log({type: 'warn', owner: 'app', module: 'inbox', submodule: '__normalizeList'}, '`navLink` ajax object does not include a URL', JSON.parse(JSON.stringify(sourceObject)));
                }
            }

            // Normalize messages
            if (list.messages) {
                list.messages.map((msg) => __normalizeMessage(msg));
            }

            return list;
        };

        const __normalizeMessage = (message) => {
            // Ensure it has an ID
            if (!message.id) {
                message.id = guid();

                // Re-use the ID as a key so we don't need to make another call to `guid()`
                if (!message.key) {
                    message.key = message.id;
                }
            }
            else {
                message.key = 'msg_' + message.id; // Prepend non-numeric text because React doesn't like numeric keys
            }

            // Ensure it has a unique key
            if (!message.key) {
                message.key = guid();
            }

            // Check for required properties:

            // Date
            // Must be a Unix timestamp
            if (!message.date || isNaN(message.date)) {
                journal.log({type: 'warn', owner: 'app', module: 'inbox', submodule: '__normalizeMessage'}, 'Message object does not have a valid `date`. Message: ', JSON.parse(JSON.stringify(message)), ', source object: ', JSON.parse(JSON.stringify(sourceObject)));

                return null;
            }
            // Convert to an integer
            else if (typeof message.date === 'string') {
                message.date = parseInt(message.date, 10);
            }

            // Create a JS-consumable `Date` object
            message.dateFormats = {
                obj: new Date(message.date),
            };

            // Full date (day + mm/dd/yyyy)
            message.dateFormats.long = dateutil.humanReadable(message.date, true);

            // Short date
            message.dateFormats.short = dateutil.relative(message.date);

            // Check non-mandatory properties:

            // Read
            if (!message.read) {
                message.read = {
                    date: 0,
                    state: false,
                };
            }
            else if (typeof message.read.state !== 'boolean') {
                message.read.state = false;
            }

            // Delete
            if (!message.delete) {
                message.delete = {
                    date: 0,
                    state: false,
                };
            }
            else if (typeof message.delete.state !== 'boolean') {
                message.delete.state = false;
            }

            // Delete flag (for internal use)
            if (typeof message.isDeleted !== 'boolean') {
                message.isDeleted = message.delete.state;
            }

            // Body and contents
            if (message.body && message.body.length && message.body.map) {
                message.body.map(__addContentsKeys);
            }

            message.description = __getDescriptionFromBody(message.body);

            return message;
        };

        const __getDescriptionFromBody = (body) => {
            let description = '';
            let rawContent = '';

            function stripHtml(html){

                //Since there are some tags that are used to create space between words, but do not reflect that in a parsed string, add spaces between starting and closing tags.
                html = html.replace(/></g, "> <");

                // Create a new div element
                var temporaryTextElement = document.createElement("div");

                // Set the HTML content with the providen
                temporaryTextElement.innerHTML = html;

                // Retrieve the text property of the element (cross-browser support)
                return temporaryTextElement.textContent || temporaryTextElement.innerText || "";
            }

            function getWordsFromString(str, wordCount = 6) {
                return str.trim().split(/\s+/).slice(0,wordCount).join(" ");
            }

            if(typeof body === 'string'){
                rawContent = body;
            }

            else if(body.length && body.length > 0){
                if(body[0].template && body[0].template === "text" && body[0].text){
                    rawContent = body[0].text;
                }
            }

            if(rawContent != ""){
                description = getWordsFromString(stripHtml(rawContent), 6);
            }

            return description;
        }

        return __normalizeList(sourceObject);
    };


    //////////////////////////////////////////
    // Expose public properties and methods //
    //////////////////////////////////////////

    return {
        init,
    };
});
