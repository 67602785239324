define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    const InboxFooter = (props) => {
        // Message Link section
        if (props.navLink) {
            let trigger;

            if(props.navLink && typeof props.navLink === 'string'){
                trigger = (
                    <a
                        href={props.navLink}
                        onClick={props.onNavLinkClick}
                    >
                        More
                    </a>
                );

            }
            else{
                trigger = (
                    <button
                        className="feta-inbox-button-link"
                        onClick={props.onNavLinkClick}
                    >
                        More
                    </button>
                );
            }

            return (
                <div className="cui-row feta-inbox-list-footer">
                    <div className="cui-col feta-inbox-link">
                        {trigger}
                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    };

    InboxFooter.propTypes = {
        onNavLinkClick: ReactPropTypes.func,
        navLink: ReactPropTypes.any,
    };

    InboxFooter.defaultProps = {
        onNavLinkClick: null,
        navLink: '',
    };

    return InboxFooter;
});
