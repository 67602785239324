define(['react', 'reactproptypes', 'TableRowCountDisplay', 'RenderIconSearch'], function (React, ReactPropTypes, TableRowCountDisplay, RenderIconSearch) {
    class TableFilterBasic extends React.Component {
        constructor (props) {
            super(props);

            // Private method binding
            this._onInputChange = this._onInputChange.bind(this);
            this._onClearButtonClick = this._onClearButtonClick.bind(this);
            this._onToggleAdvancedClick = this._onToggleAdvancedClick.bind(this);
            this._onKeyDown = this._onKeyDown.bind(this);

            this.state = {
                value: props.value,
            };
        }

        // Handles changes to the input field
        _onInputChange (evt) {
            this.setState({
                value: evt.target.value,
            });

            this.props.updateFilterBasicValue(evt.target.value);
        }

        // Handles key presses on input field
        _onKeyDown (evt){
            // Prevent default of "Enter" key
            if (evt.keyCode === 13) {
                evt.preventDefault();
            }
        }

        // Clears the input field
        _onClearButtonClick (/* evt */) {
            // Update the value in state to an empty string
            this.setState({
                value: '',
            });

            this.props.updateFilterBasicValue('');
        }

        // Toggles the advanced filters
        _onToggleAdvancedClick (evt) {
            this.props.onToggleAdvancedClick();
            const $filterWrapper = $(evt.target).closest('.feta-table-filter-wrapper');
            const $thead = $('.feta-table-floating-thead');

            $filterWrapper.toggleClass('feta-table-filter-advanced-toggle-open');

            // Fix the `top` position of the floating `<thead>`
            if ($filterWrapper.get(0).getBoundingClientRect().top < 1 && $thead.length) {
                // Advanced filter is open
                if ($filterWrapper.hasClass('feta-table-filter-advanced-toggle-open')) {
                    // No transition since we have to wait for the filter container to transition anyway
                    $thead.css('top', ($filterWrapper.parent().height() - 4) + 'px');
                    $thead.css('transition', 'none');

                    // Apply a new value once the filters have finished expanding
                    setTimeout(function () {
                        $thead.css('top', ($filterWrapper.parent().height() - 4) + 'px');
                    }, 200);
                }
                // Advanced filter is closed
                else {
                    $thead
                        .css('transition', 'top 200ms ease-out')
                        .css('top', '40px');
                }
            }
        }

        render () {
            const sourceTable = this.props.source;
            const filters = sourceTable.filter.basic;

            if (!filters) {
                return null;
            }

            const inputId = sourceTable.id + '-filter-basic';
            let inputButton;
            let rowCountDisplay;
            let advancedToggle;

            // Show a clear button when some value has been entered
            if (filters.showClearButton && this.props.value) {
                inputButton = (
                    <button
                        type="button"
                        onClick={this._onClearButtonClick}
                        className="feta-table-filter-basic-clear"
                    >
                        Clear
                    </button>
                );
            }
            // Show a search icon when the input is empty
            else {
                inputButton = (
                    <RenderIconSearch className="feta-table-filter-search-icon" />
                );
            }

            // Num rows displayed
            if (!sourceTable.isEmpty && !this.props.isSmallSize) {
                rowCountDisplay = (
                    <TableRowCountDisplay
                        count={sourceTable.isEmptyDueToFilters ? 0 : this.props.numDisplayRows}
                        total={this.props.totalRowsAvailable}
                    />
                );
            }

            // "More" button
            if (sourceTable.filter.advanced) {
                advancedToggle = (
                    <button
                        type="button"
                        onClick={this._onToggleAdvancedClick}
                        className="feta-table-filter-advanced-toggle"
                    >
                        More
                    </button>
                );
            }

            //TODO: Use `<RenderFieldText>` instead of the `<label>` and `<input>`?
            return (
                <div className="feta-table-filter-basic-wrapper">
                    <div className="cui-input-wrapper">
                        <label
                            htmlFor={inputId}
                            className="cui-hide-from-screen"
                        >
                            {filters.label + ':'}
                        </label>
                        <input
                            type="text"
                            value={this.state.value || ''}
                            onChange={this._onInputChange}
                            onKeyDown={this._onKeyDown}
                            id={inputId}
                            placeholder={filters.label}
                            className="feta-table-filter-basic-input cui-value"
                        />
                        {inputButton}
                    </div>
                    {rowCountDisplay}
                    {advancedToggle}
                </div>
            );
        }
    }

    TableFilterBasic.propTypes = {
        source: ReactPropTypes.shape({}),
        value: ReactPropTypes.string,
        updateFilterBasicValue: ReactPropTypes.func,
        onToggleAdvancedClick: ReactPropTypes.func,
        numDisplayRows: ReactPropTypes.number,
        totalRowsAvailable: ReactPropTypes.number,
        isSmallSize: ReactPropTypes.bool,
    };

    TableFilterBasic.defaultProps = {
        source: {},
        value: '',
        updateFilterBasicValue: () => {},
        onToggleAdvancedClick: () => {},
        numDisplayRows: 0,
        totalRowsAvailable: 0,
        isSmallSize: false,
    };

    return TableFilterBasic;
});
