define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    class TableFilterAdvanced extends React.Component {
        constructor (props) {
            super(props);

            // Private method binding
            this._onCheckboxChange = this._onCheckboxChange.bind(this);
        }

        _onCheckboxChange (proxyEvt) {
            this.props.updateFilterAdvCheckbox(proxyEvt);
        }

        render () {
            const sourceTable = this.props.source;
            const filters = sourceTable.filter.advanced;
            const sectionsToRender = [];

            if (!filters) {
                return null;
            }

            // Add each filter row
            filters.rows.forEach((row, rowIndex) => {
                let baseId = sourceTable.id;
                const rulesToRender = [];

                // Checkbox list
                if (row.type === 'checkbox-any' || row.type === 'checkbox-all') {
                    baseId += '_checklist_' + rowIndex + '_';

                    {row.rules.forEach((rule) => {
                        // Remove after a couple weeks (CP 1/31/17)
                        if (!rule.key) {
                            console.warn('no rule.key', rule);
                        }

                        rulesToRender.push(
                                        <input
                                            key={baseId + 'input-' + rule.key}
                                            type="checkbox"
                                            id={baseId + rule.key}
                                            defaultChecked={rule.checked}
                                            value={rule.value + '_row' + rowIndex}
                                            onChange={this._onCheckboxChange}
                                        />
                                    );

                        rulesToRender.push(
                                        <label
                                            key={baseId + 'label-' + rule.key}
                                            htmlFor={baseId + rule.key}
                                        >
                                            &nbsp;{rule.label}
                                        </label>
                                    );
                    })}

                    sectionsToRender.push(
                        <div key={baseId}>
                            <fieldset>
                                <div className="cui-selection-group cui-always-show-borders feta-break-options-small">
                                    <legend>{row.label}</legend>
                                    <div className="feta-table-filter-advanced-checkbox-rules cui-data">
                                        {rulesToRender}
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    );
                }
                // Radio button list
                else if (row.type === 'radio-list') {
                    baseId += '_radiolist_' + rowIndex + '_';

                    {row.rules.forEach((rule) => {
                        rulesToRender.push(
                                        <input
                                            key={baseId + 'input-' + rule.key}
                                            type="radio"
                                            id={baseId + rule.key}
                                            name={baseId}
                                            defaultChecked={rule.checked}
                                            value={rule.value + '_row' + rowIndex}
                                            onChange={this._onCheckboxChange}
                                        />
                                    );
                        rulesToRender.push(
                                        <label
                                            key={baseId + 'label-' + rule.key}
                                            htmlFor={baseId + rule.key}
                                        >
                                            &nbsp;{rule.label}
                                        </label>
                                    );
                    })}

                    sectionsToRender.push(
                        <div key={baseId}>
                            <fieldset>
                                <div className="cui-selection-group cui-always-show-borders feta-break-options-small">
                                    <legend>{row.label}</legend>
                                    <div className="feta-table-filter-advanced-checkbox-rules cui-data">
                                        {rulesToRender}
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    );
                }
                // No other types supported yet
            }); // We need `bind` so that we can reference `this._onCheckboxChange` properly

            return (
                <div className="feta-table-filter-advanced-wrapper">
                    {sectionsToRender}
                </div>
            );
        }
    }

    TableFilterAdvanced.propTypes = {
        source: ReactPropTypes.shape({}).isRequired,
        updateFilterAdvCheckbox: ReactPropTypes.func,
    };

    TableFilterAdvanced.defaultProps = {
        source: {},
        updateFilterAdvCheckbox: () => {},
    };

    return TableFilterAdvanced;
});
