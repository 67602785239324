(function () {

    var scripts = document.getElementById("require"),
        src = scripts.src,
        baseUrl = src.substring(src.indexOf(document.location.pathname),src.lastIndexOf("/js/main.js"));

    require.config({
        baseUrl: baseUrl,
        paths:{
            "pageScripts-epayjs": "js/components/epay",
            "pageScripts-errorpagejs": "js/components/errorpage",
            "pageScripts-existingSessionErrorjs": "js/components/existingSessionError",
            "pageScripts-genericAuthErrorjs": "js/components/genericAuthError"
        }
    });

    (function(win) {
    // We pass in the current container object (i.e. a window or frame object) so we know we are referring to the *immediate* parent.
    // If our page is nested multiple levels deep (e.g. inside an iframe that is inside another iframe) the standard `window` global
    // will refer to the top-level window which is not what we want.
    if (win.location && win.top && win.top.location) {
        if (win.location !== win.top.location) {
            win.top.location = win.location;
        }
    }
})(this);

/**
 * Initialization module
 *
 * @version 0.1.0
 *
 * @param  {module} $    jQuery module
 * @param  {module} cui  Main module for CoreUI
 * @param  {module} feta Main module for external applications
 * @return {object}      Public API for module
 *
 * @description          Kicks off the global project scripts
 */
//
require(['jquery', 'cui', 'feta', 'domReady!'], function($, cui, feta) {

    // Declares the first document.ready to make sure this code runs first.
    //cui.init(); // Commenting out until we can start defining this section

    // Make feta module globally accessible
    if (!window.feta) {
        window.feta = feta;

        feta.init();
    }

});


})();

define("../tasks/libs/requireManager/temp/settings", function(){});

