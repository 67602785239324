define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    function RenderIcon (props) {
        let iconClass;
        const prefix = 'feta-icon-';

        if (!props.type) {
            journal.log({type: 'warn', owner: 'UI', module: 'RenderIcon'}, 'Icon type not defined');
            return null;
        }

        switch (props.type) {
            case 'check':
                iconClass = 'check';
            break;

            case 'check-off':
                iconClass = 'check-off';
            break;

            case 'trashCan':
                iconClass = 'check-off';
            break;

            case 'credits-claimed':
                iconClass = 'credits-claimed';
            break;

            case 'copy-value':
                iconClass = 'copy-value';
            break;

            case 'error':
                iconClass = 'error';
            break;

            default:
        }

        if (iconClass !== undefined) {
            return (
                <i
                    className={prefix + iconClass}
                    id={props.id}
                    title={props.title}
                />
            );
        }
        else {
            journal.log({type: 'warn', owner: 'UI', module: 'RenderIcon'}, 'Icon type not found');

            return null;
        }
    }

    RenderIcon.propTypes = {
        id : ReactPropTypes.string,
        title: ReactPropTypes.string,
        type: ReactPropTypes.string,
    };

    RenderIcon.defaultProps = {
        id: null,
        title: null,
        type: null,
    };

    return RenderIcon;
});
