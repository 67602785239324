define('dynamicBlock',['jquery', 'cui', 'guid', 'kind'], function ($, cui, guid, kind) {
    ///////////////
    // Constants //
    ///////////////

    var VERSION = '1.0.0';

    var NAMESPACE = 'dynamicBlock';

    var DEFAULT_BLOCK_CONFIG = {
            // Public (set by user)
            id: '',          // Required
            show: {
                condition: {
                    items: [],
                    operator: 'and',
                },
            },
            // Private (set internally)
            fieldIds: [],    // List of field IDs that apply to this block
            elem: null,
            dependents: [],  // List of block IDs that depend on this block (i.e. are nested within this)
            isVisible: true,
        };

    var DEFAULT_FIELD_CONFIG = {
            // Public (set by user)
            id: '',
            value: '',

            // Private (set internally)
            elem: null,
            labelElem: null,
        };

    var DEFAULT_CONDITION_CONFIG = {
            // Public (set by user)
            items: [],
            operator: 'and',
        };

    var FIELD_VALUES = {
            selected: 'selected',
            unselected: 'unselected',
        };

    var OPERATORS = {
            and: 'and',
            or: 'or',
            none: '',
        };

    var CLASSES = {
            block: 'feta-dynamic-toggle',
            hidden: 'cui-hidden',
        };

    var ID_SUFFIX = {
        value: '_block',
        test: /_block$/,
    };

    var ATTRIBUTES = {
            idList: 'data-' + NAMESPACE.toLowerCase() + '-field-id',
            storeOverflow: 'data-' + NAMESPACE.toLowerCase() + '-overflow',
        };

    var ANIMATION = {
        duration: '150',
        easing: 'easeInOutCubic',
    };

    /////////////////
    // Constructor //
    /////////////////

    var DynamicBlock = function _DynamicBlock (elem, options) {
        // Store the element upon which the component was called
        this.elem = elem;
        // Create a jQuery version of the element
        this.$elem = $(elem);
        // Store the options
        this.options = options;

        // Read from HTML attribute
        this.metadata = this.$elem.data('dynamicblock-options');
    };

    //////////////////////
    // Plugin prototype //
    //////////////////////

    // This is where you define "public" functions and properties for the plugin. Most simple plugins won't have any besides `init`.

    DynamicBlock.prototype = {};

    // Default user options
    DynamicBlock.prototype.defaults = {};

    /**
     * Initializes the plugin
     * May be called multiple times
     */
    DynamicBlock.prototype.init = function () {
        this.config = $.extend({}, this.defaults, this.options, this.metadata);

        priv.init(this);

        return this;
    };

    /////////////////////
    // Private methods //
    /////////////////////

    // List of all blocks
    var blocks = {};

    // Private methods
    var priv = {};

    ////////////////////
    // Public methods //
    ////////////////////

    /**
     * Defines properties for a block and sets up its fields
     * @param   {object}  options  Settings object (or an array of them) to be setup
     */
    DynamicBlock.prototype.customize = function _customize (configs) {
        var i;
        var checkField;

        // Verify argument
        if (!configs || typeof configs !== 'object') {
            return false;
        }

        // Ensure there is an array of configurations
        if (kind(configs) !== 'array') {
            configs = [configs];
        }

        // Validate each configuration
        configs.forEach(function (config) {
            var block = $.extend(true, {}, DEFAULT_BLOCK_CONFIG, config);
            var cond;
            var addId;

            // Check that basic properties exist
            if (!block.id || !block.show || !block.show.condition || !block.show.condition.items || !block.show.condition.items.length) {
                return false;
            }

            // Setup block element
            if (!block.elem) {
                block.elem = document.getElementById(block.id);
            }

            // Must have a block element
            if (!block.elem) {
                return false;
            }

            // Add the main class
            block.elem.classList.add(CLASSES.block);

            // Check if it's visible
            if (block.elem.style.display === 'none' || block.elem.classList.contains(CLASSES.hidden)) {
                block.isVisible = false;
            }

            // Setup conditions, which will also setup fields
            if (block.show.condition) {
                block.show.condition = priv.validateCondition(block.show.condition, block.elem);
            }

            // Must have at least one condition
            if (!block.show.condition) {
                return false;
            }

            // Cache all field IDs at the top level

            // Create function to capture each field's ID
            addId = function _addId(itm) {
                // Make sure it's a field element
                if (itm.id && !itm.condition) {
                    block.fieldIds.push(itm.id);
                }
            };

            // Loop through conditions and find fields
            cond = block.show;
            while (cond && cond.condition) {
                cond.condition.items.forEach(addId);
                // Get next sub-condition
                cond = cond.condition;
            }

            // Store the block
            blocks[block.id] = block;
        });

        // Evaluate items now to hide/show blocks. This must be done after all block objects were added to `blocks`

        // Define function for loop
        checkField = function _checkField(fieldId) {
            // Show/hide each field based on its current status
            priv.evaluateField(fieldId);
        };

        // Evaluate each field on each block
        for (i in blocks) {
            // Ignore blocks that don't have a parent (since any evaluation of a parent will affect the children)
            if (blocks.hasOwnProperty(i) && !priv.hasParentBlock(i)) {
                blocks[i].fieldIds.forEach(checkField);
            }
        }
    };

    /////////////////////
    // General methods //
    /////////////////////

    priv.isFirefox = (navigator.userAgent.indexOf('Firefox') !== -1);

    /**
     * Sets up the provided dynamic block
     */
    priv.init = function _init (dt) {
        var blockElem = dt.elem;
        var block;
        var blockIdentifier;
        var fieldElem;
        var fieldElems;
        var field;

        // See if the plugin has already been informed of this block
        if (blocks.hasOwnProperty(priv.establishElementId(blockElem))) {
            // This block has custom settings and will be set up by `_customize()` directly
            return false;
        }

        blockIdentifier = blockElem.id.replace(ID_SUFFIX.test, '');

        // There are three possible scenarios:
        // Scenario 1: There is one single input field for this block.
        //             This means the identifier is an ID.
        // Scenario 2: There are multiple input fields for this block.
        //             This means the identifier is a class name.
        // Scenario 3: Some other situation which cannot be handled automatically (the page will need to create a custom JavaScript object).

        // Check for an ID
        fieldElem = document.getElementById(blockIdentifier);

        if (fieldElem) {
            // Scenario 1

            // Create the input field object
            field = $.extend(true, {}, DEFAULT_FIELD_CONFIG);
            field.id = blockIdentifier;
            field.value = FIELD_VALUES.selected;
            field.elem = fieldElem;

            // Create block object
            block = $.extend(true, {}, DEFAULT_BLOCK_CONFIG);
            block.id = blockElem.id;
            block.show.condition = $.extend(true, {}, DEFAULT_CONDITION_CONFIG);
            block.show.condition.items.push(field);
        }
        else {
            // Scenario 2

            // The identifier may be a class name, so look for elements with that class
            fieldElems = $('.' + blockIdentifier);

            if (!fieldElems.length) {
                // Scenario 3
                return false;
            }

            // Create block object
            block = $.extend(true, {}, DEFAULT_BLOCK_CONFIG);
            block.id = blockElem.id;
            block.show.condition = $.extend(true, {}, DEFAULT_CONDITION_CONFIG);
            block.show.condition.operator = OPERATORS.or;

            // Add fields
            fieldElems.each(function () {
                field = $.extend(true, {}, DEFAULT_FIELD_CONFIG);
                field.id = priv.establishElementId(this);
                field.value = FIELD_VALUES.selected;
                field.elem = this;
                field.$blockElem = dt.$elem;

                // Track the number of times the field has been evaluated. I don't really know why this is so quirky for non-inputs. Later, in `priv.evaluateField`, we need to be sure that we're not doing the first evaluation otherwise the condition will be erroneously satisfied on page load (this function is run twice during page load, for some reason, so we can't just use a boolean flag). Ugh. (CP 8/24/16)
                if (field.elem.nodeName === 'A') {
                    field.hasBeenEvaluated = 0;
                }
                else {
                    field.hasBeenEvaluated = 1;
                }

                block.show.condition.items.push(field);
            });
        }

        // Add configs
        block.config = dt.config;

        // Setup all discovered blocks
        DynamicBlock.prototype.customize([block]);
    };

    /**
     * Validate and normalize a condition object
     * @param   {object}  cond       Condition object
     * @param   {element} blockElem  Block element to which this condition applies
     * @return  {object}             Valid, normalized condition object, or `null` if it was unusable
     */
    priv.validateCondition = function _validateCondition(cond, blockElem) {
        // The condition must have an array of items
        if (!cond.items || kind(cond.items) !== 'array') {
            // Invalid condition
            return null;
        }

        // Normalize and initialize fields
        cond.items = priv.validateItems(cond.items, blockElem);

        // Make sure at least one item survived validation
        if (!cond.items.length) {
            // Invalid condition
            return null;
        }

        // Verify the operator
        if (!cond.operator || (cond.operator && !OPERATORS.hasOwnProperty(cond.operator))) {
            // Set to default
            cond.operator = OPERATORS.none;
        }

        // Recursively validate sub-conditions
        if (cond.condition) {
            cond.condition = priv.validateCondition(cond.condition, blockElem);
        }

        return cond;
    };

    /**
     * Validate and normalize fields, find the input and label elements, and set the appropriate attributes
     *
     * @param   {array}  fields  Fields to evaluate
     *
     * @return  {array}          Valid, normalized fields
     */
    priv.validateItems = function _validateItems(items, blockElem) {
        var itemsToKeep = [];

        items.forEach(function (itm) {
            var field;
            var labelElem;
            var inputElem;
            var select;
            var parentBlock;
            var ids;

            // Check if this item is a condition
            if (itm.condition) {
                // Validate condition
                itm = priv.validateCondition(itm, blockElem);

                // Store it if valid
                if (itm) {
                    itemsToKeep.push(itm);
                }

                // Nothing more to do with conditions, so skip to the next item
                return false;
            }

            // If it's not a condition, the item must be a field

            // Fill in the field object structure
            field = $.extend(true, {}, DEFAULT_FIELD_CONFIG, itm);

            // Check for a specified ID
            if (!field.id) {
                // Skip to the next field
                return false;
            }

            // Get the element if it hasn't been found already
            field.elem = field.elem || document.getElementById(field.id);

            // Element doesn't exist
            if (!field.elem) {
                // Skip to the next field
                return false;
            }

            // Cache input element
            inputElem = field.elem;

            // Setup events on the input
            // Radio button
            if (inputElem.nodeName === 'INPUT' && inputElem.type === 'radio') {
                // Handle all radio buttons in the same group
                $('input[name="' + inputElem.name + '"]').each(function () {
                    // Check if it has already been set up
                    if (!this.hasAttribute(ATTRIBUTES.idList)) {
                        this.setAttribute(ATTRIBUTES.idList, field.id);

                        // Event listener
                        this.addEventListener('change', priv.onFieldChange, false);
                    }
                    else {
                        // Check whether this ID is already listed
                        ids = this.getAttribute(ATTRIBUTES.idList).split(',');

                        if (ids.indexOf(field.id) === -1) {
                            // Add this ID to the element
                            ids.push(field.id);
                            this.setAttribute(ATTRIBUTES.idList, ids.join(','));
                        }
                    }
                });
            }
            // Dropdown
            else if (inputElem.nodeName === 'OPTION') {
                // Handle all `<option>`s in the same `<select>`
                select = $(inputElem).closest('select').get(0);

                // Check if it has already been set up
                if (!select.hasAttribute(ATTRIBUTES.idList)) {
                    select.setAttribute(ATTRIBUTES.idList, field.id);

                    // Event listener(s)
                    select.addEventListener('change', priv.onFieldChange, false);

                    // Firefox doesn't fire `change` when arrowing through the options
                    if (priv.isFirefox) {
                        select.addEventListener('keyup', priv.forceSelectChangeEvent, false);
                    }
                }
                else {
                    // Check whether this ID is already listed
                    ids = select.getAttribute(ATTRIBUTES.idList).split(',');

                    if (ids.indexOf(field.id) === -1) {
                        // Add this ID to the element
                        ids.push(field.id);
                        select.setAttribute(ATTRIBUTES.idList, ids.join(','));
                    }
                }
            }
            // Check box
            else if (inputElem.nodeName === 'INPUT' && inputElem.type === 'checkbox') {
                // Event listener
                inputElem.addEventListener('change', priv.onFieldChange, false);
            }
            // Other elements (non-inputs)
            else {
                // Event listener
                inputElem.addEventListener('click', priv.onFieldChange, false);
            }

            // Look for a label
            labelElem = field.labelElem || priv.getLabelByInput(inputElem);

            // Establish the label/block relationship
            if (labelElem) {
                priv.addAttributeValue(labelElem, 'aria-owns', blockElem.id);
                priv.addAttributeValue(labelElem, 'aria-controls', blockElem.id);

                priv.addAttributeValue(blockElem, 'aria-labelledby', priv.establishElementId(labelElem));
            }

            // Setup descriptive attributes
            blockElem.setAttribute('role', 'region');
            blockElem.setAttribute('aria-live', 'polite');

            if (!blockElem.style.display || blockElem.style.display === 'none') {
                blockElem.setAttribute('aria-hidden', 'true');
                blockElem.setAttribute('aria-expanded', 'false');

                if (labelElem) {
                    labelElem.setAttribute('aria-expanded', 'false');
                }
            }
            else {
                blockElem.setAttribute('aria-hidden', 'false');
                blockElem.setAttribute('aria-expanded', 'true');

                if (labelElem) {
                    labelElem.setAttribute('aria-expanded', 'true');
                }
            }

            // Value
            if (!field.value || !FIELD_VALUES.hasOwnProperty(field.value)) {
                field.value = FIELD_VALUES.selected;
            }

            // Check whether this input is inside another 'parent' block
            parentBlock = $(inputElem).closest('.' + CLASSES.block).get(0);

            // This `field` is dependent on `parentBlock`
            if (parentBlock && parentBlock.id && blocks.hasOwnProperty(parentBlock.id)) {
                // Find all blocks associated with this field and add them as dependents
                priv.getBlocksByFieldId(parentBlock.id.replace(ID_SUFFIX.test, '')).forEach(function (block) {
                    block.dependents.push(field.id + ID_SUFFIX.value);
                });
            }

            // Save field object
            field.elem = inputElem;

            if (labelElem) {
                field.labelElem = labelElem;
            }

            itemsToKeep.push(field);
        });

        return itemsToKeep;
    };

    /**
     * Evaluates and input field's current state and decides whether to hide/show any dependent blocks
     * @param   {string}   fieldId     ID of the input field
     */
    priv.evaluateField = function _evaluateField (fieldId) {
        // Get all blocks associated with this field
        priv.getBlocksByFieldId(fieldId).forEach(function (block) {
            // Make sure there is a condition
            if (!block.show.condition) {
                return false;
            }

            // The condition is satisfied
            if (priv.isConditionSatisfied(block.show.condition)) {
                // Make sure all of the field's parents aren't hidden
                if (!priv.hasOnlyHiddenParentBlocks(block.id)) {
                    // Show the field
                    priv.showBlockByFieldId(block, fieldId);
                }
                // Else, do nothing since the act of hiding the parent would've already hidden this field
            }
            // The condition is not satisfied
            else {
                // Hide the field
                priv.hideBlockByFieldId(block, fieldId);
            }
        });
    };

    /**
     * Verifies whether a block's condition is currently satisfied
     * Will call itself recursively to resolve all sub-conditions
     * @param   {object}   cond  Condition object
     * @return  {boolean}        True if completely satisfied, otherwise false
     */
    priv.isConditionSatisfied = function _isConditionSatisfied (cond) {
        var isSatisfied = false;
        var numSatisfiedItems = 0;

        // Nothing to test for
        if (!cond.items || !cond.items.length) {
            isSatisfied = true;
        }
        // Check items
        else {
            // Count how many items are satisfied
            cond.items.forEach(function (itm) {
                // Item is a sub-condition
                if (itm.conditon) {
                    if (priv.isConditionSatisfied(itm.condition)) {
                        numSatisfiedItems++;
                    }
                }
                // Item is not an input
                if (itm.elem && !priv.isInputElement(itm.elem)) {
                    // Find the target item and see if it is currently visible
                    if (itm.hasBeenEvaluated > 1 && itm.$blockElem && itm.$blockElem.hasClass(CLASSES.hidden)) {
                        numSatisfiedItems++;
                    }

                    itm.hasBeenEvaluated++;
                }
                // Item is an input
                else {
                    // Element needs to be selected
                    if (itm.value === FIELD_VALUES.selected && priv.isElementSelected(itm.elem)) {
                        numSatisfiedItems++;
                    }
                    // Element needs to be unselected
                    else if (itm.value === FIELD_VALUES.unselected && !priv.isElementSelected(itm.elem)) {
                        numSatisfiedItems++;
                    }
                    // No other `value` supported at this time
                }
            });

            // All conditions were satisfied
            if (cond.operator === OPERATORS.and && numSatisfiedItems === cond.items.length) {
                isSatisfied = true;
            }
            // Some conditions were satisfied
            else if (cond.operator === OPERATORS.or && numSatisfiedItems > 0) {
                isSatisfied = true;
            }
        }

        return isSatisfied;
    };

    /**
     * Display a block and its dependents
     * @param   {object}  field  Field object
     */
    priv.showBlockByFieldId = function _showBlockByFieldId (block, fieldId) {
        var field = priv.getFieldById(fieldId);

        // Display the block
        priv.showElement(block.elem);
        block.isVisible = true;

        if (typeof block.config.onMatch === 'function') {
            block.config.onMatch(block.elem, field.elem, block);
        }

        // Update visibility attributes
        field.elem.setAttribute('aria-expanded', 'true');
        block.elem.setAttribute('aria-hidden', 'false');
        block.elem.setAttribute('aria-expanded', 'true');

        if (field.labelElem) {
            field.labelElem.setAttribute('aria-expanded', 'true');
        }

        // Check for dependent blocks which may also need to be displayed
        block.dependents.forEach(function (depId) {
            // Check for block object in the master list
            if (blocks.hasOwnProperty(depId)) {
                // Re-evaluate each field in this block
                blocks[depId].fieldIds.forEach(function (id) {
                    // Need to call `evalInput` since they may or may not need to be displayed
                    priv.evaluateField(id);
                });
            }
        });
    };

    /**
     * Hide the block for a given field, as well as any dependent (nested) blocks
     * @param   {object}  field  Field object
     */
    priv.hideBlockByFieldId = function _hideBlockByFieldId (block, fieldId) {
        var field = priv.getFieldById(fieldId);

        // Hide the block
        priv.hideElement(block.elem);
        block.isVisible = false;

        if (typeof block.config.onUnmatch === 'function') {
            block.config.onUnmatch(block.elem, field.elem, block);
        }

        // Update visibility attributes
        field.elem.setAttribute('aria-expanded', 'false');
        block.elem.setAttribute('aria-hidden', 'true');
        block.elem.setAttribute('aria-expanded', 'false');

        if (field.labelElem) {
            field.labelElem.setAttribute('aria-expanded', 'false');
        }

        // Check for dependent blocks which should also be hidden
        block.dependents.forEach(function (depId) {
            // Get block object from master list
            if (blocks.hasOwnProperty(depId)) {
                // Hide each field in this block
                blocks[depId].fieldIds.forEach(function (id) {
                    // No need to re-evaluate, the block must hide as long as its parents are all hidden
                    if (priv.hasOnlyHiddenParentBlocks(blocks[depId].id)) {
                        priv.hideBlockByFieldId(blocks[depId], id);
                    }
                });
            }
        });
    };

    /**
     * Make an element appear
     *
     * @param  {element} elem  DOM element
     */
    priv.showElement = function _showElement (elem) {
        if (!elem.classList.contains(CLASSES.hidden)) {
            return false;
        }

        // Make it visible but with zero height so we can expand it
        elem.style.height = '0px';
        elem.classList.remove(CLASSES.hidden);

        // Expansion animation
        $(elem).animate(
                    {
                        height: elem.scrollHeight,
                    },
                    {
                        easing: ANIMATION.easing,
                        duration: ANIMATION.duration,
                        done: function () {
                            this.style.height = 'auto';
                        },
                    }
                );
    };

    /**
     * Make an element disappear
     *
     * @param  {element} elem  DOM element
     */
    priv.hideElement = function _hideElement (elem) {
        // Check if it's already hidden
        if (elem.classList.contains(CLASSES.hidden)) {
            return false;
        }

        // Collapse animation
        $(elem).animate(
                    {
                        height: '0px',
                    },
                    {
                        easing: ANIMATION.easing,
                        duration: ANIMATION.duration,
                        done: function () {
                            // Even though the element is effectively invisible at this point, we'll use this class later use this class as a quick test of whether it's hidden or not
                            elem.classList.add(CLASSES.hidden);
                        },
                    }
                );
    };

    ///////////////
    // Utilities //
    ///////////////

    /**
     * Get the block objects associated with a given input field ID
     * @param   {string}  id  Input field ID
     * @return  {array}       All matching blocks
     */
    priv.getBlocksByFieldId = function _getBlocksByFieldId(id) {
        var matches = [];
        var i;

        // Loop through all blocks
        for (i in blocks) {
            // Look in the block's fields
            if (blocks.hasOwnProperty(i) && blocks[i].fieldIds.indexOf(id) !== -1) {
                matches.push(blocks[i]);
            }
        }

        return matches;
    };

    /**
     * Returns a field object given its ID
     * @param   {string}  id  Field ID
     * @return  {object}      Field object
     */
    priv.getFieldById = function _getFieldById (id) {
        var field;
        var obj;
        var i;
        var _checkId = function _checkId (elem) {
            if (elem.id === id) {
                field = elem;

                // Quit loop
                return true;
            }

            // Continue loop
            return false;
        };

        // Loop through all blocks
        for (i in blocks) {
            // Find a block containing this field
            if (blocks.hasOwnProperty(i) && blocks[i].fieldIds.indexOf(id) !== -1) {
                obj = blocks[i].show;

                // Loop through conditions
                while (!field && obj && obj.condition) {
                    obj.condition.items.some(_checkId);

                    // Get next sub-condition
                    obj = obj.condition;
                }

                // Quit if the field has been found
                if (field) {
                    break;
                }
            }
        }

        return field;
    };

    /**
     * Check if any parent blocks are visible
     * @param   {string}   blockId  block ID
     * @return  {boolean}             True if it has a parent and at least one is open, otherwise false
     */
    priv.hasOnlyHiddenParentBlocks = function _hasOnlyHiddenParentBlocks(blockId) {
        var ids = priv.getParentBlockIds(blockId);
        var numIds = ids.length;
        var i = 0;

        while (i < numIds) {
            if (blocks[ids[i]].isVisible) {
                // Return immediately if any visible parent is found
                return false;
            }

            i++;
        }

        return (ids.length > 0);
    };

    /**
     * Get the IDs of all blocks that list the given block as a dependent
     * @param   {string}  blockId  block ID
     * @return  {array}              List of block IDs
     */
    priv.getParentBlockIds = function _getParentBlockIds(blockId) {
        var ids = [];
        var i;

        // Loop through all blocks
        for (i in blocks) {
            if (blocks.hasOwnProperty(i)) {
                if (blocks[i].dependents.indexOf(blockId) !== -1) {
                    ids.push(i);
                }
            }
        }

        return ids;
    };

    /**
     * Checks whether the given block is a dependent
     * @param   {string}   id  ID of a child block
     * @return  {boolean}
     */
    priv.hasParentBlock = function _hasParentBlock(id) {
        var i;

        // Loop through all blocks
        for (i in blocks) {
            // Check for a block listing the given ID as a dependent
            if (blocks.hasOwnProperty(i) && blocks[i].dependents.indexOf(id) !== -1) {
                return true;
            }
        }

        return false;
    };

    /**
     * Gets the `<label>` element for a given input field
     * @param   {object}  inputElem  Input field
     * @return  {object}             The `<label>` element, or `null` if none was found
     */
    priv.getLabelByInput = function _getLabelByInput(inputElem) {
        // If the field is an `<option>`, get its parent `<select>`
        if (/^option$/i.test(inputElem.nodeName)) {
            inputElem = $(inputElem).closest('select').get(0);
        }

        // Find a `<label>` whose `for` attribute matches the field's ID
        return $('label[for="' + priv.establishElementId(inputElem) + '"]').get(0) || null;
    };

    /**
     * Adds a value to an attribute that contains a space-separated list
     *
     * @param   {element}  elem   DOM element
     * @param   {string}  attr    Attribute name
     * @param   {string}  value   Value to be appended
     *
     * @return  {string}          Updated attribute value
     */
    priv.addAttributeValue = function _addAttributeValue(elem, attr, value) {
        var values = '';

        if (elem.hasAttribute(attr)) {
            // Get existing values from the attribute
            values = elem.getAttribute(attr).trim();

            // Add this value to the list
            values += ' ' + value;
        }
        else {
            // No other values present, so just use the given one
            values = value;
        }

        // Update attribute
        elem.setAttribute(attr, values);

        return values;
    };

    /**
     * Determine if an element is selected or checked
     *
     * @param  {element}  elem  DOM element
     *
     * @return {boolean}        True if selected or checked
     */
    priv.isElementSelected = function _isElementSelected (elem) {
        // Radio button or check box
        if (elem.nodeName === 'INPUT' && (elem.type === 'checkbox' || elem.type === 'radio')) {
            return elem.checked;
        }
        // Dropdown
        else if (/^option$/i.test(elem.nodeName)) {
            return elem.selected;
        }

        // No other input types or elements are supported
        return false;
    };

    /**
     * Tells you what an element's ID is, after ensuring it has one
     *
     * Note that this will assign a unique ID to the element if it doesn't already have one
     *
     * @param   {element}  elem  Optional element that needs an ID
     * @return  {string}         The element's current or new ID
     */
    priv.establishElementId = function _establishElementId (elem) {
        var id = '';

        // See if the element already has an ID
        if (elem && typeof elem === 'object') {
            if (elem.id && typeof elem.id === 'string') {
                return elem.id;
            }
        }

        // Randomly generate an ID
        if (!id) {
            id += guid();
        }

        // Check whether this ID is already in use
        if (document.getElementById(id)) {
            // Try again (recursive call)
            id = priv.establishElementId(null);
        }

        // Set elements's ID
        if (elem && typeof elem === 'object') {
            elem.id = id;
        }

        return id;
    };

    priv.isInputElement = function _isInputElement (elem) {
        return /^input$|^select$|^option$|^textarea$/i.test(elem.nodeName);
    };

    ////////////
    // Events //
    ////////////

    /**
     * Handles the `change` event on an input and calls `priv.evaluateField()` on the appropriate field(s)
     *
     * @param   {event}  evt   `change` event
     */
    priv.onFieldChange = function _onFieldChange (evt) {
        var targ = evt.target;
        var fieldIds;

        if (targ.nodeName === 'A') {
            evt.preventDefault();
        }

        // Get list of related IDs
        fieldIds = targ.getAttribute(ATTRIBUTES.idList);

        // Selected an radio in the same group as another option that has a dependent block
        if (fieldIds && fieldIds.length) {
            // Evaluate each ID stored on the selected option
            fieldIds.split(',').forEach(function (id) {
                priv.evaluateField(id);
            });

            // This option might also have its own block
            priv.evaluateField(targ.id);
        }
        else {
            // This option only affects one block
            priv.evaluateField(targ.id);
        }
    };

    /**
     * Force an element to fire the `change` event (primarily for Firefox)
     * @param   {event}  evt   Keyboard event (`keyup`)
     */
    priv.forceSelectChangeEvent = function _forceSelectChangeEvent (evt) {
        evt.target.blur();
        evt.target.focus();
    };


    //////////////////////////////////////////
    // Expose public properties and methods //
    //////////////////////////////////////////

    DynamicBlock.defaults = DynamicBlock.prototype.defaults;

    DynamicBlock.version = VERSION;

    // Define jQuery plugin
    $.fn.dynamicBlock = function (options) {
        return this.each(function () {
            new DynamicBlock(this, options).init();
        });
    };
});

// Each condition contains an array of items and an operator
// Each item object contains either a condition or a field's ID/value

// Example: Show alpha when (bravo is checked AND (charlie is checked OR delta is unchecked)) OR (papa is checked AND oscar is checked)
// {
//     id: "alpha",
//     show: {
//         condition: {
//             items: [
//                 {
//                     condition: {
//                         items: [
//                             { id: "bravo", value: "checked" },
//                             {
//                                 condition: {
//                                     items: [
//                                         { id: "charlie", value: "checked" },
//                                         { id: "delta", value: "unchecked" }
//                                     ],
//                                     operator: "or"
//                                 }
//                             }
//                         ],
//                         operator: "and"
//                     }
//                 },
//                 {
//                     condition: {
//                         items: [
//                             { id: "papa", value: "checked" },
//                             { id: "oscar", value: "checked" }
//                         ],
//                         operator: "and"
//                     }
//                 }
//             ],
//             operator: "or"
//         }
//     }
// }
;
