define(['react', 'reactproptypes', 'jquery', 'popover', 'kind', 'TableSortIcon', 'renderer', 'RenderItem', 'RenderIconMessage', 'guid', 'TableColumnHeader'], function (React, ReactPropTypes, $, popover, kind, TableSortIcon, renderer, RenderItem, RenderIconMessage, guid, TableColumnHeader) {
    class TableHeaderControls extends React.Component {
        constructor (props) {
            super(props);
        }

        /////////////////////
        // Private methods //
        /////////////////////

        ////////////
        // Render //
        ////////////
        render(){

            const sourceTable = this.props.source;
            const headerControls = sourceTable.headerControls;
            const selectionType = sourceTable.selectionType;

            let isSmallSize = this.props.isSmallSize;
            let elems = [];

            //Render rest of footer control contents. This is a straight rendering of JSON elements
            if(headerControls && headerControls.contents && headerControls.contents.length > 0){
                headerControls.contents.forEach((col, c) => {
                    const controlItemSource = headerControls.contents[c];

                    // TODO: Look into updating key and removing row/col index.
                    elems.push(
                        <RenderItem
                            source={controlItemSource}
                            key={headerControls.key + this.props.rowIndex  + '-' + this.props.colIndex + '-' + c}
                            colIndex={this.props.colIndex}
                            rowIndex={this.props.rowIndex}
                        />
                    );
                });
            }

            // Only show the footer controls element if there are elements to show.
            if(elems.length && elems.length > 0){
                return (
                    <div className="feta-table-header-controls">
                        {elems}
                    </div>
                );
            }

            return null;
        }
    }

    TableHeaderControls.propTypes = {
        source: ReactPropTypes.shape({}).isRequired,
        isSmallSize: ReactPropTypes.bool,        
    };

    TableHeaderControls.defaultProps = {
        isSmallSize: false,        
    };

    return TableHeaderControls;
});
