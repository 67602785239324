define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    function TableRowCountDisplay (props) {
        const text = 'Showing ' + props.count + ' of ' + props.total;

        return (
            <div className="feta-table-numrows">
                {text}
            </div>
        );
    }

    TableRowCountDisplay.propTypes = {
        count: ReactPropTypes.number,
        total: ReactPropTypes.number,
    };

    TableRowCountDisplay.defaultProps = {
        count: 0,
        total: 0,
    };

    return TableRowCountDisplay;
});
