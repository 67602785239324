define(['react'], function (React) {
    // Source files: feta-table-sort.svg and feta-table-sort-outlines.svg
    function TableSortIcon (/* props */) {
        return (
            <svg
                className="feta-table-sort-icon"
                width="18"
                height="18"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
                <path
                    className="feta-table-sort-icon-up-fill"
                    fill="#aaa"
                     d="m20.64759,9.72548l-17.29619,0a1.24259,1.07386 0 0 1 -1.23551,-1.06688c0,-0.28287 0.13537,-0.5509 0.36772,-0.75083l8.64759,-7.47511c0.23134,-0.2008 0.53946,-0.31867 0.86779,-0.31867c0.32833,0 0.63645,0.11699 0.8688,0.31779l8.64961,7.47511c0.23235,0.19993 0.36671,0.46709 0.36671,0.75083c0,0.5832 -0.55967,1.06775 -1.23652,1.06775z"
                />
                <path
                    className="feta-table-sort-icon-down-fill"
                    fill="#aaa"
                    transform="rotate(180 11.999999046325682,18.984527587890625)"
                    d="m20.64758,23.79027l-17.29619,0a1.24259,1.07386 0 0 1 -1.23551,-1.06687c0,-0.28287 0.13537,-0.5509 0.36772,-0.75083l8.64759,-7.47511c0.23135,-0.20081 0.53947,-0.31867 0.86779,-0.31867c0.32833,0 0.63645,0.11699 0.8688,0.31779l8.64961,7.47511c0.23236,0.19993 0.36672,0.46709 0.36672,0.75083c0,0.5832 -0.55967,1.06775 -1.23653,1.06775z"
                />

                <path
                    className="feta-table-sort-icon-up-outline"
                    stroke="#666"
                    strokeWidth="1.09794"
                    fill="none"
                    d="m20.3329,9.75342l-16.66681,0a1.19713,1.03457 0 0 1 -1.19005,-1.02759c0,-0.27327 0.13032,-0.53082 0.35358,-0.72376l8.33543,-7.20184c0.22225,-0.19295 0.51926,-0.30644 0.83546,-0.30644c0.3162,0 0.61321,0.11175 0.83647,0.30557l8.33442,7.20184c0.22225,0.19207 0.35257,0.4505 0.35257,0.72376c0,0.56138 -0.53946,1.02846 -1.19207,1.02846l0.00101,0z"
                />
                <path
                    className="feta-table-sort-icon-down-outline"
                    stroke="#666"
                    strokeWidth="1.09794"
                    fill="none"
                    transform="rotate(180 11.999999046325682,18.984527587890625)"
                    d="m20.3329,23.51787l-16.66681,0a1.19713,1.03457 0 0 1 -1.19006,-1.02759c0,-0.27327 0.13032,-0.53082 0.35358,-0.72376l8.33543,-7.20185c0.22225,-0.19294 0.51926,-0.30644 0.83546,-0.30644c0.31621,0 0.61321,0.11175 0.83648,0.30557l8.33441,7.20184c0.22225,0.19208 0.35257,0.4505 0.35257,0.72377c0,0.56137 -0.53946,1.02846 -1.19207,1.02846l0.00101,0z"
                />
            </svg>
        );
    }

    return TableSortIcon;
});
