define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    const ReactCSSTransitionGroup = React.addons.CSSTransitionGroup;

    class TreeSearch extends React.Component {
        constructor (/* props */) {
            super();

            // Private method binding
            this._updateSearchResults = this._updateSearchResults.bind(this);
            this._onQueryChange = this._onQueryChange.bind(this);
            this._onQueryKeyUp = this._onQueryKeyUp.bind(this);
            this._onCancelButtonClick = this._onCancelButtonClick.bind(this);

            ///////////////
            // Constants //
            ///////////////

            this.defaultState = {
                query: '',
                searchResults: [],
                highlightIndex: -1,
                showCloseButton: true,
            };

            // Initial state
            this.state = this.defaultState;
        }


        ///////////////////
        // React methods //
        ///////////////////

        componentDidMount () {
            // Listen for when the tree is closed by some external component
            $(this._treeSearch).on('treeclose', function () {
                // Reset the Search section
                this.setState(this.defaultState);
            }.bind(this));

            // Specify whether to show the Close button
            if (this.props.source.search.closeButton !== this.state.showCloseButton) {
                // Make sure it's a boolean
                if (this.props.source.search.closeButton) {
                    this.setState({
                        showCloseButton: true,
                    });
                }
                else {
                    this.setState({
                        showCloseButton: false,
                    });
                }
            }
        }

        /////////////////////
        // Private methods //
        /////////////////////

        _updateSearchResults () {
            const results = [];
            let queries;
            const __testItem = function __testItem (item) {
                let matches = 0;

                if (item.suppress) {
                    return;
                }

                // Check each piece of the query against the item
                queries.forEach((query) => {
                    if (item.text.toLowerCase().includes(query)) {
                        matches++;
                    }
                });

                // See if all pieces match
                if (matches === queries.length) {
                    results.push(item);
                }

                // Check child items
                if (item.items) {
                    item.items.forEach(__testItem);
                }
            };

            // If a non-whitespace query was provided
            if (this.state.query.trim().length) {
                // Split the input into individual words
                queries = this.state.query
                            .trim()
                            .toLowerCase()
                            .replace(/\s+/g, ' ')
                            .split(' ');
                // console.log(queries.length + ' queries: ', JSON.parse(JSON.stringify(queries)));

                this.props.searchItems.forEach(__testItem);
            }

            this.setState({
                searchResults: results,
                highlightIndex: -1,
            });
        }

        _onQueryChange (evt) {
            this.setState({
                query: evt.target.value,
            }, this._updateSearchResults);
        }

        _onQueryKeyUp (evt) {
            let index;

            if (evt.which === 38 || evt.which === 40) {
                index = this.state.highlightIndex;

                // Up arrow
                if (evt.which === 38) {
                    // Go to the previous item up
                    if (index > 0) {
                        index--;
                    }
                    // Wrap around to the last item
                    else {
                        index = this.state.searchResults.length - 1;
                    }
                }
                // Down arrow
                else if (evt.which === 40) {
                    // Go to the next item down
                    if (index < this.state.searchResults.length - 1) {
                        index++;
                    }
                    // Wrap around to the first item
                    else {
                        index = 0;
                    }
                }

                this.setState({
                    highlightIndex: index,
                }, function () {
                    //TODO: Try to scroll the item into view...

                    // let $item = $('.feta-tree-search-result-highlighted');
                    // let itemTop = $item.offset().top;
                    // let itemBottom = itemTop + $item.height();
                    // let results = $('.feta-tree-search-results').get(0);
                    // let top = results.getBoundingClientRect().top;
                    // let bottom = results.getBoundingClientRect().bottom;
                    // let difference;

                    // console.log('item: ', itemTop, '\ncontainer top: ', top, '\ncontainer bottom: ', bottom);
                    // if (itemTop < top || itemBottom > bottom) {
                    //     difference = top - itemTop;
                    //     results.scrollBy(difference);
                    // }
                    // else if (itemBottom > bottom) {
                    //     difference = itemBottom - bottom;
                    //     results.scrollBy(difference);
                    // }
                });
            }
        }

        _onCancelButtonClick (/* evt */) {
            this.setState({
                query: '',
            }, this._updateSearchResults);
        }

        ////////////
        // Render //
        ////////////

        render () {
            const searchResultsList = [];
            const buttons = [];
            const wrapperClassNames = ['feta-tree-header'];
            const controlBarClassNames = ['feta-tree-control-bar'];

            if (this.state.searchResults.length) {
                searchResultsList.push(
                    <div key="feta-tree-search-results" className="feta-tree-search-results">
                        <ul>
                            {this.state.searchResults.map((result, r) => {
                                return (
                                    <li
                                        key={result.id}
                                        className={r === this.state.highlightIndex ? 'feta-tree-search-result-highlighted' : ''}
                                    >
                                        <a
                                            href={result.href}
                                            target={result.target}
                                        >
                                            {result.text}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );

                wrapperClassNames.push('feta-tree-search-has-results');
            }

            if (this.state.showCloseButton) {
                controlBarClassNames.push('feta-tree-has-close-button');

                buttons.push(
                    <div key="feta-tree-menu-control" className="feta-tree-menu-control">
                        <button type="button" className="feta-tree-menu-close">Close</button>
                        <button type="button" className="feta-tree-menu-cancel" onClick={this._onCancelButtonClick}>Cancel</button>
                    </div>
                );
            }

            // Note that the arrow function on the first line below is used to pass a reference to the element to `componentDidMount` so we don't have to crawl the DOM
            //TODO: Replace `<input>` with `<RenderItem>`? Also it needs a hidden `<label>`.
            return (
                <div
                    className={wrapperClassNames.join(' ')}
                    ref={(refToThisElem) => this._treeSearch = refToThisElem}
                >
                    <div className={controlBarClassNames.join(' ')}>
                        <div className="feta-tree-search-input">
                            <label
                            className="cui-hide-from-screen"
                            htmlFor={this.props.source.id + "_search"}
                            >
                                Search
                            </label>

                            <input
                                type="text"
                                value={this.state.query}
                                onChange={this._onQueryChange}
                                onKeyUp={this._onQueryKeyUp}
                                placeholder="Search"
                                id={this.props.source.id + "_search"}
                            />
                        </div>
                        {buttons}
                    </div>
                    <ReactCSSTransitionGroup
                        transitionName="feta-tree-search-results"
                        transitionEnter
                        transitionEnterTimeout={200}
                        transitionLeave
                        transitionLeaveTimeout={200}
                    >
                        {searchResultsList}
                    </ReactCSSTransitionGroup>
                </div>
            );
        }
    }

    TreeSearch.propTypes = {
        source: ReactPropTypes.any, //TODO: This should be `shape()`, but I'm having trouble with console errors when I use it (CP 1/30/17)
        searchItems: ReactPropTypes.arrayOf(ReactPropTypes.any),
    };

    TreeSearch.defaultProps = {
        source: {
            search: {
                searchButton: {},
            },
        },
        searchItems: [],
    };

    return TreeSearch;
});
