/*global feta */
/* eslint react/no-unused-prop-types: 0 */
define(['react', 'reactproptypes', 'TableRow'], function (React, ReactPropTypes, TableRow) {
    function TableHead (props) {
        const sourceTable = props.source;
        const rows = sourceTable.head.rows;
        const classNames = ['feta-table-elem-thead'];

        if (props.scrollTop) {
            classNames.push('feta-table-elem-thead-sticky');
        }

        if (sourceTable.floatingHeader) {
            if (feta.browser.supports.positionSticky) {
                classNames.push('feta-table-floating-thead');
            }
            else if (props.isDummy) {
                classNames.push('feta-table-dummy-thead');
            }
        }

        return (
            <props.tagNames.thead
                className={classNames.join(' ')}
                ref={(elem) => sourceTable.floatingHeader && props.setElemRef('thead' + (props.isDummy ? 'Dummy' : ''), elem)}
            >
                {rows.map((row, r) => {
                    return (
                        <TableRow
                            row={row}
                            tagNames={props.tagNames}
                            isSmallSize={props.isSmallSize}
                            key={row.key}
                            rowIndex={r}
                            isHeader
                            scrollTop={props.scrollTop}
                            source={sourceTable}
                            sortFunc={props.sortFunc}
                            getColDimensions={props.getColDimensions}
                            onSelectAllChange={props.onSelectAllChange}
                            onInputChange={props.onInputChange}
                            onCheckChange={props.onCheckChange}
                            getTableCell={props.getTableCell}
                        />
                    );
                })}
            </props.tagNames.thead>
        );
    }

    TableHead.propTypes = {
        source: ReactPropTypes.shape({}),
        scrollTop: ReactPropTypes.number,
        tagNames: ReactPropTypes.shape({}),
        isSmallSize: ReactPropTypes.bool,
        sortFunc: ReactPropTypes.func,
        getColDimensions: ReactPropTypes.func,
        onSelectAllChange: ReactPropTypes.func,
        onInputChange: ReactPropTypes.func,
        onCheckChange: ReactPropTypes.func,
        getTableCell: ReactPropTypes.func,
        setElemRef: ReactPropTypes.func,
        isDummy: ReactPropTypes.bool,
    };

    TableHead.defaultProps = {
        source: {},
        scrollTop: 0,
        tagNames: {},
        isSmallSize: false,
        sortFunc: () => {},
        getColDimensions: () => {},
        onSelectAllChange: () => {},
        onInputChange: () => {},
        onCheckChange: () => {},
        getTableCell: () => {},
        setElemRef: () => {},
        isDummy: false,
    };

    return TableHead;
});
