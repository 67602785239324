/* eslint "no-unused-vars": 0 */
// We need to include `TableColumn` here to appease RequireJS, otherwise we get an error about not finding `RenderIconSearch`
define(['react', 'reactproptypes', 'TableColumn'], function (React, ReactPropTypes, TableColumn) {
    const TableRowExpand = (props) => {
        const parentRow = props.parent;
        let expandedCells = null;
        const tagNames = JSON.parse(JSON.stringify(props.tagNames));
        const styles = Object.assign({}, props.inlineStyle); // We need to clone this to avoid the React error "Mutating `style` is deprecated. Consider cloning it beforehand." when we call `delete styles.display` below. (CP 1/31/17)

        // Force `<TableColumn/>` to use `<div>` instead of `<td>`
        tagNames.td = 'div';

        if (props.height) {
            styles.height = props.height + 'px';
        }

        // Visiblity
        if (!props.isVisible) {
            styles.display = 'none';
        }
        else if (styles.display === 'none') {
            delete styles.display;
        }

        // Add an additional row with the expanded column(s)
        if (parentRow.expand.contents) {
            const getColDimensions = props.getColDimensions;
            const onSelectRowChange = props.onSelectRowChange;
            const onSelectAllChange = props.onSelectAllChange;

            expandedCells = (
                <tagNames.expandCell
                    colSpan={parentRow.columns.length}
                    className="feta-table-row-expand-child"
                    key={parentRow.expand.key + '_feta-table-row-expand-child'}
                >
                    {parentRow.expand.contents.map((col) => props.getTableCell(
                                                            col,
                                                            props,
                                                            {
                                                                rowIndex: parentRow.index,
                                                                parentRow: parentRow,
                                                                getColDimensions: getColDimensions,
                                                                onSelectRowChange: onSelectRowChange,
                                                                onSelectAllChange: onSelectAllChange,
                                                                tagNames: tagNames,
                                                                isExpandChild: true,
                                                            }
                                                        )
                    )}
                </tagNames.expandCell>
            );
        }

        return (
            <tagNames.tr
                className="feta-table-elem-tr"
                style={styles}
                key={parentRow.expand.key}
            >
                {expandedCells}
            </tagNames.tr>
        );
    };

    TableRowExpand.propTypes = {
        parent: ReactPropTypes.shape({}),
        tagNames: ReactPropTypes.shape({}),
        inlineStyle: ReactPropTypes.shape({}),
        height: ReactPropTypes.number,
        isVisible: ReactPropTypes.bool,
        getColDimensions: ReactPropTypes.func,
        onSelectRowChange: ReactPropTypes.func,
        onSelectAllChange: ReactPropTypes.func,
        getTableCell: ReactPropTypes.func,
    };

    TableRowExpand.defaultProps = {
        parent: {},
        tagNames: {},
        inlineStyle: {},
        height: 0, //FIXME: Is this a sane value? (CP 1/31/17)
        isVisible: false,
        getColDimensions: () => {},
        onSelectRowChange: () => {},
        onSelectAllChange: () => {},
        getTableCell: () => {},
    };

    return TableRowExpand;
});
