define(['react', 'reactproptypes', 'InboxListItem', 'InboxListEmptyMsg'], function (React, ReactPropTypes, InboxListItem, InboxListEmptyMsg) {
    const InboxList = (props) => {
        // These three declarations are unnecessary but will appease a bug in ESLint for `react/no-unused-prop-types`
        const onItemClick = props.onItemClick;
        const currentIndex = props.currentIndex;
        const onDeleteClick = props.onDeleteClick;
        let messageList = props.listOfMessages;

        const onMessageClick = (...eventArgs) => {
            onItemClick(...eventArgs);
        };

        // Empty list
        if (!messageList.length) {
            messageList.push(InboxListEmptyMsg());
        }
        else {
            messageList = messageList.map((message, index) => {
                if (!message || message.isDeleted) {
                    return null;
                }

                return (
                    InboxListItem({
                        key: message.key,
                        message: message,
                        index: index,
                        currentIndex: currentIndex,
                        onMessageClick: onMessageClick,
                        onDeleteClick: onDeleteClick,
                        restoreMessage: props.restoreMessage,
                    })
                );
            });
        }

        return (
            <ul className="feta-inbox-list-list">
                {messageList}
            </ul>
        );
    };

    InboxList.propTypes = {
        currentIndex: ReactPropTypes.number,
        listOfMessages: ReactPropTypes.array,
        onItemClick: ReactPropTypes.func,
        onDeleteClick: ReactPropTypes.func,
        restoreMessage: ReactPropTypes.func,
    };

    InboxList.defaultProps = {
        currentIndex: 0,
        listOfMessages: [],
        onItemClick: () => {},
        onDeleteClick: () => {},
        restoreMessage: () => {},
    };

    return InboxList;
});
