define(['jquery', 'popover', 'renderer'], function ($, popover, renderer) {


    const NAMESPACE = 'progressBar';
    const VERSION = '1.0.0';

    const SUFFIX = "progressBar";
    const CLASS_NAMES = {
        wrapper: "feta-progress-bar",
        current:"feta-progress-bar-current",
        responsiveWrapper:"feta-progress-bar-responsive",
        responsiveCurrent:"feta-progress-bar-responsive-current",
        responsiveVisited:"feta-progress-bar-responsive-visited",
        responsiveUnvisited:"feta-progress-bar-responsive-unvisited",
        popoverOpen:"feta-progress-bar-responsive-open",
        linkStyle:"feta-style-link"
    };

    const progressDataDefault = {
        active:false,
        text:'',
        link:'',
    };

    let progressBarPopover;

    ////////////////////
    // Public methods //
    ////////////////////
    const _init = () =>{
        let wrapperElem;
        let progressBar;
        let progressItemsList;
        let progressItemsData = [];
        let currentStep;
        let activeCount = 0;

        wrapperElem = document.querySelector('.'+CLASS_NAMES.wrapper);
        if(!wrapperElem){
            //No wrapper so don't need to continue.
            return null;
        }

        progressBar = wrapperElem.querySelector("ol, ul");
        if(!progressBar){
            // journal.log({type: 'error', owner: 'UI', module: 'progressBar', submodule: 'init'}, 'No progress bar found.');
            return null;
        }

        progressItemsList = progressBar.querySelectorAll('li');
        if(!progressItemsList || progressItemsList.length < 1){
            journal.log({type: 'error', owner: 'UI', module: 'progressBar', submodule: 'init'}, 'No progress bar elements found.');
            return null;
        }

        //Build data object.
        progressItemsList.forEach((progressItem) => {
            let item = Object.assign({}, progressDataDefault);
            let itemLink;

            //Active check
            if(progressItem.classList.contains(CLASS_NAMES.current)){
                item.active = true;
                activeCount++;
            }

            itemLink = progressItem.querySelector('a, button');

            if(itemLink){
                item.text = itemLink.textContent;
                item.href = itemLink.getAttribute('href');
                item.onclick = itemLink.onclick;
            }
            else{
                item.text = progressItem.textContent;
            }

            progressItemsData.push(item);
        });

        if(activeCount > 1){
            journal.log({type: 'error', owner: 'UI', module: 'progressBar', submodule: 'init'}, 'Multiple active progress bar items set.');
        }

        if(progressItemsData.length>0){
            setupMobileProgressBar(progressItemsData);
        }
    };

    const setupMobileProgressBar = (dataObject) => {
        let wrapperElem;
        let responsiveProgressBar;
        let backgroundBar;
        let stepLabel;
        let stepLabelIndicator;
        let activeIndex = 0;
        let popoverList;

        wrapperElem = document.querySelector('.'+CLASS_NAMES.wrapper);

        popoverList = document.createElement("ol");

        dataObject.forEach((progressData, dataIndex) => {
            let progressItem = document.createElement("li");

            if(progressData.active){
                activeIndex = dataIndex;
                progressItem.classList.add(CLASS_NAMES.responsiveCurrent);
            }

            //build popover list.
            if(progressData.href){
                let progressLink = document.createElement('a');
                progressLink.href=progressData.href;
                progressLink.textContent = progressData.text
                progressItem.appendChild(progressLink);
                progressItem.classList.add(CLASS_NAMES.responsiveVisited);
            }else if(progressData.onclick){
                let progressButton = document.createElement('button');
                progressButton.onclick=progressData.onclick;
                progressButton.textContent = progressData.text
                progressButton.classList.add(CLASS_NAMES.linkStyle);
                progressItem.appendChild(progressButton);
                progressItem.classList.add(CLASS_NAMES.responsiveVisited);                
            }else {
                progressItem.textContent = progressData.text;

                //If not active, assume all text nodes are unvisited.
                if(!progressData.active){
                    progressItem.classList.add(CLASS_NAMES.responsiveUnvisited);
                }
            }

            popoverList.appendChild(progressItem);
        });

        //Does the responsive progressbar exist, if so, destroy it.
        responsiveProgressBar = wrapperElem.querySelector("." + CLASS_NAMES.responsiveWrapper);

        if(responsiveProgressBar){
            responsiveProgressBar.remove();
        }

        responsiveProgressBar = document.createElement('div');
        responsiveProgressBar.classList.add(CLASS_NAMES.responsiveWrapper);

        backgroundBar = document.createElement('div');
        backgroundBar.classList.add('feta-progress-bar-responsive-background');
        backgroundBar.style.width = ((activeIndex + 1) / dataObject.length * 100) + "%";
        responsiveProgressBar.appendChild(backgroundBar);

        stepLabel = document.createElement('span');
        stepLabel.tabIndex = 0;
        stepLabel.textContent = "Step " + (activeIndex+1) + " of " + dataObject.length;
        responsiveProgressBar.appendChild(stepLabel);

        wrapperElem.appendChild(responsiveProgressBar);

        if(progressBarPopover){
            progressBarPopover.destroy();
        }

        progressBarPopover = $.popover(responsiveProgressBar,{
            html: popoverList,
            display: {
                className: 'feta-button-menu-popover feta-progress-bar-popover',
            },
            location: 'below-center',
            resizeMobile: false,
            hideOnResize:true,
        });

        progressBarPopover.$popover.on('show.cui.popover', function() {
            responsiveProgressBar.classList.add(CLASS_NAMES.popoverOpen);
        });

        progressBarPopover.$popover.on('hide.cui.popover', function() {
            responsiveProgressBar.classList.remove(CLASS_NAMES.popoverOpen);
        });
    }

    //////////////////////////////////////////
    // Expose public properties and methods //
    //////////////////////////////////////////

    return {
        init:_init,
    };
});
