define(['react', 'reactproptypes', 'TreeFilter', 'TreeSearch', 'TreeBody'], function (React, ReactPropTypes, TreeFilter, TreeSearch, TreeBody) {
    class Tree extends React.Component {
        constructor (/* props */) {
            super();

            // Private method binding
            this._toggleList = this._toggleList.bind(this);
            this._onFilterChange = this._onFilterChange.bind(this);

            ///////////////
            // Constants //
            ///////////////

            this.CLASSES = {
                hidden: 'cui-hidden',
            };

            this.ANIMATION = {
                duration: 150,
                easing: 'easeInOutCubic',
            };

            // Initial state
            this.state = {
                itemList: null,
                isFiltered: false,
                itemsToSearch: [], // Complete list of links that can be searched
            };
        }


        ///////////////////
        // React methods //
        ///////////////////

        componentWillMount () {
            const itemList = this.props.source;
            let itemsToSearch = this.state.itemsToSearch;

            // Gather links to search
            itemList.items.forEach((section) => {
                if (!section.suppress && section.items && section.items.length) {
                    itemsToSearch = itemsToSearch.concat(section.items);
                }
            });

            this.setState({
                itemsToSearch,
                itemList,
            });
        }


        /////////////////////
        // Private methods //
        /////////////////////

        /**
         * Opens or closes a specific (sub-)list and updates the component state
         *
         * @param   {string}  targetId  ID of the list to toggle
         *
         * @return  {object}            The updated item list object
         */
        _toggleList (targetId, targetHeaderElem) {
            const itemList = this.state.itemList;
            let nextListState;
            let itemToSearch;

            // Check if the item is the top-level list
            if (itemList.id === targetId) {
                if (itemList.isOpen) {
                    itemList.isOpen = false;
                    nextListState = 'close';
                    itemList.userOpen = false;
                }
                else {
                    itemList.isOpen = true;
                    nextListState = 'open';
                    itemList.userOpen = true;
                }

                itemList.wasManuallyToggled = true;
            }
            else {
                // Keep track of which (sub-)list we're currently checking
                itemToSearch = itemList;

                // Find child item with at least a partial match of the target ID
                // Function is defined here to avoid re-defining it every time the `while` loop is run
                const findChildren = (parentItem) => {
                    // Exact item found
                    if (parentItem.id === targetId) {
                        if (parentItem.isOpen) {
                            parentItem.isOpen = false;
                            nextListState = 'close';
                            parentItem.userOpen = false;
                        }
                        else {
                            parentItem.isOpen = true;
                            nextListState = 'open';
                            parentItem.userOpen = true;
                        }

                        parentItem.wasManuallyToggled = true;
                        itemToSearch = null; // quit the `while` loop
                        return false; // quit the `some` loop
                    }
                    // Not found, and no more  child items to search
                    else if (!parentItem.items || !parentItem.items.length) {
                        itemToSearch = null; // quit the `while` loop
                        return false; // quit the `some` loop
                    }
                    // Need to search this sub-item next
                    else {
                        // itemToSearch = parentItem; // Continue the `while` loop
                        parentItem.items.some(findChildren); // Continue searching children.
                        return false; // quit the `some` loop
                    }
                };

                // Continue checking each list and sub-list until we find a match or there are no more sub-lists to check
                while (itemToSearch) {
                    if (!itemToSearch.items || !itemToSearch.items.length) {
                        break;
                    }

                    // Find the child item with at least a partial match of the target ID
                    itemToSearch.items.some(findChildren);
                }
            }

            // Toggle the list's visibility
            if (nextListState !== 'open' && nextListState !== 'close') {
                journal.log({type: 'error', owner: 'UI', module: 'Tree', submodule: '_toggleList'}, 'Invalid next state: ', nextListState, $(targetHeaderElem).get(0));
            }

            // Update the state
            this.setState({
                itemList,
            });

            return itemList;
        }

        // Filters the tree based on a user-entered query
        _onFilterChange (query) {
            const itemList = this.props.source;
            let pieces;
            let numPieces;
            let isFiltered;

            const __testList = (item, parentMatch) => {
                const matches = pieces.filter((piece) => {
                                    return (item.text && item.text.toLowerCase().includes(piece));
                                }).length;

                //reset item properties.
                item.isMatch = false;
                item.childMatch = false;
                item.parentMatch = false;
                item.isOpen = false;

                if (parentMatch) {
                    item.parentMatch = true;
                }

                if (matches === numPieces) {
                    item.isHighlighted = true;
                    item.wasManuallyToggled = false;
                    item.isOpen = true;
                    item.isMatch = true;
                }
                else {
                    item.isHighlighted = false;
                }

                if (item.items) {
                    const items = item.items;
                    const showChildren = (item.isMatch || parentMatch);

                    for (let i = 0; i < items.length; i++) {
                        if (__testList(items[i], showChildren)) {
                            item.childMatch = true;
                            item.isOpen = true;
                        }
                    }
                }

                return (item.isMatch || item.childMatch);
            }

            const __resetList = (item) => {
                item.isOpen = !!item.userOpen;

                if (item.items) {
                    const items = item.items;

                    for (let i = 0; i < items.length; i++) {
                        __resetList(items[i]);
                    }
                }

                return item.isOpen;
            }

            // If a non-whitespace query was provided
            if (query.trim().length && itemList.items) {
                // Split the input into individual words
                pieces = query
                            .trim()
                            .toLowerCase()
                            .replace(/\s+/g, ' ')
                            .split(' ');

                numPieces = pieces.length;
                isFiltered = true;

                // Update `itemList`
                __testList(itemList);
            }
            // No query, but it was filtered before so we need to reset the list so every item is shown
            else if (this.state.isFiltered) {
                pieces = [''];
                numPieces = 1;
                isFiltered = false;
                // Update `itemList`
                __resetList(itemList);
            }
            else {
                return;
            }

            this.setState({
                itemList,
                isFiltered,
            });
        }


        ////////////
        // Render //
        ////////////

        render () {
            let searchSection;
            let filterSection;

            if (!this.state.itemList) {
                return null;
            }

            // Search section
            if (this.state.itemList.search && this.state.itemList.search.type === 'text') {
                searchSection = (
                    <TreeSearch
                        source={this.state.itemList}
                        searchItems={this.state.itemsToSearch}
                    />
                );
            }
            // Filter section
            else if (this.state.itemList.filter && this.state.itemList.filter.type === 'text') {
                filterSection = (
                    <TreeFilter
                        source={this.state.itemList}
                        searchItems={this.state.itemsToSearch}
                        onFilterChange={this._onFilterChange}
                    />
                );
            }

            return (
                <div className="feta-tree">
                    {searchSection}
                    {filterSection}
                    <TreeBody
                        itemList={this.state.itemList}
                        toggleList={this._toggleList}
                        isFiltered={this.state.isFiltered}
                    />
                </div>
            );
        }
    }

    Tree.propTypes = {
        source: ReactPropTypes.shape({}),
    };

    Tree.defaultProps = {
        source: {},
    };

    return Tree;
});
