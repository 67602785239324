define(['jquery'], function ($) {
    // const NAMESPACE = 'clickBlocker';
    // const VERSION = '1.0.0';

    const SUFFIX = "blocker";
    const CLASS_NAMES = {
        blocker: "feta-click-blocker-spinner",
        wrapper: "feta-click-blocker-wrapper",
        pageBlocker: "feta-click-blocker-page",
        message: "feta-click-blocker-message"
    };

    ////////////////////
    // Public methods //
    ////////////////////

    const addClickBlocker = ($container) => {

        if(typeof $container !== 'jQuery'){
            $container = $($container);
        }

        //if the wrapper is present, there is already a blocker active.
        if($container.hasClass(CLASS_NAMES.wrapper) || $container.hasClass(CLASS_NAMES.blocker)){
            return;
        }

        $container.addClass(CLASS_NAMES.wrapper);

        //TODO: if click blocker is being applied to a 'large' element, add a larger spinner and loading please wait message. Doing this can shift css selector of form.click-blocker-wrapper to just adding the class to teh click blocker itself for sizes/styles. Other options can then be added as needed, size + message etc.

        const $blocker = $('<div/>', {
                            'id': $container[0].id +"_"+ SUFFIX,
                            'class': CLASS_NAMES.blocker,
                            // 'html': '<p>Loading, Please Wait.</p>' //Message for large spinner.
                        });
        $container.append($blocker);
    };

    const removeClickBlocker = ($container) => {
        if(typeof $container !== 'jQuery'){
            $container = $($container);
        }

        $container.find('.'+CLASS_NAMES.blocker).remove();
        $container.removeClass(CLASS_NAMES.wrapper);
    };

    const blockPage = () => {
        const $blocker = $('<div/>', {
            'class': CLASS_NAMES.blocker + " feta-click-blocker-page",
            // 'html': '<p>Loading, Please Wait.</p>' //Message for large spinner.
        });

        $("body").append($blocker);
    };

    const createPageLoadingMessage = () => {
         const $blocker = $('<div/>', {
            'class': CLASS_NAMES.blocker + " feta-click-blocker-page",
            'html': '<p class="'+CLASS_NAMES.message+'">Loading, Please Wait.</p>' //Message for large spinner.
        });

        $("body").addClass(CLASS_NAMES.wrapper);
        $("body").append($blocker);
    };

    const removeFromPage = () => {
        $("body").find('.feta-click-blocker-page').remove();
    };

    //////////////////////////////////////////
    // Expose public properties and methods //
    //////////////////////////////////////////

    return {
        addClickBlocker,
        removeClickBlocker,
        blockPage,
        removeFromPage,
        createPageLoadingMessage
    };
});
