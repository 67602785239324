/*global feta */
define(['react', 'reactproptypes', 'TableHead', 'TableBody', 'TableFoot', 'TableFilter', 'TableResizer', 'TableColumn', 'TableRowCountDisplay', 'TableUtils', 'TableFooterControls', 'TableHeaderControls', 'TableLegend', 'TableControls', 'TablePaging', 'guid'], function (React, ReactPropTypes, TableHead, TableBody, TableFoot, TableFilter, TableResizer, TableColumn, TableRowCountDisplay, TableUtils, TableFooterControls, TableHeaderControls, TableLegend, TableControls, TablePaging, guid) {
    class Table extends React.Component {
        constructor (props) {
            super(props);

            // Private method binding
            this._updateFilterBasicValue = this._updateFilterBasicValue.bind(this);
            this._getColDimensions = this._getColDimensions.bind(this);
            this._updateColDimensions = this._updateColDimensions.bind(this);
            this._updateFilterAdvCheckbox = this._updateFilterAdvCheckbox.bind(this);
            this._applySorting = this._applySorting.bind(this);
            this._applyAdvancedFilter = this._applyAdvancedFilter.bind(this);
            this._updateViewRows = this._updateViewRows.bind(this);
            this._onShowMoreClick = this._onShowMoreClick.bind(this);
            this._onSelectAllChange = this._onSelectAllChange.bind(this);
            this._onSelectAllButtonClick = this._onSelectAllButtonClick.bind(this);
            this._onSelectRowChange = this._onSelectRowChange.bind(this);
            this._onInputChange = this._onInputChange.bind(this);
            this._onCheckChange = this._onCheckChange.bind(this);
            this._onWindowResize = this._onWindowResize.bind(this);
            this._isWindowSmall = this._isWindowSmall.bind(this);
            this._determineRowsToRender = this._determineRowsToRender.bind(this);
            this._onTableScroll = this._onTableScroll.bind(this);
            this._onResizerClick = this._onResizerClick.bind(this);
            this._onExpandableRowClick = this._onExpandableRowClick.bind(this);
            this._fetchExpandContent = this._fetchExpandContent.bind(this);
            this._onClickSelectableRow = this._onClickSelectableRow.bind(this);
            this._getTableCell = this._getTableCell.bind(this);
            this._setupFakeFloatingHeader = this._setupFakeFloatingHeader.bind(this);
            this._onWindowScroll = this._onWindowScroll.bind(this);
            this._setElemRef = this._setElemRef.bind(this);
            this._initJqueryComponents = this._initJqueryComponents.bind(this);

            this._onExpandAllControlClick = this._onExpandAllControlClick.bind(this);
            this._onCollapseAllControlClick = this._onCollapseAllControlClick.bind(this);

            this._onFooterControlRemoveClick = this._onFooterControlRemoveClick.bind(this);
            this._onFooterControlRemoveRow = this._onFooterControlRemoveRow.bind(this);

            this._onFooterControlRemoveAllClick = this._onFooterControlRemoveAllClick.bind(this);
            this._onFooterControlRemoveAllCallback = this._onFooterControlRemoveAllCallback.bind(this);

            this._getSelectedRowSelectionIds = this._getSelectedRowSelectionIds.bind(this);
            this._getAllRowSelectionIds = this._getAllRowSelectionIds.bind(this);
            this._onClearSelectionControlClick = this._onClearSelectionControlClick.bind(this);

            ///////////////
            // Constants //
            ///////////////

            this.ORIGINAL_TABLE = null; // The original table object. This should only serve as a reference and shouldn't be altered. Add new properties to the component class if you need to save any calculated data.

            this.SMALL_SIZE_THRESHOLD = 768; // SMALL_SIZE_THRESHOLD size should be in line with media query breakpoints, measuring screen size, not element size. Any nesting of tables/elements will lead to inconsistant rendering methods at specific breakpoints when using the elements width.

            this.ROWS_TO_ADD_UPON_EACH_SCROLL = 20;

            // Viewport
            this.DEFAULT_INITIAL_ROWS = 10; // This should match `this.numRowsVisibleInViewport`
            this.DEFAULT_MIN_ROWS = 5;
            this.DEFAULT_MAX_ROWS = 25;

            // Tag names to be used by the table elements, organized by screen size
            this.TAG_NAMES = {
                largeSize: {
                    table: 'table',
                    thead: 'thead',
                    tbody: 'tbody',
                    tfoot: 'tfoot',
                    tr: 'tr',
                    td: 'td',
                    th: 'th',
                    expandCell: 'td',
                },
                smallSize: {
                    table: 'div',
                    thead: 'div',
                    tbody: 'div',
                    tfoot: 'div',
                    tr: 'div',
                    td: 'div',
                    th: 'div',
                    expandCell: 'div',
                },
            };

            ////////////////////////
            // Mutable properties //
            ////////////////////////

            this.sourceTable = null; // A copy of the table object with all rows which is used primarily for sorting. Refer to this version of the table in nearly all situations unless you know what you're doing. The only exception I've found so far is that I need `ORIGINAL_TABLE` to undo all sorting.
            this.colSorting = {}; // Associated array of columns with their current sort direction ('asc' or 'desc')
            this.lastSortedCol = -1;
            this.scrollTop = 0;
            this.$tableWrapper = null; // The `<div>` that immediately surrounds the `<table>`
            this.headerCellElems = [];
            this.showMoreRowsStep = 10; // How many more rows to show at a time when clicking "Show More" on small screens
            this.numRowsVisibleInViewport = 10; // How many rows are visible without scrolling
            this.resizeSteps = { // Number of rows to display when using the resizer. These values will be overwritten in many cases.
                initial: {
                    numRows: 10, // Will match `viewport.height.initial` when available
                },
                min: {
                    numRows: 5,  // Will be generally half of `initial` but with a minimum of 3 for UX reasons
                },
                max: {
                    numRows: 25, // Will match the total number of rows, but max out at 25 for UX reasons
                },
            };
            this.totalRowsAvailable = -1; // Total number of rows in client + server
            this.filterBasicRules = null;

            // Refs
            this.scrollableElem = null;
            this.theadElem = null;
            this.fakeFloatingThead = null;
            this.fakeFloatingFilter = null;

            //TODO: Remove this when the fake version of the header is working
            if (props.source.floatingHeader && feta && !feta.browser.supports.positionSticky) {
                props.source.floatingHeader = false;
            }

            ///////////////////
            // Initial state //
            ///////////////////

            this.state = (function () {
                const _getDefaultState = function (props) {
                    const heightPerRow = 28;  // Magic number; just happens to be the height of a non-wrapping body row with late 2016 styles
                    let headerRowHeight = 36; // Magic number; just happens to be the height of a non-wrapping header row with late 2016 styles
                    let numDisplayRows;
                    let maxRowsToRender = null;

                    // Header row is taller if there's sorting
                    if (props.source.sortCols) {
                        headerRowHeight = 39; // Magic number; just happens to be the height of a non-wrapping header row with sortable column(s) with late 2016 styles
                    }

                    //Disable table scrolling
                    if (props.source.viewport) {
                        journal.log({type: 'warning', owner: 'UI', module: 'Table', submodule: 'getInitialState'}, 'Table scrolling is currently not a supported feature. Please remove the `viewport` property from the table json. ', JSON.parse(JSON.stringify(props.source.viewport)));

                        props.source.viewport = false;
                    }

                    // Viewport should use the default settings
                    if (props.source.viewport === true) {
                        props.source.viewport = {
                            height: {
                                initial: this.DEFAULT_INITIAL_ROWS,
                                min: this.DEFAULT_MIN_ROWS,
                                max: this.DEFAULT_MAX_ROWS,
                            },
                        };
                    }

                    // Viewport settings
                    if (props.source.viewport && props.source.viewport.height && props.source.viewport.height.initial) {
                        this.numRowsVisibleInViewport = props.source.viewport.height.initial;
                        numDisplayRows = props.source.viewport.height.initial;
                    }
                    else {
                        numDisplayRows = props.source.body.rows.length;
                    }

                    if(!props.source.type){
                        props.source.type = "default";
                    }

                    // Total number of rows (client + server)
                    if (props.source.paging && props.source.paging.total) {
                        this.totalRowsAvailable = props.source.paging.total;
                    }
                    else {
                        this.totalRowsAvailable = props.source.body.rows.length;
                    }

                    if(props.source.maxRowsToRender){
                        maxRowsToRender = props.source.maxRowsToRender;
                    }

                    // Set resizer settings
                    this.resizeSteps.initial.numRows = numDisplayRows;
                    this.resizeSteps.max.numRows = Math.min(25, props.source.body.rows.length);
                    this.resizeSteps.min.numRows = Math.min(5, (props.source.body.rows.length / 2));

                    const viewportHeight = this._calcViewportHeight(heightPerRow, this.numRowsVisibleInViewport, headerRowHeight);

                    const visibleEnd = Math.min(this.numRowsVisibleInViewport, props.source.body.rows.length - 1);
                    const displayEnd = Math.min(this.numRowsVisibleInViewport * 2, numDisplayRows - 1);

                    return {
                        heightPerRow,
                        viewportHeight,
                        isScrollable: false,
                        headerRowHeight,
                        displayRows: props.source.body.rows,
                        total: props.source.body.rows.length,
                        numDisplayRows,
                        visibleStart: 0,
                        visibleEnd,
                        displayStart: 0,
                        displayEnd,
                        resizeStep: 'initial',
                        maxRowsToRender,
                    };
                }.bind(this);

                const theState = _getDefaultState(props);
                let displayRows = [];
                let numDisplayRows = 0;

                if (!props || !props.source) {
                    journal.log({type: 'error', owner: 'UI', module: 'Table', submodule: 'getInitialState'}, 'No props or source table available. Value of `props` and `this`, respectively: ', JSON.parse(JSON.stringify(props)), JSON.parse(JSON.stringify(this)));

                    // This is the minimum state needed to render without throwing errors, though no rows will be displayed on the page
                    return theState;
                }

                // Set properties that won't change
                this.ORIGINAL_TABLE = JSON.parse(JSON.stringify(props.source)); // Make a copy so changes to `sourceTable` don't affect `ORIGINAL_TABLE`
                // this.sourceTable = props.source;

                let updatedSource = TableUtils.updateTableJSON(props.source);

                this.sourceTable = TableUtils.normalizeSourceTable(updatedSource);

                //TODO
                //Normalize sourceTable. Currently code that performs this is outside of the table component. It should be converted for more modular functions and brought inside the component for cases where the table is adding / updating content after initialization.

                // Viewport
                if (this.sourceTable.viewport && this.sourceTable.viewport.height) {
                    numDisplayRows = this.sourceTable.viewport.height.initial;
                    this.showMoreRowsStep = this.sourceTable.viewport.height.step;
                    theState.isScrollable = (numDisplayRows < this.sourceTable.body.rows.length);
                }
                // Determine how many rows to display initially
                else if (this.sourceTable.view && Object.prototype.hasOwnProperty.call(this.sourceTable.view, 'initialValue')) {
                    journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'getInitialState'}, 'Table is using deprecated `view` property, use `viewport` instead: ', JSON.parse(JSON.stringify(this.sourceTable)));
                    // A reduced view has been defined
                    numDisplayRows = parseInt(this.sourceTable.view.initialValue, 10);
                }

                // Add support for special style/legacy styles.
                if (this.sourceTable && typeof this.sourceTable.renderStyle === 'string') {
                    journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'getInitialState'}, 'Table is using deprecated `renderStyle` property: ', JSON.parse(JSON.stringify(this.sourceTable.renderStyle)));
                }


                // Get the rows that can be displayed
                if (this.sourceTable.body && this.sourceTable.body.rows) {
                    displayRows = this.sourceTable.body.rows;

                    // Display all rows by default
                    if (!numDisplayRows) {
                        numDisplayRows = this.sourceTable.body.rows.length;
                    }
                }
                else {
                    journal.log({type: 'error', owner: 'UI', module: 'Table', submodule: 'getInitialState'}, 'Table has no body rows: ', JSON.parse(JSON.stringify(this.sourceTable)));
                }

                theState.numDisplayRows = numDisplayRows;
                theState.isSmallSize = this._isWindowSmall();
                theState.header = {};

                // Rows that are actually being displayed at the moment (i.e. after filtering and pagination have whittled down the list)
                theState.displayRows = displayRows;

                return theState;
            }.bind(this)());
        }


        ///////////////////
        // React methods //
        ///////////////////

        componentWillMount () {
            const sourceTable = this.sourceTable;
            const newState = this.state;
            const lineBreakRegex = /(<\/?br\s*\/?>)/; // Regex to find `<br>` tags. Note that the parentheses will keep the tag in place when using `str.split`, see http://stackoverflow.com/a/12001989/348995

            // Replaces "<br>" with an actual `<br>` element in an array of strings
            const _replaceLineBreak = (words) => {
                const resultWords = [];
                let isChanged = false;

                words.forEach((word) => {
                    // Has a line break
                    if (lineBreakRegex.test(word)) {
                        const pieces = word.split(lineBreakRegex);

                        pieces.forEach((piece) => {
                            if (lineBreakRegex.test(piece)) {
                                resultWords.push(<br key={guid()} />);
                                isChanged = true;
                            }
                            else {
                                resultWords.push(piece);
                            }
                        });
                    }
                    // Does not have a line break
                    else {
                        resultWords.push(word);
                    }

                    // Insert a space before the next word
                    resultWords.push(' ');
                });

                // Only return the array if something changed
                if (isChanged) {
                    // Remove the last space added at the end of the loop
                    resultWords.pop();

                    return resultWords;
                }
                else {
                    return null;
                }
            };

            // Check for filtering
            if (sourceTable.filter) {
                if (!newState.filter) {
                    newState.filter = {};
                }

                // Basic
                if (sourceTable.filter.basic) {
                    if (!newState.filter.basic) {
                        newState.filter.basic = {};
                    }

                    // Add initial value to the state (i.e. pre-fill for the text input)
                    newState.filter.basic.value = sourceTable.filter.basic.query;
                }

                if (!newState.filter.basic.value) {
                    newState.filter.basic.value = sourceTable.filter.basic.query;
                }

                this.filterBasicRules = sourceTable.filter.basic.rules;

                // Validate the advanced filters
                if (sourceTable.filter.advanced && sourceTable.filter.advanced.rows) {
                    newState.filter.advanced = {
                        rows: [],
                    };

                    sourceTable.filter.advanced.rows.forEach((sourceRow, sourceRowIndex) => {
                        let needToDeleteRows = false;

                        // Check box or radio button list
                        //TODO: Legacy
                        if (sourceRow.type === 'checkbox-any' || sourceRow.type === 'checkbox-all' || sourceRow.type === 'radio-list') {
                            // Add an object for each check box to the state
                            sourceRow.rules.forEach((rule, ruleIndex) => {
                                rule.isRadio = (sourceRow.type === 'radio-list');
                                rule.isCheckbox = /^checkbox\-/.test(sourceRow.type);

                                rule.comparisons.forEach((comparison, c) => {
                                    // There must be a `value` to compare against, no matter what type of match this is
                                    if (typeof comparison.value === 'undefined') {
                                        journal.log({type: 'error', owner: 'UI', module: 'Table'}, 'advanced filter test has no `value` property: ', JSON.parse(JSON.stringify(comparison)), JSON.parse(JSON.stringify(rule)));

                                        // Remove the comparison from the rule
                                        rule.comparisons.splice(c, 1);
                                    }
                                });

                                // Toss out any rule list that has no valid comparisons
                                if (!rule.comparisons || !rule.comparisons.length) {
                                    journal.log({type: 'warn', owner: 'UI', module: 'Table'}, 'advanced filter has no valid comparisons, ignoring it: ', JSON.parse(JSON.stringify(rule)));

                                    // Mark this row for removal. If we removed it right now it would shorten the array and we'd skip the next rule.
                                    rule.deleteMe = true;
                                    needToDeleteRows = true;
                                }

                                // A `value` is not required by the server, but we need one to track when check boxes and radios are checked/unchecked
                                if (!rule.value) {
                                    rule.value = guid();
                                }

                                // Replace the rule object in the array with our updated version
                                sourceRow.rules[ruleIndex] = rule;
                            });

                            newState.filter.advanced.rows.push(sourceRow);

                            // Remove bad rule objects from `sourceTable` since that's what `<TableFilterAdvanced/>` references when it renders the rules
                            if (needToDeleteRows) {
                                sourceTable.filter.advanced.rows[sourceRowIndex].rules = sourceTable.filter.advanced.rows[sourceRowIndex].rules.filter((rule) => !rule.deleteMe );
                            }
                        }
                        else {
                            journal.log({type: 'error', owner: 'UI', module: 'Table', submodule: 'componentWillMount'}, 'Unsupported advanced filter type: "' + sourceRow.type + '"', sourceRow);
                        }
                    });
                }
            }

            // Look for line breaks and add soft hyphens (auto line-breaks) in long words
            // We have to do this in JSX so that we can insert the `<br>` and `<wbr>` as an actual element rather than a string of HTML
            // sourceTable.head.rows.forEach((row) => {
            //     row.columns.forEach((col) => {
            //         let words = [];
            //         let isChanged = false;

            //         if (!col.text) {
            //             return;
            //         }

            //         // Handle long words
            //         col.text.split(/(\s+|(?:<\/?br\s*\/?>))/).forEach((word) => {
            //             if (!/^\s+$/.test(word)) {
            //                 if (word.length >= 10 && !col.dontBreak) {
            //                     isChanged = true;

            //                     words.push(word.substr(0, 6));
            //                     words.push(<wbr key={col.key + '_wbr_' + col.text.length} />);
            //                     words.push(word.substr(6));
            //                 }
            //                 else {
            //                 words.push(word);
            //                 }

            //                 // Insert a space before the next word
            //                 words.push(' ');
            //             }
            //         });

            //         // Remove the last space added at the end of the loop
            //         words.pop();

            //         // Handle line breaks
            //         const resultWords = _replaceLineBreak(words);

            //         // Only update the table if we actually added a break
            //         if (resultWords) {
            //             isChanged = true;
            //             words = resultWords;
            //         }

            //         // Only update the table if we actually added something
            //         if (isChanged) {
            //             col.texts = words;
            //         }
            //     });
            // });

            // Look for line breaks in the body
            sourceTable.body.rows.forEach((row) => {
                row.columns.forEach((col) => {
                    if (!col.text) {
                        return;
                    }

                    const resultWords = _replaceLineBreak(col.text.split(/\s+/));

                    // Only update the table if we actually added a break
                    if (resultWords) {
                        col.texts = resultWords;
                    }
                });
            });

            newState.header = sourceTable.head;

            // Apply filtering
            this._applyFilters(newState);
        }

        componentDidMount () {
            let heightPerRow = this.state.heightPerRow;
            let headerRowHeight = this.state.headerRowHeight;
            const newState = {};

            // Determine the header row height
            headerRowHeight = this.$tableWrapper.find('thead').height();

            // Store the value if it changed
            if (headerRowHeight !== this.state.headerRowHeight) {
                newState.headerRowHeight = headerRowHeight;
            }

            // Determine the body row height
            heightPerRow = this._getOverallRowHeight();

            // Store the value if it changed
            if (heightPerRow !== this.state.heightPerRow) {
                newState.heightPerRow = heightPerRow;
            }

            // Calculate a new viewport height if either the header or body heights changed
            if (newState.heightPerRow || newState.headerRowHeight) {
                if (newState.heightPerRow) {
                    newState.viewportHeight = this._calcViewportHeight(newState.heightPerRow, this.numRowsVisibleInViewport, newState.headerRowHeight);
                }
                else {
                    newState.viewportHeight = this._calcViewportHeight(heightPerRow, this.numRowsVisibleInViewport, newState.headerRowHeight);
                }
            }

            // Check for a change in screen size
            const newScreenSizeValue = this._isWindowSmall();

            if (newScreenSizeValue !== null) {
                newState.isSmallSize = newScreenSizeValue;
            }

            // Only set the state if there is an actual change
            if (newScreenSizeValue !== null || newState.viewportHeight) {
                this.setState(newState);
            }

            // Watch for screen size changes for responsive layoyts
            window.addEventListener('resize', this._onWindowResize, false);
            window.addEventListener('orientationchange', this._onWindowResize, false);

            this._initJqueryComponents();

            // Setup floating header
            if (this.sourceTable.floatingHeader && feta && !feta.browser.supports.positionSticky && !this.fakeFloatingThead) {
                this._setupFakeFloatingHeader();
            }
        }

        componentWillUnmount() {
            //Remove bound events when node unmounts.
            window.removeEventListener('resize', this._onWindowResize, false);
            window.removeEventListener('orientationchange', this._onWindowResize, false);
        }

        componentDidUpdate(prevProps, prevState) {
        	this._initJqueryComponents();
            feta.iflowFieldMethods.setupContainer(this.$tableWrapper[0]);
        }

        /////////////////////
        // Private methods //
        /////////////////////

        _initJqueryComponents() {
        	// jQuery and react do not share onChange events. bind a separate onchange event to trigger the react onchange method for fields that rely on manually triggering jQuery.onChange like datepickers. 

        	const jqueryInputChange = (evt) =>{
        		this._onInputChange(evt);
        	};

        	//Setup any jquery driven controls like date pickers.            
            this.$tableWrapper.find('.cui-date').each(function(index, elem){
            	$(elem).off("change", jqueryInputChange);
            	$(elem).datepicker();
            	$(elem).on("change", jqueryInputChange);	
            });
        }

        ///////////////
        // Filtering //
        ///////////////

        _applyFilters (workingCopyOfState) {
            const needToUpdateState = (typeof workingCopyOfState !== 'undefined');

            // Use the current state if none was provided (it's optional)
            if (!needToUpdateState || typeof workingCopyOfState !== 'object') {
                workingCopyOfState = this.state;
            }

            // Quit immediately if the table doesn't have anything to filter
            if (!workingCopyOfState.filter) {
                // But do apply the provided state first
                if (needToUpdateState) {
                    this.setState(workingCopyOfState);
                }

                return;
            }

            let rowsToKeep = [].concat(this.sourceTable.body.rows); // Start out with all rows

            // Apply basic filter first since it's performant and may get rid of many rows before the heavier advanced filtering is applied
            if (workingCopyOfState.filter.basic) {
                rowsToKeep = this._applyBasicFilter(rowsToKeep, workingCopyOfState);
            }

            // Apply advanced filters
            if (workingCopyOfState.filter.advanced) {
                rowsToKeep = this._applyAdvancedFilter(rowsToKeep, workingCopyOfState);
            }

            // No rows left to display after filtering
            if (rowsToKeep.length === 0) {
                this.sourceTable.isEmptyDueToFilters = true;
            }
            else {
                this.sourceTable.isEmptyDueToFilters = false;
            }

            // Update the state object
            workingCopyOfState.displayRows = rowsToKeep;
            workingCopyOfState.numDisplayRows = rowsToKeep.length;

            // Apply the state
            this.setState(workingCopyOfState);
        }

        // Applies the basic filter to the given row array
        _applyBasicFilter (rowsToFilter, workingCopyOfState) {
            const rowsToKeep = [];
            const prevState = workingCopyOfState || this.state;
            let filterBasicValue = prevState.filter.basic.value;

            // No filter to be applied
            if (!filterBasicValue) {
                return rowsToFilter;
            }

            //Get array of ignored characters.
            const ignoredCharacters = this.filterBasicRules.matchText.ignoredCharacters;

            filterBasicValue = filterBasicValue.toLowerCase();

            // Remove any ignored characters from search text.
            if (ignoredCharacters && ignoredCharacters.length > 0) {
                filterBasicValue = this._removeIgnoredCharacters(filterBasicValue, ignoredCharacters);
            }

            // Loop through rows
            rowsToFilter.forEach((row) => {
                let foundMatch = false;

                // Test each column against the filter string
                this.filterBasicRules.matchText.columns.forEach((colIndex) => {
                    if (!foundMatch && row.columns && row.columns[colIndex]) {
                        if (row.columns[colIndex].text) {
                            let compareText = row.columns[colIndex].text.toLowerCase();

                            // Remove any ignored characters from compare text.
                            if (ignoredCharacters && ignoredCharacters.length > 0) {
                                compareText = this._removeIgnoredCharacters(compareText, ignoredCharacters);
                            }

                            if (compareText.includes(filterBasicValue)) {
                                foundMatch = true;
                            }
                        }
                    }
                });

                if (foundMatch) {
                    rowsToKeep.push(row);
                }
            });

            return rowsToKeep;
        }

        //Accepts a string and a single or array of ignored characters.
        _removeIgnoredCharacters(string, ignoredCharacters) {
            let updatedString = string;

            // Escape special characters and return a new regexp.
            const _createIgnoreRegExp = function (character) {
                return new RegExp(character.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&'),'g');
            };

            if (typeof ignoredCharacters === 'string') {
                return updatedString.replace(_createIgnoreRegExp(ignoredCharacters), '');
            }
            else if (Array.isArray(ignoredCharacters)) {
                ignoredCharacters.forEach((ignoreCharacter) =>{
                    if (typeof ignoreCharacter === 'string') {
                        updatedString = updatedString.replace(_createIgnoreRegExp(ignoreCharacter), '');
                    }
                    else {
                        journal.log({type: 'error', owner: 'UI', module: 'Table', submodule: '_removeIgnoredCharacters'}, 'Unsupported ignored characters:', ignoreCharacter);
                    }
                });

                return updatedString;
            }
            else {
                journal.log({type: 'error', owner: 'UI', module: 'Table', submodule: '_removeIgnoredCharacters'}, 'Unsupported ignored characters:', ignoredCharacters);

                return string;
            }
        }

        /**
         * Applies the advanced filters to the given row array
         *
         * @param {array}   rowsToFilter        List of rows to be filtered
         * @param {object}  workingCopyOfState  Optional state object that needs to be applied with `setState()`
         */
        _applyAdvancedFilter (rowsToFilter, workingCopyOfState) {
            const prevState = workingCopyOfState || this.state;
            const rowsToKeep = [];

            /**
             * Tests a single comparison object against a table row
             *
             * @param   {object}  comparison            Comparison that is being tested against
             * @param   {object}  rule                  Rule that contains the comparison
             * @param   {object}  tableRow              Row that is being evaluated
             *
             * @return  {boolean}                       Whether the row fulfils the comparison
             */
            const testComparison = function _testComparison (comparison, rule, tableRow) {
                let numInnerTests = 0;
                let numInnerPassed = 0;

                // Perform the test:

                // Metadata comparison
                if (comparison.rowMetadata) {
                    numInnerTests++;

                    if (tableRow.metadata) {
                        // String search for value
                        if (comparison.operator === 'contains') {
                            if (tableRow.metadata[comparison.rowMetadata].includes(comparison.value)) {
                                numInnerPassed++;
                            }
                        }
                        // Value matches exactly
                        else if (tableRow.metadata[comparison.rowMetadata] === comparison.value) {
                            numInnerPassed++;
                        }
                    }
                }
                // Column property comparison
                else if (comparison.colProp) {
                    let numColTests = 0;
                    let numColsPassed = 0;

                    numInnerTests++;

                    comparison.colIndices.forEach((colIndex) => {
                        const col = tableRow.columns[colIndex];

                        numColTests++;

                        if (col && col[comparison.colProp] === comparison.value) {
                            numColsPassed++;
                        }
                    });

                    // All columns matched the test(s)
                    if (numColTests === numColsPassed) {
                        numInnerPassed++;
                    }
                }
                // Mathematical comparison
                else if (comparison.mathOperator && !isNaN(comparison.column)) {
                    const col = tableRow.columns[comparison.column];
                    let value;

                    numInnerTests++;

                    // Get the column's value; first try `value`, then fall back to `text`
                    if (!isNaN(col.value)) {
                        value = col.value;
                    }
                    else if (Object.prototype.hasOwnProperty.call(col, 'text')) {
                        value = parseFloat(col.text);
                    }
                    // No value
                    else {
                        // For math purposes, we'll assume the value would've been `0` since the cell is empty
                        value = 0;
                    }

                    // Make sure we got a number
                    if (isNaN(value)) {
                        // journal.log({type: 'warn', owner: 'UI', module: 'Table'}, 'advanced filter: cannot do math comparison because the cell value is not a number.\nrow: ', tableRow, '\ncomparison: ', comparison, '\nrule: ', rule);

                        return false;
                    }

                    // Cell's value must be greater than the rule's value
                    if (comparison.mathOperator === 'gt') {
                        if (value > comparison.value) {
                            numInnerPassed++;
                        }
                    }
                    // Cell's value must be less than the rule's value
                    else if (comparison.mathOperator === 'lt') {
                        if (value < comparison.value) {
                            numInnerPassed++;
                        }
                    }
                    // Cell's value must be equal to the rule's value
                    else if (comparison.mathOperator === 'eq') {
                        if (value === comparison.value) {
                            numInnerPassed++;
                        }
                    }
                    // No other comparison types are supported
                    else {
                        journal.log({type: 'error', owner: 'UI', module: 'Table', submodule: '_applyAdvancedFilter'}, 'Unsupported math comparison.\nrow: ', tableRow, '\ncomparison: ', comparison, '\nrule: ', rule);

                        // Retract this increment since we didn't actually test anything
                        numInnerTests--;
                    }
                }
                else if (comparison.textOperator && !isNaN(comparison.column)) {
                    const col = tableRow.columns[comparison.column];
                    let compareValue = comparison.value;
                    let colValue;

                    numInnerTests++;

                    // Get the column's value; first try `text`...
                    if (Object.prototype.hasOwnProperty.call(col, 'text')) {
                        colValue = col.text;
                    }
                    // ...then fall back to `value` and convert it to a string
                    else if (!isNaN(col.value)) {
                        colValue = '' + col.value;
                    }
                    // No value
                    else {
                        // For string operation purposes, we'll assume the value would've been an empty string since the cell is empty
                        colValue = '';
                    }

                    // Make sure we got a string
                    if (typeof colValue !== 'string' || typeof compareValue !== 'string') {
                        journal.log({type: 'warn', owner: 'UI', module: 'Table'}, 'advanced filter: cannot do string comparison because the cell value and/or compare value is not a string.\nrow: ', tableRow, '\ncomparison: ', comparison, '\nrule: ', rule);

                        return false;
                    }

                    // Convert to lower case if the test isn't case-sensitive
                    if (!comparison.caseSensitive) {
                        colValue = colValue.toLowerCase();
                        compareValue = compareValue.toLowerCase();
                    }

                    // Cell's value must match the given text
                    if (comparison.textOperator === 'contains') {
                        // String can be found anywhere
                        if (!Object.prototype.hasOwnProperty.call(comparison, 'index')) {
                            if (colValue.includes(compareValue)) {
                                numInnerPassed++;
                            }
                        }
                        // String must be at a specific location
                        else if (!isNaN(comparison.index)) {
                            if (colValue.indexOf(compareValue) === comparison.index) {
                                numInnerPassed++;
                            }
                        }
                        // Invalid comparison object
                        else {
                            if (isNaN(comparison.index)) {
                                journal.log({type: 'error', owner: 'UI', module: 'Table', submodule: '_applyAdvancedFilter'}, 'Cannot perform string comparison because the `index` is not a number.\nrow: ', tableRow, '\ncomparison: ', comparison, '\nrule: ', rule);
                            }
                            else {
                                journal.log({type: 'error', owner: 'UI', module: 'Table', submodule: '_applyAdvancedFilter'}, 'String comparison failed for unknown reason.\nrow: ', tableRow, '\ncomparison: ', comparison, '\nrule: ', rule);
                            }
                        }
                    }
                    // No other comparison types are supported
                    else {
                        journal.log({type: 'error', owner: 'UI', module: 'Table', submodule: '_applyAdvancedFilter'}, 'Unsupported string comparison.\nrow: ', tableRow, '\ncomparison: ', comparison, '\nrule: ', rule);

                        // Retract this increment since we didn't actually test anything
                        numInnerTests--;
                    }
                }
                else {
                    journal.log({type: 'error', owner: 'UI', module: 'Table', submodule: '_applyAdvancedFilter'}, 'Unsupported rule type.\nrow: ', tableRow, '\ncomparison: ', comparison, '\nrule: ', rule);
                }

                // Check whether this comparison was fulfilled
                if (numInnerTests === numInnerPassed) {
                    return true;
                }
                else {
                    return false;
                }
            };

            /**
             * Tests a table row against a rule which is a check box list
             *
             * @param   {object}  tableRow  Row that is being evaluated
             * @param   {array}   rules     List of rules (i.e. check boxes) to be tested against
             *
             * @return  {object}            Boolean values `passed` (whether the table row passed the rule) and `ignore` (whether the caller should care about this test)
             */
            const testCheckboxRules = function _testCheckboxRules (tableRow, rules, matchAll) {
                let numRulesTested = 0;
                let numRulesPassed = 0;
                const result = {
                    ignore: false,
                    passed: false,
                };

                // Loop through each rule (i.e. the check boxes in the row's rules)
                rules.forEach((rule) => {
                    let numComparisonsTested = 0;
                    let numComparisonsPassed = 0;

                    // Only test if the check box is checked
                    if (rule.checked && rule.comparisons) {
                        numRulesTested++;

                        rule.comparisons.forEach((comparison) => {
                            numComparisonsTested++;

                            if (testComparison(comparison, rule, tableRow, numRulesTested)) {
                                numComparisonsPassed++;
                            }
                        });

                        // If all applicable comparisons have passed, then the rule has passed
                        if (numComparisonsTested === numComparisonsPassed) {
                            numRulesPassed++;
                        }
                    }
                });

                if (numRulesTested === 0) {
                    result.ignore = true;
                }
                else {
                    // Match all of the checked boxes
                    if (matchAll && numRulesTested === numRulesPassed) {
                        result.passed = true;
                    }
                    // Match any of the checked boxes
                    else if (!matchAll) {
                        // Since we consider check boxes to be additive, if there were any that passed at all, consider the rule to be fulfilled
                        if (numRulesPassed > 0) {
                            result.passed = true;
                        }
                        // All rules in this row have passed
                        else if (numRulesTested === numRulesPassed) {
                            result.passed = true;
                        }
                    }
                }

                return result;
            };

            /**
             * Tests a table row against a rule which is a radio button list
             *
             * @param   {object}  tableRow  Row that is being evaluated
             * @param   {array}   rules     List of rules (i.e. radio buttons) to be tested against
             *
             * @return  {object}            Boolean values `passed` (whether the table row passed the rule) and `ignore` (whether the caller should care about this test)
             */
            const testRadioRules = function _testRadioRules (tableRow, rules) {
                let numRulesTested = 0;
                let numRulesPassed = 0;
                const result = {
                    ignore: false,
                    passed: false,
                };

                // Loop through each rule (i.e. the check boxes in the row's rules)
                rules.forEach((rule) => {
                    let numComparisonsTested = 0;
                    let numComparisonsPassed = 0;

                    // Only test if the check box is checked
                    if (rule.checked && rule.comparisons) {
                        numRulesTested++;

                        rule.comparisons.forEach((comparison) => {
                            numComparisonsTested++;

                            if (testComparison(comparison, rule, tableRow, numRulesTested)) {
                                numComparisonsPassed++;
                            }
                        });

                        // If all applicable comparisons have passed, then the rule has passed
                        if (numComparisonsTested > 0 && numComparisonsTested === numComparisonsPassed) {
                            numRulesPassed++;
                        }
                    }
                });

                if (numRulesTested === 0) {
                    result.ignore = true;
                }
                else if (numRulesTested === numRulesPassed) {
                    result.passed = true;
                }

                return result;
            };

            // Everything above here is variable & function declaration; the basic algorithm begins below


            ///////////////////////
            // Evaluate the rows //
            ///////////////////////

            // Table filter has rows of radio and check box rules to be tested
            if (prevState.filter.advanced.rows) {
                // Loop through all rows and check each one against the filters
                rowsToFilter.forEach((tableRow) => {
                    let numTested = 0;
                    let numPassed = 0;

                    // Loop through all sets of rules
                    prevState.filter.advanced.rows.forEach((filterRow) => {
                        let result;

                        if (filterRow.type === 'checkbox-any') {
                            result = testCheckboxRules(tableRow, filterRow.rules);
                        }
                        else if (filterRow.type === 'checkbox-all') {
                            result = testCheckboxRules(tableRow, filterRow.rules, true);
                        }
                        else if (filterRow.type === 'radio-list') {
                            result = testRadioRules(tableRow, filterRow.rules);
                        }

                        if (!result.ignore) {
                            numTested++;

                            if (result.passed) {
                                numPassed++;
                            }
                        }
                    });

                    // If all applicable tests have passed, keep the row
                    if (numTested === numPassed) {
                        rowsToKeep.push(tableRow);
                    }
                });

                return rowsToKeep;
            }
            // Nothing to test, so just return all of the rows
            else {
                return rowsToFilter;
            }
        }

        // Updates the state with the current basic filter value
        _updateFilterBasicValue (newValue) {
            const newState = this.state;

            // Set the new value, then update the filtered rows
            newState.filter.basic.value = newValue;

            this._applyFilters(newState);
        }

        // Updates the state with an advanced check box's value
        _updateFilterAdvCheckbox (evt) {
            const inputValue = this._normalizeCheckBoxValue(evt.target.value).split('_row');
            const value = inputValue[0];
            const filterRowIndex = parseInt(inputValue[1], 10);
            const newState = this.state;
            const isChecked = !!evt.target.checked;
            let ruleObj;

            newState.filter.advanced.rows[filterRowIndex].rules.forEach((rule) => {
                if (value === rule.value) {
                    ruleObj = rule;
                }
            });

            if (!ruleObj) {
                console.warn('Could not find rule: ', value, newState.filter.advanced.rows);

                return true;
            }

            // Radio button
            if (ruleObj.isRadio) {
                // The radio is now checked AND it was already checked before this
                if (isChecked && ruleObj.checked) {
                    // Uncheck it to allow the user to clear the filter
                    $(evt.target).prop('checked', false);

                    // Also need to uncheck all other radios to make sure we don't end up with more than one of them checked
                    newState.filter.advanced.rows[filterRowIndex].rules.forEach((rule) => {
                        rule.checked = false;
                    });
                }
                else {
                    // Uncheck all radios first to make sure we don't end up with more than one of them checked
                    newState.filter.advanced.rows[filterRowIndex].rules.forEach((rule) => {
                        rule.checked = false;
                    });

                    // Now check the one that the user selected
                    ruleObj.checked = isChecked;
                }
            }
            // Check box
            else {
                // Update this checkbox in the state
                ruleObj.checked = isChecked;
            }

            // Filter with and update the state
            this._applyFilters(newState);

            return true;
        }

        /////////////
        // Sorting //
        /////////////

        _applySorting (colObj) {
            const colIndex = colObj.colIndex;
            const rows = this.sourceTable.body.rows;
            let sortDir = '';
            let needToUpdateState = false; // Flag used to prevent unnecessary state changes, e.g. if the order of the rows did not actually change

            const getColValue = (column) => {
                let columnValue = "";


                if(column["data-sortvalue"] && column["data-sortvalue"]!= ""){
                    columnValue = column["data-sortvalue"];
                }
                // Value (e.g. numeric)
                else if ((column.attributes && column.attributes.value)) {
                    columnValue = column.attributes.value;
                }
                // Text
                else if (column.text) {
                    columnValue = column.text;
                }
                // Interactive item(s)
                else if (column.items) {
                    //FIXME: Sort by number of items, I guess?
                    columnValue = column.items.length;

                }
                // Link, but no top-level text (FIXME: is this even a valid use case, or is it a holdover from the very early days of summer 2016 when the table JSON was not fully developed? CP 11/29/16)
                else if (column.url) {
                    //FIXME: We can't really sort these since the visible text is always the same, right?
                }
                else if(column.contents){
                    //For each column contents, get the text value and append to the column value.
                    column.contents.forEach((column)=>{
                        if(column.text){
                            columnValue += column.text;
                        }
                    });
                }

                return columnValue;
            }

            //FIXME: reverting to the original sorting is working here (uncomment the code below to re-enable it, and remove the third condition from the first `if` statement). However, the table doesn't actually render the correct rows due to something not being updated properly in `_applySorting()`. (CP 11/21/16)

            // Change to ascending if we've never sorted this column, or if the last sort was on a different column, or the last sort was descending
            if (!this.colSorting[colIndex] || colIndex !== this.lastSortedCol || this.colSorting[colIndex] === 'desc') {
                this.colSorting[colIndex] = 'asc';
                sortDir = 'asc';
            }
            // Change to descending if we ascended last time
            // else if (this.colSorting[colIndex] === 'asc') {
            else {
                this.colSorting[colIndex] = 'desc';
                sortDir = 'desc';
            }
            // // Revert back to unsorted if we descended last time
            // else {
            //     this.colSorting[colIndex] = '';
            //     sortDir = '';
            //     console.info('[_applySorting] reset');

            //     // Copy the rows from the original table
            //     rows = [].concat(this.ORIGINAL_TABLE.body.rows);

            //     // Skip sorting, but be sure to update the state
            //     this.sourceTable.head.rows[0].columns[colIndex].sortDir = '';
            //     needToUpdateState = true;
            // }

            this.lastSortedCol = colIndex;

            if (sortDir === 'asc' || sortDir === 'desc') {
                // Sort all rows in the table (not just displayed or unfiltered rows)
                rows.sort((rowA, rowB) => {
                    let colA;
                    let colB;
                    let valueA;
                    let valueB;

                    // If we're ascending, keep A and B as-is. If we're descending, swap their places so our comparisons will have the opposite effect
                    if (sortDir === 'asc') {
                        colA = rowA.columns[colIndex];
                        colB = rowB.columns[colIndex];
                    }
                    else {
                        colA = rowB.columns[colIndex];
                        colB = rowA.columns[colIndex];
                    }

                    // Get the values to compare:
                    valueA = getColValue(colA);
                    valueB = getColValue(colB);

                    // Compare the values
                    if (valueA < valueB) {
                        needToUpdateState = true;
                        return -1;
                    }
                    else if (valueA > valueB) {
                        needToUpdateState = true;
                        return 1;
                    }
                    else {
                        return 0;
                    }
                });

                // Update columns with their current sorting direction
                this.sourceTable.head.rows[0].columns.forEach((col, c) => {
                    // Current column
                    if (c === colIndex) {
                        this.sourceTable.head.rows[0].columns[c].sortDir = sortDir;
                    }
                    // Some other column that is not being sorted right now
                    else {
                        this.sourceTable.head.rows[0].columns[c].sortDir = '';
                    }
                });
            }

            // Only update the state if something actually changed
            if (needToUpdateState) {
                // Update the table's body rows with the same rows but in a new order
                this.sourceTable.body.rows = rows;

                // Apply filters, but force the state to be updated even if nothing is filtered
                this._applyFilters(true);
            }
        }


        ///////////////////
        // Miscellaneous //
        ///////////////////

        // Returns a `<TableColumn>`
        // This is meant to reduce redundancies and avoid maintenance issues with the various places in <TableRow> and <TableRowExpand> that the column component is invoked
        // `localProps` is the same as `this.props` from the calling component
        _getTableCell (col, localProps, otherProps) {
            return (
                <TableColumn
                    col={col}
                    tagNames={localProps.tagNames}
                    isSmallSize={localProps.isSmallSize}
                    key={col.key}
                    colIndex={col.index}
                    rowIndex={localProps.row.index}
                    isHeader={localProps.isHeader}
                    scrollTop={localProps.scrollTop}
                    source={localProps.source}
                    sortFunc={localProps.sortFunc}
                    onInputChange={localProps.onInputChange}
                    onCheckChange={localProps.onCheckChange}
                    {...otherProps}
                />
            );
        }

        _updateViewRows (numDisplayRows, workingCopyOfState) {
            const nextState = workingCopyOfState || this.state;

            numDisplayRows = parseInt(numDisplayRows, 10);

            if (isNaN(numDisplayRows)) {
                // Apply the state that was passed to us before quitting
                if (workingCopyOfState) {
                    this.setState({
                        numDisplayRows: workingCopyOfState.numDisplayRows,
                    });
                }

                return true;
            }

            nextState.numDisplayRows = numDisplayRows;

            this.setState({
                numDisplayRows: numDisplayRows,
            });

            return false;
        }

        _calcViewportHeight (heightPerRow, rowsPerBody, headerRowHeight) {
            return (heightPerRow * rowsPerBody) + headerRowHeight + 1; // +1px so the bottom border of the last row shows through
        }

        // Displays additional rows at the bottom of the table (only on small screens)
        _onShowMoreClick (evt) {
            let difference = this.state.displayRows.length - this.state.numDisplayRows;
            let newTotal;

            // There are more rows that we can display
            if (difference > 0) {
                // Cap the number of new rows
                if (difference > this.showMoreRowsStep) {
                    difference = this.showMoreRowsStep;
                }

                newTotal = difference + this.state.numDisplayRows;

                this.setState({
                    total: newTotal,
                    numDisplayRows: newTotal,
                    visibleEnd: (difference + this.state.visibleEnd),
                    displayEnd: (difference + this.state.displayEnd),
                });

                // Make sure the button is visible
                evt.target.classList.remove('cui-hidden');
            }
            // There are no more rows to display
            else {
                // Hide the button
                evt.target.classList.add('cui-hidden');
            }
        }

        // Handles changes to a 'Select All' check box in the header
        _onSelectAllChange (evt) {
            const isChecked = !!evt.target.checked;
            const newHeaderState = this.state.header;
            const newDisplayRowsState = this.state.displayRows;

            if (isChecked) {
                // Body rows
                newDisplayRowsState.forEach((row) => {
                    if (row.selection.empty || row.selection.readOnly) {
                        return;
                    }

                    row.columns[0].contents[0].input.attributes.checked = 'checked';
                    row.isSelected = true;
                });

                // Header row
                newHeaderState.rows[0].columns[0].contents[0].input.attributes.checked = 'checked';
                newHeaderState.rows[0].isSelected = true;
            }
            else {
                // Body rows
                newDisplayRowsState.forEach((row) => {
                    if (row.selection.empty || row.selection.readOnly) {
                        return;
                    }

                    row.isSelected = false;

                    if (Object.prototype.hasOwnProperty.call(row.columns[0].contents[0].input.attributes, 'checked')) {
                        delete row.columns[0].contents[0].input.attributes.checked;
                    }
                });

                // Header row
                newHeaderState.rows[0].isSelected = false;

                if (Object.prototype.hasOwnProperty.call(newHeaderState.rows[0].columns[0].contents[0].input.attributes, 'checked')) {
                    delete newHeaderState.rows[0].columns[0].contents[0].input.attributes.checked;
                }
            }

            this.setState({
                header: newHeaderState,
                displayRows: newDisplayRowsState,
            });
        }

         _onSelectAllButtonClick(evt) {

            let validInput = null;
            let isChecked = null;
            const newHeaderState = this.state.header;
            const newDisplayRowsState = this.state.displayRows;

            //Find select all button in current state. 

            if(newHeaderState.rows.length > 0){
            	
            	if(newHeaderState.rows[0].columns && newHeaderState.rows[0].columns.length > 0){
            		
            		let selectAllCell = newHeaderState.rows[0].columns[0];

            		if(selectAllCell.contents && selectAllCell.contents.length > 0){
            			let selectAllInput = selectAllCell.contents[0].input;
						if(selectAllInput && selectAllInput.attributes){
							validInput = true;
							//Invert isChecked since we are also toggleing the select all value. 
	            			isChecked = !selectAllInput.attributes.checked;
            			}           		            		
            		}
            	}
            }
			
			if(validInput){

	            if (isChecked) {
	                // Body rows
	                newDisplayRowsState.forEach((row) => {
	                    if (row.selection.empty || row.selection.readOnly) {
	                        return;
	                    }

	                    row.columns[0].contents[0].input.attributes.checked = 'checked';
	                    row.isSelected = true;
	                });

	                // Header row
	                newHeaderState.rows[0].columns[0].contents[0].input.attributes.checked = 'checked';
	                newHeaderState.rows[0].isSelected = true;
	            }
	            else {
	                // Body rows
	                newDisplayRowsState.forEach((row) => {
	                    if (row.selection.empty || row.selection.readOnly) {
	                        return;
	                    }

	                    row.isSelected = false;

	                    if (Object.prototype.hasOwnProperty.call(row.columns[0].contents[0].input.attributes, 'checked')) {
	                        delete row.columns[0].contents[0].input.attributes.checked;
	                    }
	                });

	                // Header row
	                newHeaderState.rows[0].isSelected = false;

	                if (Object.prototype.hasOwnProperty.call(newHeaderState.rows[0].columns[0].contents[0].input.attributes, 'checked')) {
	                    delete newHeaderState.rows[0].columns[0].contents[0].input.attributes.checked;
	                }
	            }

	            this.setState({
	                header: newHeaderState,
	                displayRows: newDisplayRowsState,
	            });

            }
        }


        // Handles changes to a check box that is meant to select a row
        _onSelectRowChange (arg1, arg2 /*, arg3*/) {
            let target;
            let rowIndex;

            // Argument was an event
            if (arg1.target) {
                target = arg1.target;
                rowIndex = parseInt(target.getAttribute('data-feta-elem-index-row'), 10);
            }
            // Argument was refData
            else {
                target = arg2.target;
                rowIndex = arg1.rowIndex;
            }

            const isChecked = !!target.checked;
            const newHeaderState = this.state.header;
            const newDisplayRowsState = this.state.displayRows;
            let numChecked = isChecked ? 1 : 0;

            //need to use for loop since you cannot break out of a forEach loop.
            for(let i=0;i<newDisplayRowsState.length; i++){
                row = newDisplayRowsState[i];
                if (row.index === rowIndex) {
                    rowIndex = i;
                    break;
                }
            }

            // Count how many rows are now checked, and also update this check box in state
            newDisplayRowsState.forEach((row, r) => {
                // The check box that was just checked
                if (r === rowIndex) {

                    // Update it
                    if (isChecked) {
                        row.columns[0].contents[0].input.attributes.checked = 'checked';
                        row.isSelected = true;
                    }
                    else {
                        row.isSelected = false;

                        if (Object.prototype.hasOwnProperty.call(row.columns[0].contents[0].input.attributes, 'checked')) {
                            delete row.columns[0].contents[0].input.attributes.checked;
                        }
                    }
                }
                // Some other check box
                else {
                    if (row.selection.empty || row.selection.readOnly) {
                        // Even though this row is unselectable, consider it 'checked' so that our count matches up when we want to see whether to check the 'select all' box
                        numChecked++;

                        return;
                    }

                    if (row.isSelected || row.columns[0].contents[0].input.attributes.checked === 'checked') {
                        numChecked++;
                    }
                }
            });

            // Update the header to reflect whether all rows are checked:

            // Has a "select all" box in the header
            if ('multiple' === this.sourceTable.selectionType && this.sourceTable.selectAll == true) {
                // All rows are checked
                if (numChecked === newDisplayRowsState.length) {
                    // Make sure the header is also checked
                    newHeaderState.rows[0].columns[0].contents[0].input.attributes.checked = 'checked';
                    newHeaderState.rows[0].isSelected = true;
                }
                // Not all rows are checked
                else {
                    // Only uncheck the header box if it was checked before, otherwise leave it alone
                    if (newHeaderState.rows[0].isSelected || (newHeaderState.rows[0].columns[0].contents && newHeaderState.rows[0].columns[0].contents[0].input.attributes.checked === 'checked')) {
                        delete newHeaderState.rows[0].columns[0].contents[0].input.attributes.checked;
                        newHeaderState.rows[0].isSelected = false;
                    }
                }

                this.setState({
                    header: newHeaderState,
                    displayRows: newDisplayRowsState,
                });
            }
            // No "select all" box in the header
            else {
                this.setState({
                    displayRows: newDisplayRowsState,
                });
            }
        }

        // Handles changes to a field's value
        _onInputChange (arg1, arg2, arg3) {
            const newDisplayRowsState = this.state.displayRows;
          
            // Argument was an event
            if (arg1.target) {
            	const target = arg1.target;
                const value = target.value;
                let rowIndex = parseInt(target.getAttribute('data-feta-elem-index-row'), 10);
                const colIndex = parseInt(target.getAttribute('data-feta-elem-index-col'), 10);
                const contentsIndex = parseInt(target.getAttribute('data-feta-elem-index-contents'), 10) || 0;

                //Match displayRow
                newDisplayRowsState.forEach((row, r) => {
                    if (row.index === rowIndex) {
                        rowIndex = r;
                       
                        return;
                    }
                });

                // Update the value in the data store:

                // First, look in the specified row
                if (newDisplayRowsState[rowIndex].columns[colIndex].contents[contentsIndex]) {
                    newDisplayRowsState[rowIndex].columns[colIndex].contents[contentsIndex].input.attributes.value = value;
                }
                // If it wasn't found, then look for the input in the expanded row
                else if (newDisplayRowsState[rowIndex].expand) {
                    newDisplayRowsState[rowIndex].expand.contents[colIndex].contents[contentsIndex].input.attributes.value = value;
                }
            }
            // Argument was refData
            else {
            	const target = arg2.target;
                const value = target.value;
                let rowIndex = arg1.rowIndex;
                const colIndex = arg1.colIndex;
                const contentsIndex = arg1.contentsIndex;
                const selectedValue = target.selectedValue;



				//Match displayRow
                newDisplayRowsState.forEach((row, r) => {
                    if (row.index === rowIndex) {
                        rowIndex = r;
                       
                        return;
                    }
                });

                // Update the value in the data store:
                
                // First, look in the specified row
                // Added check to match ID since a first column selection control was being targeted instead of an input within the expand row. 
                if (newDisplayRowsState[rowIndex].columns[colIndex].contents &&
                    newDisplayRowsState[rowIndex].columns[colIndex].contents[contentsIndex] &&
                    newDisplayRowsState[rowIndex].columns[colIndex].contents[contentsIndex].input &&
                    newDisplayRowsState[rowIndex].columns[colIndex].contents[contentsIndex].input.attributes &&
                    typeof newDisplayRowsState[rowIndex].columns[colIndex].contents[contentsIndex].input.attributes.value !== 'undefined' &&
                    target.id == newDisplayRowsState[rowIndex].columns[colIndex].contents[contentsIndex].input.attributes.id) {
              
                	if(newDisplayRowsState[rowIndex].columns[colIndex].contents[contentsIndex].type && newDisplayRowsState[rowIndex].columns[colIndex].contents[contentsIndex].type == "select"){
                		newDisplayRowsState[rowIndex].columns[colIndex].contents[contentsIndex].input.value = value;
                	}                	

                	newDisplayRowsState[rowIndex].columns[colIndex].contents[contentsIndex].input.attributes.value = value;	                 
                }
                // If it wasn't found, then look for the input in the expanded row
                else if (newDisplayRowsState[rowIndex].expand &&
                         newDisplayRowsState[rowIndex].expand.contents[colIndex].contents[contentsIndex] &&
                         newDisplayRowsState[rowIndex].expand.contents[colIndex].contents[contentsIndex].input) {
                    newDisplayRowsState[rowIndex].expand.contents[colIndex].contents[contentsIndex].input.attributes.value = value;
                }
                else {
                    // Manually search through the data store for an input with the same ID
                    const targetId = (arg3 && arg3.id) ? arg3.id : target.id;
                    const isTargetChecked = !!target.checked;

                    if (targetId) {
                        // First look in the expanded row because if we're at this point in the code, the input is probably tucked in there
                        if (newDisplayRowsState[rowIndex].expand && newDisplayRowsState[rowIndex].expand.contents[colIndex]) {
                            const startingObject = newDisplayRowsState[rowIndex].expand.contents[colIndex];

                            /**
                             * Recursively searches contents for an element with the same ID as `targetId`
                             *
                             * @param {object} searchObject Content object (i.e. from a `contents` array)
                             */
                            const findContentById = (searchObject) => {
                                if (searchObject.contents) {
                                    searchObject.contents.forEach((content) => {
                                        // This object matches
                                        if (content.input && content.input.attributes && content.input.attributes.id === targetId) {
                                            content.input.attributes.value = value;
                                        }
                                        else if (content.button && content.button.attributes && content.button.attributes.id === targetId) {
                                            content.button.attributes.value = value;
                                        }
                                        else if (content.template === 'inputGroup') {
                                            content.options.forEach((option) => {
                                                if (option.input && option.input.attributes && option.input.attributes.id === targetId) {
                                                    if (/radio|checkbox/.test(option.input.attributes.type)) {
                                                        if (option.input.attributes.hasOwnProperty('checked')) {
                                                            option.input.attributes.checked = isTargetChecked;
                                                        }
                                                        else if (option.input.attributes.hasOwnProperty('defaultChecked')) {
                                                            option.input.attributes.defaultChecked = isTargetChecked;
                                                        }
                                                    }

                                                    option.input.attributes.value = value;
                                                }
                                            });
                                        }
                                        // Not a match, but it has contents that we can recursively search through
                                        else if (content.contents) {
                                            findContentById(content);
                                        }
                                    });
                                }
                            };

                            findContentById(startingObject);
                        }
                    }
                }
            }
            // Update the state
            this.setState({
                displayRows: newDisplayRowsState,
            });
        }

        /**
         * Handles changes to a regular check box (NOT one that is meant to select a row) or a radio button
         *
         * @param {object} evt Event or refData
         */
        _onCheckChange (arg1, arg2 /*, arg3*/) {
            let target;
            let rowIndex;
            let colIndex;
            let contentsIndex;

            // Argument was an event
            if (arg1.target) {
                target = arg1.target;
                rowIndex = parseInt(target.getAttribute('data-feta-elem-index-row'), 10);
                colIndex = parseInt(target.getAttribute('data-feta-elem-index-col'), 10);
                contentsIndex = parseInt(target.getAttribute('data-feta-elem-index-contents'), 10) || 0; //FIXME: This index may not be defined on all inputs since it was recently added (CP 6/2017)
            }
            // Argument was refData
            else {
                target = arg2.target;
                rowIndex = arg1.rowIndex;
                colIndex = arg1.colIndex;
                contentsIndex = arg1.contentsIndex;
            }

            const isChecked = !!target.checked;
            const newDisplayRowsState = this.state.displayRows;

            //Match displayRow
            newDisplayRowsState.forEach((row, r) => {
                if (row.index === rowIndex) {
                    rowIndex = r;
                    return;
                }
            });

            // console.log('_onCheckChange for [' + rowIndex + ', ' + colIndex + ', ' + contentsIndex + ']\narguments: ', arguments);
            // console.log('_onCheckChange for [' + rowIndex + ', ' + colIndex + ', ' + contentsIndex + ']\nargs: ', arguments);
            const targetInput = newDisplayRowsState[rowIndex].columns[colIndex].contents[contentsIndex].input;

            // If it's a radio button, we need to uncheck all of the other buttons in the group to maintain the normal radio button behavior. React will override the browser's auto-unchecking of other radios because it's rendering based on the buttons' states.
            if (targetInput.attributes.type === 'radio') {
                //TODO: Make sure the radio's value actually changed so we can avoid updating the state unnecessarily. This only happens when clicking on a radio that was already checked, so it's a bit of an edge case.
                newDisplayRowsState.forEach((row, r) => {
                    // This is the radio that was just checked
                    if (rowIndex === r) {
                        // Update it
                        if (isChecked) {
                            row.columns[colIndex].contents[contentsIndex].input.attributes.checked = 'checked';

                            if (Object.prototype.hasOwnProperty.call(row, 'isSelected')) {
                                row.isSelected = true;
                            }
                        }
                        else {
                            if (Object.prototype.hasOwnProperty.call(row, 'isSelected')) {
                                row.isSelected = false;
                            }

                            if (Object.prototype.hasOwnProperty.call(row.columns[0].contents[contentsIndex].input.attributes, 'checked')) {
                                delete row.columns[0].contents[contentsIndex].input.attributes.checked;
                            }
                        }
                    }
                    // This is some other row; check for a radio that belongs in the same group (same `name` attribute) and uncheck it
                    else {
                        if (row.selection && (row.selection.empty || row.selection.readOnly)) {
                            return;
                        }

                        if (Object.prototype.hasOwnProperty.call(row, 'isSelected')) {
                            row.isSelected = false;
                        }

                        if (row.columns[colIndex].contents[contentsIndex] && row.columns[colIndex].contents[contentsIndex].input.attributes.checked) {
                            delete row.columns[colIndex].contents[contentsIndex].input.attributes.checked;
                        }
                    }
                });
            }
            // Otherwise, just update the value in state as usual
            else {
                const row = newDisplayRowsState[rowIndex];

                if (!row.selection || (!row.selection.empty && !row.selection.readOnly)) {
                    if (isChecked) {
                        row.columns[colIndex].contents[contentsIndex].input.attributes.checked = 'checked';
                    }
                    else {
                        row.columns[colIndex].contents[contentsIndex].input.attributes.checked = '';
                    }
                }
            }

            this.setState({
                displayRows: newDisplayRowsState,
            });
        }

        // Converts a check box or radio button's `value` into a valid property name that we can use internally (i.e. strip the non-alphanumeric characters)
        _normalizeCheckBoxValue (value) {
            return value.replace(/\W/g, '_');
        }

        // Reads and stores the width for a given header cell
        // This function is passed via props to `<TableColumn/>` which is where it actually gets called
        _getColDimensions (col, colIndex, elem) {
            const headerCol = this.sourceTable.head.rows[0].columns[colIndex];

            if (!headerCol.width || !this.headerCellElems[colIndex]) {
                // Get the element's width unless it has a class that governs the width
                if (!elem.className.includes('-width-')) {
                    headerCol.width = elem.offsetWidth;
                }

                // Get the element's height
                headerCol.height = elem.offsetHeight;

                // Track the height of the tallest cell
                // We only need to store it in one place since every cell will get the same value
                if (!this.sourceTable.head.stickyHeight || this.sourceTable.head.stickyHeight < headerCol.height) {
                    this.sourceTable.head.stickyHeight = headerCol.height;
                }

                this.headerCellElems[colIndex] = elem;
            }
        }

        // Gets new column widths (i.e. when window is resized)
        // This is mostly needed for IE. Chrome will keep the columns sized proportionally to the original widths that were gathered at the time of first rendering, but IE shifts a little bit. Regardless this procedure doesn't seem too expensive since the DOM elements are already in memory.
        _updateColDimensions () {
            this.headerCellElems.forEach((elem, i) => {
                const headerCol = this.sourceTable.head.rows[0].columns[i];

                // Update the element's new width unless it has a class that governs the width
                if (!elem.className.includes('-width-')) {
                    headerCol.width = elem.offsetWidth;
                }

                // Update the element's new height
                headerCol.height = elem.offsetHeight;

                // Track the height of the tallest cell
                if (this.sourceTable.head.stickyHeight < headerCol.height) {
                    this.sourceTable.head.stickyHeight = headerCol.height;
                }
            });
        }

        // Determines the default height for a row
        _getOverallRowHeight () {
            const allHeights = [];    // Stores all rows' heights
            const uniqueHeights = []; // Stores only unique height values
            const heightCounts = {};  // Stores the number of times each height occurs (i.e. to keep track of the most common values)
            let measuredRows = 0;
            const $rows = this.$tableWrapper.find('tbody').find('tr');

            // Collect actual heights for all currently-rendered rows
            $rows.each(function _$rowsEach (index, elem) {
                // Skip the dummy rows
                if (index === 0 || index === $rows.length - 1) {
                    return true;
                }

                const rowHeight = elem.clientHeight || elem.outerHeight || elem.scrollHeight;

                allHeights.push(rowHeight);

                // Track unique heights
                if (!uniqueHeights.includes(rowHeight)) {
                    uniqueHeights.push(rowHeight);
                }

                // Track occurrence of heights:

                if (!heightCounts[rowHeight]) {
                    heightCounts[rowHeight] = 0;
                }

                heightCounts[rowHeight]++;
                measuredRows++;
            });

            if (measuredRows === 0) {
                return 0;
            }

            // All row heights are the same
            if (uniqueHeights.length === 1) {
                return uniqueHeights[0];
            }

            // There are only two row heights which are 1X and 2X (i.e. some rows just happen to wrap once)
            if (uniqueHeights.length === 2 && (uniqueHeights[0] === uniqueHeights[1] * 2 || uniqueHeights[1] === uniqueHeights[0] * 2)) {
                // Use the 1X height
                return Math.min(uniqueHeights[0], uniqueHeights[1]);
            }

            // 3+ different row heights
            if (uniqueHeights.length > 2) {
                //Return the weighted average of unique rows.
                let weightedTotal = 0;
                let weightedAverage = 0;

                uniqueHeights.forEach((uniqueHeight) => {
                    if (heightCounts[uniqueHeight]) {
                        weightedTotal += uniqueHeight * heightCounts[uniqueHeight];
                    }
                });

                weightedAverage = Math.ceil(weightedTotal / measuredRows);

                return weightedAverage;
            }

            // If one height is more common, use that one, I guess...
            if (heightCounts[uniqueHeights[0]] > heightCounts[uniqueHeights[1]]) {
                return uniqueHeights[0];
            }

            if (heightCounts[uniqueHeights[0]] < heightCounts[uniqueHeights[1]]) {
                return uniqueHeights[1];
            }

            // Otherwise use the tallest row height. This may cause scrollbars when we don't need them, but it avoids putting extra whitespace
            return Math.max(uniqueHeights[0], uniqueHeights[1]);
        }

        // Selects a row's first-column check box when the user clicks somewhere on the row
        _onClickSelectableRow (/*row, evt , ...eventArgs */) {
            // const target = evt.target;
            // const $target = $(target);

            // // Ignore clicks on inputs, buttons, and links
            // if (!$target.is('input, button, a')) {
            //     //FIXME: find the input using the `row` object and change its value programmatically
            //     $target.closest('tr').find('td').first().find('input').click();
            // }
        }

        // Toggles visibility of the child row for a given parent row
        _onExpandableRowClick (parentRow, ...eventArgs) {
            const newStateRows = this.state.displayRows;
            const target = eventArgs[0].target;

            // Ignore clicks on inputs
            if (!/input|select|textarea/i.test(target.nodeName)) {
                newStateRows.forEach((row) => {
                    if (row === parentRow) {
                        if (row.expand.settings.isVisible) {
                            row.expand.settings.isVisible = false;
                        }
                        else {
                            row.expand.settings.isVisible = true;
                        }
                    }
                });

                this.setState({
                    displayRows: newStateRows,
                    numDisplayRows: newStateRows.length,
                });
            }
        }

        _fetchExpandContent (parentRow/*, ...eventArgs*/) {
            const request = {};

            request.url = (parentRow.expand.url) ? parentRow.expand.url : null;
            request.params = (parentRow.expand.params) ? parentRow.expand.params : null;

            request.onSuccess = (settings, data) => {
                // Clean data and add any needed keys.
                if (data.length) {
                    data = TableUtils.normalizeExpandRow(data, parentRow.key);
                }
                else {
                    // No data was returned.
                    journal.log({type: 'error', owner: 'UI', module: 'Table', submodule: '_fetchExpandContent'}, 'Invalid data returned', data);
                    return;
                }

                parentRow.expand.contents =  data;
                parentRow.expand.settings.isVisible = true;
                parentRow.expand.isLoading = false;
                this.setState({
                    sourceTable: this.sourceTable,
                });
            };

            request.onError = (/*settings, data*/) => {
                parentRow.expand.isLoading = false;
                this.setState({
                    sourceTable: this.sourceTable,
                });
            }

            // Set isLoading property to disable the click event on the row so the user can not trigger fetch multiple times
            // Activate spinner.
            parentRow.expand.isLoading = true;

            this.setState({
                sourceTable: this.sourceTable,
            });
            feta.fetch.request(request);
        }

        _onWindowResize (/* evt */) {
            // Determine if the window has changed between large and small
            const newValue = this._isWindowSmall();

            // Get new column widths (only if the screen is large, since it's not applicable to small screens)
            if (newValue !== true) {
                this._updateColDimensions();
            }

            if (newValue !== null) {
                this.setState({
                    isSmallSize: newValue,
                });
            }
        }

        _onWindowScroll (/* evt */) {
            // Make sure the screen is large, otherwise there's nothing to do in here
            if (this.state.smallSize) {
                return true;
            }

            // Table is still entirely within the viewport
            if (this.$tableWrapper.get(0).getBoundingClientRect().top >= 0) {
                // this.theadDummyElem.classList.add('cui-hide-from-screen');

                this.$theadElem.find('th').each(function (i, elem) {
                    elem.style.width = 'auto';
                }.bind(this));

                this.$theadElem.css({
                    position: 'relative',
                    top: 'auto',
                    width: 'auto',
                });
            }
            // Table is at least partially outside the viewport
            else {
                // this.theadDummyElem.classList.remove('cui-hide-from-screen');

                this.$theadElem.find('th').each(function (i, elem) {
                    elem.style.width = this.theadElemCellWidths[i] + 'px';
                }.bind(this));

                this.$theadElem.css({
                    position: 'fixed',
                    top: '40px',
                    width: this.theadElemWidth + 'px',
                });
            }
        }

        /**
         * Determines the new value for `this.state.isSmallSize`, or `null` if there is no change
         *
         * Note that callers should check for `null` to avoid updating the state unnecessarily (e.g. if the screen is resized but not enough to warrant a new layout)
         *
         * @return  {mixed}  Boolean if it's a new state value, or `null` if it should be ignored
         */
        _isWindowSmall () {
            const theState = this.state || {}; // `this.state` isn't always defined when this function is called
            const width = window.innerWidth;

            // Stop and return the current value if we don't have a valid width (e.g. hidden elements will have width=0)
            if (!width) {
                return (typeof theState.isSmallSize === 'boolean') ? theState.isSmallSize : null;
            }

            // Screen is now small but was previously large (or the width was unknown)
            if (width < this.SMALL_SIZE_THRESHOLD && !theState.isSmallSize) {
                return true;
            }
            // Screen is now large but was previously small (or the width was unknown)
            else if (width >= this.SMALL_SIZE_THRESHOLD && theState.isSmallSize) {
                return false;
            }

            // Indicate to the caller that there is no change to the state
            return null;
        }

        _setupFakeFloatingHeader () {
            this.theadElemWidth = this.$theadElem.width();

            this.theadElemCellWidths = [];

            this.$theadElem.find('th').each(function (i, elem) {
                // console.log(i, elem);
                const boundingRect = elem.getBoundingClientRect();
                this.theadElemCellWidths.push(boundingRect.right - boundingRect.left);
            }.bind(this));

            window.addEventListener('scroll', this._onWindowScroll);
        }


        _getSelectedRowSelectionIds() {
            const newDisplayRowsState = this.state.displayRows;
            const checkedRows = [];

            // Count how many rows are now checked
            newDisplayRowsState.forEach((row, r) => {
                // Some other check box
                if(!(row.selection && row.selection.empty)){
                    if (row.isSelected || row.columns[0].contents[0].input.attributes.checked == true) {
                        //determine if a key was set,
                        if(row.key){
                            checkedRows.push(row.key);
                        }else{
                            checkedRows.push(row.columns[0].contents[0].input.attributes.id);
                        }
                    }
                }
            });

            return checkedRows;
        }


        _getAllRowSelectionIds(){
            const newDisplayRowsState = this.state.displayRows;
            const checkedRows = [];

            // Count how many rows are now checked
            newDisplayRowsState.forEach((row, r) => {
                // Some other check box
                if(!(row.selection && row.selection.empty)){
                    if (row.columns[0].contents[0].input) {
                        if(row.key){
                            checkedRows.push(row.key);
                        }else{
                            checkedRows.push(row.columns[0].contents[0].input.attributes.id);
                        }
                    }
                }
            });

            return checkedRows;
        }

        _onFooterControlRemoveClick () {
            let selectedRows = this._getSelectedRowSelectionIds();

            if(!this.props.source.footerControls){
                return;
            }
            let footerControls = this.props.source.footerControls;

            let removeFunction = (footerControls.remove && footerControls.remove.removeFunction) ? footerControls.remove.removeFunction : null;
            let itemName = (footerControls.remove && footerControls.remove.itemName) ? footerControls.remove.itemName : "row";
            let noItemsSelected = (footerControls.remove && footerControls.remove.noItemsSelected) ? footerControls.remove.noItemsSelected : "No "+itemName+"s were selected";

            if(selectedRows.length <= 0){

                feta.processUserMessages({
                    "template": "userMessages",
                    "parameters": {
                        "field": [
                            {
                                "elem": this.props.source.container,
                                "messages": [
                                    {
                                        "parameters":{
                                            "id":this.props.source.id+"_remove_no_items_selected"
                                        },
                                        "template": "message",
                                        "type": "error",
                                        "text": noItemsSelected
                                    }
                                ]
                            },
                        ]
                    }
                });
            }
            else{

                feta.userMessage.removeMessageById(this.props.source.id+"_remove_no_items_selected");

                feta.confirm.create({
                    'confirmMessage': 'Are you sure you want to remove the selected '+itemName+'(s)?',
                    'callback': removeFunction,
                    'callbackArgs': selectedRows
                });
            }
        }

        _onFooterControlRemoveRow(row){
        
        }

        _onExpandAllControlClick (){
            const newStateRows = this.state.displayRows;

            newStateRows.forEach((row) => {
                if (row.expand) {
                    if (!row.expand.settings.isVisible) {
                        row.expand.settings.isVisible = true;
                    }
                }
            });

            this.setState({
                displayRows: newStateRows,
                numDisplayRows: newStateRows.length,
            });
        }

        _onCollapseAllControlClick (){
            const newStateRows = this.state.displayRows;

            newStateRows.forEach((row) => {
                if (row.expand) {
                    if (row.expand.settings.isVisible) {
                        row.expand.settings.isVisible = false;
                    }
                }
            });

            this.setState({
                displayRows: newStateRows,
                numDisplayRows: newStateRows.length,
            });
        }

        _onFooterControlRemoveAllClick () {
            let selectedRows = this._getAllRowSelectionIds();

            if(!this.props.source.footerControls){
                return;
            }
            let footerControls = this.props.source.footerControls;

            let removeFunction = (footerControls.remove && footerControls.remove.removeFunction) ? footerControls.remove.removeFunction : null;
            let itemName = (footerControls.remove && footerControls.remove.itemName) ? footerControls.remove.itemName : "row";

            feta.userMessage.removeMessageById(this.props.source.id+"_remove_no_items_selected");

            feta.confirm.create({
                'confirmMessage': 'Are you sure you want to remove all '+itemName+'(s)?',
                'callback': this._onFooterControlRemoveAllCallback,
                'callbackArgs': removeFunction
            });
        }

        _onFooterControlRemoveAllCallback(removeFunction){
            const newHeaderState = this.state.header;
            const newDisplayRowsState = this.state.displayRows;

            // Body rows
            newDisplayRowsState.forEach((row) => {
                if (row.selection.empty || row.selection.readOnly) {
                    return;
                }

                row.columns[0].contents[0].input.attributes.checked = 'checked';
                row.isSelected = true;
            });

            // Header row
            newHeaderState.rows[0].columns[0].contents[0].input.attributes.checked = 'checked';
            newHeaderState.rows[0].isSelected = true;

            this.setState({
                header: newHeaderState,
                displayRows: newDisplayRowsState,
            });

            let selectedRows = this._getSelectedRowSelectionIds()

            feta.functionCall(event, removeFunction, [selectedRows]);
        }

        _onClearSelectionControlClick(evt){
            const newDisplayRowsState = this.state.displayRows;

            // Body rows
            newDisplayRowsState.forEach((row) => {
                if (!row.selection || row.selection.empty || row.selection.readOnly) {
                    return;
                }

                row.isSelected = false;

                if (Object.prototype.hasOwnProperty.call(row.columns[0].contents[0].input.attributes, 'checked')) {
                    delete row.columns[0].contents[0].input.attributes.checked;
                }
            });

            this.setState({
                displayRows: newDisplayRowsState,
            });
        }

        /////////////////////
        // Scroll handling //
        /////////////////////

        /**
         * Determines which rows should be rendered based on the current scroll position
         */
        _determineRowsToRender () {
            this.scrollTop = this.scrollableElem.scrollTop;

            const visibleStart = Math.floor(this.scrollTop / this.state.heightPerRow);
            const visibleEnd = Math.min(visibleStart + this.numRowsVisibleInViewport, this.state.total - 1);

            const displayStart = parseInt(Math.max(0, Math.floor(this.scrollTop / this.state.heightPerRow) - (this.numRowsVisibleInViewport * 1.5)), 10);
            const displayEnd = parseInt(Math.min(displayStart + (4 * this.numRowsVisibleInViewport), this.state.total - 1), 10);
            // const displayEnd = parseInt(
            //                     Math.max(
            //                         Math.min(displayStart + (4 * this.numRowsVisibleInViewport), this.state.total - 1),
            //                         this.state.numDisplayRows
            //                     )
            //                     , 10
            //                 );
            // console.log('\nscrollTop = ', this.scrollTop, '\nvisibleStart = ', visibleStart, '\nvisibleEnd = ', visibleEnd, '\n> difference = ', (visibleEnd - visibleStart), '\ndisplayStart = ', displayStart, '\n> difference = ', (visibleEnd - displayStart), '\ndisplayEnd = ', displayEnd, '\n> difference = ', (displayEnd - displayStart), '\nrecordsPerBody = ', this.numRowsVisibleInViewport);

            this.setState({
                visibleStart,
                visibleEnd,
                displayStart,
                displayEnd,
                scroll: this.scrollTop,
            });

            this._onTableScroll();
        }

        // Handles scroll events and requests new rows to be rendered if necessary
        _onTableScroll () {
            this.scrollTop = this.scrollableElem.scrollTop;

            const offsetHeight = this.scrollableElem.offsetHeight;
            const scrollHeight = this.scrollableElem.scrollHeight;
            const percentScrolledDown = (this.scrollTop / (scrollHeight - offsetHeight)) * 100;
            const distanceFromBottom = (scrollHeight - offsetHeight) - this.scrollTop;
            let numLocalRowsNotYetShown;

            // console.log(('' + percentScrolledDown).substr(0, 4) + '% scrolled down, ' + distanceFromBottom + 'px from the bottom');
            if (percentScrolledDown >= 80 || (distanceFromBottom / this.state.heightPerRow < 5)) {

                // console.info('Need more rows: more than 80% to the bottom or ' + Math.floor(distanceFromBottom / this.state.heightPerRow) + ' rows from the bottom');
                numLocalRowsNotYetShown = this.sourceTable.body.rows.length - this.showMoreRowsStep;

                // Look for new rows locally
                if (numLocalRowsNotYetShown && this.sourceTable.paging.step) {
                    // console.info(numLocalRowsNotYetShown + ' more local rows to display');
                    this._updateViewRows(this.state.numDisplayRows + Math.min(10, numLocalRowsNotYetShown));
                }

                // // Ask the server for more
                // else if (false) {
                //     // console.warn('No more local rows');
                // }
                // // Otherwise, there's nothing more to load, so do nothing (don't disable the scroll event handlers or it will cause issues when scrolling back up)
                // else {
                //     // console.warn('Nothing more to load');
                // }
            }
            // else {
            //     console.log('Not the bottom!\nscrollTop = ' + this.scrollTop + '\ncombined = ' + (scrollHeight - offsetHeight) + '\nscrollHeight = ' + scrollHeight + '\noffsetHeight = ' + offsetHeight);
            // }
        }

        _onResizerClick () {
            const oldStep = this.state.resizeStep;
            let newStep;

            if (oldStep === 'initial') {
                newStep = 'max';
            }
            else if (oldStep === 'max') {
                newStep = 'min';
            }
            else {
                newStep = 'initial';
            }

            const newViewportHeight = this._calcViewportHeight(this.state.heightPerRow, this.resizeSteps[newStep].numRows, this.state.headerRowHeight);

            this.setState({
                viewportHeight: newViewportHeight,
                resizeStep: newStep,
            });
        }

        // Store an element reference passed back by a child component
        _setElemRef (nodeName, elem) {
            this[nodeName + 'Elem'] = elem;
            this['$' + nodeName + 'Elem'] = $(elem);
        }


        //////////////////////////
        // Normalization methods//
        //////////////////////////



        ////////////
        // Render //
        ////////////

        render () {
            const sourceTable = this.sourceTable;
            const tagNames = (this.state.isSmallSize ? this.TAG_NAMES.smallSize : this.TAG_NAMES.largeSize);
            const outerWrapperClassNames = ['feta-table-wrapper'];
            const scrollWrapperClassNames = [];
            const scrollWrapperStyles = {};
            let getColDimensions = null;
            let onShowMoreClick = null;
            let tableTitle = null;
            let tablePaging = null;
            let filterSection = null;
            let tableMessages = null;
            let tableSections = []; // Will contain thead and tfoot (as applicable) so they can be inserted inside the `<table>` simultaneously
            const resizer = null;
            let footerControls = null;
            let headerControls = null;
            let tableControls = null;
            let tableControlItems = null;
            let tableSelectAll = null;
            let clearSelection = null;
            let legend = null;
            let rowCountDisplay;
            // if (1 || window.DEBUG) { console.warn('*** RENDER *** [' + this.sourceTable.container + '] ' + this.state.displayRows.length + ' rows out of ' + sourceTable.body.rows.length); }

            if(sourceTable.isEmpty){
                outerWrapperClassNames.push("feta-table-is-empty");
            }

            // Click handler for small screens
            if (this.state.isSmallSize) {
                onShowMoreClick = this._onShowMoreClick;
                 outerWrapperClassNames.push("feta-table-responsive");

            }
            // Class names and prop function for scrollable tables
            else if (this.state.isScrollable) {
                scrollWrapperClassNames.push('feta-table-height-overflow');
                scrollWrapperStyles.maxHeight = this.state.viewportHeight + 'px';

                getColDimensions = this._getColDimensions;
            }

            // Class names for filtering
            if (sourceTable.body.rows.length > this.state.displayRows.length) {
                outerWrapperClassNames.push('feta-table-is-filtered');
            }

            //Add class name for render style
            if (typeof sourceTable.type == "string") {
                 switch(sourceTable.type){
                    case 'action':
                    outerWrapperClassNames.push('feta-table-style-action');
                    break;

                    case 'default':
                    default:
                    outerWrapperClassNames.push('feta-table-style-default');
                }
            }
            else{
                outerWrapperClassNames.push('feta-table-style-default');
            }

            //FIXME:  Special class names should be depreciated
            if (sourceTable.renderStyle === 'client-summary') {
                journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'render'}, 'Depreciated renderStyle used. Setting class name for: ', sourceTable.renderStyle);
                outerWrapperClassNames.push('feta-table-client-summary');
            }
            else if (sourceTable.renderStyle === 'employee-summary') {
                journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'render'}, 'Depreciated renderStyle used. Setting class name for: ', sourceTable.renderStyle);
                outerWrapperClassNames.push('feta-table-employee-summary');
            }


            //Don't display rows if there are too many

            if(this.state.maxRowsToRender){
                if(this.state.maxRowsToRender < this.state.numDisplayRows){
                    this.state.numDisplayRows = 0;

                    this.state.displayRows = [
                    {
                        columns: [
                            {
                                text: 'There are too many results to display. Please refine your search.',
                                cellProps: {
                                    colSpan: sourceTable.head.rows[0].columns.length,
                                    style: {fontStyle: 'italic'},
                                    className: 'cui-align-center',
                                },
                                key: sourceTable.id + "_maxDisplayCol",
                            },
                        ],
                        key: sourceTable.id + "_maxDisplayRow",
                    }];
                }
            }

            ///////////////////////////
            // Build table structure //
            ///////////////////////////

            // Below we will add the necessary sub-components needed for the table based on screen size and other factors

            if ((sourceTable.title && sourceTable.renderStyle && sourceTable.renderStyle.showTitle) || (sourceTable.title && this.state.isSmallSize)) {
                tableTitle = (
                    <h4>{sourceTable.title}</h4>
                );
            }

            // Filters, if applicable
            if (this.state.filter && !sourceTable.isEmpty) {
                filterSection = (
                    <div
                        className={(this.sourceTable.floatingHeader && feta && feta.browser.supports.positionSticky) ? 'feta-table-floating-container' : 'feta-table-filter-section'}
                    >
                        <TableFilter
                            source={sourceTable}
                            isSmallSize={this.state.isSmallSize}
                            filters={this.state.filter}
                            updateFilterBasicValue={this._updateFilterBasicValue}
                            updateFilterAdvCheckbox={this._updateFilterAdvCheckbox}
                            sortFunc={this._applySorting}
                            totalRowsAvailable={this.totalRowsAvailable}
                            numDisplayRows={this.state.numDisplayRows}
                        />
                    </div>
                );
            }




            // Table body
            tableSections.push(
                <TableBody
                    key="tbody"
                    source={sourceTable}
                    isScrollable={this.state.isScrollable}
                    isSmallSize={this.state.isSmallSize}
                    tagNames={tagNames}
                    rows={this.state.displayRows}
                    total={this.state.numDisplayRows}
                    visibleStart={this.state.visibleStart}
                    visibleEnd={this.state.visibleEnd}
                    displayStart={this.state.displayStart}
                    displayEnd={this.state.displayEnd}
                    defaultRowHeight={this.state.heightPerRow}
                    onSelectRowChange={this._onSelectRowChange}
                    onInputChange={this._onInputChange}
                    onCheckChange={this._onCheckChange}
                    onShowMoreClick={onShowMoreClick}
                    onExpandableRowClick={this._onExpandableRowClick}
                    fetchExpandContent={this._fetchExpandContent}
                    onClickSelectableRow={this._onClickSelectableRow}
                    getTableCell={this._getTableCell}
                />
            );

            // Table footer
            //TODO: Update check to validate if filters are applied instead of relying on display row count.
            if (sourceTable.foot && (this.totalRowsAvailable === this.state.numDisplayRows)) {
                tableSections.push(
                    <TableFoot
                        key="tfoot"
                        source={sourceTable}
                        isSmallSize={this.state.isSmallSize}
                        tagNames={tagNames}
                        getTableCell={this._getTableCell}
                    />
                );
            }

            if(sourceTable.legend){
                legend = (
                    <TableLegend
                        key="legend"
                        source={sourceTable}
                        isSmallSize={this.state.isSmallSize}
                    />
                );
            }

            if(sourceTable.paging){
                tablePagingHeader = (
                    <TablePaging
                        key="pagingHeader"
                        location="header"
                        source={sourceTable}
                        isSmallSize={this.state.isSmallSize}
                    />
                );

                tablePagingFooter = (
                    <TablePaging
                        key="pagingFooter"
                        location="footer"
                        source={sourceTable}
                        isSmallSize={this.state.isSmallSize}
                    />
                
                );
            }

            if((sourceTable.renderStyle && sourceTable.renderStyle.switchTo) || (sourceTable.renderStyle && sourceTable.renderStyle.expandControls)){

                tableControlItems = (
                    <TableControls
                        key="tableControls"
                        source={sourceTable}
                        isSmallSize={this.state.isSmallSize}
                        onExpandAllControlClick = {this._onExpandAllControlClick}
                        onCollapseAllControlClick = {this._onCollapseAllControlClick}                        
                    />
                );
            }

            if(sourceTable.headerControls){
                headerControls = (
                    <TableHeaderControls
                        key="headerControls"
                        source={sourceTable}
                        isSmallSize={this.state.isSmallSize}
                    />
                );
            }

            if ((sourceTable.footerControls || sourceTable.remove) && (this.totalRowsAvailable === this.state.numDisplayRows)) {
                footerControls = (
                    <TableFooterControls
                        key="footerControls"
                        source={sourceTable}
                        isSmallSize={this.state.isSmallSize}
                        onFooterControlRemoveClick={this._onFooterControlRemoveClick}
                        onFooterControlRemoveAllClick={this._onFooterControlRemoveAllClick}
                    />
                );
            }

            // Table header, for large screens only
            if (!this.state.isSmallSize && sourceTable.head) {
                // Dummy copy of the `<thead>` for browsers that don't support position:sticky
                if (this.sourceTable.floatingHeader && feta && !feta.browser.supports.positionSticky) {
                    tableSections.unshift(
                        <TableHead
                            isDummy
                            key="theadDummy"
                            source={sourceTable}
                            scrollTop={this.scrollTop}
                            isSmallSize={this.state.isSmallSize}
                            tagNames={tagNames}
                            sortFunc={this._applySorting}
                            onSelectAllChange={this._onSelectAllChange}
                            getColDimensions={getColDimensions}
                            getTableCell={this._getTableCell}
                            setElemRef={this._setElemRef}
                        />
                    );
                }

                tableSections.unshift(
                    <TableHead
                        key="thead"
                        source={sourceTable}
                        scrollTop={this.scrollTop}
                        isSmallSize={this.state.isSmallSize}
                        tagNames={tagNames}
                        sortFunc={this._applySorting}
                        onSelectAllChange={this._onSelectAllChange}
                        getColDimensions={getColDimensions}
                        getTableCell={this._getTableCell}
                        setElemRef={this._setElemRef}
                    />
                );
            }

            // Add the `<table>` wrapper around the head/body/foot
            tableSections = [
                <tagNames.table
                    className="feta-table-elem-table"
                    key="table"
                >
                    {tableSections}
                </tagNames.table>,
            ];

            // Scroll wrapper, only for large screens
            if (!this.state.isSmallSize) {
                // Put everything into a wrapper so the body can scroll
                tableSections = [
                    <div
                        key="scrollableElem"
                        className={scrollWrapperClassNames.join(' ')}
                        style={scrollWrapperStyles}
                        onScroll={this._determineRowsToRender}
                        ref={(elem) => this.scrollableElem = elem}
                    >
                        {tableSections}
                    </div>
                ];
            }
            // Display row count for small screens
            else if (!sourceTable.isEmpty) {
                rowCountDisplay = (
                    <TableRowCountDisplay
                        count={sourceTable.isEmptyDueToFilters ? 0 : this.state.numDisplayRows}
                        total={this.totalRowsAvailable}
                    />
                );
            }

            // Resizer button
            //TODO: The button does work, but the functionality is on hold until UI determines the design specs
            // if (this.state.isScrollable) {
            //     resizer = (
            //         <TableResizer
            //             steps={this.resizeSteps}
            //             currentStep={this.state.resizeStep}
            //             onResizerClick={this._onResizerClick}
            //         />
            //     );
            // }


            if(sourceTable.selectable && sourceTable.selectionType == "multiple" && sourceTable.selectAll == true && this.state.isSmallSize){            
                tableSelectAll = (
                    <button
                        type="button"
                        className=""
                        onClick={this._onSelectAllButtonClick}                                
                        >
                            Select All
                    </button>                    
                );
            }

            if(sourceTable.selectable === true && sourceTable.renderStyle && sourceTable.renderStyle.clearSelection === true){
                clearSelection = (
                    <a className="feta-table-clear-selection"
                       onClick = {this._onClearSelectionControlClick}>
                    Clear Selection
                    </a>
                );
            }


            // tableControlItems = (
            //     <div className="feta-table-controls">
            //         <span><strong>&nbsp;+&nbsp;</strong></span>
            //         <span><strong>&nbsp;-&nbsp;</strong></span>
            //         <span><strong>&nbsp;[&nbsp;]>&nbsp;</strong></span>
            //     </div>
            // );


            if(clearSelection || legend || tableControlItems || tableSelectAll){




                tableControls = (
                    <div className="feta-table-control-row">
                        {tableSelectAll}
                        {clearSelection}
                        {legend}
                        {tableControlItems}
                    </div>
                );
            }



            //Create location for table field messages
            tableMessages = (
                <div
                    className="feta-table-messages"
                    key="messages"
                />
            );

            return (
                <div
                    className={outerWrapperClassNames.join(' ')}
                    ref={(_tableWrapper) => this.$tableWrapper = $(_tableWrapper)}
                >
                    {headerControls}
                    {tableTitle}
                    {tableMessages}
                    {tablePagingHeader}
                    {filterSection}
                    {tableControls}                    
                    {tableSections}
                    {footerControls}
                    {tablePagingFooter}
                    {resizer}
                    {rowCountDisplay}
                </div>
            );
        }
    }

    Table.propTypes = {
        source: ReactPropTypes.any,
        // source: ReactPropTypes.shape({
        //     sortCols: [],
        //     viewport: {
        //         height: 0,
        //     },
        //     paging: {
        //         total: 0,
        //     },
        //     body: {
        //         rows: [],
        //     },
        // }),
    };

    Table.defaultProps = {
        source: {
            // sortCols: [],
            // viewport: {
            //     height: 0,
            // },
            // paging: {
            //     total: 0,
            // },
            // body: {
            //     rows: [],
            // },
        },
    };

    return Table;
});
