define(['react', 'reactproptypes', 'jquery', 'popover', 'kind', 'TableSortIcon', 'renderer', 'RenderItem', 'RenderIconMessage', 'guid', 'TableColumnHeader'], function (React, ReactPropTypes, $, popover, kind, TableSortIcon, renderer, RenderItem, RenderIconMessage, guid, TableColumnHeader) {
    class TableLegend extends React.Component {
        constructor (props) {
            super(props);

        }

        /////////////////////
        // Private methods //
        /////////////////////

        ////////////
        // Render //
        ////////////
        render(){

            const sourceTable = this.props.source;
            const legend = sourceTable.legend;
            let isSmallSize = this.props.isSmallSize;
            let elems = [];

            //Render rest of footer control contents. This is a straight rendering of JSON elements
            if(legend && legend.contents && legend.contents.length > 0){
                legend.contents.forEach((col, c) => {
                    const controlItemSource = legend.contents[c];

                    // TODO: Look into updating key and removing row/col index.
                    elems.push(
                        <li className="feta-table-legend-item">
                            <RenderItem
                                source={controlItemSource}
                                key={legend.key + this.props.rowIndex  + '-' + this.props.colIndex + '-' + c}
                                colIndex={this.props.colIndex}
                                rowIndex={this.props.rowIndex}
                            />
                        </li>
                    );
                });
            }

            // Only show the footer controls element if there are elements to show.
            if(elems.length && elems.length > 0){
                return (
                    <div className="feta-table-legend">
                        <ul>
                        {elems}
                        </ul>
                    </div>
                );
            }

            return null;
        }
    }

    TableLegend.propTypes = {
        source: ReactPropTypes.shape({}).isRequired,
        isSmallSize: ReactPropTypes.bool,
    };

    TableLegend.defaultProps = {
        isSmallSize: false,
    };

    return TableLegend;
});
