// (function (win) {
//     // We pass in the current container object (i.e. a window or frame object) so we know we are referring to the *immediate* parent. If our page is nested multiple levels deep (e.g. inside an iframe that is inside another iframe) the standard `window` global will refer to the top-level window which is not what we want.
//     if (win.location && win.top && win.top.location) {
//         if (win.location !== win.top.location) {
//             win.top.location = win.location;
//         }
//     }
// })(this);

// Global variables class
// =====================================================================================
var uiGlobal = {
};


// Base path:
uiGlobal.assetPath = '/iflow/'; // Server path

// Images have their own variable `sIFLOWImagesPath` for legacy purposes
uiGlobal.sIFLOWImagesPath = uiGlobal.assetPath + 'images/';

