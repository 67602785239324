/*global feta */
define(['react', 'reactproptypes', 'RenderItem', 'RenderIconTrashCan'], function (React, ReactPropTypes, RenderItem, RenderIconTrashCan) {
    const InboxListItem = (props) => {
        const message = props.message;
        const itemClasses = ['cui-row feta-inbox-list-item'];
        let deleteButton;
        let deleteButtonElem;
        let restoreOverlay;
        const onMessageClickProp = props.onMessageClick; // Unnecessary but prevents a false positive for the ESLint rule `react/no-unused-prop-types`
        const index = props.index; // Unnecessary but prevents a false positive for the ESLint rule `react/no-unused-prop-types`


        const onMessageClick = (...eventArgs) => {
            onMessageClickProp(index, ...eventArgs);
        };

        const onMessageKeyPress = (...eventArgs) =>{
            let evt = eventArgs[0];

            evt.preventDefault();


            if(evt.which == 13 || evt.which == 32){
                onMessageClickProp(index, ...eventArgs);
            }
        };

        if (!message.read || !message.read.state) {
            itemClasses.push('feta-inbox-list-item-unread');
        }

        // Message can be deleted
        if (message.delete.url) {
            const onDeleteClick = props.onDeleteClick; // Unnecessary but prevents a false positive for the ESLint rule `react/no-unused-prop-types`
            const onConfirmDelete = () => {
                onDeleteClick(index);
            };
            const onClick = (...eventArgs) => {
                // Don't let the modal open
                eventArgs[0].stopPropagation();
            };

            const onDeleteKeyPress = (...eventArgs) =>{
                let evt = eventArgs[0];

                evt.preventDefault();
                evt.stopPropagation();
            };

            deleteButton = (
                <button
                    type="button"
                    onClick={onClick}
                    onKeyPress={onDeleteKeyPress}
                    title="Remove message"
                    ref={(el) => deleteButtonElem = el}
                >
                    {RenderIconTrashCan()}
                    <span>Remove message</span>
                </button>
            );

            setTimeout(() => {
                feta.confirm.setup(deleteButtonElem, {callback: onConfirmDelete});
            }, 100);
        }

        return (
            <li
                key={message.key}
                className={itemClasses.join(' ')}
                onClick={onMessageClick}
                onKeyPress={onMessageKeyPress}
                role="button"
                tabIndex="0"
            >
                {restoreOverlay}

                <div className='feta-inbox-list-item-content'>

                    <div className='feta-inbox-list-item-date'>
                        {message.dateFormats.long}
                    </div>

                    <div className='feta-inbox-list-item-subject-wrapper'>
                        <div className='feta-inbox-list-item-subject'>
                            {message.subject}
                        </div>

                        {message.description &&
                            <div className='feta-inbox-list-item-description'>
                                {message.description}&hellip;
                            </div>
                        }
                    </div>
                </div>

                <div className='feta-inbox-list-item-controls'>
                    <div className='feta-inbox-list-item-delete'>
                        {deleteButton}
                    </div>
                </div>

            </li>
        );
    };

    InboxListItem.propTypes = {
        message: ReactPropTypes.shape({}).isRequired,
        index: ReactPropTypes.number,
        onMessageClick: ReactPropTypes.func,
        onDeleteClick: ReactPropTypes.func,
    };

    InboxListItem.defaultProps = {
        message: {},
        index: 0,
        onMessageClick: () => {},
        onDeleteClick: () => {},
    };

    return InboxListItem;
});
