/*jshint esversion: 6*/
define(['guid'], function (guid) {
    return {
        submit: (evt, options) => {
            let frm = false;
            let validation = false;

            if (!(evt instanceof Event) &&  (typeof evt !== 'object' ||  (typeof evt === 'object' && !evt.hasOwnProperty('target')))) {
                options = evt;
                evt = undefined;
            }

            // Identify the proper form by option id or by the buttons native form
            if (options.id && typeof options.id === 'string') {

                frm = document.getElementById(options.id);
            }

            if (frm) {

                journal.log({ type: 'info', owner: 'Developer', module: 'feta', submodule: 'form', func: 'submit' }, "Submitting form:", frm);

                frm.submit();
                return true;
            }
        },

        virtual: (evt, formSettings, formMap, submitAfter, newWindow, settings) => {
            if (!(evt instanceof Event)) {
                settings = newWindow;
                newWindow = submitAfter;
                submitAfter = formMap;
                formMap = formSettings;
                formSettings = evt;
                evt = undefined;
            }

            function populateAndSubmit () {
                // Process the map if there strict setting is not present
                if (typeof formMap === 'object' && settings !== 'strict') {
                    const inputs = ['INPUT', 'SELECT', 'TEXTAREA', 'SPAN'];

                    // Loop through the page and find existing form values
                    for (const field in formMap) {
                        const fieldID = formMap[field];
                        const $pageField = $('#' + fieldID);

                        // check to see if we wound the field
                        if ($pageField.length === 1) {
                            // Figure out how to extract the value form an input of static element
                            if (inputs.indexOf($pageField[0].nodeName) !== -1) {
                                formMap[field] = $pageField.val().trim();
                            }
                            else {
                                formMap[field] = $pageField.text().trim();
                            }
                        }
                        // Check to see if the value is a string and submit that as a static value
                        else if ($pageField.length === 0 && typeof fieldID === 'string') {
                            journal.log({type: 'warning', owner: 'UI', module: 'feta', submodule: 'form', func: 'virtual'}, 'Navigation source: ' + fieldID + ' was not an ID for an element on the page. Treating this as a static string value.' );

                            formMap[field] = fieldID;
                        }
                        else {
                            // Prevent form submit
                            submitAfter = false;

                            journal.log({type: 'error', owner: 'UI', module: 'feta', submodule: 'form', func: 'virtual'}, 'Navigation Map for virtual form failed to find reference for: "', fieldID, '"');
                        }
                    }
                }

                // Create the form
                const virtualForm = document.createElement('form');

                // Hide it so the fields don't show
                virtualForm.style.display = 'none';

                // Now add the hidden inputs
                for (const inputAttr in formMap) {
                    const input = document.createElement('input');

                    input.setAttribute('type', 'hidden');
                    input.setAttribute('name', inputAttr);
                    input.setAttribute('value', formMap[inputAttr]);

                    virtualForm.appendChild(input);
                }

                // Add form attributes
                for (const attr in formSettings) {
                    if (formSettings[attr] !== '' && formSettings[attr] !== undefined) {
                        virtualForm.setAttribute(attr, formSettings[attr]);
                    }
                }

                // Create a name for a window so we can target it

                // Check to see if we new windows
                if (newWindow) {
                    const winName = guid();

                    virtualForm.setAttribute('target', winName);

                    // Add a name input for the popup
                    const popupInput = document.createElement('input');

                    popupInput.setAttribute('type', 'hidden');
                    popupInput.setAttribute('name', 'fw_popup_name');
                    popupInput.setAttribute('value', true);

                    virtualForm.appendChild(popupInput);

                    // Open a blank window
                    formSubmitWindow = window.open('', winName/* , 'scrollbars=yes,menubar=no,resizable=yes,toolbar=no,width=900,height=700,titlebar=yes,status=no,location=no' */);
                    formSubmitWindow.isPopup = true;

                    // _priv.childWindow = formSubmitWindow;
                }

                // Add form to the page body
                document.body.appendChild(virtualForm);

                if (submitAfter) {
                    const frm = document.getElementById(formSettings.id);

                    frm.submit();

                    if (newWindow) {
                        setTimeout(function () {
                            formSubmitWindow.focus();
                        }, 1000);
                    }
                }
            }

            const defaultFormSetting = {
                action: '',
                method: 'POST',
                name: 'virtual',
                id: 'virtual',
            };

            let formSubmitWindow = false;

            // Merge defaults and developer defined settings.
            formSettings = $.extend({}, defaultFormSetting, formSettings);

            let $form = $('form[name="' + formSettings.name + '"]');

            // Check to see if the form name is virutal as we will auto clean this one up
            if ($form.length === 1 && $form.attr('name') === 'virtual') {
                journal.log({type: 'info', owner: 'UI', module: 'feta', submodule: 'form', func: 'virtual'}, 'Flushing existing virtual form.');

                $form.remove();

                $form = undefined;
            }
            else if ($form.length === 0) {
                $form = undefined;
            }

            // Check to see if another virutal form already exists
            if ($form === null || $form === undefined) {
                populateAndSubmit();
            }
            else {
                journal.log({type: 'error', owner: 'UI', module: 'feta', submodule: 'form', func: 'virtual'}, 'Unable to create virtual form: "', $form.attr('name'), '" because this forms with this name already exists. Only forms named "virtual" can be removed automatically.');
            }
        }
    };
});
