define(['react', 'reactproptypes', 'RenderItem'], function (React, ReactPropTypes, RenderItem) {
    const InboxListEmptyMsg = (/* props */) => {
        const itemClasses = ['cui-row feta-inbox-list-empty-message'];

        return (
            <li
                key="empty-message"
                className={itemClasses.join(' ')}
            >
                <RenderItem
                    template="grid"
                    type="column"
                >
                    {'There are no messages to display.'}
                </RenderItem>
            </li>
        );
    };

    return InboxListEmptyMsg;
});
