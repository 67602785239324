define([], function () {
    // const NAMESPACE = 'uiTimer';
    // const VERSION = '1.0.0';

    const uiTimer = function(timeoutFunction, duration, step){
        this._timeoutFunction = (timeoutFunction) ? timeoutFunction : undefined;
        this._duration = (duration) ? duration : 0;

        this._step = (step) ? step : null;
        this._delta;
        this._startTime;

        this._endTime;
        this._remainingTime;
        this._paused = false;
    };

    ////////////////////
    // Public methods //
    ////////////////////

    //Starts the timer.
    uiTimer.prototype.start = function(){
        let dateObj = new Date();

        if(!this._timeoutFunction){
            journal.log({type: 'error', owner: 'UI', module: 'uiTimer', submodule: 'start'}, 'Timer does not have a timeoutFunctions set.');
            return;
        }

        this._startTime = dateObj.getTime();
        this._endTime = this._startTime + this._duration;
        this._paused = false;

        let uit = this;

        //If step is set, use interval timer. More resource heavy but more accurate implementation.
        if(uit._step){
            this.activeTimer = setInterval(function(){
                let newTime = new Date();
                if(newTime.getTime() > uit._endTime){
                    clearInterval(uit.activeTimer);
                    uit._timeoutFunction();
                }
            }, uit._step);
        }
        else{
            this.activeTimer = setTimeout(function(){
                uit._timeoutFunction();
            }, uit._duration);
        }
    };

    //Stops the timer.
    uiTimer.prototype.stop = function(){
        clearInterval(this.activeTimer);
    };

    //Pauses the timer.
    uiTimer.prototype.pause = function(){
        if(this._paused){
            return;
        }

        clearInterval(this.activeTimer);

        let newDate = new Date();
        let currentTime = newDate.getTime();

        this._paused = true;
        this._remainingTime = this._endTime - currentTime;
    };

    //Restarts timer with remaining duration from pause.
    uiTimer.prototype.unPause = function(){
        if(!this._paused){
            return;
        }

        if(this._remainingTime){
            //Update duration
            this._duration = this._remainingTime;
            this._remainingTime = null;
        }

        this.start();
    };

    uiTimer.prototype.setDuration = function(newDuration){
        if(typeof newDuration == "number"){
            this._duration = newDuration;
        }
    };

    uiTimer.prototype.setTimeoutFunction = function(newTimeoutFunction){
        if(newTimeoutFunction){
            this._timeoutFunction = newTimeoutFunction;
        }
    };

    uiTimer.prototype.setStep = function(newStep){
        if(newStep){
            this._step = newStep;
        }
        else{
            this._step = null;
        }
    };

    //////////////////////////////////////////
    // Expose public properties and methods //
    //////////////////////////////////////////

    return uiTimer;
});
