/*global fwData:true*/
/*eslint no-undef: "error"*/
define(['jquery', 'tree'], function ($, tree) {
    let $sideMenu;
    let $bodyWrapper;
    let $appBanner;
    let $navMenuToggle;
    let _isMenuOpen = false;
    let $window;

    const _init = () => {
        if (typeof fwData === 'undefined') {
            // This is required by `_render()` so we might as well quit now if it's not defined
            return false;
        }

        // Cache global elements
        $sideMenu = $('.feta-nav');
        $appBanner = $('.feta-app-header');
        $window = $(window);

        // Attempt to render the side menu navigation
        if (_render()) {
            // If successful, continue setting things up:

            // Cache global elements
            $bodyWrapper = $('#cui-body-wrapper');

            //Add nav id to nav element.
            if($("#nav").length < 1 && !$sideMenu.attr('id')){
                $sideMenu.attr('id', 'nav');
            }

            //Add nav id to nav element.
            if(!$sideMenu.attr('tabindex')){
                $sideMenu.attr('tabindex', 0);
            }

            // Setup sub-components
            if ($appBanner.length) {
                _menuSetup();
            }
            else {
                journal.log({type: 'warn', owner: 'UI', module: 'navigation', submodule: '_init'}, 'No app header element');
            }
        }

        // uNav toggling
        $('.feta-toggle-unav').on('click', _navToggleUnav);
    };

    /**
     * Render the global, off-canvas navigation (i.e. the 'side menu')
     *
     * @return  {boolean}  Whether or not the menu was actually created
     */
    const _render = () => {
        if ($appBanner.length) {
            $navMenuToggle = $appBanner.find('.feta-toggle-nav');
        }

        // Make sure we have items to render
        if (fwData.navigation && fwData.navigation.items && fwData.navigation.items.length) {
            // Check for the container
            if (!$sideMenu.length) {
                // Only display an error if the element is missing and we actually had something we wanted to render
                journal.log({type: 'error', owner: 'UI', module: 'navigation', submodule: '_render'}, 'No `<nav>` element, cannot render menu');

                return false;
            }

            // Make sure the filter is enabled
            if (!fwData.navigation.search && !fwData.navigation.filter) {
                journal.log({type: 'error', owner: 'UI', module: 'navigation', submodule: '_render'}, 'Must have `filter` defined');
            }

            if(fwData.navigation){
                if(fwData.navigation.search){
                    journal.log({type: 'error', owner: 'UI', module: 'navigation', submodule: '_render'}, 'Navigation menu must use `filter` instead of `search`');

                    if(!fwData.navigation.filter){
                        fwData.navigation.filter = fwData.navigation.search;
                        delete fwData.navigation.search;
                    }

                }
            }

            // Render the menu using Tree
            tree.init(fwData.navigation, $sideMenu.get(0));

            // Update the button
            if ($navMenuToggle) {
                $navMenuToggle.find('span').text(fwData.navigation.title || '');
            }

            return true;
        }
        else {
            // Log a warning in OLS where we'd expect to have navigation
            if (fwData.environment === 'ols' && !(fwData.page && fwData.page.type === 'splash')) {
                journal.log({type: 'warn', owner: 'UI', module: 'navigation', submodule: '_render'}, 'No menu items to render.\n`fwData` = ', fwData);
            }

            // Remove the button from the app banner
            if ($navMenuToggle) {
                $navMenuToggle.addClass('cui-hidden');
                $('.feta-app-title').css({borderLeft: 'none'});
            }

            return false;
        }
    };


    ///////////////////////
    // Side nav and uNav //
    ///////////////////////

    const _navToggleUnav = (/*evt*/) => {
        if (window.matchMedia('(max-width: 829px)').matches) {
            document.documentElement.classList.toggle('feta-unav-visible');
        }
    };


    ///////////////
    // Side menu //
    ///////////////

    const _menuSetup = () => {
        // Toggle menu from main screen
        $navMenuToggle.on('click', (evt) => {
            evt.preventDefault();

            if (_isMenuOpen) {
                _menuClose();
            }
            else {
                _menuOpen();
            }
        });

        // Close button
        $('.feta-tree-menu-close').on('click', (/*evt*/) => {
            _menuClose();
        });

    };

    const _menuOpen = () => {
        let menuTop = $navMenuToggle.position().top - $window.scrollTop() - 3;
        menuTop = (menuTop > 0) ? menuTop : 0;

        menuTop = $navMenuToggle.position().top - 3;

        $bodyWrapper.css({
            overflowY: 'hidden',
        });

        //Set top of menu
        $sideMenu
            .css({
                top: menuTop,
            });

        //Reveal menu
        $sideMenu
            .css({
                transform: 'translateX(0)',
            });

        _isMenuOpen = true;

        $navMenuToggle.attr('aria-expanded','true');

        // Close the menu the next time the main body is clicked
        // We need a timeout, otherwise the click that resulting in calling `_menuOpen` will trigger the event handler we're about to set up
        setTimeout(function () {
            $bodyWrapper.one('click', _menuClose);
            $sideMenu.keydown( _checkMenuKey);
        }, 1);

        //Give focus to the side menu once triggered.
        var x = window.scrollX, y = window.scrollY;
        $sideMenu.focus()
        window.scrollTo(x, y);
    };

    const _checkMenuKey = (evt) => {

        if(evt.which == 27){
            if($(evt.target).is('input')){
                return false;
            }

            _menuClose();



            evt.preventDefault();
        }
    };

    const _menuClose = (/*evt*/) => {

        $sideMenu
            .css({
                transform: 'translateX(-100%)',
            });

        // Allow the rest of the page to scroll again
        $bodyWrapper.css({
            overflowY: 'auto',
        });

        _isMenuOpen = false;
        $navMenuToggle.attr('aria-expanded','false');

        // Tell the `tree` component that it's closing, e.g. so the search results can be cleared
        $sideMenu.trigger('treeclose');

        //Remove click handler from body if it was not triggered.
        $bodyWrapper.off('click', _menuClose);
        $sideMenu.off('keydown', _checkMenuKey);

        $navMenuToggle.focus();
    };

    //////////////////////////////////////////
    // Expose public properties and methods //
    //////////////////////////////////////////

    return {
        init: _init,
    };
});
