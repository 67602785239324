define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    const InboxModalHeader = function (props) {
        const message = props.listOfMessages[props.currentIndex];
        let viewFormButtonColumn;
        const onViewFormClick = function (...eventArgs) {
            props.onViewFormClick(message, ...eventArgs);
        };

        if (!message || message.key === 'empty-message') {
            return null;
        }

        // View Form
        if (message.attachments && message.attachments.length) {
            //TODO: there can be multiple attachments in the array
            viewFormButtonColumn = (
                <div className="cui-col cui-align-right">
                    <button
                        type="button"
                        className="cui-button-primary"
                        onClick={onViewFormClick}
                    >
                        View
                    </button>
                </div>
            );
        }

        return (
            <header className="cui-modal-header cui-uiBox-header">
                <div className="cui-modal-header-content">
                    <div className="cui-row">
                        <div className="cui-col">
                            <h1>{message.subject}</h1>
                        </div>
                    </div>
                    <div className="cui-row">
                        <div className="cui-col">
                            <h2>{message.dateFormats.long}</h2>
                            {message.sentto && message.sentto.email &&
                                <p>To: {message.sentto.email}</p>
                            }
                        </div>
                        {viewFormButtonColumn}
                    </div>

                    <button
                        className="cui-modal-hide"
                        onClick={props.onCloseModal}
                    >
                        Close Modal
                    </button>
                </div>
            </header>
        );
    };

    InboxModalHeader.propTypes = {
        listOfMessages: ReactPropTypes.array,
        currentIndex: ReactPropTypes.number,
        onCloseModal: ReactPropTypes.func,
        onViewFormClick: ReactPropTypes.func,
    };

    InboxModalHeader.defaultProps = {
        listOfMessages: [],
        currentIndex: 0,
        onCloseModal: () => {},
        onViewFormClick: () => {},
    };

    return InboxModalHeader;
});
