/* eslint "no-unused-vars": 0 */
// We need to include `TableColumn` here to appease RequireJS, otherwise we get an error about not finding `RenderIconSearch`
define(['react', 'reactproptypes', 'TableColumn'], function (React, ReactPropTypes, TableColumn) {
    class TableRow extends React.Component {
        constructor (props) {
            super(props);

            // Private method binding to the class's namespace
            this._onExpandableHeaderClick = this._onExpandableHeaderClick.bind(this);
            this._onRowClick = this._onRowClick.bind(this);
            this._onSelectionClick = this._onSelectionClick.bind(this);
            this._stripHtml = this._stripHtml.bind(this);

            ///////////////
            // Constants //
            ///////////////

            this.CLASSES = {
                hidden: 'cui-hidden',
                expanded: 'feta-table-row-expanded',
            };

            this.ANIMATION = {
                duration: '100',
                easing: 'easeInOutCubic',
            };
        }


        /////////////////////
        // Private methods //
        /////////////////////

        // Mobile row expansion
        _onExpandableHeaderClick (evt) {
            const rowHeader = evt.currentTarget;
            const $rowHeader = $(rowHeader);
            const $rowBody = $rowHeader.parent().find('.feta-table-row-body');
            const rowBody = $rowBody.get(0);

            // Don't let `_onRowClick()` get called
            evt.stopPropagation();

            // Currently collapsed, need to expand
            if (rowBody.classList.contains(this.CLASSES.hidden)) {
                // Make it visible but with zero height so we can expand it
                rowBody.style.height = '0px';
                rowBody.classList.remove(this.CLASSES.hidden);

                $rowHeader.parent().addClass(this.CLASSES.expanded);

                // Expansion animation
                $rowBody.animate(
                    {
                        height: rowBody.scrollHeight,
                    },
                    {
                        easing: this.ANIMATION.easing,
                        duration: this.ANIMATION.duration,
                        done: function () {
                            rowBody.style.height = 'auto';
                        }.bind(this),
                    }
                );
            }
            // Currently expanded, need to collapse
            else {
                // Collapse animation
                $rowBody.animate(
                    {
                        height: '0px',
                    },
                    {
                        easing: this.ANIMATION.easing,
                        duration: this.ANIMATION.duration,
                        done: function () {
                            // Even though the element is effectively invisible at this point, we use this class as a quick test of whether it's hidden or not
                            rowBody.classList.add(this.CLASSES.hidden);
                            $rowHeader.parent().removeClass(this.CLASSES.expanded);
                        }.bind(this),
                    }
                );
            }
        }

        // Intermediary function so that we can pass the `row` object directly to the target function. Without this, we would've had to use `.bind` on the event handler in `<TableBody>` which is terrible for performance.
        _onRowClick (...args) {
            if (!this.props.isSmallSize) {
                if (this.props.onExpandableRowClick) {
                    // this.props.onExpandableRowClick(this.props.row, ...args);
                }
                else if (this.props.onClickSelectableRow) {
                    // console.log('calling onClickSelectableRow from TableRow => _onRowClick');
                    // this.props.onClickSelectableRow(this.props.row, ...args);
                }
            }
        }

        _onSelectionClick(evt){
        	evt.preventDefault();
        	evt.stopPropagation();
        }

        _stripHtml (html) {

            //Since there are some tags that are used to create space between words, but do not reflect that in a parsed string, add spaces between starting and closing tags.
            html = html.replace(/></g, "> <");

            // Create a new div element
            var temporaryTextElement = document.createElement("div");

            // Set the HTML content with the providen
            temporaryTextElement.innerHTML = html;

            // Retrieve the text property of the element (cross-browser support)
            return temporaryTextElement.textContent || temporaryTextElement.innerText || "";
        }

        ////////////
        // Render //
        ////////////

        render () {
            const sourceTable = this.props.source;
            const row = this.props.row;
            const cols = row.columns;
            const hasExpandableHeader = (this.props.isSmallSize && sourceTable.hasPrimarySecondaryCols);
            const rowClassNames = (row.rowProps && row.rowProps.className) ? row.rowProps.className.split(' ') : [];
            let headerCells = [];
            let bodyCells = null;
            let expandCells = null;
            const controlCells = [];
            const styles = this.props.inlineStyle ? Object.assign({},this.props.inlineStyle) : {};
            const rowAttrs = {
                onClick: this._onRowClick,
                key: row.key,
            };
            let isSelectable = true;
            let isSelectionRow = false;
            let responsiveHeight = null;

            if (!cols) {
                return null;
            }

            const _addClass = (col, newClass) =>{
                if(col.cellProps.className){
                    if(col.cellProps.className.indexOf(newClass) > -1){
                        return false;
                    }
                    else{
                        col.cellProps.className += " " + newClass;
                        return true;
                    }
                }
                else{
                    col.cellProps.className = newClass;
                    return true;
                }
            }

            /////////////////////////
            //Row Rendering Functions
            /////////////////////////
            /*Row Types:
                default
                action
                navigation
                expandable(may be a subset of all other row types)
                radio
            */

            const _renderDesktopRow = () => {
               bodyCells = cols.map((col) => this.props.getTableCell(
                        col,
                        this.props,
                        {
                            getColDimensions: this.props.getColDimensions,
                            onSelectRowChange: this.props.onSelectRowChange,
                            onSelectAllChange: (this.props.isHeader ? this.props.onSelectAllChange : null),
                            hasExpandIndicator: (row.expand && row.expand.settings.indicators.includes(col.index)),
                            onExpandableRowClick: (this.props.onExpandableRowClick) ? this.props.onExpandableRowClick : null,
                            parentExpandableRow: (this.props.onExpandableRowClick) ? this.props.row : null
                        }
                    )
                );

                // Enforce a height
                if (this.props.height) {
                    styles.height = this.props.height + 'px';
                }

                 // Additional adjustments if this row is a parent to an expanded row
                if (row.expand) {
                    if (row.expand.settings.isVisible) {
                        rowClassNames.push('feta-table-row-expand-parent-open');
                        rowAttrs['aria-expanded'] = true;
                    }
                    else{
                        rowAttrs['aria-expanded'] = false;
                    }

                    if(row.expand.isLoading){
                        rowClassNames.push('feta-table-row-expand-loading');
                    }

                    // Make the row keyboard-navigable
                    rowAttrs.tabIndex = 0;
                    rowAttrs.onKeyDown = (...args) => {
                        const evt = args[0];

                        // Enter or spacebar key
                        if (evt.which === 13 || evt.which === 32) {
                            // Prevent spacebar from scrolling the page
                            evt.preventDefault();

                            // Toggle the row
                            if (this.props.onExpandableRowClick) {
                                this.props.onExpandableRowClick(row, ...args);
                            }
                        }
                    };
                }
            }

            const _renderActionRow = () => {
                //TODO: Update to use a definition and loop to define mobile header groups. Could create a general rendering function that just takes the type/definition.

                const primaryCols = (sourceTable.renderStyle && sourceTable.renderStyle.primaryCols) ? sourceTable.renderStyle.primaryCols: [];
                const secondaryCols = (sourceTable.renderStyle && sourceTable.renderStyle.secondaryCols) ? sourceTable.renderStyle.secondaryCols: [];
                const actionCols = (sourceTable.renderStyle.actionCols) ? sourceTable.renderStyle.actionCols : [];
                const indicatorCols = (sourceTable.renderStyle.indicatorCols) ? sourceTable.renderStyle.indicatorCols : [];

                const ACTION_CLASSES = {
                    primary: "feta-primary",
                    secondary: "feta-secondary",
                    action: "feta-action",
                    indicator: "feta-indicator"
                }

                //Setup empty object to hold all of the possible rendered content groups.
                const actionRowContents = {
                    primary:[],
                    secondary:[],
                    action:[],
                    indicator:[]
                };

                // If this is a footer row dont render.
                if(this.props.isFooter){
                    return null;
                }

                bodyCells = cols.map((col, index) =>{
                    let addPrimary,
                        addSecondary,
                        addAction,
                        addIndicator = false;

                    if(primaryCols.length > 0 && primaryCols.indexOf(index) > -1){
                        _addClass(col, ACTION_CLASSES.primary);
                        addPrimary = true;
                    }
                    if(secondaryCols.length > 0 && secondaryCols.indexOf(index) > -1){
                        _addClass(col, ACTION_CLASSES.secondary);
                        addSecondary = true;
                    }
                    if(actionCols.length > 0 && actionCols.indexOf(index) > -1){
                        _addClass(col, ACTION_CLASSES.action);
                        addAction = true;
                    }
                    if(indicatorCols.length > 0 && indicatorCols.indexOf(index) > -1){
                        _addClass(col, ACTION_CLASSES.indicator);
                        addIndicator = true;
                    }

                    const cell = this.props.getTableCell(
                        col,
                        this.props,
                        {
                            getColDimensions: this.props.getColDimensions,
                            onSelectRowChange: this.props.onSelectRowChange,
                            onSelectAllChange: (this.props.isHeader ? this.props.onSelectAllChange : null),
                            hasExpandIndicator: (row.expand && row.expand.settings.indicators.includes(col.index)),
                        }
                    );

                    if(addPrimary){
                        actionRowContents.primary.push(cell);
                    }
                    if(addSecondary){
                        actionRowContents.secondary.push(cell);
                    }
                    if(addIndicator){
                        actionRowContents.indicator.push(cell);
                    }
                    if(addAction){
                        actionRowContents.action.push(cell);
                    }

                    return cell;
                });

                //Add and hide body cells.
                bodyCells = (
                        <div
                            className={'feta-table-row-body ' + this.CLASSES.hidden}
                            onClick={this.props.onClick}
                            style={styles}
                            key={row.key + '_wrapper'}
                        >
                            {bodyCells}
                        </div>
                    );

                //Assemble headerCells.
                headerCells = (
                    <div
                        className="feta-table-row-header feta-action-table"
                        style={styles}
                        key={'feta-table-row-header-' + row.key}
                    >
                        <div className="information-group">
                            {actionRowContents.primary.length > 0 &&
                                <div className="primary-group">
                                    {actionRowContents.primary}
                                </div>
                            }

                            {actionRowContents.secondary.length > 0 &&
                                <div className="secondary-group">
                                    {actionRowContents.secondary}
                                </div>
                            }
                        </div>

                        {actionRowContents.indicator.length > 0 &&
                            <div className="indicator-group">
                                {actionRowContents.indicator}
                            </div>
                        }

                        {actionRowContents.action.length > 0 &&
                            <div className="action-group">
                                {actionRowContents.action}
                            </div>
                        }
                    </div>
                );
            }

            const _renderResponsiveFooter = () => {

                //Get secondary col.
                const secondaryCols = (sourceTable.renderStyle.secondaryCols) ? sourceTable.renderStyle.secondaryCols: [];
                let secondaryFooterCol = null;
                let secondaryFooterCell = null;

                if(secondaryCols.length > 0){
                    // Offset column index if table is a selection table
                    const secondaryColReference = (sourceTable.selectionType) ? (secondaryCols[0]+1) : secondaryCols[0];
                    secondaryFooterCol = cols[secondaryColReference];

                    if(secondaryFooterCol){

                        if(secondaryFooterCol.classNames && (secondaryFooterCol.classNames.indexOf("footer-total-value") < 0)){
                            secondaryFooterCol.classNames.push("footer-total-value");
                        }

                        secondaryFooterCell = this.props.getTableCell(
                            secondaryFooterCol,
                            this.props,
                            {
                                smallHideLabel:true
                            });
                    }

                    bodyCells = (
                        <div
                            className={'feta-table-row-body feta-table-row-footer'}
                            onClick={this.props.onClick}
                            style={styles}
                            key={row.key + '_wrapper'}
                        >
                            <div className="footer-total-label">Total:</div>
                            {secondaryFooterCell}
                        </div>
                    );
                }
            }

            //Default responsive row
            const _renderDefaultRow = () => {
                const expandableAjaxLoadMessage = "Show more";
                let headerSelectionControl = null;
                //If this is a footer row, render the generic responsive footer.
                if(this.props.isFooter){
                    _renderResponsiveFooter();
                    return null;
                }


                if(sourceTable.isEmpty){
                    bodyCells = (
                         <div
                            className={'feta-table-row-body'}
                            // style={styles}
                            key={row.key + '_wrapper'}
                        >
                        {cols.map((col) => this.props.getTableCell(
                                    col,
                                    this.props,
                                    {
                                        getColDimensions: this.props.getColDimensions,
                                        onSelectRowChange: this.props.onSelectRowChange,
                                        onSelectAllChange: (this.props.isHeader ? this.props.onSelectAllChange : null),
                                        // hasExpandIndicator: (row.expand && row.expand.indicators.includes(col.index)),
                                        // onExpandableRowClick: (this.props.onExpandableRowClick) ? this.props.onExpandableRowClick : null,
                                        // parentExpandableRow: (this.props.onExpandableRowClick) ? this.props.row : null
                                        smallHideLabel:true
                                    }
                                )
                            )}
                        </div>
                    );
                }

                // Faux header for small screens
                else if (hasExpandableHeader) {
                    rowClassNames.push('feta-table-row-has-header');

                    responsiveHeight = 50;

                    const headerCellsOnClick = (...args) => {
                        this._onExpandableHeaderClick(...args);

                        if (this.props.onClick) {
                            this.props.onClick(...args);
                        }
                    };

                    const rowSelectionOnClick = (evt) =>{	                    	
                    	evt.stopPropagation();                    
                    };

                    if (row.primarySuperCol) {
                        
                        const primarySuperCol = row.primarySuperCol;
                        primarySuperCol.key = primarySuperCol.key ? primarySuperCol.key : "primary";
                        if(primarySuperCol.classNames && (primarySuperCol.classNames.indexOf("primary-group") < 0)){
                            primarySuperCol.classNames.push("primary-group");
                        }
                        else{
                            primarySuperCol.classNames = ["primary-group"];
                        }

                        if(primarySuperCol.text && primarySuperCol.text != ""){
                            primarySuperCol.text = this._stripHtml(primarySuperCol.text);
                        }

                        //Check for text, if not present, check if there is a contents array and grab the first item with text.
                        if(!primarySuperCol.text){                          
                            // First node is text node
                            if(primarySuperCol.responsiveHeaderContents && primarySuperCol.responsiveHeaderContents[0] && primarySuperCol.responsiveHeaderContents[0].text){
                                primarySuperCol.text  = primarySuperCol.responsiveHeaderContents[0].text;
                                delete primarySuperCol.responsiveHeaderContents;
                            }
                            // First node is button node
                            else if(primarySuperCol.responsiveHeaderContents && primarySuperCol.responsiveHeaderContents[0] && primarySuperCol.responsiveHeaderContents[0].type && primarySuperCol.responsiveHeaderContents[0].type == 'button'  && primarySuperCol.responsiveHeaderContents[0].input && 
                                primarySuperCol.responsiveHeaderContents[0].input.text){
                                                               
                                primarySuperCol.text  = primarySuperCol.responsiveHeaderContents[0].input.text;
                                delete primarySuperCol.responsiveHeaderContents;   
                            }
                            // First node is an input node
                            else if(primarySuperCol.responsiveHeaderContents && 
                                primarySuperCol.responsiveHeaderContents[0] && 
                                primarySuperCol.responsiveHeaderContents[0].type && 
                                primarySuperCol.responsiveHeaderContents[0].type == 'text'  && 
                                primarySuperCol.responsiveHeaderContents[0].input && 
                                primarySuperCol.responsiveHeaderContents[0].input.attributes && 
                                primarySuperCol.responsiveHeaderContents[0].input.attributes.value){
                                    
                                primarySuperCol.text  = primarySuperCol.responsiveHeaderContents[0].input.attributes.value;
                                delete primarySuperCol.responsiveHeaderContents;   
                            }
                        }

                        //If there still isn't any primary text, assume it was a new input field.
                        if(!primarySuperCol.text){
                            primarySuperCol.text = "(New)";
                        }

                        headerCells.push(primarySuperCol);
                    }

                    if (row.secondarySuperCol) {
                    	const secondarySuperCol = row.secondarySuperCol;
                    	let secondaryDataValue = "";

                        secondarySuperCol.key = secondarySuperCol.key ? secondarySuperCol.key : "secondary";

                        if(secondarySuperCol.classNames && (secondarySuperCol.classNames.indexOf("secondary-group") < 0)){
                            secondarySuperCol.classNames.push("secondary-group");
                        }
                        else{
                            secondarySuperCol.classNames = ["secondary-group"];
                        }

                        //Add label if header column for secondary row has text.
                        const secondaryColReference = (row.selection) ? (sourceTable.renderStyle.secondaryCols[0]+1) : sourceTable.renderStyle.secondaryCols[0];
                        const secondaryHeaderCol = Object.assign({},sourceTable.head.rows[0].columns[secondaryColReference]);

                        let secondaryHeaderLegendIcon = null;

                        let secondaryCellStyle = row.columns[secondaryColReference].style;
                        //If cell has style, apply specific styles to secondary-group
                        if(secondaryCellStyle){
                            if(secondaryCellStyle.indexOf('currency') > -1){
                                secondarySuperCol.classNames.push("cui-currency");
                            }
                        }

                        //Check for text, if not present, check if there is a contents array and grab the first item with text.
                        if(!secondaryHeaderCol.text){
                            if(secondaryHeaderCol.contents && secondaryHeaderCol.contents[0] && secondaryHeaderCol.contents[0].text){
                                secondaryHeaderCol.text  = secondaryHeaderCol.contents[0].text;
                            }
                        }

                        //Check for text, if not present, check if there is a contents array and grab the first item with text.
                        if(secondarySuperCol.text){
                        	secondaryDataValue = secondarySuperCol.text
                        }
                        else{
                            if(secondarySuperCol.responsiveHeaderContents && secondarySuperCol.responsiveHeaderContents[0] && secondarySuperCol.responsiveHeaderContents[0].text){
                                secondaryDataValue  = secondarySuperCol.responsiveHeaderContents[0].text;
                                //delete secondarySuperCol.responsiveHeaderContents;
                            }
                            else if(secondarySuperCol.responsiveHeaderContents && secondarySuperCol.responsiveHeaderContents[0] && secondarySuperCol.responsiveHeaderContents[0].input && secondarySuperCol.responsiveHeaderContents[0].input.attributes && secondarySuperCol.responsiveHeaderContents[0].input.attributes.value){
                            	secondaryDataValue  = secondarySuperCol.responsiveHeaderContents[0].input.attributes.value;                            	                            	
                            }
                            else if(secondarySuperCol.responsiveHeaderContents && secondarySuperCol.responsiveHeaderContents[0] && secondarySuperCol.responsiveHeaderContents[0].template &&  secondarySuperCol.responsiveHeaderContents[0].template == "icon" &&  secondarySuperCol.responsiveHeaderContents[0].attributes &&  secondarySuperCol.responsiveHeaderContents[0].attributes.title){
                                secondaryHeaderLegendIcon = secondarySuperCol.responsiveHeaderContents[0];
                            }
                            else if(secondarySuperCol.responsiveHeaderContents && secondarySuperCol.responsiveHeaderContents[0] && 
                                secondarySuperCol.responsiveHeaderContents[0].style &&  
                                secondarySuperCol.responsiveHeaderContents[0].style.indexOf('icon') != -1 &&
                                secondarySuperCol.responsiveHeaderContents[0].contents[0] &&
                                secondarySuperCol.responsiveHeaderContents[0].contents[0].attributes &&
                                secondarySuperCol.responsiveHeaderContents[0].contents[0].attributes.title){

                                secondaryHeaderLegendIcon = secondarySuperCol.responsiveHeaderContents[0].contents[0];
                            }
                            else{
                            	if(secondarySuperCol.contents && secondarySuperCol.contents.length == 2 && secondarySuperCol.contents[1].text){
                            		secondaryDataValue = secondarySuperCol.contents[1].text;
                            	}
                            }
                        }

                        if(secondaryHeaderCol.text){
                            let secondaryHeaderContent = [
                                {
                                    'text':secondaryHeaderCol.text+": ",
                                    "attributes":{
                                        "className":"secondary-group-header-label",
                                    },
                                    "key":"secondary-group-header-label"
                                }                                
                            ];

                            //Add value if present. 
                            if(secondaryDataValue != ""){
                            	secondaryHeaderContent.push({
                                    'text':secondaryDataValue,
                                    "attributes":{
                                        "className":"secondary-group-header-data",
                                    },
                                    "key":"secondary-group-header-data"
                                });
                            }

                            if(secondaryHeaderLegendIcon){
                                secondaryHeaderContent.push(secondaryHeaderLegendIcon);
                            }
       
                            secondarySuperCol.contents = secondaryHeaderContent;

                            //Remove text since we are updating to use a contents array.
                            delete secondarySuperCol.text;
                        }                      

                        headerCells.push(secondarySuperCol);
                    }                  

                    if(isSelectionRow){

                    	let selectionClassList = "feta-table-header-selection";
                    	

                    	if(sourceTable.selectionType){
                    		selectionClassList += " feta-table-header-selection-type-" + sourceTable.selectionType;
                    	}
                    	
                    	if(!isSelectable){
                    		selectionClassList += " feta-table-header-selection-read-only";
                    	}
                    	
                    	if((row.columns[0].contents && row.columns[0].contents.length>0 && row.columns[0].contents[0].input.attributes.checked == 'checked') || (row.columns[0].contents && row.columns[0].contents.length>0 && row.columns[0].contents[0].input.attributes.checked == true)){
                    		selectionClassList += " feta-table-header-selection-selected";
                    	
                    	}
                    	else if(row.selection.readOnly && row.selection.checked){
                    		selectionClassList += " feta-table-header-selection-selected";	                    		
                    	}

                    	headerSelectionControl = (
                    		<div
                        		className={selectionClassList} 
                        		onClick={rowSelectionOnClick}
                        	>
                    		{this.props.getTableCell(
                                        cols[0],
                                        this.props,
                                        {
                                            onSelectRowChange: this.props.onSelectRowChange,
                                            onExpandableRowClick: (this.props.onExpandableRowClick) ? this.props.onExpandableRowClick : null,
                                            parentExpandableRow: (this.props.onExpandableRowClick) ? this.props.row : null
                                        }
                                    )
                    		}
                    		</div>
                    	);

                    	//Outdated mockup using button instead of pulling input from row. 	
                    	headerSelectionControl2 = (
                    		<div
                        		className={selectionClassList}
                        		onClick={rowSelectionOnClick}
                        		>
                        		<button
                        			className="feta-table-header-selection-trigger"
                        			>
                        				Select this row
                        		</button>
                        	</div>
                    	);
                    }


                    if (headerCells.length) {
                        headerCells = (
                            <div
                                className="feta-table-row-header"
                                onClick={headerCellsOnClick}
                                style={styles}
                                key={'feta-table-row-header-' + row.key}
                            >
                            	
                            	{headerSelectionControl}	
                            	
                            	<div className="feta-table-header-data">
                                {headerCells.map((col) => {
                                	let hideLabel = true;

                                	if(col.key == "primary"){
                                		hideLabel = false;
                                	}

                                    return (
                                        <div
                                            className={col.classNames ? col.classNames.join(' ') : ''}
                                            key={'feta-table-row-header-' + col.key}
                                        >
                                            {this.props.getTableCell(
                                                col,
                                                this.props,
                                                {
                                                    onSelectAllChange: this.props.onSelectAllChange,
                                                    getColDimensions: this.props.getColDimensions,
                                                    smallHideLabel: hideLabel
                                                }
                                            )}
                                        </div>
                                    );
                                })}
                                </div>
                            </div>
                        );
                    }
                    else {
                        headerCells = (
                            <div
                                className="feta-table-row-header"
                                onClick={headerCellsOnClick}
                                style={styles}
                                key={'feta-table-row-header-' + row.key}
                            >
                                {cols.map((col) => {
                                    if (col.isNotPrimaryNorSecondary) {
                                        return null;
                                    }

                                    let hideLabel = true;

                                	if(col.key == "primary"){
                                		hideLabel = false;
                                	}

                                    return (
                                        <div
                                            className={col.classNames ? col.classNames.join(' ') : ''}
                                            key={'feta-table-row-header-' + col.key}
                                        >
                                            {this.props.getTableCell(
                                                col,
                                                this.props,
                                                {
                                                    onSelectAllChange: this.props.onSelectAllChange,
                                                    getColDimensions: this.props.getColDimensions,
                                                    isResponsiveHeader:true,
                                                    smallHideLabel: hideLabel
                                                }
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    }

                    // Put the normal cells in a wrapper
                    bodyCells = (
                        <div
                            className={'feta-table-row-body ' + this.CLASSES.hidden}
                            onClick={this.props.onClick}
                            style={styles}
                            key={row.key + '_wrapper'}
                        >


                            {cols.map((col, index) => {
                                // if column is a control, move it to the controlCells to render at the bottom of the content.
                                if(col.control || col.isControlCell){
                                    controlCells.push(col);
                                    return null;
                                }
                                else if(isSelectionRow && index == 0){
                                	return null;
                                }
                                else{
                                    return this.props.getTableCell(
                                        col,
                                        this.props,
                                        {
                                            onSelectRowChange: this.props.onSelectRowChange,
                                            onExpandableRowClick: (this.props.onExpandableRowClick) ? this.props.onExpandableRowClick : null,
                                            parentExpandableRow: (this.props.onExpandableRowClick) ? this.props.row : null
                                        }
                                    )
                                }
                            })}
                            {row.expand && row.expand.contents && row.expand.contents.length > 0 &&
                                <div className="expandable-content">
                                {
                                    row.expand.contents.map((col) => this.props.getTableCell(
                                            col,
                                            this.props,
                                            {
                                                // smallHideLabel:true,
                                                isExpandChild:true
                                            }
                                        )
                                    )
                                }
                                </div>
                            }
                            {row.expand && row.expand.url && ((row.expand.contents && row.expand.contents.length === 0) || !row.expand.contents) &&
                                <div className="feta-table-expandable-content-ajax">
                                    {row.expand.isLoading &&
                                        <button
                                            type="button"
                                            className={"feta-table-expandable-content-ajax-loading-button"}
                                        >
                                            {expandableAjaxLoadMessage}
                                        </button>
                                    }

                                    {!row.expand.isLoding && !row.expand.isLoading === true &&
                                        <button
                                            type="button"
                                            onClick={(this.props.onExpandableRowClick) ?  () => this.props.onExpandableRowClick(row) : null}
                                        >
                                            {expandableAjaxLoadMessage}
                                        </button>
                                    }
                                </div>
                            }

                            {controlCells && controlCells.length > 0 &&
                                <div className="controls-content">
                                {
                                    controlCells.map((col) => this.props.getTableCell(
                                            col,
                                            this.props,
                                            {
                                                // smallHideLabel:true
                                            }
                                        )
                                    )
                                }
                                </div>
                            }
                        </div>
                    );
                }
                // No faux header for small screens
                else {
                    bodyCells = (
                         <div
                            className={'feta-table-row-body'}
                            // style={styles}
                            key={row.key + '_wrapper'}
                        >
                        {cols.map((col) => this.props.getTableCell(
                                    col,
                                    this.props,
                                    {
                                        getColDimensions: this.props.getColDimensions,
                                        onSelectRowChange: this.props.onSelectRowChange,
                                        onSelectAllChange: (this.props.isHeader ? this.props.onSelectAllChange : null),
                                        hasExpandIndicator: (row.expand && row.expand.settings.indicators.includes(col.index)),
                                        onExpandableRowClick: (this.props.onExpandableRowClick) ? this.props.onExpandableRowClick : null,
                                        parentExpandableRow: (this.props.onExpandableRowClick) ? this.props.row : null
                                    }
                                )
                            )}
                        </div>

                    );
                    //If there is an expand row add it.
                    if(row.expand && row.expand.contents && row.expand.contents.length > 0){
                        expandCells = (
                                        <div
                                            className="expandable-content"
                                            key={row.key + 'expandable_wrapper'}
                                        >
                                            {row.expand.contents.map((col) => this.props.getTableCell(
                                                    col,
                                                    this.props,
                                                    {
                                                        // smallHideLabel:true
                                                    }
                                                ))
                                            }
                                        </div>
                                    );
                    }

                    else if(row.expand && row.expand.url && ((row.expand.contents && row.expand.contents.length === 0) || !row.expand.contents)){

                        expandCells = (
                            <div className="feta-table-expandable-content-ajax">
                                {row.expand.isLoading &&
                                    <button
                                        type="button"
                                        className={"feta-table-expandable-content-ajax-loading-button"}
                                    >
                                        {expandableAjaxLoadMessage}
                                    </button>
                                }
                                {!row.expand.isLoding && !row.expand.isLoading === true &&
                                    <button
                                        type="button"
                                        onClick={(this.props.onExpandableRowClick) ?  () => this.props.onExpandableRowClick(row) : null}
                                    >
                                        {expandableAjaxLoadMessage}
                                    </button>
                                }
                            </div>
                        );
                    }
                }

                // Enforce a height
                if (this.props.height && !sourceTable.isEmpty) {
                    // Only use `min-height` for small screens so that there's enough room when it expands
                    styles.minHeight = (responsiveHeight) ? responsiveHeight + 'px' : this.props.height + 'px';

                    // Remove fixed height if it was set earlier (e.g. the screen size went from large to small)
                    if (styles.height) {
                        delete styles.height;
                    }
                }

                // Additional adjustments if this row is a parent to an expanded row
                if (row.expand) {
                    if (row.expand.settings.isVisible) {
                        rowClassNames.push('feta-table-row-expand-parent-open');
                    }

                    if(row.expand.isLoading){
                        rowClassNames.push('feta-table-row-expand-loading');
                    }

                    // Make the row keyboard-navigable
                    rowAttrs.tabIndex = 0;
                    rowAttrs.onKeyDown = (...args) => {
                        const evt = args[0];

                        // Enter or spacebar key
                        if (evt.which === 13 || evt.which === 32) {
                            // Prevent spacebar from scrolling the page
                            evt.preventDefault();

                            // Toggle the row
                            if (this.props.onExpandableRowClick) {
                                this.props.onExpandableRowClick(row, ...args);
                            }
                        }
                    };
                }
            }

            
            ///////////////////////////////////////
            //Apply logic that applies to all rows.
            ///////////////////////////////////////

            // Determine if a selectable row should appear selectable/clickable.
            if (row.selection && (row.selection.readOnly === true || row.selection.empty === true)) {
                isSelectable = false;
            }

            if(sourceTable.selectable && row.selection){
            	isSelectionRow = true;
            }

            // Make the row look clickable and enable click events in Safari
            if (!this.props.isHeader && !this.props.isFooter && ((this.props.onClickSelectableRow && isSelectable) || this.props.onExpandableRowClick)) {
                // styles.cursor = 'pointer';
            }
            else {
                // Remove the cursor style
                delete styles.cursor;
            }

            if (this.props.isStriped) {
                rowClassNames.push('striped');
            }

            ////////////////////////////////////
            //Determine what row view to render.
            ////////////////////////////////////

            //Responsive views for mobile/tablets
            if(this.props.isSmallSize){
                switch(sourceTable.type){
                    case 'action':
                    _renderActionRow();
                    break;

                    case 'default':
                    default:
                    _renderDefaultRow();
                }
            }
            else{
                _renderDesktopRow();
            }

            if(bodyCells === null){
                return null;
            }

            return (
                <this.props.tagNames.tr
                    className={rowClassNames.join(' ')}
                    style={styles}
                    {...rowAttrs}
                >
                    {headerCells}
                    {bodyCells}
                    {expandCells}
                </this.props.tagNames.tr>
            );
        }
    }

    TableRow.propTypes = {
        source: ReactPropTypes.shape({}).isRequired,
        row: ReactPropTypes.shape({}).isRequired,
        onClickSelectableRow: ReactPropTypes.func,
        onExpandableRowClick: ReactPropTypes.func,
        onClick: ReactPropTypes.func,
        getColDimensions: ReactPropTypes.func,
        onSelectAllChange: ReactPropTypes.func,
        onSelectRowChange: ReactPropTypes.func,
        isSmallSize: ReactPropTypes.bool,
        isHeader: ReactPropTypes.bool,
        isFooter: ReactPropTypes.bool,
        height: ReactPropTypes.number,
        inlineStyle: ReactPropTypes.shape({}),
        getTableCell: ReactPropTypes.func,
        isStriped: ReactPropTypes.bool
    };

    TableRow.defaultProps = {
        source: {},
        row: {},
        onClickSelectableRow: () => {},
        onExpandableRowClick: () => {},
        onClick: () => {},
        getColDimensions: () => {},
        onSelectAllChange: () => {},
        onSelectRowChange: () => {},
        isSmallSize: false,
        isHeader: false,
        isFooter: false,
        height: 40, //FIXME No idea how to handle this default...
        inlineStyle: {},
        getTableCell: () => {},
        isStriped: false
    };

    return TableRow;
});
