define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    // SVG source file: feta-pointer-right.svg
    const RenderIconPointerRight = (props) => {
        return (
            <svg
                className={(props && props.className) ? props.className : ''}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
            >
                <path
                    d="M18.042 12.245l.023-.044.016-.05.02-.042.006-.052c0-.016.005-.032.005-.05s-.003-.032-.004-.05c0-.016-.002-.034-.005-.05-.003-.017-.01-.03-.013-.048l-.015-.05c-.004-.016-.012-.03-.02-.045-.01-.016-.016-.032-.026-.05-.01-.013-.02-.03-.033-.04-.01-.013-.017-.026-.03-.037L6.81.16c-.205-.212-.543-.216-.754-.01-.21.204-.216.542-.01.753L16.828 12 6.048 23.095c-.208.21-.2.55.01.754.1.1.236.15.37.15.14 0 .28-.06.382-.168L17.95 12.37c.014-.01.02-.024.03-.036.013-.014.025-.027.034-.042.01-.015.018-.032.027-.048z"
                    fill="#555"
                />
            </svg>
        );
    };

    RenderIconPointerRight.propTypes = {
        className: ReactPropTypes.string,
    };

    RenderIconPointerRight.defaultProps = {
        className: '',
    };

    return RenderIconPointerRight;
});
