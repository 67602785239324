define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    // SVG source file: feta-pointer-left.svg
    const RenderIconPointerLeft = (props) => {
        return (
            <svg
                className={(props && props.className) ? props.className : ''}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
            >
                <path
                    d="M5.958 12.245l-.023-.044-.016-.05-.02-.042-.006-.052c0-.016-.005-.032-.005-.05s.003-.032.004-.05c0-.016.002-.034.005-.05.003-.017.01-.03.013-.048l.015-.05c.004-.016.012-.03.02-.045.01-.016.016-.032.026-.05.01-.013.02-.03.033-.04.01-.013.017-.026.03-.037L17.19.16c.205-.212.543-.216.754-.01.21.204.216.542.01.753L7.172 12l10.78 11.095c.207.21.2.55-.01.754-.102.1-.237.15-.37.15-.14 0-.28-.06-.383-.168L6.042 12.372c-.013-.012-.02-.025-.03-.037-.012-.014-.024-.027-.033-.042-.01-.015-.017-.032-.026-.048z"
                    fill="#555"
                />
            </svg>
        );
    };

    RenderIconPointerLeft.propTypes = {
        className: ReactPropTypes.string,
    };

    RenderIconPointerLeft.defaultProps = {
        className: '',
    };

    return RenderIconPointerLeft;
});
