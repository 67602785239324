define(['jquery', 'popover', 'renderer'], function ($, popover, renderer) {


    const NAMESPACE = 'dropMenu';
    const VERSION = '1.0.0';

    const SUFFIX = "dropMenu";
    const CLASS_NAMES = {
        buttonMenu: "feta-drop-menu",
        wrapper: "feta-drop-menu-wrapper",
        buttonMenuToggle: "feta-button-menu-toggle"

    };


    const defaults = {
        "type":"button",
        "text":"",
        "template":"buttonMenu",
        "items":[],

    }

    ////////////////////
    // Public methods //
    ////////////////////
    const _init = (sourceObject, mountNode) =>{

        if (!sourceObject) {
            journal.log({type: 'error', owner: 'app', module: NAMESPACE, submodule: 'init'}, 'No source list was provided');

            return false;
        }

        if (!mountNode && sourceObject.container) {
            mountNode = document.querySelector(sourceObject.container);
        }

        if (!mountNode) {
            journal.log({type: 'error', owner: 'app', module: NAMESPACE, submodule: 'init'}, 'Could not get mount node');

            return false;
        }

        sourceObject = _normalize(sourceObject);

        if (!sourceObject) {
            journal.log({type: 'error', owner: 'app', module: NAMESPACE, submodule: 'init'}, 'Source object failed normalization ', JSON.parse(JSON.stringify(sourceObject)));

            return false;
        }
        _render(sourceObject);

    };


    const _normalize = (sourceObject) => {
        let newSource = $.extend(true, {},  defaults, sourceObject);

        return newSource;
    }

    const _render = (sourceObject) => {

        let menuItems = sourceObject.items;
        let menuListHTML = document.createElement('ul');

        //Get any popover options from the sourceObject.

        //Build html of link menu

        //Create popover trigger and insert into container, clearing all other contents.

        if(menuItems && menuItems.length > 0){
            let menuObject = menuItems.map((item) => {

                if (item.template || item.type) {

                    let json = {
                        template:"field",
                        contents:[item]
                    }

                    let menuListItem = document.createElement('li');

                    let itemHtml = renderer.getHTML(json);

                    menuListItem.appendChild(itemHtml);

                    menuListHTML.appendChild(menuListItem);
                }
            });

            let container = document.querySelectorAll(sourceObject.container);

            if(container && container.length > 0){

                let triggerElement = document.createElement('button');

                triggerElement.type = "button";
                triggerElement.className = CLASS_NAMES.buttonMenuToggle;
                triggerElement.innerHTML = sourceObject.text;
                let containerElement = container[0];
                containerElement.appendChild(triggerElement);

                $.popover(
                    triggerElement,
                    {
                        html: menuListHTML,

                        display: {
                            className: 'feta-button-menu-popover',
                        },
                        resizeMobile:false,
                    }
                );
            }
        }
    };

    //////////////////////////////////////////
    // Expose public properties and methods //
    //////////////////////////////////////////

    return {
        init:_init,

    };
});
