define([], function () {
    // const NAMESPACE = 'dateutil';
    // const VERSION = '1.0.0';


    ////////////////////
    // Public methods //
    ////////////////////

    /**
     * Returns the current Unix timestamp or converts one to the local time zone
     *
     * @param   {mixed}  timestamp  Unix timestamp (in milliseconds) as either an integer or string
     *
     * @return  {number}            Local Unix timestamp
     */
    const getLocalTimestamp = (timestamp) => {
        if (!timestamp) {
            timestamp = parseInt(Math.floor(new Date().getTime()), 10);
        }
        else if (typeof timestamp === 'string') {
            timestamp = parseInt(timestamp, 10);
        }

        return parseInt(Math.floor(new Date(timestamp).getTime()), 10);
    };

    /**
     * Converts timestamp to the format "3/14/05 at 10:13PM"
     *
     * @param   {number}   timestamp        JS-style Unix timestamp (in milliseconds). If omitted, current time will be used.
     * @param   {boolean}  forceFullFormat  Forces the output to be full MM/DD/YYYY HH:SS format, rather than "2 days ago" etc
     *
     * @return  {string}                    Human-readable date
     */
    const humanReadable = (timestamp, forceFullFormat) => {
        if (!timestamp) {
            timestamp = getLocalTimestamp();
        }

        const d = new Date(parseInt(timestamp, 10));
        let hour = d.getHours();
        let ampm = 'AM';
        let mins = d.getMinutes();
        const now = new Date(parseInt(getLocalTimestamp(), 10));
        const yesterday = new Date(parseInt(getLocalTimestamp(), 10));
        let dateString = '';

        if (!forceFullFormat) {
            // Check for yesterday
            yesterday.setDate(yesterday.getDate() - 1);

            if (yesterday.getDate() === d.getDate() && yesterday.getMonth() === d.getMonth()) {
                dateString = 'Yesterday ';
            }
            // Check for past week
            else {
                const diff = parseInt(now.getTime(), 10) - parseInt(timestamp, 10);
                const hoursAgo = diff / 60 / 60000;

                if (hoursAgo > 24 && hoursAgo < 168) {
                    dateString = d.toString().substr(0, 3) + ' '; // Returns abbreviated "Tue", etc
                }
            }
        }

        // Else, write absolute date
        if (!dateString.length) {
            dateString = (d.getMonth() + 1).toString() + '/' + d.getDate();

            // Only write the year if it's not this year
            if (d.getFullYear() !== now.getFullYear()) {
                const yearShort = (d.getFullYear() % 1000);

                // Add zero padding
                if (yearShort < 10) {
                    dateString += '/' + '0' + yearShort;
                }
                else {
                    dateString += '/' + yearShort;
                }
            }

            dateString += ' at ';
        }

        // Get hour/minute
        if (hour >= 12) {
            hour -= 12;
            ampm = 'PM';
        }

        if (hour === 0) {
            hour = 12;
        }

        if (mins < 10) {
            mins = '0' + mins;
        }

        return dateString + hour + ':' + mins + ampm;
    };

    /**
     * Returns the relative time since the given timestamp, e.g. "6 months, 3 days, 1h 24m"
     * If `forceFullFormat` is false, it will produce "X:YZ ago" for only the last two hours; anything older will use the format given by `humanReadable()`.
     * If `forceFullFormat` is true, it will say anything up through 'XXX months, YYY days ... ago'
     */
    const relative = (timestamp, forceFullFormat) => {
        const now = getLocalTimestamp();



        const difference = parseInt(now, 10) - parseInt(timestamp, 10);
        let t = 0;
        let timeAgo = '';

        if (forceFullFormat) {
            // months
            if (parseInt(difference / 2592000000, 10) > 0) {
                timeAgo += '~' + (parseInt(difference / 3600, 10)).toString() + ' months, ';
            }

            // days
            if (parseInt(difference / 86400000, 10) > 0) {
                timeAgo += (parseInt(difference / 86400, 10)).toString() + ' days, ';
            }

            // hours
            timeAgo += (parseInt(difference / 3600000, 10)).toString() + ' hr ';

            // minutes
            timeAgo += (parseInt((difference / 60000) % 60000, 10)).toString() + ' min ago';
        }
        // Less than a minute ago
        else if (difference < 60000) {
            t  = parseInt(difference / 1000, 10);

            if (t === 0) {
                timeAgo += "0";
            }
            else {
                timeAgo += t.toString();
            }

            timeAgo += ' sec ago';
        }
        // In the last hour (between 1 and 59 minutes ago)
        else if (difference >= 60000 && difference < 3600000) {
            t = parseInt(difference / 60000, 10);
            timeAgo += t.toString() + ' min ago';
        }
        // Between 1 and 2 hours ago
        else if (difference >= 3600000 && difference < 7200000) {
            // hours
            timeAgo += (parseInt(difference / 3600000, 10)).toString() + ' hr ';

            // minutes
            t = parseInt((difference / 60000) % 60, 10);

            if (t > 0) {
                timeAgo += t + ' min';
            }

            timeAgo += ' ago';
        }
        // Between 3 and 24 hours ago
        else if (difference >= 7200000 && difference < 86400000) {
            const humanReadableVersion = humanReadable(timestamp);

            if (humanReadableVersion.indexOf(' at ') > -1) {
                timeAgo += humanReadableVersion.substr(humanReadableVersion.indexOf(' at ') + 4);
            }
            else {
                timeAgo += humanReadableVersion;
            }
        }
        // More than 24 hours ago; display the full date and time
        else {
            timeAgo = humanReadable(timestamp);
        }

        return timeAgo;
    };


    //////////////////////////////////////////
    // Expose public properties and methods //
    //////////////////////////////////////////

    return {
        getLocalTimestamp,
        humanReadable,
        relative,
    };
});
