/*global feta */
define('tooltip',['jquery', 'popover', 'analytics'], function ($, popover, analytics) {
    ///////////////
    // Constants //
    ///////////////
    var VERSION = '0.0.1';

    var NAMESPACE = 'tooltip';

    var CLASSES = {
        tooltip: 'feta-' + NAMESPACE,
        hasBeenSetup: 'feta-' + NAMESPACE + '-has-been-setup',
    };

    /////////////////
    // Constructor //
    /////////////////

    var Tooltip = function _Tooltip (elem, options) {
        // Create both a jQuery copy and a regular DOM copy of the element
        if (elem instanceof $) {
            this.$link = elem;
            this.link = elem.get(0);
        }
        else if (elem instanceof HTMLElement) {
            this.link = elem;
            this.$link = $(elem);
        }
        else {
            //FIXME: Not sure if these two lines are a good idea or not. I just want to prevent things from failing when the tooltip is not associated with a link. (CP 8/8/16)
            this.$link = $();
            this.link = document.createElement('a');
        }

        // Store the options
        this.options = options;

        // Extract data attribute options
        this.metadata = this.$link.data('tooltip-options');

        return this;
    };

    //////////////////////
    // Plugin prototype //
    //////////////////////

    Tooltip.prototype = {};

    // Default user options
    // This mirrors the config for the popover component
    Tooltip.prototype.defaults = {
        html: '',
        display: {
            css: {
            },
            className: '',
            offset: {
                top: 0,
                left: 0,
            },
        },
        location: 'below-right',
        hideOnResize: false,
        hideOnEscape: true,
        gainFocus: true,
        isModal: true,
        showOnCreate: false,
        resizeMobile:false,
    };

    /**
     * Initializes the plugin
     * May be called multiple times
     */
    Tooltip.prototype.init = function _Tooltip_init () {
        var tooltip;

        // Introduce defaults that can be extended either globally or using an object literal
        if (typeof this.options === 'string') {
            this.config = $.extend(true, {}, this.defaults);
            this.config.html = this.options;
        }
        else {
            this.config = $.extend(true, {}, this.defaults, this.options, this.metadata);
        }

        // Create new tooltip object using this instance
        tooltip = this;

        // ID to be used for analytics tracking
        tooltip.analyticsId = feta.pageInfo.appPlatform +
                              '|' +
                              feta.pageInfo.appCode +
                              '|' +
                              feta.pageInfo.pageTitle
                                    .replace(/\s+/g, '')
                                    .replace(/\W/g, '_') +
                              '|';

        // Get HTML if none was provided
        if (!tooltip.config.html) {
            // Link is associated with an element on the page:

            if (tooltip.link.hasAttribute('href') && /^#.+/.test(tooltip.link.getAttribute('href'))) {
                // Get the tooltip's content from the linked element
                tooltip.config.html = $(tooltip.link.getAttribute('href')).html();

                tooltip.analyticsId += document.location.hash;
            }
            else if (tooltip.link.hasAttribute('data-tooltip-source')) {
                tooltip.config.html = $('#' + tooltip.link.getAttribute('data-tooltip-source')).html();
                tooltip.analyticsId += tooltip.link.getAttribute('data-tooltip-source');
            }
        }
        else if (tooltip.link.hasAttribute('data-tooltip-source')) {
            // Get the part of the URL after the last slash
            tooltip.analyticsId += tooltip.link.getAttribute('data-tooltip-source');
        }
        else if (tooltip.link.hasAttribute('href')) {
            // Get the part of the URL after the last slash
            tooltip.analyticsId += tooltip.link.getAttribute('href').replace(document.location.href.substr(document.location.href.lastIndexOf('/') + 1), '');
        }

        // Add the tooltip custom class
        if (tooltip.config.display.className) {
            tooltip.config.display.className += ' ';
        }

        tooltip.config.display.className += CLASSES.tooltip;

        // Instantiate a Popover object which will handle most of the functionality for us (display, behavior, etc)
        tooltip.popover = $.popover(tooltip.$link, tooltip.config);

        // Create a more practical reference to the tooltip elem
        tooltip.$tooltip = tooltip.popover.$popover;

        // Log events to analytics:

        analytics.trackEvent('tooltip', 'Initialize', tooltip.analyticsId);

        tooltip.$tooltip.on('show.cui.popover', function (/*evt*/) {
            analytics.trackEvent('tooltip', 'Show', tooltip.analyticsId);
        }.bind(tooltip));

        // Show the tooltip now, if requested
        if (tooltip.config.showOnCreate) {
            tooltip.popover.show(tooltip);
        }

        // Make the element so we know it has been setup already
        tooltip.$link.addClass(CLASSES.hasBeenSetup);

        // Return this instance of the plugin
        return tooltip;
    };

    /**
     * Hides the tooltip
     *
     * @param   {function}  callback         Optional function to run after closing the tooltip. It will receive the Tooltip instance as an argument.
     */
    Tooltip.prototype.hide = function _Tooltip_hide (callback) {
        var tooltip = this;

        tooltip.popover.hide(this);

        // Check to see if the caller included a callback function
        if (typeof callback === 'function') {
            callback(this);
        }
    };

    /**
     * Display the tooltip
     *
     * @param   {function}  callback  Optional function to run after closing the tooltip. It will receive the Tooltip instance as an argument.
     */
    Tooltip.prototype.show = function _Tooltip_show (callback) {
        var tooltip = this;

        tooltip.popover.show(this);

        // Check to see if the caller included a callback function
        if (typeof callback === 'function') {
            callback(this);
        }
    };

    /**
     * (Re)position the tooltip
     *
     * @param   {function}  callback  Optional function to run after closing the tooltip. It will receive the Tooltip instance as an argument.
     */
    Tooltip.prototype.position = function _Tooltip_position (callback) {
        var tooltip = this;

        tooltip.popover.position(this);

        // Check to see if the caller included a callback function
        if (typeof callback === 'function') {
            callback(this);
        }
    };

    /**
     * Destroy the tooltip
     *
     * @param   {function}  callback  Optional function to run after closing the tooltip. It will receive the Tooltip instance as an argument.
     */
    Tooltip.prototype.destroy = function _Tooltip_destroy (callback) {
        var tooltip = this;

        tooltip.popover.hide(this, true);

        // Check to see if the caller included a callback function
        if (typeof callback === 'function') {
            callback(tooltip);
        }

        return tooltip;
    };

    //////////////////////////////////////////
    // Expose public properties and methods //
    //////////////////////////////////////////

    Tooltip.defaults = Tooltip.prototype.defaults;

    Tooltip.version = VERSION;

    // Define jQuery plugin
    window.$.fn.tooltip = function $_fn_tooltip (options) {
        return this.each(function $_fn_tooltip_each () {
            new Tooltip(this, options).init();
        });
    };

    window.$.tooltip = function $_tooltip (toggler, options) {
        return new Tooltip(toggler, options).init();
    };
});

