define(['react', 'reactproptypes', 'jquery', 'popover', 'kind', 'TableSortIcon', 'renderer', 'RenderItem', 'RenderIconMessage', 'guid', 'TableColumnHeader'], function (React, ReactPropTypes, $, popover, kind, TableSortIcon, renderer, RenderItem, RenderIconMessage, guid, TableColumnHeader) {
    class TableControls extends React.Component {
        constructor (props) {
            super(props);

            //Private method binding
            this._getExpandControls = this._getExpandControls.bind(this);
            this._getSwitchControls = this._getSwitchControls.bind(this);
        }

        /////////////////////
        // Private methods //
        /////////////////////
        _getExpandControls(){
            const sourceTable = this.props.source;
            const renderStyle = (sourceTable.renderStyle) ? sourceTable.renderStyle : null;

            let expandControls = null;

            if(renderStyle && renderStyle.expandControls){

                if(typeof renderStyle.expandControls === "boolean"){

                    expandControls = (
                        <div className="feta-table-expand-controls">
                            <button
                                type="button"
                                className="cui-icon feta-icon-table-collapse"
                                onClick={this.props.onCollapseAllControlClick}
                                title="Collapse all rows"
                            >
                                Collapse all rows
                            </button>
                            <button
                                type="button"
                                className="cui-icon feta-icon-table-expand"
                                onClick={this.props.onExpandAllControlClick}
                                title="Expand all rows"
                                >
                                    Expand all rows
                            </button>
                        </div>

                    );
                }
            }

            return expandControls;
        }

        _getSwitchControls() {
            const sourceTable = this.props.source;
            const renderStyle = (sourceTable.renderStyle) ? sourceTable.renderStyle : null;

            let switchControlsElem = null;

            let collapsedText = "Switch to expanded view";
            let expandedText = "Switch to normal view";

            if(renderStyle && renderStyle.switchTo){
                let switchTo = renderStyle.switchTo;

                let switchControlClasses = "cui-icon feta-icon-table-expand-view";
                let switchControlText = collapsedText;

                if(switchTo.expanded){
                   switchControlClasses = "cui-icon feta-icon-table-collapse-view";
                   switchControlText = expandedText;
                }

                if(switchTo.url){
                    switchControlsElem = (
                        <a
                            className={switchControlClasses}
                            title={switchControlText}
                            href={switchTo.url}
                        >
                            {switchControlText}
                        </a>
                    );
                }else if(switchTo.switchFunction){

                    let switchFunction = (switchTo.switchFunction) ? switchTo.switchFunction : null;
                    let clickFunction = null;

                    if (typeof switchFunction === 'string' && typeof window[switchFunction] === 'function') {
                        clickFunction = function(){
                            feta.functionCall(switchFunction);
                        };
                    }

                    switchControlsElem = (
                        <button
                            type="button"
                            className={switchControlClasses}
                            title={switchControlText}
                            onClick={clickFunction}
                        >
                            {switchControlText}
                        </button>
                    );
                }
            }

            if(switchControlsElem){
                return(
                    <div className="feta-table-switch-controls">
                        {switchControlsElem}
                    </div>
                );
            }

            return switchControlsElem;
        }


        ////////////
        // Render //
        ////////////
        render(){

            const sourceTable = this.props.source;
            const headerControls = sourceTable.headerControls;
            const selectionType = sourceTable.selectionType;

            let isSmallSize = this.props.isSmallSize;

            let expandControls = null;
            let switchControls = null;
            let elems = [];


            if(!isSmallSize){
	            expandControls = this._getExpandControls();
	            if(expandControls){
	                elems.push(expandControls);
	            }
	        }

            switchControls = this._getSwitchControls();
            if(switchControls){
                elems.push(switchControls);
            }


            // Only show the table controls element if there are elements to show.
            if(elems.length && elems.length > 0){
                return (
                    <div className="feta-table-controls">
                        {elems}
                    </div>
                );
            }

            return null;
        }
    }

    TableControls.propTypes = {
        source: ReactPropTypes.shape({}).isRequired,
        isSmallSize: ReactPropTypes.bool,
        onExpandAllControlClick: ReactPropTypes.func,
        onCollapseAllControlClick: ReactPropTypes.func,
    };

    TableControls.defaultProps = {
        isSmallSize: false,
        onExpandAllControlClick: () => {},
        onCollapseAllControlClick: () => {}
    };

    return TableControls;
});
