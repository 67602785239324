/*jshint esversion: 6 */
/*global feta */
define(['jquery', 'clickBlocker', 'userMessage'], function ($, clickBlocker, userMessage) {
    // Public entry point which kicks off the request

    const request = (options) => {
        const settings = $.extend({}, DEFAULT_REQUEST, options);
        const defaultErrorPage = "default-error.html";

        let method = 'POST';

        //Functions added to this array are called on ajax complete.
        settings.onSystemComplete = [];
        settings.onSystemBeforeSuccess = [];

        if (!settings.url) {
            journal.log({type: 'error', owner: 'UI', module: 'feta.fetch', submodule: 'request'}, 'No URL provided. Options: ', options);

            return false;
        }


        if (!options.onSuccess) {
            if (!settings.container) {
                journal.log({type: 'error', owner: 'app', module: 'feta.fetch', submodule: 'request'}, 'No container provided. Options: ', options);

                return false;
            }

            // Get the container element
            settings.$container = $(settings.container);

            if (!settings.$container.length) {
                journal.log({type: 'error', owner: 'app', module: 'feta.fetch', submodule: 'request'}, 'Could not find the container "' + settings.container + '". Options: ', options);

                return false;
            }

            //Add clickblocker to element
            clickBlocker.addClickBlocker(settings.$container);

            //Add clear function to onSystemComplete function array.
            settings.onSystemComplete.push(clearClickblocker);


            if(settings.$container.get(0).nodeName === "FORM"){
                settings.onSystemBeforeSuccess.push(pageRefresh);

                settings.onSystemComplete.push(resetFocus);
            }

        }

        // Set the Jade action, if it was supplied separately
        if (settings.action) {
            settings.params.jadeAction = settings.action;
        }

        //Set request method t get if running server locally for testing.
        if ((location.hostname === 'localhost' || location.hostname === '127.0.0.1') && location.port === '8888' ) {
            // journal.log({type: 'warning', owner: 'UI', module: 'feta', submodule: 'fetch', func: 'request'}, 'Request method being forced to "GET"');

            method = 'GET';
        }


        // Send the request
        return $.ajax({
                    url: settings.url,
                    data: settings.params,
                    method: method,
                    cache : false,
                })
                .done((data, textStatus, jqXHR) => {

                    if (settings.onSystemBeforeSuccess && settings.onSystemBeforeSuccess.length > 0) {
                        for (let i = 0; i < settings.onSystemBeforeSuccess.length; i++) {
                            settings.onSystemBeforeSuccess[i](settings, data, textStatus, jqXHR);
                        }
                    }

                    settings.onSuccess(settings, data, textStatus, jqXHR);
                })
                .fail((jqXHR, textStatus, errorThrown) => {

                    if(settings.redirectOnError){
                        if(settings.redirectOnError.url && typeof settings.redirectOnError.url === 'string' && settings.redirectOnError.url !== ""){
                            window.location.href = settings.redirectOnError.url;
                        }
                        else{
                            window.location.href = defaultErrorPage;
                        }
                    }

                    settings.onError(settings, jqXHR, textStatus, errorThrown);
                })
                .always((...args) => { // Args will match either `done` or `fail`, whichever applies

                    settings.onComplete(settings, ...args);

                    if (settings.onSystemComplete && settings.onSystemComplete.length > 0) {
                        for (let i = 0; i < settings.onSystemComplete.length; i++) {
                            settings.onSystemComplete[i](settings, ...args);
                        }
                    }
                });
    };

    /**
     * Creates an ajax request and includes the process map as parameters
     *
     * @param {mixed}   requestMap    Process map (array or hash)
     * @param {object}  ajaxSettings  Optional settings for the ajax request
     */
    const requestWithMap = (requestMap, ajaxSettings = {}) => {
        ajaxSettings.params = $.extend(true, {}, ajaxSettings.params, feta.requestMap(requestMap));
        return request(ajaxSettings);
    };

    // Successful request handler
    const ajaxSuccess = (settings, data/*, textStatus, jqXHR*/) => {
        // console.info('[ajaxSuccess] ' + settings.url + '\n', data);
        feta.render(data, settings);
    };

    // Failed request handler
    const ajaxFail = (settings, jqXHR, textStatus, errorThrown) => {
        journal.log({type: 'error', owner: 'app', module: 'feta.fetch', submodule: 'ajaxFail'}, 'Ajax response reported a failure. ', settings, jqXHR, textStatus, errorThrown);

        let errorMessage = null;

        if (settings.$container) {
            errorMessage = {
                "template": "userMessages",
                "parameters": {
                    "field": [
                        {
                            "elem": settings.container,
                            "parameters": {
                                "pageNotifier": "false",
                                "scroll": "true",
                            },
                            "messages": [
                                {
                                    "template": "message",
                                    "type": "error",
                                    "text": settings.errorMessage,
                                }
                            ]
                        }
                    ]
                }
            };
        }
        else{
            errorMessage = {
                "template": "userMessages",
                "parameters": {
                    "page": [
                        {
                            "template": "message",
                            "type": "error",
                            "text": settings.errorMessage
                        }
                    ],
                }
            };
        }

        if(errorMessage){
            feta.processUserMessages(errorMessage);
        }
    };

    const clearClickblocker = (settings/* , args */) => {
        const $container = settings.$container;

        clickBlocker.removeClickBlocker($container);
    };

    const pageRefresh = (settings, data, textStatus, jqXHR) => {
        //Perform any page refresh tasks here.
        clearMessages(settings, data, textStatus, jqXHR);

        clearLegend(settings, data, textStatus, jqXHR);
    };

    const clearMessages = (settings, data, textStatus, jqXHR) => {
        const messages = userMessage.messageStore;

        if(messages && messages.length > 0 ){
            const messagesToRemove = [];

            for(let i=0; i < messages.length; i++ ){
                if((messages[i].type === "page") || messages[i].element === data.container ){
                    messagesToRemove.push($("#"+messages[i].id));
                }
            }

            messagesToRemove.forEach((message) => {
                userMessage.removeMessage(message);
            });
        }
    };

    const clearLegend = (settings, data, textStatus, jqXHR) => {
        let $legend = $(".feta-page-legend");

        if($legend.length>=0){
            $legend.html("");
            $legend.addClass('cui-hidden');
        }
    };

    const resetFocus = (settings, data, textStatus, jqXHR) => {
        if(textStatus === "success"){
            window.scrollTo(0,0);

            let pageTitle = document.querySelector(".feta-page-title");
            if(pageTitle){
                pageTitle.tabIndex = "-1";
                pageTitle.focus();
            }
        }
    };

    const DEFAULT_REQUEST = {
        url: '', // Required
        container: '', // Selector for the element that should contain the response's contents. Required.
        action: '', // Jade action
        params: {},
        errorMessage: 'Our system can\'t display these contents at this time.',
        onSuccess: ajaxSuccess,
        onError: ajaxFail,
        onComplete: () => {},
        redirectOnError: false,
    };

    /**
     * @public
     *
     * Public API
    */
    return {
        request,
        requestWithMap,
    };
});
