define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    function TableResizer (props) {
        return (
            <button
                type="button"
                onClick={props.onResizerClick}
            >
                Resize
            </button>
        );
    }

    TableResizer.propTypes = {
        onResizerClick: ReactPropTypes.func,
    };

    TableResizer.defaultProps = {
        onResizerClick: () => {},
    };

    return TableResizer;
});
