/*jshint esversion: 6 */
/**
 * iflow module
 *
 * @description          Main module for iflow
 * @version              0.1.0
 *
 * @return {object}      Public API for module
 */

define(['jquery', 'cui', 'tableToJSON', 'iflowUiMethods', 'uiTimer', 'mutex', 'fieldMethods', 'iflowUiUtil', 'iflowUiGlobal'], function _iflow ($, cui, tableToJSON, iflowUiMethods, uiTimer, mutex, iflowFieldMethods) {

    // Private properties and CONSTANTS
    var VERSION = '0.1.0';
    var MODULE_NAME = 'iflow';

    // Private API namespace
    var _priv = {};

    /////////////////////
    // Private methods //
    /////////////////////

    /**
     * Initializes module
     *
     * @private
     */
    var _init = function _init() {
        /*DEBUG - START*/
        // console.log('[init] ' + MODULE_NAME + ' version ' + VERSION + ' initialization');
        /*DEBUG - END*/

        //Verify environment, if not iflow exit.
        if(fwData.environment && fwData.environment == "iflow"){
        	
        	journal.log({type: 'info', owner: 'UI', module: 'iflow', submodule: 'init'}, 'Detected iflow environment, initializing iflow specific code');

        	if (document.domain === 'localhost' || document.location.protocol === 'file:') {
	            updateLocalVariables();
	        }

	        //Detect tables needing converting.
	        tableToJSON.init();

	        //Get list of tables that need conversion
	        let htmlTables = document.querySelectorAll('.iflow-convert-table');
	        if(htmlTables){
	            for(let i=0;i<htmlTables.length;i++){
	                tableToJSON.htmlToJSON(htmlTables[i]);
	            }
	        }

	        iflowUiMethods.init();

	         //Detect to initalize session timeout.
        	sessionTimeout.init();

	        updateLegacyContent();
	        addMissingAriaTags();
			addSupportLinks();

	        mutex.init();
            iflowFieldMethods.init();
		}
	    else{
	    	return;
	    }
    };

    var updateLocalVariables = function _updateLocalVariables() {

        if(uiGlobal){
            // Update asset file paths to match the S: drive structure since this is a mockup and not DEVM/UTM/Prod
            uiGlobal.assetPath = '../../dist/'; // S: drive path
            uiGlobal.sIFLOWImagesPath = uiGlobal.assetPath + 'images/';
            // uiGlobal.assets.urls.spinwheel = uiGlobal.assetPath + 'js/compiled/spinwheel.js';
            // uiGlobal.assets.urls.fastclick = uiGlobal.assetPath + 'js/compiled/fastclick.js';
            // uiGlobal.assets.urls.banner = uiGlobal.assetPath + 'js/compiled/banner.js';

            // // uNav
            // uiGlobal.assets.urls.unav.banner = uiGlobal.assets.protocol + '//static-assets.ny.gov/load_global_menu/ajax?iframe=true&target=blank';

            // Uncomment this to import the test version of the uNav (only applies when running a server; does not affect HTML files opened from a drive)
            // if (document.location.protocol.indexOf('http') !== -1) {
            //     uiGlobal.assets.urls.unav.banner = uiGlobal.assets.protocol + '//nygovdev.prod.acquia-sites.com/load_global_menu/ajax?iframe=true&target=blank';
            // }

            uiGlobal.appCode = '_UI_';
        }

    };

    var updateLegacyContent = function _updateLegacyContent(){

        // Help Tags: Updates ID structure of legacy field instructions.
        let instructionsWrapper = document.querySelector('#instructionsFooter');
        if(instructionsWrapper){

            let helpTagContents = document.querySelectorAll('#instructionsFooter > div');

            helpTagContents.forEach((fieldInstruction) => {
                let header = fieldInstruction.querySelector('h4');

                if(header){
                    if(header.id){
                        if(!fieldInstruction.id){
                            fieldInstruction.id = header.id;
                            header.removeAttribute('id');
                        }
                    }
                }
            });
        }
    };

    var addMissingAriaTags = function _addMissingAriaTags(){
        // Disable this on filesystem. We want mockups to be accurate. Currently there the iflow tag library does not support adding aria attributes, this will be resolved when we migrate to a json based rendering system.
         // Enable development logging
        if (document.location.protocol === 'file:') {
            journal.log({type: 'info', owner: 'UI', module: 'iflow', submodule: 'addMissingAriaTags'}, 'Disabled dynamic aria-tag replacement since this is on a filesystem(mockup).');
            return false;
        }

        let bodyWrapper = document.getElementById("cui-body-wrapper");
        if(bodyWrapper){
	        let missingAriaRequired = bodyWrapper.querySelectorAll('.cui-required input:not([type="checkbox"]):not([type="radio"]):not([type="hidden"]):not([aria-required="true"]), .cui-required select:not([aria-required="true"])');

	        if (missingAriaRequired.length) {

	            for (let i = 0; i < missingAriaRequired.length; i++) {
	                let field = missingAriaRequired[i];
	                field.setAttribute('aria-required', 'true');
	            }
	        }
	    }
    };

    var addSupportLinks = function _addSupportLinks(){

    	let divider =  {
            "type": "divider"
        };
        let supportLink = {
            "text": "Support",
            "href": "https://www.tax.ny.gov/e-services/otc/help/tips_os.htm",
            "target":"_blank"
        };
        let contactUsLink = {
            "text": "Contact Us",
            "href": "https://www.tax.ny.gov/help/contact/",
            "target":"_blank"
        };

        if(fwData.helpMenu){
        	//Append support and contact us links if they are missing

        	if(fwData.helpMenu.items){
        		var hasSupportLink = false;
        		var hasContactLink = false;
        		for(let i=0;i<fwData.helpMenu.items.length;i++){
        			if(fwData.helpMenu.items[i].text == supportLink.text && fwData.helpMenu.items[i].href == supportLink.href){
        				hasSupportLink = true;
        			}

        			if(fwData.helpMenu.items[i].text == contactUsLink.text && fwData.helpMenu.items[i].href == contactUsLink.href){
        				hasContactLink = true;
        			}
        		}

        		if(!hasSupportLink || !hasContactLink){
	        		fwData.helpMenu.items.push(divider);
	        	
	        		if(!hasSupportLink){
	        			fwData.helpMenu.items.push(supportLink);
	        		}
	        		if(!hasContactLink){
	        			fwData.helpMenu.items.push(contactUsLink);        			
	        		}	        		
	        	}
        	}
        	else{
        		fwData.helpMenu.items = [
	            	supportLink,
	            	contactUsLink            
	            ];
        	}

        }
        else{
        	fwData.helpMenu = fwData.helpMenu = {
	            "text": "Get Help", 
	            "items": [
	            	supportLink,
	            	contactUsLink            
	            ]
	        };
        }
    };

    var sessionTimeout = {
        sessionTimer: null,
    };

    sessionTimeout.init = function _init(){
        //Only start the session timeout if sessionTimeout options have been defined in fwData
        if(fwData && fwData.app && fwData.app.sessionTimeout && fwData.app.sessionTimeout.duration && fwData.app.sessionTimeout.duration > 0){
            let sessionOptions = fwData.app.sessionTimeout;

            if(sessionTimeout.sessionTimer && sessionTimeout.sessionTimer instanceof uiTimer){
                sessionTimer.stop();
            }

            sessionTimeout.sessionTimer = new uiTimer(function(){
                sessionTimeout.create(sessionOptions);
            }, sessionOptions.duration);

            sessionTimeout.sessionTimer.start();
        }
    };

    //Creates a new modal
    sessionTimeout.create = function _create(sessionOptions) {
        let timeoutMessage =  "Your online session is about to time out. Select OK to continue the session or select Cancel to allow your session to time out.";

        let modalSettings = {
            htmlString : timeoutMessage,
        };

        // Fully-custom message
        if (sessionOptions.confirmText && typeof sessionOptions.confirmText === 'string') {
            modalSettings.htmlString = sessionOptions.confirmText;
        }

        // Set up event handlers once the modal has been displayed
        const _setup = (modal) => {
            modal.$self
                .find('.feta-confirm-button-yes')
                    .on('click', {modal: modal}, _onYesClick);

            modal.$self
                .find('.feta-confirm-button-no')
                    .on('click', {modal: modal}, _onNoClick);
        };

        const _destroy = (modal) => {
            modal.hide();
            modal.destroy();
        };

        // Event handlers for this particular modal instance
        const _onNoClick = (evt) => {
            const modal = evt.data.modal;
            _destroy(modal);
        };

        const _onYesClick = (evt) => {
            const modal = evt.data.modal;
            _destroy(modal);

            // Send request to server to refresh session
            sessionTimeout.server.sendRequest();
        };

        // Create and display the modal
        const _createModal = () => {
            const $confirmModal = $.modal({
                html: '<p>' + modalSettings.htmlString + '</p>',
                footer: {
                    html:   '<button type="button" class="feta-confirm-button-yes">OK</button>' +
                            '<button type="button" class="feta-confirm-button-no">Cancel</button>',
                    className: 'cui-align-right',
                },
                display: {
                    closeButton: false,
                    width:"500px"
                },
                hideOnEscape: false,
                overlay: {
                    closeOnClick:false
                },
                modalClass: 'feta-confirm-modal',
                onCreate: (modal) => {
                    _setup(modal);
                },
                hideDestroy: true,
            });

            $confirmModal.show();
        };

        // Check to see if the module has been loaded yet
        if (require.defined('modal')) {
            // Create the modal immediately
            _createModal();
        }
        else {
            // Load the module and then create the modal
            cui.load('modal', () => {
                _createModal();
            });
        }
    };

    sessionTimeout.server = {
        sendRequest: function _session_timeout_send_request(){
            try {
                // Make ajax call to the app's sessionRefresh page
                uiMethods.ajax.execute({
                    sUrl: uiUtil.url.getAppBaseUrl() + 'sessionRefresh',
                    onSuccess: sessionTimeout.server.handleResponse,
                    onError: sessionTimeout.redirectToAuth,
                    sMethod:"get"

                });
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        },

        handleResponse: function _session_timeout_handle_response(xmlHTTP){
            try {
                if (!xmlHTTP) { return false; }
                if (!xmlHTTP.responseText) { return false; }
                if (/true/i.test(xmlHTTP.responseText) && xmlHTTP.status === 200) {
                    // Server session was renewed, so restart the timer
                    sessionTimeout.sessionTimer.stop();
                    sessionTimeout.sessionTimer.start();
                }
                else {
                    // Server session expired, so redirect to the auth page
                    uiMethods.session.redirectToAuth();
                }
            }
            catch (e) {
                uiUtil.errorHandling.jsLog.addEntry(e, arguments);
            }
        }
    };

    sessionTimeout.redirectToAuth =  function _session_redirectToAuth(xmlHTTP) {
        try {
            window.location.href = uiUtil.url.getAppBaseUrl() + uiUtil.url.sGenericAuthErrorUrl;
        }
        catch (e) {
            uiUtil.errorHandling.jsLog.addEntry(e, arguments);
        }
    };

    /**
     * Public API
     *
     * @public
    */
    return {
        init: _init,
        fieldMethods: iflowFieldMethods
    };
});
