define(['guid'], function (guid) {
    // SVG source file: feta-pointer-left.svg

    const normalizeSourceTable = (sourceTable) => {

        let baseId;

        let maxBodyColumns;

        // Some pieces of code rely on having an ID, so make sure one is defined even if it's a random string
        if (!sourceTable.id) {
            sourceTable.id = guid();
        }

        // Normalize viewport config
        if (sourceTable.viewport && sourceTable.viewport.height) {
            // Table has only a max height but no initial height
            if (!sourceTable.viewport.height.initial && sourceTable.viewport.height.max) {
                sourceTable.viewport.height.initial = Math.min(10, sourceTable.viewport.height.max);
            }

            // Fill in defaults as needed:

            if (!sourceTable.viewport.height.initial) {
                sourceTable.viewport.height.initial = 10;
            }

            if (!sourceTable.viewport.height.step) {
                sourceTable.viewport.height.step = 10;
            }
        }


        if(sourceTable.renderStyle){
            // Add classes to primary/secondary columns in the body so we don't need to run this logic during every `render`
            if ((sourceTable.renderStyle.primaryCols && sourceTable.renderStyle.primaryCols.length) || (sourceTable.renderStyle.secondaryCols && sourceTable.renderStyle.secondaryCols.length)) {
                sourceTable.hasPrimarySecondaryCols = true;
            }
        }


        // Add a place for React to store info about sorting
        sourceTable.sortCols = [];

        if (!sourceTable.head.rows) {
            sourceTable.head.rows = [];
        }

        if (!sourceTable.body.rows) {
            sourceTable.body.rows = [];
        }

        // Stub out paging settings
        if (!sourceTable.paging) {
            sourceTable.paging = {
                total: sourceTable.body.rows.length,
                currIndex: 0,
                step: 0
            };
        }
        else {
            if (!sourceTable.paging.total) {
                sourceTable.paging.total = sourceTable.body.rows.length;
            }

            if (!sourceTable.paging.step) {
                sourceTable.paging.step = 0;
            }

            if (!sourceTable.paging.currIndex) {
                sourceTable.paging.currIndex = 0;
            }
        }

        // Table body has no rows
        sourceTable.isEmpty = (!sourceTable.body.rows.length);

        // Add a dummy row to empty tables
        if (sourceTable.isEmpty) {
            sourceTable.body.rows.push({
                columns: [
                    {
                        text: 'There is no information to display.',
                        attributes: {
                            colSpan: sourceTable.head.rows[0].columns.length,
                            style: {fontStyle: 'italic'},
                            className: 'cui-align-center',
                        },
                    },
                ],
            });
        }
        // Non-empty tables
        else {
            // Add classes and other properties to rows and cells so we don't have to keep checking for their existence in the JSX:

            // Header
            sourceTable.head.rows.forEach(function (row, r) {
                var baseId;
                var cell;

                row.rowProps = {
                    className: 'feta-table-elem-tr',
                };

                // Row selection
                if (sourceTable.selectionType) {
                    baseId = sourceTable.id + '_selection_row';

                    // "Select all" box in header
                    if (sourceTable.selectionType === 'multiple' && sourceTable.selectAll == true) {
                        cell = _getDefaultSelectionCell(baseId, r, row);

                        cell.selectionType = 'all-rows';
                        // cell.text = 'Select all rows';
                        cell.contents[0].label.text = 'Select all';
                        cell.attributes = {
                            className: 'cui-width-icon',
                        };

                        row.columns.unshift(cell);
                    }
                    // Any other type, e.g `multiple-without-all`
                    else if (sourceTable.selectionType.indexOf('multiple') === 0) {
                        row.columns.unshift({
                            text: '',
                            attributes: {
                                className: 'cui-width-icon',
                            },
                            key: row.key + '_col0_' + r,
                        });
                    }
                    // Radio button selection
                    else if (sourceTable.selectionType === 'single') {
                        // Create an empty cell
                        row.columns.unshift({
                            text: '',
                            attributes: {
                                className: 'cui-width-icon',
                            },
                        });
                    }
                }
            });

            // Body
            sourceTable.body.rows.forEach(function (row, r) {
                // These are 'super' columns that combine the content of all primary or secondary columns into one object so that we can display them in one 'cell' on small screens
                var primarySuperCol = {};
                var addPrimaryCol = false;
                var secondarySuperCol = {
                    classNames: [],
                };
                var addSecondaryCol = false;

                row.rowProps = {
                    className: 'feta-table-elem-tr',
                };

                if(row.style){
		            row = _addRowStyle(row);
		        }
             
                if(row.columns && row.columns.length){

                    if(!maxBodyColumns || row.columns.length > maxBodyColumns){
                        maxBodyColumns = row.columns.length;
                    }
                }

                row.columns.forEach(function (col, c) {

                    //Due to how the code is structured selection columns have not been added to the row yet, but they have been added to the header row
                    var headerIndex = c;
                    if(sourceTable.selectionType && (sourceTable.selectionType === 'single' || sourceTable.selectionType === 'multiple' || sourceTable.selectionType.indexOf('multiple') === 0)){
                        //Increment header index to account for selection row.
                        headerIndex += 1;
                    }

                    var headerCol = sourceTable.head.rows[0].columns[headerIndex];
                    var classNames = [];

                    if (!col.attributes) {
                        col.attributes = {};
                    }

                    // if(headerCol.style){
                    //     if(col.style){
                    //         let styleList = col.style.trim().split(',');
                    //         //Only add the value if it doesn't already exist in the properyList
                    //         if(styleList.indexOf(headerCol.style) === -1){
                    //             col.style += "," + headerCol.style;
                    //         }
                    //     }
                    //     else{
                    //         col.style = headerCol.style;
                    //     }
                    // }

                    if (col.attributes.className) {
                        classNames = col.attributes.className.trim().split(' ');
                    }


                    if(!headerCol){
                        sourceTable.head.rows[0].columns.push({
                            "type": "header",
                            "attributes": {
                                "data-type": "alpha",
                            },
                            "text": "",
                        });

                        headerCol = sourceTable.head.rows[0].columns[headerIndex];

                        journal.log({type: 'error', owner: 'UI', module: 'Table', submodule: 'normalizeSourceTable'}, 'Table is missing header columns compared to body columns');                        
                    }

                    // Column is primary
                    if (sourceTable.renderStyle && sourceTable.renderStyle.primaryCols && sourceTable.renderStyle.primaryCols.indexOf(c) !== -1) {
                        classNames.push('feta-table-row-cell-primary');

                        if(col.text){
                            // Add to the super column
                            if (!primarySuperCol.text) {
                                primarySuperCol.text = col.text;
                            }
                            else {
                                primarySuperCol.text += ' ' + col.text;
                            }
                            addPrimaryCol = true;
                        }
                        else if(col.contents){
                            primarySuperCol.responsiveHeaderContents = col.contents;
                            addPrimaryCol = true;
                        }


                    }
                    // Column is secondary
                    else if (sourceTable.renderStyle && sourceTable.renderStyle.secondaryCols && sourceTable.renderStyle.secondaryCols.indexOf(c) !== -1) {
                        classNames.push('feta-table-row-cell-secondary');

                        addSecondaryCol = true;

                        if(col.text){
                            // Add to the super column
                            if (!secondarySuperCol.text) {
                                secondarySuperCol.text = col.text;
                            }
                            else {
                                secondarySuperCol.text += ' ' + col.text;
                            }
                            addSecondaryCol = true;
                        }
                        else if(col.contents){
                            secondarySuperCol.responsiveHeaderContents = col.contents;
                            addSecondaryCol = true;
                        }

                        // Check if this column is numeric/monetary
                        // TODO: Determine if secondary numeric columns need to be right aligned.
                        // if ((headerCol.type && /numeric|currency/.test(headerCol.type)) || (headerCol.attributes && /numeric|currency/.test(headerCol.attributes['data-type']))) {
                        if ((headerCol.type && /currency/.test(headerCol.type)) || (headerCol.attributes && /currency/.test(headerCol.attributes['data-type']))) {

                            // if(classNames.indexOf('cui-currency') === -1){
                            //     classNames.push('cui-currency');
                            // }

                            // Add to the super column
                            // if (secondarySuperCol.classNames.indexOf('cui-currency') === -1) {
                            //     secondarySuperCol.classNames.push('cui-currency');
                            // }
                        }
                    }
                    // Column is neither primary nor secondary
                    else {
                        col.isNotPrimaryNorSecondary = true;
                    }

                    // Check if this column is monetary
                    if ((headerCol.type && /currency/.test(headerCol.type)) || (headerCol.attributes && /currency/.test(headerCol.attributes['data-type']))) {

                        // if(classNames.indexOf('cui-currency') === -1){
                        //     classNames.push('cui-currency');
                        // }

                        // Also add to the super column
                        if (sourceTable.secondaryCols && sourceTable.secondaryCols.indexOf(c) !== -1) {
                            if (secondarySuperCol.classNames.indexOf('cui-currency') === -1) {
                                secondarySuperCol.classNames.push('cui-currency');
                            }
                        }
                    }

                    // Check if this column is a control
                    // if ((headerCol.type && /control/.test(headerCol.type))){
                    //     col.isControlCell = true;
                    // }

                    if (col.contents) {
                        classNames.push('feta-table-col-field');
                    }

                    if (headerCol.attributes) {
                        // No wrap
                        // if (headerCol.attributes.noWrap) {
                        //     classNames.push('cui-no-wrap');
                        // }

                        // Sorting
                        if (headerCol.attributes['data-sort']) {
                            if (sourceTable.sortCols.indexOf(c) === -1) {
                                sourceTable.sortCols.push(c);
                            }
                        }
                    }

                    if(col.type == "body"){
                        journal.log({type: 'error', owner: 'UI', module: 'Table', submodule: 'normalizeSourceTable'}, 'Table cell is using unsupported type value `body`. This should be removed');

                        delete col.type;
                    }


                    //FIXME: We shouldn't need both of these lines, try to remove the second one throughout the JSX
                    col.attributes.className = classNames.join(' ');
                    col.classNames = classNames;
                });

                // Expandable options
                if (row.expand) {
                    row.rowProps.className += ' feta-table-row-expand-parent';

                    if(!row.expand.settings){
                        row.expand.settings = {};
                    }

                    if (!row.expand.settings.indicators) {
                        row.expand.settings.indicators = [0];
                    }

                    if(row.expand.indicators){
                        journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'normalizeSourceTable'}, 'Table is using deprecated `expand.indicators` property, use `expand.settings.indicators` instead: ', JSON.parse(JSON.stringify(row)));

                        //FIXME: Start depreciation fix
                        if(row.expand.indicators !== 0){
                            row.expand.settings.indicators = row.expand.indicators;
                        }
                        //End depreciation fix
                    }

                    if(row.expand.isVisible){
                        journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'normalizeSourceTable'}, 'Table is using deprecated `expand.isVisible` property, use `expand.settings.isVisible` instead: ', JSON.parse(JSON.stringify(row)));

                        row.settings.isVisible = row.isVisible;
                        delete row.isVisible;
                    }
                }

                // Update the row object with the super column(s)
                if (addPrimaryCol) {
                    sourceTable.body.rows[r].primarySuperCol = primarySuperCol;
                }

                if (addSecondaryCol) {
                    sourceTable.body.rows[r].secondarySuperCol = secondarySuperCol;
                }
            });

            // Foot
            if(sourceTable.foot && sourceTable.foot.rows){
                sourceTable.foot.rows.forEach(function (row) {
                    row.rowProps = {
                        className: 'feta-table-elem-tr',
                    };

                    // Row selection
                    if (sourceTable.selectionType) {
                        // Create an empty cell
                        row.columns.unshift({
                            text: '',
                            attributes: {
                                className: 'cui-width-icon',
                            },
                        });
                    }
                });
            }

            ///////////////////////////
            // Add selection columns //
            ///////////////////////////

            // Check box selection
            if (sourceTable.selectionType && sourceTable.selectionType.indexOf('multiple') === 0) {
                baseId = sourceTable.id + '_selection_row';

                // Add a new column by inserting a cell at the beginning of each row with a check box
                sourceTable.body.rows.forEach(function (row, r) {
                    var cell;
                    var rowIndex = r + 1;
                    // Make sure this object exists so we can inspect it throughout the component without having to continually verify its existence
                    if (!row.selection) {
                        row.selection = {};
                    }

                    // Row has no selection, so insert an empty cell
                    if (row.selection.empty) {
                        cell = {
                            text: '',
                        };
                    }
                    // Read-only
                    else if (row.selection.readOnly) {
                        if (row.selection.checked) {
                            cell = {
                                contents: [
                                    {
                                        label: {
                                            attributes: {
                                                for: baseId + rowIndex
                                            },
                                            text: 'Include this row',
                                            "visibility": "hidden",
                                            "hasLayout": false
                                        },
                                        input: {
                                            attributes: {
                                                type: 'checkbox',
                                                name: baseId + rowIndex,
                                                id: baseId + rowIndex,
                                                checked:"checked",
                                            },
                                            readOnly: true,
                                        },
                                        template: 'field',
                                        type: 'checkbox',
                                    }
                                ],
                            };
                        }
                        else {
                            cell = {
                                contents: [
                                    {
                                        label: {
                                            attributes: {
                                                for: baseId + rowIndex
                                            },
                                            text: 'Include this row',
                                            "visibility": "hidden",
                                            "hasLayout": false
                                        },
                                        input: {
                                            attributes: {
                                                type: 'checkbox',
                                                name: baseId + rowIndex,
                                                id: baseId + rowIndex,
                                            },
                                            readOnly: true,
                                        },
                                        template: 'field',
                                        type: 'checkbox',
                                    }
                                ],
                            };
                        }
                    }
                    // Standard check box
                    else {
                        cell = _getDefaultSelectionCell(baseId, r + 1, row);

                        // Box is checked by default
                        if (row.selection.checked) {
                            cell.contents[0].input.attributes.checked = true;
                        }
                    }

                    row.columns.unshift(cell);

                    // Increment the expandable indicator(s) to account for the selection column
                    if (row.expand) {
                        row.expand.settings.indicators = row.expand.settings.indicators.map(function (i) {
                            return i + 1;
                        });
                    }
                });
            }
            // Radio button selection
            else if (sourceTable.selectionType === 'single') {
                baseId = sourceTable.id + '_selection_row';

                sourceTable.body.rows.forEach(function (row, r) {
                    var rowIndex = r + 1;
                    var cell;

                    // Make sure this object exists so we can inspect it throughout the component without having to continually verify its existence
                    if (!row.selection) {
                        row.selection = {};
                    }

                    // Row has no selection, so insert an empty cell
                    if (row.selection.empty) {
                        cell = {
                            text: '',
                        };
                    }
                    // Read-only
                    else if (row.selection.readOnly) {
                        if (row.selection.checked) {
                            cell = {
                                contents: [
                                    {
                                        label: {
                                            attributes: {
                                                for: baseId + rowIndex
                                            },
                                            text: 'Include this row',
                                            "visibility": "hidden",
                                            "hasLayout": false
                                        },
                                        input: {
                                            attributes: {
                                                type: 'radio',
                                                name: baseId,
                                                id: baseId + rowIndex,
                                                checked: 'checked'
                                            },
                                            readOnly: true,
                                        },
                                        template: 'field',
                                        type: 'radio',
                                    }
                                ],
                            };
                        }
                        else {
                            cell = {
                                contents: [
                                    {
                                        label: {
                                            attributes: {
                                                for: baseId + rowIndex
                                            },
                                            text: 'Include this row',
                                            "visibility": "hidden",
                                            "hasLayout": false
                                        },
                                        input: {
                                            attributes: {
                                                type: 'radio',
                                                name: baseId,
                                                id: baseId + rowIndex,
                                            },
                                            readOnly: true,
                                        },
                                        template: 'field',
                                        type: 'radio',
                                    }
                                ],
                            };
                        }
                    }
                    // Standard radio button
                    else {
                        cell = _getDefaultSelectionCell(baseId, rowIndex, row, true);

                        // Box is checked by default
                        if (row.selection.checked) {
                            cell.contents[0].input.attributes.checked = true;
                        }
                    }

                    row.columns.unshift(cell);
                });
            }
            else if (sourceTable.selectionType) {
                journal.log({type: 'error', owner: 'UI', module: 'table', submodule: 'normalize'}, 'Unsupported `selectionType` value: "' + sourceTable.selectionType + '" ', JSON.parse(JSON.stringify(sourceTable)));
            }
        }

        // Add keys for React and indices. We do this at the end to make sure we include all of the selection cells and don't end up with duplicate key values.

        // Head
        var rowIndex = 0;
        var numRows = sourceTable.head.rows.length;
        var row;
        var col;
        var colIndex;
        while (rowIndex < numRows) {
            row = sourceTable.head.rows[rowIndex];

            row.key = row.key || sourceTable.id + '_headRow_' + rowIndex;
            row.index = rowIndex;

            colIndex = 0;
            while (colIndex < row.columns.length) {
                col = _addCellProps(row.columns[colIndex], true);

                col.key = row.key + '_col_' + colIndex;
                col.index = colIndex;

                col = _addColContentKeys(col);

                colIndex++;
            }

            rowIndex++;
        }

        // Body
        rowIndex = 0;
        numRows = sourceTable.body.rows.length;
        while (rowIndex < numRows) {
            row = sourceTable.body.rows[rowIndex];

            row.key = row.key || sourceTable.id + '_' + rowIndex;
            row.index = rowIndex;

            if (row.expand) {
                row.expand.key = row.key + '_expand';

                if (row.expand.contents) {
                    row.expand.contents = normalizeExpandRow(row.expand.contents, row.expand.key);
                }
            }

            colIndex = 0;
            while (colIndex < row.columns.length) {
                col = _addCellProps(row.columns[colIndex]);

                col.key = row.key + '_col_' + colIndex;
                col.index = colIndex;

                col = _addColContentKeys(col);

                colIndex++;
            }

            rowIndex++;
        }

        // Foot
        if (sourceTable.foot && sourceTable.foot.rows) {
            rowIndex = 0;
            numRows = sourceTable.foot.rows.length;
            while (rowIndex < numRows) {
                row = sourceTable.foot.rows[rowIndex];

                row.key = row.key || sourceTable.id + '_footRow_' + rowIndex;
                row.index = rowIndex;

                colIndex = 0;

                while (colIndex < row.columns.length) {
                    col = _addCellProps(row.columns[colIndex]);

                    col.key = row.key + '_col_' + colIndex;
                    col.index = colIndex;

                    col = _addColContentKeys(col);

                    colIndex++;
                }

                let footerColumnOffset = 0;

                if(sourceTable.selectable){
                    footerColumnOffset = 1;
                }

                if(row.columns.length > (maxBodyColumns + footerColumnOffset)){
                    /*DEBUG: START*/
                    console.log(sourceTable);
                    /*DEBUG: END*/
                    journal.log({type: 'error', owner: 'UI', module: 'Table', submodule: 'normalizeSourceTable'}, 'Table footer column count does not match body row column count.');
                }

                rowIndex++;
            }
        }

        // Filters
        if (sourceTable.filter) {
            if (sourceTable.filter.advanced && sourceTable.filter.advanced.rows) {
                rowIndex = 0;
                numRows = sourceTable.filter.advanced.rows.length;
                while (rowIndex < numRows) {
                    row = sourceTable.filter.advanced.rows[rowIndex];

                    row.key = sourceTable.id + '_filterAdvRow' + rowIndex;

                    colIndex = 0;
                    while (colIndex < row.rules.length) {
                        col = row.rules[colIndex];

                        col.key = row.key + '_rule' + colIndex;

                        // col = _addColContentKeys(col);

                        colIndex++;
                    }

                    rowIndex++;
                }
            }
        }

        return sourceTable;
    };

    const updateTableJSON = (sourceTable) => {
        //Fix locations of json attributes.
        //Define renderstyle if it dosent exist.

        if(!sourceTable.renderStyle){
            sourceTable.renderStyle = {};
        }

        //Ironically we are still using renderStyle.primary/secondary cols from a rendering perspective and polyfilling support for `responsive.type`, but we want to unify the method used to create tables. S
        if(sourceTable.renderStyle.primaryCols){
            journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'updateTableJSON'}, 'Table is using deprecated `renderStyle.primaryCols` property. Please use header column `responsive.type` property instead');
        }

        if(sourceTable.primaryCols){
            journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'updateTableJSON'}, 'Table is using deprecated `primaryCols` property. Please use header column `responsive.type` property instead');

            if(sourceTable.renderStyle.primaryCols){
                journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'updateTableJSON'}, 'primaryCols property found in two locations. Please remove primaryCols as top level table property.');
            }
            else{
                sourceTable.renderStyle.primaryCols = sourceTable.primaryCols;
            }

            delete sourceTable.primaryCols;
        }

        if(sourceTable.renderStyle.secondaryCols){
            journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'updateTableJSON'}, 'Table is using deprecated `renderStyle.secondaryCols` property. Please use header column `responsive.type` property instead');
        }

        if(sourceTable.secondaryCols){
            journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'updateTableJSON'}, 'Table is using deprecated `secondaryCols` property. Please use header column `responsive.type` property instead');

            if(sourceTable.renderStyle.secondaryCols){
                journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'updateTableJSON'}, 'secondaryCols property found in two locations. Please remove secondaryCols as top level table property.');
            }
            else{
                sourceTable.renderStyle.secondaryCols = sourceTable.secondaryCols;
            }

            delete sourceTable.secondaryCols;
        }

        if(sourceTable.renderStyle && sourceTable.renderStyle.type){
            journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'updateTableJSON'}, 'Table is using deprecated `renderStyle.type` property. Please use top level type property');

            if(sourceTable.type === 'default'){
                sourceTable.type = sourceTable.renderStyle.type;
            }

            delete sourceTable.renderStyle.type;
        }

        if(sourceTable.remove){
            journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'updateTableJSON'}, 'Table is using deprecated `remove` property, please move this property under `footerControls`.');

            if(sourceTable.footerControls){
                if(sourceTable.footerControls.remove){
                    journal.log({type: 'warn', owner: 'UI', module: 'Table', submodule: 'updateTableJSON'}, 'Remove property found in two locations. Please remove property from top level of table.');
                }
                else{
                    sourceTable.footerControls.remove = sourceTable.remove;
                }
            }
            else{
                sourceTable.footerControls = {
                    "remove": sourceTable.remove
                }
            }
            delete sourceTable.remove;
        }

        //Set table options based on style attribute
        if(sourceTable.style){

            let stylePieces = sourceTable.style.split(',');

            stylePieces.forEach((style)=>{
                let styleName = style.trim();

                switch(styleName){
                    case 'clearSelection':
                        journal.log({type: 'info', owner: 'UI', module: 'Table', submodule: 'updateTableJSON'}, 'Adding `clearSelection` control via style');
                        if(sourceTable.renderStyle){
                            sourceTable.renderStyle.clearSelection = true;
                        }
                        else{
                            sourceTable.renderStyle = {"clearSelection":true};
                        }

                    break;

                    case 'showTitle':
                        journal.log({type: 'info', owner: 'UI', module: 'Table', submodule: 'updateTableJSON'}, 'Adding `showTitle` control via style');
                        if(sourceTable.renderStyle){
                            sourceTable.renderStyle.showTitle = true;
                        }
                        else{
                            sourceTable.renderStyle = {"showTitle":true};
                        }
                    break;

                    case 'expandControls':
                        journal.log({type: 'info', owner: 'UI', module: 'Table', submodule: 'updateTableJSON'}, 'Adding `expandControls` control via style');
                        if(sourceTable.renderStyle){
                            sourceTable.renderStyle.expandControls = true;
                        }
                        else{
                            sourceTable.renderStyle = {"expandControls":true};
                        }

                    break;

                    case 'hideFooterResponsive':
                        journal.log({type: 'info', owner: 'UI', module: 'Table', submodule: 'updateTableJSON'}, 'Adding `hideFooterResponsive` control via style');

                    break;

                    default:
                }
            });
        }

        //Build out renderStyle column arrays until support is updated throughout the component to render off of the responsive.type property.
        if(sourceTable.head){
            if(sourceTable.head.rows){
                sourceTable.head.rows.forEach((row, r)=>{

                    if(row.columns){

                        row.columns.forEach((column, c)=>{
                            if(column.responsive){
                                if(column.responsive.type){
                                    switch(column.responsive.type){
                                        case 'primary':
                                            if(sourceTable.renderStyle.primaryCols){
                                                sourceTable.renderStyle.primaryCols.push(c);
                                            }
                                            else{
                                                sourceTable.renderStyle.primaryCols = [c];
                                            }
                                            break;

                                        case 'secondary':
                                            if(sourceTable.renderStyle.secondaryCols){
                                                sourceTable.renderStyle.secondaryCols.push(c);
                                            }
                                            else{
                                                sourceTable.renderStyle.secondaryCols = [c];
                                            }
                                            break;

                                        case 'action':
                                            if(sourceTable.renderStyle.actionCols){
                                                sourceTable.renderStyle.actionCols.push(c);
                                            }
                                            else{
                                                sourceTable.renderStyle.actionCols = [c];
                                            }
                                            break;

                                        case 'indicator':
                                            if(sourceTable.renderStyle.indicatorCols){
                                                sourceTable.renderStyle.indicatorCols.push(c);
                                            }
                                            else{
                                                sourceTable.renderStyle.indicatorCols = [c];
                                            }
                                            break;
                                        default:
                                        break;
                                    }
                                }
                            }
                        });
                    }
                });
            }
        }

        if(sourceTable.body){
            if(sourceTable.body.rows){
                sourceTable.body.rows.forEach((row, r)=>{
                    if(row.expand){
                    	if(row.expand.columns){
                    		if(!row.expand.contents){
                    			row.expand.contents = row.expand.columns;                    			
                    		}

                    		journal.log({type: 'warning', owner: 'UI', module: 'Table', submodule: 'updateTableJSON'}, 'Table using depreciated row.expand.columns. Use row.expand.contents instead.');
                    		delete row.expand.columns;
                    	}
                    }
                });
            }
        }

        return sourceTable
    }

    // Returns the object for a cell containing an unchecked check box or radio button
    const _getDefaultSelectionCell = (baseId, rowIndex, row, isRadio) => {
        var type = isRadio ? 'radio' : 'checkbox';
        var name = isRadio ? baseId : baseId + rowIndex;

        rowIndex = rowIndex || 0;

        return {
            selectionType: 'row',
            contents: [
                {
                    label: {
                        attributes: {
                            for: baseId + rowIndex,
                        },
                        text: 'Include this row',
                        "visibility": "hidden",
                        "hasLayout": false
                    },
                    input: {
                        attributes: {
                            type: type,
                            name: name,
                            id: baseId + rowIndex,
                            value: row.key,
                        },
                    },
                    template: 'field',
                    type: type,
                },
            ],
        };
    };

    // Convert hyphenated-strings to camelCase
    const toCamelCase = (str) => {
        return str
                .replace(/-([a-z])/g, function (firstLetter) {
                    return firstLetter[1].toUpperCase();
                });
    };

        // Adds `col.attributes` to
    const _addCellProps = (col, isHeader) => {
        col.cellProps = Object.assign({}, {style: {}, className: ' '}, col.cellProps);

        if(col.style){
            col = _addCellStyle(col);
        }

        if (col.attributes) {
            Object.keys(col.attributes).forEach(function (key) {
                var val = col.attributes[key];

                // Merge inline styles with existing object
                if (key === 'style') {
                    col.cellProps.style = Object.assign({}, val, col.cellProps.style);
                }
                // Add classes to the existing string
                else if (key === 'className') {
                    col.cellProps.className += ' ' + val;
                }
                // Translate some key names
                else if (key === 'url') {
                    col.cellProps.href = val;

                    delete col.attributes.url;
                }
                else if (key === 'noWrap') {
                    // Convert the property to a class
                    if (!isHeader) {
                        col.cellProps.className += ' cui-no-wrap';
                    }

                    delete col.attributes[key];
                }
                // Move some keys to the `style` object
                else if (/\w-align$/.test(key)) { // e.g. `text-align` or `vertical-align`
                    // Remove the property for `<th>` elements (e.g. for a curreny column we don't want the header cell to be right-aligned, just the body cells)
                    if (isHeader) {
                        delete col.attributes[key];
                    }
                    // Fix the name and move it to `style` for `<td>` elements
                    else {
                        key = toCamelCase(key);
                        col.cellProps.style[key] = val;
                    }
                }
                // Some other attribute, just add it to the element's props (but ignore incompatible keys)
                else if (!/data-sort|data-type/.test(key)) {
                    // Convert camelCase
                    if (/-/.test(key)) {
                        key = toCamelCase(key);
                    }

                    col.cellProps[key] = val;
                }
            });
            // console.log('col is now ', col);
        }

        col.cellProps.className = col.cellProps.className.trim();

        return col;
    };

    const _addColContentKeys = (col) => {
        var index;
        var numItems;
        var item;

        if (col.contents) {
            index = 0;
            numItems = col.contents.length;
            while (index < numItems) {
                item = col.contents[index];

                // if (item.key) { console.warn('[table => _addColContentKeysitem => contents] item already has a key ', item, '\nparent col: ', col); }
                item.key = col.key + '_contents' + index;

                // Recursively check child contents
                if (item.contents) {
                    item = _addColContentKeys(item);
                }

                index++;
            }
        }

        if (col.control && col.control.items) {
            index = 0;
            numItems = col.control.items.length;
            while (index < numItems) {
                item = col.control.items[index];

                // if (item.key) { console.warn('[table => _addColContentKeysitem => control] item already has a key ', item, '\nparent col: ', col); }
                item.key = col.key + '_control' + index;

                // Recursively check child contents
                if (item.control && item.control.items) {
                    item = _addColContentKeys(item);
                }

                index++;
            }
        }

        return col;
    };

    const _addCellStyle = (col) => {
        styles = col.style;

        if (styles !== undefined) {

            if (styles.indexOf(',') !== -1) {
                styles = styles.split(',');
            }
            else {
                styles = [styles];
            }

            for (var i = 0, len = styles.length; i < len; i++) {
                switch (styles[i].trim()) {

                    case 'currency':
                        col = _addCellStyleValue(col, 'className', 'cui-currency');
                        break;

                    case 'align-right':
                        col = _addCellStyleValue(col, 'className', 'cui-align-right');
                        break;

                    case 'align-center':
                        col = _addCellStyleValue(col, 'className', 'cui-align-center');
                        break;

                    case 'no-wrap':
                        col = _addCellStyleValue(col, 'className', 'cui-no-wrap');
                        break;

                    case 'icon':
                        col = _addCellStyleValue(col, 'className', 'cui-width-icon');
                        break;

                    case 'not-applicable':
                        col = _addCellStyleValue(col, 'className', 'feta-not-applicable');
                        break;

                    case 'min-width':
                        col = _addCellStyleValue(col, 'className', 'feta-min-width');
                        break;

                    case 'indent':
                        col = _addCellStyleValue(col, 'className', 'feta-table-cell-indent');
                        break;
                }
            }
        }

        return col;
    }

    const _addRowStyle = (row) => {
        styles = row.style;

        if (styles !== undefined) {

            if (styles.indexOf(',') !== -1) {
                styles = styles.split(',');
            }
            else {
                styles = [styles];
            }

            for (var i = 0, len = styles.length; i < len; i++) {
                switch (styles[i].trim()) {

                    case 'header':
                        row = _addRowStyleValue(row, 'className', 'feta-table-section-header-row');
                        break;                  
                }
            }
        }

        return row;
    }

    const _addCellStyleValue =  (obj, type, value) => {

        // Check to see if this attribute value exists
        if (obj.cellProps.hasOwnProperty(type)) {
            let propertyList = obj.cellProps[type].trim().split(' ');

            //Only add the value if it doesn't already exist in the properyList
            if(propertyList.indexOf(value) === -1){
                obj.cellProps[type] += ' ' + value;
            }
        }
        else {
            // Defautl does not have this type, so lets create it.
            obj.cellProps[type] = value;
        }
        return obj;
    };

    const _addRowStyleValue =  (obj, type, value) => {
    	
        // Check to see if this attribute value exists
        if (obj.rowProps.hasOwnProperty(type)) {
            let propertyList = obj.rowProps[type].trim().split(' ');

            //Only add the value if it doesn't already exist in the properyList
            if(propertyList.indexOf(value) === -1){
                obj.rowProps[type] += ' ' + value;
            }
        }
        else {
            // Defautl does not have this type, so lets create it.
            obj.rowProps[type] = value;
        }
        return obj;
    };

    const normalizeExpandRow = (expand, rowKey) =>{
        let colIndex = 0;
        let col;

        rowKey = (rowKey) ? rowKey : expand.key;

        if(expand.length){
            while (colIndex < expand.length) {
                col = _addCellProps(expand[colIndex]);

                col.key = rowKey + '_col_' + colIndex;
                col.index = colIndex;

                col = _addColContentKeys(col);

                colIndex++;
            }
        }
        return expand;
    };

    return {
        normalizeSourceTable,
        normalizeExpandRow,
        updateTableJSON
    };
});
