define(['react', 'reactproptypes', 'jquery', 'popover', 'kind', 'TableSortIcon', 'renderer', 'RenderItem', 'RenderIconMessage', 'guid', 'TableColumnHeader'], function (React, ReactPropTypes, $, popover, kind, TableSortIcon, renderer, RenderItem, RenderIconMessage, guid, TableColumnHeader) {
    class TableColumn extends React.Component {
        constructor (props) {
            super(props);

            // Private method binding
            this._onActionsToggleClick = this._onActionsToggleClick.bind(this);
            this._ignoreClick = this._ignoreClick.bind(this);
            this._onCellClick = this._onCellClick.bind(this);
            this._breakLine = this._breakLine.bind(this);
        }


        /////////////////////
        // Private methods //
        /////////////////////

        _breakLine (text) {
            if(text.indexOf("\n")== -1){
                return text;
            }
            else{
                let regex = /(\n)/g;

                return text.split(regex).map(function(line, index) {
                    return line.match(regex) ? <br key={"key_" + index} /> : line;
                });
            }
        };

        _onActionsToggleClick (evt) {
            const $button = $(evt.target);

            // Don't let the event bubble up to the row
            evt.stopPropagation();

            // Need to setup the popover
            if (!evt.target.classList.contains('cui-popover-toggle')) {
                // Set it up and display it immediately since the user has already clicked on the button once
                $.popover(
                        $button,
                        {
                            html: $button.parent().find('.feta-table-actions-wrapper').html(),

                            display: {
                                className: 'feta-table-actions-popover',
                            },
                            resizeMobile:false,
                        }
                    )
                    .show();

                $(document.body).on('click', this._ignoreClick);
            }
        }

        // Eat clicks outside of the popover
        _ignoreClick (evt) {
            if (!$(evt.target).closest('.cui-popover').length) {
                evt.preventDefault();
                evt.stopPropagation();

                $(document.body).off('click', this._ignoreClick);
            }
        }

        //Handle any clicks of selection elements within the
        _onCellClick(evt){
            const target = evt.target;
            const $target = $(target);

            if(this.props.hasExpandIndicator){
                this.props.onExpandableRowClick(this.props.parentExpandableRow, evt);
            }
            // Ignore clicks on inputs, buttons, and links
            else if (!$target.is('input, button, a')) {
                //FIXME: find the input using the `row` object and change its value programmatically
                $target.closest('td').find('input').first().click();
            }
        }

        ////////////
        // Render //
        ////////////

        render () {
            const sourceTable = this.props.source;
            let col = this.props.col;
            let colLabel;
            let elems = [];
            const cellCassNames = (col.cellProps && col.cellProps.className) ? col.cellProps.className.split(' ') : [];
            const contentClassNames = [];
            const isSmallSizeAndIsSelection = (this.props.isSmallSize && this.props.colIndex === 0 && sourceTable.selectionType);
            const smallHideLabel = (this.props.smallHideLabel)? this.props.smallHideLabel : false;
            let helpTag;
            let isControlColumn = false;

            if(col.cellProps){
                col.cellProps.onClick = this._onCellClick;
            }

            // Use the alternate, small-screen version of a column if one was provided
            if (this.props.isSmallSize && col.hasOwnProperty('smallSize')) {
                journal.log({type: 'error', owner: 'UI', module: 'TableColumn'}, 'Column should not have the deprecated `smallSize` property: ', col);
                col = col.smallSize;
            }

            // Header column
            if (this.props.isHeader) {
                return (
                    <TableColumnHeader
                        {...this.props}
                    />
                );
            }
            // Body or footer column
            else {
                const headerCol = sourceTable.head.rows[0].columns[this.props.colIndex];
                let displayHeaderCol = true;
                
                //Check headerCol for type control
                if(headerCol && headerCol.type && (headerCol.type == 'controls' || headerCol.type === 'control')){
                    isControlColumn = true;
                }

                //Check to hide header in responsive view                
                if(headerCol && headerCol.responsive && headerCol.responsive.hideHeader){
                    displayHeaderCol = false;
                }

                // Render the cell based on its content
                cellCassNames.push('feta-table-elem-td');

                // Add a label prefix for small screens
                if (this.props.isSmallSize && !smallHideLabel && displayHeaderCol) {
                    // Table has a selection column
                    if (isSmallSizeAndIsSelection) {
                        // This class enables us to layoyut the label & check box differently than normal columns
                        cellCassNames.push('feta-table-col-has-selection');
                    }
                    // Table has selection
                    else {
                        //FIXME: `<label for>` should match an `<input>`... or maybe this should just be a `<div>`? Can't remember if it's a true label for any particular element (CP 1/31/17)
                        if(headerCol && headerCol.text){
                            colLabel = (
                                <label
                                    className="feta-table-col-label"
                                    htmlFor="feta-table-col-label"
                                >
                                    {headerCol.text + ': '}
                                </label>
                            );
                        }
                        else if(headerCol && headerCol.contents && headerCol.contents.length > 0){


                            const colLabelContents = [];
                            // TODO: generate label from headers contents array.
                            headerCol.contents.forEach((col, c) => {
                                const cellItemSource = headerCol.contents[c];



                                if(cellItemSource.template){

                                    //Handle special templates here.

                                    if(cellItemSource.template == "helpIcon"){
                                        //Don't add it to the cell contents.
                                        helpTag = (
                                            <RenderItem
                                                source={cellItemSource}
                                                //inputOnly
                                                key={headerCol.key + this.props.rowIndex  + '-' + this.props.colIndex + '-' + c}
                                                colIndex={this.props.colIndex}
                                                rowIndex={this.props.rowIndex}
                                            />
                                        );

                                    }
                                    else{
                                        colLabelContents.push(
                                            <RenderItem
                                                source={cellItemSource}
                                                //inputOnly
                                                key={headerCol.key + this.props.rowIndex  + '-' + this.props.colIndex + '-' + c}
                                                colIndex={this.props.colIndex}
                                                rowIndex={this.props.rowIndex}
                                            />
                                        );
                                    }

                                }
                                else{
                                    colLabelContents.push(
                                        <RenderItem
                                            source={cellItemSource}
                                            //inputOnly
                                            key={headerCol.key + this.props.rowIndex  + '-' + this.props.colIndex + '-' + c}
                                            colIndex={this.props.colIndex}
                                            rowIndex={this.props.rowIndex}
                                        />
                                    );
                                }


                            });

                            colLabelContents.push(': ');

                            colLabel = (
                                <label
                                    className="feta-table-col-label"
                                    htmlFor="feta-table-col-label"
                                >
                                    {colLabelContents}
                                </label>
                            );
                        }
                    }
                }

                // Expand
                if (this.props.hasExpandIndicator) {
                    cellCassNames.push('feta-table-cell-expand-indicator');
                }

                //Control
                if(isControlColumn){
                    cellCassNames.push('cui-align-center feta-table-controls');
                }

                // Field
                if (col.contents) {

                    if(col.text && col.text != ""){

                        elems.push(
                            <span class="feta-data">{this._breakLine(col.text)}</span>
                        )
                    }


                    let isFieldContent = false;
                    col.contents.forEach((item, contentsIndex) => {
                        let onChange;

                        if (item.template === 'field') {
                            isFieldContent = true;

                            // Make sure it's a supported type for the table body
                            if (!/checkbox|radio|text|button|hidden|password|date|select/.test(item.type) && !/link/.test(item.template)) {
                                journal.log({type: 'error', owner: 'UI', module: 'TableColumn'}, 'Field type or template is not supported in the table body and will not be rendered. Type: "' + item.type + '", template: "' + item.template + '", column: ' + this.props.colIndex + '.\nItem: ', item, '\ncol: ', col);

                                return false;
                            }

                            if (this.props.isSmallSize || isSmallSizeAndIsSelection) {
                                // Tell `<RenderField/>` not to hide the `<label>` element like it normally would in a table
                                item.doNotHideLabel = true;

                                if (item.label && item.label.attributes) {
                                    item.label.attributes.className = (item.label.attributes.className || '').replace('cui-hide-from-screen', '');
                                    // item.label.attributes.className = (item.label.attributes.className || '').replace('cui-desc', 'feta-table-col-label');
                                }
                            }
                            else {
                                item.doNotHideLabel = false;
                            }

                            // Check box that selects the row
                            if (item.type === 'checkbox' && col.selectionType === 'row') {
                                onChange = this.props.onSelectRowChange;
                                onChange = (...args) => {
                                    this.props.onSelectRowChange({colIndex: this.props.colIndex, rowIndex: this.props.rowIndex, contentsIndex: contentsIndex}, ...args);
                                };
                            }
                            else if (item.type === 'checkbox' || item.type === 'radio') {
                                let inputOnChange = null;

                                if(item.input && item.input.attributes && item.input.attributes.onChange){
                                    inputOnChange = item.input.attributes.onChange;
                                }

                                onChange = this.props.onCheckChange;
                                onChange = (...args) => {
                                    this.props.onCheckChange({colIndex: this.props.colIndex, rowIndex: this.props.rowIndex, contentsIndex: contentsIndex}, ...args);    

                                    if(inputOnChange){
                                        //Slight timeout is needed for table data to process
                                        setTimeout(inputOnChange, 50);      
                                    }
                                };

                                if (this.props.isSmallSize){
                                    cellCassNames.push('feta-field-bool');
                                }
                            }
                            else {
                                let inputOnChange = null;

                                if(item.input && item.input.attributes && item.input.attributes.onChange){
                                    inputOnChange = item.input.attributes.onChange;
                                }
                                onChange = this.props.onInputChange;
                                onChange = (...args) => {
                                    this.props.onInputChange({colIndex: this.props.colIndex, rowIndex: this.props.rowIndex, contentsIndex: contentsIndex}, ...args);
                                    
                                    if(inputOnChange){
                                        //Slight timeout is needed for table data to process
                                        setTimeout(inputOnChange, 50);      
                                    }
                                };
                            }

                            // ## Disabling the creation and positioning of labels within the table. Label should styled and positioned via the renderer using the visibility and hasLayout property instead of requesting an input only element and generating a label.
                            // Create the label
                            // if (item.label && !this.props.isExpandChild) {
                            //     // Hide the label, except for radio buttons outside of the first column
                            //     if (!this.props.isSmallSize && !isSmallSizeAndIsSelection && (this.props.colIndex === 0 || item.type !== 'radio')) {
                            //         item.label.attributes = item.label.attributes || {};
                            //         item.label.attributes.className = (item.label.attributes.className ? item.label.attributes.className : '').replace(/\s*cui-hide-from-screen\s*/, '') + ' cui-hide-from-screen';
                            //     }

                            //     // Modify the label object so the renderer can handle it properly
                            //     const label = item.label;

                            //     label.type = 'label';

                            //     elems.push(
                            //         <RenderItem
                            //             source={label}
                            //             key={item.key + '_label'}
                            //             colIndex={this.props.colIndex}
                            //             rowIndex={this.props.rowIndex}
                            //             contentsIndex={contentsIndex}
                            //         />
                            //     );
                            // }
                        }

                        // Make sure there's an event handler that can be passed down to children
                        if (!onChange && item.contents && item.contents.length) {
                            onChange = (...args) => {
                                this.props.onInputChange({colIndex: this.props.colIndex, rowIndex: this.props.rowIndex, contentsIndex: contentsIndex}, ...args);
                            };
                        }


                        if(this.props.isExpandChild){
                            // Create the input
                            elems.push(
                                <div
                                    className='feta-table-col-data cui-inline-field'
                                    key={item.key}
                                >
                                    <RenderItem
                                        source={item}
                                        //inputOnly={!this.props.isExpandChild}
                                        onChange={onChange}
                                        colIndex={this.props.colIndex}
                                        rowIndex={this.props.rowIndex}
                                        contentsIndex={contentsIndex}
                                    />
                                    {helpTag}
                                </div>
                            );
                        }
                        else{
                            // When rendering multiple non-field contents, we want to display them in sequence without wrapping elements.
                            elems.push(
                                <RenderItem
                                    source={item}
                                    //inputOnly={!this.props.isExpandChild}
                                    onChange={onChange}
                                    colIndex={this.props.colIndex}
                                    rowIndex={this.props.rowIndex}
                                    contentsIndex={contentsIndex}
                                />
                            );

                            if(helpTag){
                                elems.push(helpTag);
                            }

                        }


                    });

                    //if the contents array is not field content, wrap the elements nad display the col label.
                    if(!isFieldContent){
                        elems = (
                            <div className="multiple-non-field-content feta-table-col-data">
                                {elems}
                            </div>
                            );
                    }
                    if(!isFieldContent){
                        cellCassNames.push('non-cell-output');
                    }

                    return (
                        <this.props.tagNames.td
                            {...col.cellProps}
                            className={cellCassNames.join(' ')}
                        >
                            {colLabel}
                            {elems}
                        </this.props.tagNames.td>
                    );
                }
                // Single element
                else if (col.template || col.type) {


                    if(col.type !== "footer"){
                        //Should be invalid condition. All template/types should be within contents array. Only valid property outside are attributes, style, and text.
                        journal.log({type: 'error', owner: 'UI', module: 'TableColumn'}, 'JSON object should be within contents array and not top level of column. Type: "' + col.type + '", template: "' + col.template + '", column: ' + this.props.colIndex + '.\ncol: ', col);
                    }



                    const contents = (
                        <RenderItem
                            source={col}
                            // inputOnly
                            key={col.key + this.props.rowIndex + '-' + this.props.colIndex}
                            colIndex={this.props.colIndex}
                            rowIndex={this.props.rowIndex}
                        />
                    );

                    return (
                        <this.props.tagNames.td
                            {...col.cellProps}
                            className={cellCassNames.join(' ')}
                        >

                            {
                                this.props.isSmallSize
                                    ?
                                        <div className="feta-table-col-data">
                                            <span className="feta-data">{contents} {helpTag}</span>
                                        </div>
                                    :
                                        contents
                            }
                        </this.props.tagNames.td>
                    );
                }
                // Interactive item(s) like menus
                else if (col.control) {

                    cellCassNames.push('cui-align-center feta-table-controls');

                    if (col.control.type) {
                        if (col.control.type === 'menu-select') {
                            return (
                                <this.props.tagNames.td
                                    {...col.cellProps}
                                    className={cellCassNames.join(' ')}
                                >

                                    <button
                                        type="button"
                                        className={'feta-table-actions-toggle' + (this.props.isSmallSize ? ' feta-table-col-data' : '')}
                                        onClick={this._onActionsToggleClick}
                                    >
                                        {col.control.label}
                                    </button>
                                    <div className="feta-table-actions-wrapper cui-hidden">
                                        <ul>
                                            {col.control.items.map((item) => {
                                                // Remove this conditional once it no longer logs problems (CP 1/31/17)
                                                if (!item.key) {
                                                    console.warn('item has no key: ', item, '\ncol: ', col);
                                                    item.key = guid();
                                                }

                                                if (item.template || item.type) {
                                                    return (
                                                        <li key={item.key}>
                                                            <RenderItem
                                                                source={item}
                                                                //inputOnly
                                                                colIndex={this.props.colIndex}
                                                                rowIndex={this.props.rowIndex}
                                                                isSmallSize={this.props.isSmallSize}
                                                            />
                                                        </li>
                                                    );
                                                }
                                                else {
                                                    return (
                                                        <li key={item.key}>
                                                            <a href={item.url}>
                                                                {item.text}
                                                            </a>
                                                        </li>
                                                    );
                                                }
                                            })}
                                        </ul>
                                    </div>
                                </this.props.tagNames.td>
                            );
                        }
                        else if (col.control.type === 'button') {
                            // No label, just the control
                            return (
                                <this.props.tagNames.td
                                    {...col.cellProps}
                                    className={cellCassNames.join(' ')}
                                >
                                    <a href={col.url} role="button" className={this.props.isSmallSize ? 'feta-table-col-data' : ''}>
                                        {col.text}
                                    </a>
                                </this.props.tagNames.td>
                            );
                        }
                        else {
                            journal.log({type: 'error', owner: 'UI', module: 'TableColumn'}, 'Invalid control type: ', col.control.type);

                            return null;
                        }
                    }
                    else {
                        journal.log({type: 'error', owner: 'UI', module: 'TableColumn'}, 'No control type: ', col.control);

                        return null;
                    }
                }
                // Icon
                else if (col.icon) {
                    let messageIcon = null;
                    let text = col.text;

                    contentClassNames.push('cui-align-center');

                    if (col.icon.type) {
                        if (col.icon.type === 'message') {
                            text = null;
                            messageIcon = (
                                <RenderIconMessage />
                            );
                        }

                        contentClassNames.push('feta-icon-' + col.icon.type);
                    }

                    if (col.icon.className) {
                        contentClassNames.push(col.icon.className);
                    }

                    // Linked icon
                    if (col.cellProps && col.cellProps.href) {
                        // With badge
                        if (!/undefined|null/.test(kind(col.icon.badge))) { // Need a complex test because the value may be falsy yet valid, e.g. `0`
                            elems.push(
                                <a
                                    href={col.cellProps.href}
                                    className={contentClassNames.join(' ')}
                                    data-badge={col.icon.badge}
                                    key={col.key + this.props.colIndex + '-icon'}
                                >
                                    {messageIcon}
                                    {text}
                                    {
                                        col.icon.hasOwnProperty('badge')
                                            ?
                                                <span className="feta-icon-badge">{col.icon.badge}</span>
                                            :
                                                null
                                    }
                                </a>
                            );
                        }
                        // No badge
                        else {
                            elems.push(
                                <a
                                    href={col.cellProps.href}
                                    className={contentClassNames.join(' ')}
                                    key={col.key + this.props.colIndex + '-icon'}
                                >
                                    {messageIcon}
                                    {text}
                                </a>
                            );
                        }
                    }
                    // Image
                    else if (col.icon.src) {
                        elems.push(
                            <img
                                src={col.icon.src}
                                className={contentClassNames.join(' ')}
                                key={col.key + this.props.colIndex + '-icon'}
                                alt={col.icon.alt}
                                title={text}
                            />
                        );
                    }

                    // Plain icon
                    if (!elems.length) {
                        elems.push(
                            <span
                                className={contentClassNames.join(' ')}
                                key={col.key + this.props.colIndex + '-icon'}
                            >
                                {messageIcon}
                                {text}
                            </span>
                        );
                    }

                    return (
                        <this.props.tagNames.td
                            {...col.cellProps}
                            className={cellCassNames.join(' ')}
                        >
                            {
                                //colLabel
                            }
                            {elems}
                        </this.props.tagNames.td>
                    );
                }
                // Plain or linked text
                else if (col.text || col.texts) {
                    const contents = col.texts || col.text;

                    if (col.cellProps && col.cellProps.href) {
                        return (
                            <this.props.tagNames.td
                                {...col.cellProps}
                                className={cellCassNames.join(' ')}
                            >
                                {colLabel}
                                <a
                                    href={col.cellProps.href}
                                    className={this.props.isSmallSize ? 'feta-table-col-data' : ''}
                                >
                                    <span className="feta-data">{this._breakLine(contents)}</span>
                                </a>
                            </this.props.tagNames.td>
                        );
                    }
                    // Plain text
                    else {
                        return (
                            <this.props.tagNames.td
                                {...col.cellProps}
                                className={cellCassNames.join(' ')}
                            >
                                {colLabel}
                                {
                                    this.props.isSmallSize
                                        ?
                                            <div className="feta-table-col-data">
                                                <span className="feta-data">{this._breakLine(contents)} {helpTag}</span>
                                            </div>
                                        :
                                            <span className="feta-data">{this._breakLine(contents)}</span>
                                }
                            </this.props.tagNames.td>
                        );
                    }
                }
                // Empty column
                else {

                	if(colLabel){
						return (
	                        <this.props.tagNames.td
	                            {...col.cellProps}
	                            className={cellCassNames.join(' ')}
	                        >
	                        	{colLabel}                        
	                        	{
                                    this.props.isSmallSize
                                        ?
                                            <div className="feta-table-col-data">
                                                <span className="feta-data"></span>
                                            </div>
                                        :
                                            <span className="feta-data"></span>
                                } 
	                        </this.props.tagNames.td>
	                    );
                    }

                    else{
                    	cellCassNames.push('feta-table-empty-cell');
						return (
	                        <this.props.tagNames.td
	                            {...col.cellProps}
	                            className={cellCassNames.join(' ')}
	                        >
	                        	{''}
	                        </this.props.tagNames.td>
	                    );
                    }
                }
            }
        }
    }

    TableColumn.propTypes = {
        source: ReactPropTypes.shape({}).isRequired,
        col: ReactPropTypes.shape({}).isRequired,
        isSmallSize: ReactPropTypes.bool,
        colIndex: ReactPropTypes.number,
        isHeader: ReactPropTypes.bool,
        onCheckChange: ReactPropTypes.func,
        onInputChange: ReactPropTypes.func,
        rowIndex: ReactPropTypes.number,
        hasExpandIndicator: ReactPropTypes.bool,
        onSelectRowChange: ReactPropTypes.func,
        isExpandChild: ReactPropTypes.bool,
        onExpandableRowClick: ReactPropTypes.func,
        parentExpandableRow: ReactPropTypes.shape({}),
        smallHideLabel: ReactPropTypes.bool

    };

    TableColumn.defaultProps = {
        isSmallSize: false,
        colIndex: 0,
        isHeader: false,
        onCheckChange: () => {},
        onInputChange: () => {},
        rowIndex: 0,
        hasExpandIndicator: false,
        onSelectRowChange: () => {},
        isExpandChild: false,
        onExpandableRowClick: () => {},
        parentExpandableRow: {},
        smallHideLabel: false
    };

    return TableColumn;
});
