/*jshint esversion: 6 */
/*global feta */
define(['table', 'guid'], function (table, guid) {
    // Public entry point which kicks off the request

    const init = () =>{
        //Add any needed polyfills.

        // Add for each to NodeList. Needed for IE11
        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = function (callback, thisArg) {
                thisArg = thisArg || window;
                for (var i = 0; i < this.length; i++) {
                    callback.call(thisArg, this[i], i, this);
                }
            };
        }
    };

    const _tableTypes = {
        "rset" :{ //Some tables are just 'rset'?

        },
        "checkBoxCollection":{ //Additional table property??

        },
        "form-table" : {

        },
        "form-table-condensed":{

        },
        "nav-table" :{

        },
        "radio-table": {

        },
        "form-table-expandable":{

        }
    };

    const convertClassPrefix = "ifct";

    const _tableClasses = {
        checkboxSelection:"checkBoxCollection",
        radioSelection: "radio-table",
        hideResponsiveFooter:"hideResponsiveFooter"
    };

    const objectIsEmpty = (obj) => {
        for(var key in obj){
            return false;
        }
        return true;
    };

    const classConversions = {
        "minWidth":"cui-width-icon",
        "clearField":"clearField",
        "hidden":"cui-hidden"
    };

    const styleFromClassConversions = {
    	"rt":"align-right",
    	"noWrap":"no-wrap",
    	"minWidth":"min-width",
    	"center":"align-center",
    	"moneyField":"currency",
    	"currency":"currency",    	
        "not-applicable":"not-applicable",
        "align-right":"align-right",
        "align-center":"align-center",
        "no-wrap":"no-wrap",
        "icon":"icon",
        "min-width":"min-width",
        "new-line":"new-line", 
        "copyValue":"copy-value",
        "clearField":"clear-field",
        "indented":"indent",
    };

    const imgFileToStyle = {
        "credits_claimed.png":"credits-claimed"
    };

    const convertClass = (classList) => {

        let classArray = (classList) ? classList.split(' '): [];
        for(let i=0; i < classArray.length; i++){

            if(classConversions[classArray[i]]){
                classArray[i] = classConversions[classArray[i]];
            }
        }

        return classArray.join(" ");
    };

    const styleFromClass = (classList) =>{
    	let styleList = "";
    	let classArray = (classList) ? classList.split(' '): [];

        for(let i=0; i < classArray.length; i++){
        	 if(styleFromClassConversions[classArray[i]]){
                if(styleList == ""){
					styleList += styleFromClassConversions[classArray[i]];
                }
                else{
                	styleList += ", " + styleFromClassConversions[classArray[i]];	
                }                	
            }
        }

    	return styleList;
    };

    //Pulls attrbutes from a html node and converts them to an attributes JSON object.
    const pullAttributes = (htmlElement, ignore = []) =>{
    	let jsonAttr = {};

        //Loop through each attribute and set the corisponding attribute within our defined json structure.
        if(!htmlElement.attributes || htmlElement.attributes.length <= 0){
            return [];
        }

        for(let i = 0; i < htmlElement.attributes.length; i++){

            let nodeAttr = htmlElement.attributes[i];

            //Check if attribute is listed in the ignore list.
            if(ignore.indexOf(nodeAttr.name) === -1){

                switch(nodeAttr.name){

                    case 'class':
                        jsonAttr.className = convertClass(nodeAttr.value);
                    break;

                    case 'required':
                    case 'selected':
                    case 'multiple':
                    // Do nothing.
                    break;

                    case 'checked':
                        // If any variation of the checked attribute is found, convert it to checked="checked"
                        jsonAttr[nodeAttr.name] = "checked";
                    break;

                    default:
                        jsonAttr[nodeAttr.name] = nodeAttr.value;
                    break;

                }
            }
        }

        //Return the new json attributes object if present or false.
        return jsonAttr;

    };

    const getNodeJSON = (htmlNode) =>{
        let nodeJSON = {};
        let nodeChildJSON = [];
        //Check for any child nodes, text content does not count as a child node.
        if(htmlNode.children && htmlNode.children.length > 0){
            /*DEBUG - START*/
            //TODO: Add recursive call if node has children', htmlNode
            /*DEBUG - END*/

            let childrenNodes = htmlNode.children;

            for(let i= 0; i < childrenNodes.length;i++){
                let childNode = childrenNodes[i];
                nodeChildJSON.push(getNodeJSON(childNode));
            }
        }

        switch(htmlNode.nodeType){
            case  Node.TEXT_NODE:
                let textContent = htmlNode.textContent.trim();

                if(textContent != ""){
                    nodeJSON = {"text":textContent};
                }
            break;

            case Node.ELEMENT_NODE:
                switch(htmlNode.tagName){

                    case 'A':
                        //Check if help tag.
                        if(htmlNode.classList.contains("icon-help") || htmlNode.classList.contains("help")){
                            let helpJSON = {
                                "template": "helpIcon"
                            };

                            //Set source if it is present in the old help tag.
                            let nodeHref = htmlNode.getAttribute("href");
                            if(nodeHref){
                                helpJSON.source = nodeHref.replace("#", "");
                            }

                            nodeJSON = helpJSON;
                        }
                        else if(htmlNode.classList.contains("copyValue")){
                            let copyButtonJSON = {
                                "type": "button",
                                "template": "field",
                                "input": {
                                    "attributes": {
                                        "type": "button",
                                        "name": "",                                        
                                    },
                                    "icon": "copy-value",
                                    "text": ""
                                }
                            };

                            copyButtonJSON.input.attributes = pullAttributes(htmlNode, ["style", "onclick"]);
                            copyButtonJSON.input.text = htmlNode.textContent.trim() || htmlNode.innerText.trim();
                            //Set source if it is present in the old help tag.
                           
                            nodeJSON = copyButtonJSON;
                        }
                        else if(htmlNode.classList.contains("calendar")){
                            break;
                        }
                        else{

                            //base link json
                            let linkJSON = {
                                "template": "link"
                            };

                            linkJSON.attributes = pullAttributes(htmlNode, ["style", "onclick"]);

                            linkJSON.text = (htmlNode.textContent != "") ? htmlNode.textContent.trim() : "";

                            //Bind onclick
                            let onclickContents = htmlNode.getAttribute('onclick');
                            if(onclickContents){
                                linkJSON.attributes.onClick = htmlNode.onclick;

                                if(onclickContents.indexOf("uiMethods") >= 0){

                                    let functionStart = onclickContents.indexOf('.');
                                    let functionEnd = onclickContents.indexOf('(');

                                    if(functionStart != -1 && functionEnd != -1 && ((functionEnd - functionStart) > 1) ){
                                        //Get function name;
                                        let uiMethodFunctionName = onclickContents.substring(functionStart + 1, functionEnd);

                                    }
                                    else{
                                        journal.log({type: 'error', owner: 'UI', module: 'iflow', submodule: 'tableToJSON'}, 'Invalid function assigned to onclick event.');
                                    }

                                }

                                /*DEBUG - START*/
                                //TODO: Add onclick event converter.
                                /*DEBUG - END*/
                            }

                            nodeJSON = linkJSON;
                        }

                    break;

                    case 'BUTTON':
                        let buttonJSON = {
                            "type": "button",
                            "template": "field",
                            "input": {
                                "attributes": {
                                    "type": "",
                                    "name": "",
                                    "title": ""
                                },
                                "text": ""
                            }
                        };
                        
                        buttonJSON.input.attributes = pullAttributes(htmlNode, ["style", "onclick", "tabindex"]);
                        buttonJSON.input.text = htmlNode.textContent.trim() || htmlNode.innerText.trim();

                        if(htmlNode.classList.contains("icon-delete")){
                            buttonJSON.input.icon = "trash-can";
                            buttonJSON.input.attributes.title = htmlNode.title || buttonJSON.input.text;
                        }

                        if(htmlNode.classList.contains("link")){
                            buttonJSON.input.style = "link";
                        }

                        //Bind onclick
                        let onclickContents = htmlNode.getAttribute('onclick');
                        if(onclickContents){
                            buttonJSON.input.attributes.onClick = htmlNode.onclick;

                            if(onclickContents.indexOf("uiMethods") >= 0){

                                let functionStart = onclickContents.indexOf('.');
                                let functionEnd = onclickContents.indexOf('(');

                                if(functionStart != -1 && functionEnd != -1 && ((functionEnd - functionStart) > 1) ){
                                    //Get function name;
                                    let uiMethodFunctionName = onclickContents.substring(functionStart + 1, functionEnd);

                                }
                                else{
                                    journal.log({type: 'error', owner: 'UI', module: 'iflow', submodule: 'tableToJSON'}, 'Invalid function assigned to onclick event.');
                                }

                            }

                            /*DEBUG - START*/
                            //TODO: Add onclick event converter.
                            /*DEBUG - END*/
                        }

                        nodeJSON = buttonJSON;

                    break;

                    case 'BR':

                        let breakJSON = {
                            "template":"output",
                            "text":"<br/>",
                            "attributes":{
                                "className":"break"
                            }
                        };
                        nodeJSON = breakJSON;

                        /*DEBUG - START*/
                        //TODO: Add better handling for br tags
                        /*DEBUG - END*/

                    break;

                    //Not sure how to handle spans and paragraphs tags encountered in table content. For now we are just returning as an output node.
                    /* jshint ignore:start */
                    case 'SPAN':
                        //Break for specialty icons. 
                        if(htmlNode.classList.contains("feta-icon-check")){
                            let title = (htmlNode.textContent);

                            nodeJSON = {
                                "contents": [
                                    {
                                        "template": "icon",
                                        "attributes": {
                                            "title": title
                                        },
                                        "icon": "check"
                                    }
                                ],
                                "style": "icon, align-center"
                            };
                            break;
                        }
                        else if(htmlNode.classList.contains("feta-icon-check-off")){
                            let title = htmlNode.textContent;

                            nodeJSON = {
                                "contents": [
                                    {
                                        "template": "icon",
                                        "attributes": {
                                            "title": title
                                        },
                                        "icon": "check-off"
                                    }
                                ],
                                "style": "icon, align-center"
                            };
                            break;
                        }  
                        else if(htmlNode.classList.contains("feta-icon-credits-claimed")){
                            let title = htmlNode.textContent;

                            nodeJSON = {
                                "contents": [
                                    {
                                        "template": "icon",
                                        "attributes": {
                                            "title": title
                                        },
                                        "icon": "credits-claimed"
                                    }
                                ],
                                "style": "icon, align-center"
                            };
                            break;
                        }  
                        else if(htmlNode.classList.contains("appended-text")){
                            break;
                        }  
                        else if(htmlNode.classList.contains("dataMask")){
                            break;
                        }  

                    /* jshint ignore:end */
                    case 'P':
                        let outputJSON = {
                            "template":"output"
                        };

                        outputJSON.text = (htmlNode.textContent != "") ? htmlNode.textContent.trim() : "";

                        if(nodeChildJSON.length > 0){
                            // /*DEBUG - START*/
                            // console.log("There are node children but we are outputing as html");
                            // console.log(nodeChildJSON);
                            // /*DEBUG - END*/
                        }

                        nodeJSON = outputJSON;

                    break;

                    case 'LABEL':
                    break;
                    case 'INPUT':
                        switch(htmlNode.type){
                            case 'text':{
                                let textJSON = {
                                    "type": "text",
                                    "template": "field",
                                    "input": {
                                        "attributes": {

                                            "type": "text",

                                        },
                                    },
                                };

                                let inputId = htmlNode.id.trim();

                                let inputLabel = document.querySelector('[for="'+inputId+'"]');

								//Check sibling nodes for prefix/suffix
								let prefix;
								let suffix = htmlNode.parentNode.querySelector('.appended-text');							

								if(suffix){
									let suffixContent = suffix.textContent.trim();
									
									if(suffixContent != ""){
										textJSON.suffix = {
											"text":suffixContent
										};
									}									
								}

                                let dateControl = htmlNode.parentNode.querySelector('.calendar');
                                if(dateControl){
                                    textJSON.type = 'date';
                                }


                                if(inputLabel){
                                    textJSON.label = {
                                        "attributes": {
                                        },
                                    };

                                    //Populate label JSON with properties of existing inputLabel
                                    textJSON.label.attributes = pullAttributes(inputLabel, ["style", "class"]);
                                    textJSON.label.text = inputLabel.textContent.trim();
                                    textJSON.label.attributes.for = inputId;

                                    // if hidden label
                                    if(inputLabel.classList.contains('cui-hide-from-screen') || inputLabel.classList.contains('hidden') || inputLabel.classList.contains('hideOffScreen') ){
                                        textJSON.label.visibility = "hidden";
                                        textJSON.label.hasLayout = false;                                                                    
                                    }
                                }

                                textJSON.input.attributes = pullAttributes(htmlNode, ["style", "class", "tabindex"]);

                                if(inputLabel && inputLabel.classList.contains('clearField')){
                                    
                                    if(textJSON.input){

                                        if(textJSON.input.style){
                                            textJSON.input.style += "," + "clear-field";
                                        }
                                        else{
                                            textJSON.input.style = "clear-field";
                                        }
                                    }
                                }

                                nodeJSON = textJSON;
                            } break;

                            case 'checkbox': {
                                let textJSON = {
                                    "type": "checkbox",
                                    "template": "field",
                                    "input": {
                                        "attributes": {

                                            "type": "checkbox",

                                        },
                                    },
                                };

                                let inputId = htmlNode.id.trim();

                                let inputLabel = document.querySelector('[for="'+inputId+'"]');

                                if(inputLabel){
                                    textJSON.label = {
                                        "attributes": {
                                        },
                                    };

                                    textJSON.label.attributes = pullAttributes(inputLabel, ["style", "class"]);
                                    textJSON.label.text = inputLabel.textContent.trim();
                                    textJSON.label.attributes.for = inputId;

                                     // if hidden label
                                    if(inputLabel.classList.contains('cui-hide-from-screen') || inputLabel.classList.contains('hidden') || inputLabel.classList.contains('hideOffScreen')  ){
                                        textJSON.label.visibility = "hidden";
                                        textJSON.label.hasLayout = false;                                                                   
                                    }
                                }

                                textJSON.input.attributes = pullAttributes(htmlNode, ["style"]);

                                nodeJSON = textJSON;
                            } break;

                            case 'radio': {
                                let textJSON = {
                                    "type": "radio",
                                    "template": "field",
                                    "input": {
                                        "attributes": {

                                            "type": "radio",

                                        },
                                    },
                                };

                                let inputId = htmlNode.id.trim();

                                let inputLabel = document.querySelector('[for="'+inputId+'"]');

                                if(inputLabel){
                                    textJSON.label = {
                                        "attributes": {
                                        },
                                    };

                                    textJSON.label.attributes = pullAttributes(inputLabel, ["style", "class"]);
                                    textJSON.label.text = inputLabel.textContent.trim();
                                    textJSON.label.attributes.for = inputId;

                                     // if hidden label
                                    if(inputLabel.classList.contains('cui-hide-from-screen') || inputLabel.classList.contains('hidden') || inputLabel.classList.contains('hideOffScreen')  ){
                                        textJSON.label.visibility = "hidden";
                                        textJSON.label.hasLayout = false;                                                                  
                                    }
                                }

                                textJSON.input.attributes = pullAttributes(htmlNode, ["style"]);

                                nodeJSON = textJSON;
                            } break;

                            case 'hidden':{
                                let hiddenJSON = {
                                    "type": "hidden",
                                    "template": "field",
                                    "input": {
                                        "attributes": {
                                            "id": "",
                                            "name": "",
                                            "value": "",
                                            "type": "hidden"
                                        },
                                    }
                                };

                                hiddenJSON.input.attributes = pullAttributes(htmlNode, ["style"]);

                                nodeJSON = hiddenJSON;
                            } break;

                            case 'button':{
                                let buttonJSON = {
                                    "type": "button",
                                    "template": "field",
                                    "input": {
                                        "attributes": {
                                            "type": "",
                                            "name": "",
                                            "title": ""
                                        },
                                        "text": ""
                                    }
                                };

                                buttonJSON.input.attributes = pullAttributes(htmlNode, ["style", "onclick", "tabindex"]);
                                buttonJSON.input.text = htmlNode.value;

                                if(htmlNode.classList.contains("icon-delete")){
                                    buttonJSON.input.icon = "trash-can";
                                    buttonJSON.input.attributes.title = htmlNode.title || buttonJSON.input.text;
                                }

                                //Bind onclick
                                let onclickContents = htmlNode.getAttribute('onclick');
                                if(onclickContents){
                                    buttonJSON.input.attributes.onClick = htmlNode.onclick;

                                    if(onclickContents.indexOf("uiMethods") >= 0){

                                        let functionStart = onclickContents.indexOf('.');
                                        let functionEnd = onclickContents.indexOf('(');

                                        if(functionStart != -1 && functionEnd != -1 && ((functionEnd - functionStart) > 1) ){
                                            //Get function name;
                                            let uiMethodFunctionName = onclickContents.substring(functionStart + 1, functionEnd);

                                        }
                                        else{
                                            journal.log({type: 'error', owner: 'UI', module: 'iflow', submodule: 'tableToJSON'}, 'Invalid function assigned to onclick event.');
                                        }

                                    }

                                    /*DEBUG - START*/
                                    //TODO: Add onclick event converter.
                                    /*DEBUG - END*/

                                }

                                nodeJSON = buttonJSON;

                            } break;

                            default:
                            journal.log({type: 'error', owner: 'UI', module: 'iflow', submodule: 'tableToJSON'}, 'html node definition not found for: ' + htmlNode.tagName + " type: "+ htmlNode.type);
                        }
                    break;

                    case 'SELECT':
                    	// {
                     //        "type": "select",
                     //        "template": "field",
                     //        "label": {
                     //            "text": "Pick your favorite color",
                     //            "attributes": {
                     //                "for": "E_TEST_SELECT"
                     //            },
                     //            "visibility": "hidden",
                     //            "hasLayout": false
                     //        },
                     //        "input": {
                     //            "attributes": {
                     //                "name": "E_TEST_SELECT",
                     //                "id": "E_TEST_SELECT",
                     //            },
                     //            "options": [
                     //                {
                     //                    "text": "Blue",
                     //                    "value": "blue",
                     //                },
                     //                {
                     //                    "text": "Red",
                     //                    "value": "red",
                     //                },
                     //                {
                     //                    "text": "Green",
                     //                    "value": "green",
                     //                },
                     //            ],
                     //            "value": "blue",
                     //        }
                     //    };

                    	let selectJSON = {
                            "type": "select",
                            "template": "field",
                            "input": {
                                "attributes": {
                                    "name": "",
                                    "id": "",
                                },
                                "options": [],
                                "value": "",
                            }
                        };

                        for(let i=0; i<nodeChildJSON.length;i++){
                        	if(nodeChildJSON[i].selected){
                        		selectJSON.input.value = nodeChildJSON[i].value;                        		
                        	}
                        	delete nodeChildJSON[i].selected;
                        }

                        selectJSON.input.options = nodeChildJSON;
						
						let selectID = htmlNode.id.trim();
						let selectLabel = document.querySelector('[for="'+selectID+'"]');
                        
                        if(selectLabel){
                            selectJSON.label = {
                                "attributes": {
                                },
                            };

                            selectJSON.label.attributes = pullAttributes(selectLabel, ["style", "class"]);
                            selectJSON.label.text = selectLabel.textContent.trim();
                            selectJSON.label.attributes.for = selectID;

                             // if hidden label
                            if(selectLabel.classList.contains('cui-hide-from-screen') || selectLabel.classList.contains('hidden') || selectLabel.classList.contains('hideOffScreen')  ){
                                selectJSON.label.visibility = "hidden";
                                selectJSON.label.hasLayout = false;                                                                    
                            }
                        }

                        selectJSON.input.attributes = pullAttributes(htmlNode, ["style"]);

                    	nodeJSON = selectJSON;

                    break;

                    case 'OPTION':
                    	let optionJSON =  {
                            "text": "",
                            "value": ""
                        };

                        optionJSON.text = (htmlNode.innerText) ? htmlNode.innerText : "";
                        optionJSON.value = (htmlNode.value) ? htmlNode.value : "";
                        optionJSON.selected = (htmlNode.selected) ? htmlNode.selected : false;

                        nodeJSON = optionJSON;

                    break;

                    case 'IMG':
                        if(htmlNode.classList.contains('expImg')){
                            //This is added automatically with expandable table functionality. 
                            break;
                        }
                        
                        let imageSrcPieces = htmlNode.src.split("/");
                        let imageIcon = imageSrcPieces[imageSrcPieces.length -1];

                        if(imgFileToStyle[imageIcon]){
                           let iconJSON = {
                                "template": "icon",
                                "attributes": {
                                    "title": htmlNode.title
                                },
                                "icon": imgFileToStyle[imageIcon]
                            };
                            nodeJSON = iconJSON;
                        }

                    break;                  

                    default:
                        journal.log({type: 'error', owner: 'UI', module: 'iflow', submodule: 'tableToJSON'}, 'html node definition not found for: ' + htmlNode.tagName);

                }

            break;

            default:
                journal.log({type: 'error', owner: 'UI', module: 'iflow', submodule: 'tableToJSON'}, 'Node definition not found for ' + htmlNode);
        }

        return nodeJSON;

    };

    const getExpandRowJSON = (expandHtml) =>{

        let expandContentJSON = [];
        let inputs = expandHtml.querySelectorAll('input');

        if(inputs.length == 1){
            let input = inputs[0];
            let inputJSON = getNodeJSON(input);

            if(inputJSON.label){
                inputJSON.label.hasLayout = true;
                inputJSON.label.visibility = true;
            }

            //Check if money wrapper is present
            if(expandHtml.querySelector('.moneyField')){
                if(inputJSON.input.style){
                    if(!inputJSON.input.style.indexOf('currency')){
                        inputJSON.input.style += " currency";
                    }
                }
                else{
                    inputJSON.input.style = "currency";
                }
            }

            //Check if itag is present. 
            let helpTag = expandHtml.querySelector('.help');
            if(helpTag){
                let helpTagJSON = getNodeJSON(helpTag);
                inputJSON.controls = [helpTagJSON];
            }

            expandContentJSON.push(inputJSON);        
        }
        else if(inputs.length>1){
            inputs.forEach((input, inputsIndex) =>{                    
                let inputJSON = getNodeJSON(input);
            });        
        }

        let expandRowWrapperJSON = {           
            "type": "column",
            "template": "grid",
            "contents": expandContentJSON            
        };

        return expandRowWrapperJSON;

    };

    const parseNode = (node) =>{
        let nodeJSON = {};

        if(node.children){
            //children json
            let nodeChildrenContentsJson = [];
            let nodeChildren = node.children;
            nodeChildren.forEach((childNode, n) => {
                nodeChildrenContentsJson.push(parseNode(childNode));
            });

            nodeJSON.contents = nodeChildrenContentsJson;
        }
        else {
            //Get node contents.
        }

        return nodeJSON;
    };

    const htmlToJSON = (htmlElement) =>{
  
        let tableJSON = {
            "container":"",
            "title":"",
            "body":{
                "rows": []
            },
            "head": {
                "rows": []
            },
            "foot":{
                "rows": []
            }
        };

        let tableOptions = {};

        let htmlRset = htmlElement.querySelector('.rset');

        let htmlTable = htmlRset.querySelector("table");

        let htmlHead = htmlTable.querySelector("thead");
        let htmlBody = htmlTable.querySelector("tbody");
        let htmlFoot = htmlTable.querySelector("tfoot");

        let tableStyleIndexes = {
            currency:[],
        };

        let tableId = (htmlTable.id) ? htmlTable.id : guid();

        tableJSON.id = tableId;

        let containerID = tableId + "_iflow_conv";
        tableJSON.container = "#"+containerID;

        let tablePrimarySet = false;        

        //Set any special flags based on table type.
        //Is selection table?
        if(htmlRset.classList.contains(_tableClasses.checkboxSelection)){
            tableOptions.selectable = true;
            tableJSON.selectionType = "multiple";
            tableJSON.selectable = true;
        }
        else if(htmlRset.classList.contains(_tableClasses.radioSelection)){
            tableOptions.selectable = true;
            tableJSON.selectionType = "single";
            tableJSON.selectable = true;
        }   

        //Check for any special style/configurations
        if(htmlRset.classList.contains(_tableClasses.hideResponsiveFooter)){
            if(tableJSON.renderStyle){
                tableJson.renderStyle.hideFooter = true;
            }else{
                tableJSON.renderStyle = {
                    "hideFooter" : true
                };
            }
        }

        let htmlElementChildren = htmlElement.children;
        if(htmlElementChildren.length > 1){
            let headerControlJSON = {
                "contents":[
                    {
                    "type": "search",
                    "template": "composite",
                    "parts":{}
                    }
                ]
            };

            //process header row. assume add composite. 
            if(htmlElementChildren[0].classList.contains('linePn')){

                let labelElem = htmlElementChildren[0].querySelector('.labelPn label');
                if(labelElem){

                    let labelJSON = {
                        "template": "field",
                        "label": {
                            "attributes": {
                                "for": labelElem.htmlFor
                            },
                            "text": labelElem.textContent
                        }
                    };
                    headerControlJSON.contents[0].parts.label = labelJSON;                    
                }

                let dataWrapper = htmlElementChildren[0].querySelector('.dataPn');

                if(dataWrapper.children.length > 0){
                    for(let i=0; i<dataWrapper.children.length; i++){
                        let childElem = dataWrapper.children[i];
                        let json = getNodeJSON(childElem);

                        if(json.type == "button"){
                            headerControlJSON.contents[0].parts.button = json;
                        }
                        else{
                            headerControlJSON.contents[0].parts.text = json;
                        }
                    }
                }

                if(Object.keys(headerControlJSON.contents[0].parts).length > 0){
                    tableJSON.headerControls = headerControlJSON;
                    htmlElementChildren[0].parentNode.removeChild(htmlElementChildren[0]);
                }
            }            
        }

        //Are there any footer controls
        let htmlFooterControl = htmlRset.querySelector(".tableBtnGroupBelowNoFooter, .tableBtnGroupBelow");        

        if(htmlFooterControl){
            let htmlRemoveButton = htmlFooterControl.querySelector('.iflow-convert-table-footer-remove');
            if(htmlRemoveButton){
                let itemName = (htmlRemoveButton.dataset.iflowConvertTableItemname) ? htmlRemoveButton.dataset.iflowConvertTableItemname : "";
                let removeFunction =  (htmlRemoveButton.dataset.iflowConvertTableRemovefunction) ? htmlRemoveButton.dataset.iflowConvertTableRemovefunction : "";
                let noItemsSelected =  (htmlRemoveButton.dataset.iflowConvertTableNoitemsselected) ? htmlRemoveButton.dataset.iflowConvertTableNoitemsselected : "";

                if(itemName != "" || removeFunction != ""){
                    let removeJSON = {
                        "itemName": itemName,
                        "removeFunction": removeFunction,
                        "noItemsSelected": noItemsSelected
                    };

                    if(tableJSON.footerControls){
                        tableJSON.footerControls.remove = removeJSON;
                    }else{
                        tableJSON.footerControls = {
                            remove : removeJSON
                        };
                    }
                }
            }else{
                //try to manually parse remove button. 
                let footerInputs = htmlFooterControl.querySelectorAll('input[type=button]');

                footerInputs.forEach((input, inputIndex)=>{
                    if(input.title == "Remove" || input.value == "Remove"){
                        let removeJSON = {
                            // "itemName": itemName,
                            // "removeFunction": removeFunction,
                            // "noItemsSelected": noItemsSelected
                        };

                        let onclickString = input.getAttribute("onclick");

                        if(onclickString.indexOf('remove(') != -1){
                            /* jshint ignore:start */
                            onclickString = onclickString.replace("javascript:uiMethods.selectedItems.remove(", "");
                            /* jshint ignore:end */
                            
                            onclickString = onclickString.replace(/{/g, "");
                            onclickString = onclickString.replace(/\}\);/g, "");

                            let onclickStringParts = onclickString.split(',');
                            
                            onclickStringParts.forEach((part, partIndex)=>{
                                part = part.replace(/ /g, "");
                                part = part.replace(/:/g, "");
                                part = part.replace(/'/g, "");

                                if(part.indexOf("sItemName") > -1){
                                    removeJSON.itemName = part.replace("sItemName", "");
                                }
                                else if(part.indexOf("oFunction") > -1){
                                    removeJSON.removeFunction = part.replace("oFunction", "");   
                                }
                            });
                        
                            if(tableJSON.footerControls){
                                tableJSON.footerControls.remove = removeJSON;
                            }else{
                                tableJSON.footerControls = {
                                    remove : removeJSON
                                };
                            }
                        }
                    }
                });               
            }
        }

        let htmlLegend = htmlRset.querySelector('.legend');
        if(htmlLegend){
            
            let legendJSON = {
                "contents":[]
            };

            let legendIcons = htmlLegend.querySelectorAll('.feta-icon');

            if(legendIcons.length > 0){
                legendIcons.forEach((icon, iconIndex)=>{
                    let legendItemJSON = {
                        "contents":[]
                    };

                    let iconType = "";
                    let iconText = icon.textContent;

                    const iconClasses = ["feta-icon-check", "feta-icon-check-off", "feta-icon-delete", "feta-icon-credits-claimed", "feta-icon-table-expand", "feta-icon-table-collapse", "feta-icon-table-expand-view", "feta-icon-table-collapse-view", "feta-icon-required", "feta-icon-error", "feta-icon-warning", "feta-icon-informational"];

                    iconClasses.forEach((iconClass, iconClassIndex) =>{                    
                        if(icon.classList.contains(iconClass)){
                            iconType = iconClass.split('feta-icon-')[1];                                              
                        }
                    });

                    if(iconType){
                        let iconJSON = {
                            "template": "icon",
                            "attributes": {
                                "title": iconText
                            },
                            "icon": iconType
                        };

                        legendItemJSON.contents.push(iconJSON);
                    }

                    if(iconText){
                        legendItemJSON.contents.push({"text": "- " + iconText});
                    }

                    legendJSON.contents.push(legendItemJSON);
                });

                tableJSON.legend = legendJSON;
            }
            else{
                //No legend icons were found, check for images and process each row manually. 

                let legendRows = htmlLegend.querySelectorAll('li');
               
                legendRows.forEach((legendRow, iconIndex)=>{
                    let legendItemJSON = {
                        "contents":[]
                    };

                    let rowImage = legendRow.querySelector('img');
                    
                    if(rowImage){
                        let imageSrcPieces = rowImage.src.split("/");
                        let imageIcon = imageSrcPieces[imageSrcPieces.length -1];

                        if(imgFileToStyle[imageIcon]){
                    

                           let iconJSON = {
                                "template": "icon",
                                "attributes": {
                                    "title": rowImage.title
                                },
                                "icon": imgFileToStyle[imageIcon]
                            };

                            legendItemJSON.contents.push(iconJSON);
                        }
                    }

                    if(legendRow.textContent){
                        legendItemJSON.contents.push({"text": legendRow.textContent});
                    }

                    if(rowImage || legendRow.textContent){
                        legendJSON.contents.push(legendItemJSON);
                    }

                });

                tableJSON.legend = legendJSON;
            }
        }


        let htmlExpandRow = htmlRset.querySelector('.expandRowsFunctions');
        if(htmlExpandRow){
            let expandRows = htmlExpandRow.querySelector('.feta-icon-table-expand');
            let collapseRows = htmlExpandRow.querySelector('.feta-icon-table-collapse');

            let expandView = htmlExpandRow.querySelector('.feta-icon-table-expand-view');
            let collapseView = htmlExpandRow.querySelector('.feta-icon-table-collapse-view');

            if(expandRows || collapseRows || expandView || collapseView){

                if(expandRows || collapseRows){
                    if(tableJSON.renderStyle){
                        tableJSON.renderStyle.expandControls = true;
                    }else{
                        tableJSON.renderStyle = { "expandControls" : true};
                    }
                }

                if(expandView || collapseView){
                                        
                    if(!tableJSON.switchTo){
                        tableJSON.switchTo = { };
                    }
                }
            }

            //May not have been converted from image tags. process each row. 
            else if(htmlExpandRow.querySelector('img')){
                let expandItems = htmlExpandRow.querySelectorAll('li');
                let collapsePresent = false;
                let colViewPresent = false;
                let expViewPresent = false;

                expandItems.forEach((controlCol, iconIndex)=>{

                    let controlImage = controlCol.querySelector('img');

                    if(controlImage){
                        let controlImageSrcPieces = controlImage.src.split("/");
                        let controlImageIcon = controlImageSrcPieces[controlImageSrcPieces.length -1];

                        switch(controlImageIcon){
                            case 'collapseAll.gif':
                                collapsePresent = true;
                            break;

                            case 'expandAll.gif':
                                collapsePresent = true;
                            break;

                            case 'colView.gif':
                                colViewPresent = true;
                            break;

                            case 'expView.gif':
                                expViewPresent = true;
                            break;

                            default:
                        }
                    }
                });

                if(collapsePresent){
                    if(tableJSON.renderStyle){
                        tableJSON.renderStyle.expandControls = true;
                    }else{
                        tableJSON.renderStyle = { "expandControls" : true};
                    }
                }

                if(expViewPresent || colViewPresent){
                                        
                    //"switchTo": {
                    //     "expanded":
                    //     "url": "#",
                    //     "switchFunction":"functionName"
                    // }
                    if(!tableJSON.renderStyle){
                        tableJSON.renderStyle = {
                            "switchTo":{}
                        };
                    }
                    else if(!tableJSON.renderStyle.switchTo){
                        tableJSON.renderStyle.switchTo = { };
                    }

                    if(expViewPresent){
                        tableJSON.renderStyle.switchTo.expanded = false;
                    }
                    else{
                        tableJSON.renderStyle.switchTo.expanded = true;
                    }



                    if(window.toggleViewPage && typeof window.toggleViewPage === "function"){                                
                        tableJSON.renderStyle.switchTo.switchFunction = 'toggleViewPage';                      
                    }
                }
            }
        }

        //Process Table Head
        let htmlHeadRow = htmlHead.querySelector('tr');
        if(htmlHeadRow){

            let htmlHeadColumns = htmlHeadRow.querySelectorAll('th');

            let rowJSON = {"columns":[]};

            htmlHeadColumns.forEach((column, columnIndex) => {
                let columnClasses = column.classList;

                let columnChildren = column.childNodes;

                let columnJSON = {"contents":[], "attributes":{}};

                //These attributes will override any pulled from column html. Use these when setting options flagged by convert-classes.
                let additionalAttributes = {};

                if(tableOptions.selectable && columnIndex == 0){
                    //Set selectable options here using header row and then skip the rest of the column rendering
                    let selectAllCheckbox = column.querySelector('input[type="checkbox"]');

                    if(selectAllCheckbox){
                         tableJSON.selectAll = true;
                    }

                    return;
                }



                //Detect primary column
                if(columnClasses.contains('table-primary')){

                    if(!columnJSON.responsive){
                        columnJSON.responsive = {
                            "type":"primary"
                        };
                    }
                    else{
                        columnJSON.responsive.type += " primary ";    
                    }                   

                    tablePrimarySet = true;
                }

                //Detect secondary column
                if(columnClasses.contains('table-secondary')){

                    if(!columnJSON.responsive){
                        columnJSON.responsive = {
                            "type":"secondary"
                        };
                    }
                    else{
                        columnJSON.responsive.type += " secondary ";    
                    }                    
                }

                //Detect responsive hidden
                if(columnClasses.contains('ift-hide-responsive')){

                    if(!columnJSON.responsive){
                        columnJSON.responsive = {
                            "hideHeader":true
                        };
                    }
                    else{
                        columnJSON.responsive.hideHeader = true;    
                    }                    
                }

                if(columnClasses.contains(convertClassPrefix+'-column-sort')){
                    additionalAttributes["data-sort"] = true;
                    columnClasses.remove(convertClassPrefix+'-column-sort');
                }

                //Apply general column attributes
                columnJSON.attributes = pullAttributes(column, ["scope", "title", "class"]);

                columnJSON.attributes = Object.assign(columnJSON.attributes, additionalAttributes);

                if(columnClasses.contains('hidden')){
                    if(columnJSON.attributes.className){
                        columnJSON.attributes.className += ' cui-hidden';
                    }else{
                        columnJSON.attributes.className = 'cui-hidden';    
                    }                    
                }

                columnChildren.forEach((childNode, childNodeIndex)=>{

                    // TODO: Call function to recursivly parse node here.
                    let nodeJSON = getNodeJSON(childNode);

                    if(nodeJSON.text && nodeJSON.text.indexOf("$") >= 0){
                        tableStyleIndexes.currency.push(columnIndex);
                    }

                    if(!objectIsEmpty(nodeJSON)){
                        columnJSON.contents.push(nodeJSON);
                    }

                });

               rowJSON.columns.push(columnJSON);

            });

            tableJSON.head.rows.push(rowJSON);

        }

        //Process Table Foot
        let htmlFootRow = (htmlFoot) ? htmlFoot.querySelector('tr') : undefined;
        if(htmlFootRow){
            let htmlFootColumns = htmlFootRow.querySelectorAll('td');

            let footerRow = {
                "columns":[]
            };

            let totalColSpan = 0;

            htmlFootColumns.forEach((column, columnIndex) => {
                let jsonColumn = {"contents":[]};
                let columnChildren = column.childNodes;
                let columnClasses = column.classList;

                let colspan = parseInt(column.getAttribute("colspan"));

                let isHeaderRow = (columnClasses.contains('rowHeader') ? true:false);


                if(tableOptions.selectable && columnIndex == 0){
                    
                    //Set selectable options here using header row and then skip the rest of the column rendering
                    
                    if(colspan && colspan > 1){
                        colspan = colspan -1;
                    }
                    else{
                        return;
                    }
                }

                //Determine colspan if  any. If colspan is used, push that many empty rows to the columns array.
                // let colspan = parseInt(column.getAttribute("colspan"));
                if(colspan){
                    totalColSpan += colspan;
                    for(let i = 1; i < colspan; i++){
                        footerRow.columns.push({});
                    }
                }

                //Set columnOffset to account for any colspan used.
                let columnOffset = 0;

                if(totalColSpan > 0){
                    columnOffset = totalColSpan - 1;
                }

                columnChildren.forEach((childNode, childNodeIndex)=>{

                    // TODO: Call function to recursivly parse node here.
                    let nodeJSON = getNodeJSON(childNode);

                    if(!objectIsEmpty(nodeJSON)){
                        jsonColumn.contents.push(nodeJSON);
                    }

                });

                jsonColumn.style = styleFromClass(column.className); 

                if(isHeaderRow){
                     jsonColumn.style = (jsonColumn.style) ? jsonColumn.style + ", align-right" : "align-right";
                }

                if(!isHeaderRow && (tableStyleIndexes.currency.indexOf(columnIndex + columnOffset) >= 0) && (jsonColumn.style.indexOf("currency")<0)){
                    jsonColumn.style = (jsonColumn.style) ? jsonColumn.style + ", currency" : "currency";
                }

                if(!isHeaderRow && columnClasses.contains("noWrap") && columnClasses.contains("hasBorder")){
                    jsonColumn.style = (jsonColumn.style) ? jsonColumn.style + ", currency" : "currency";   
                }

                 //Check if it is a pure text node
                if(jsonColumn.contents.length == 1){
                	let keys = Object.keys(jsonColumn.contents[0]);

                	if(keys.length == 1 && keys[0] == "text"){
                		jsonColumn.text = jsonColumn.contents[0].text;
                		delete jsonColumn.contents;
                	}
                }

                footerRow.columns.push(jsonColumn);

            });

            tableJSON.foot.rows.push(footerRow);
        }

        //Process Table Body
        if(htmlBody){

            let htmlBodyRows = htmlBody.querySelectorAll('tr');
            let emptyRowCount = 0;


            htmlBodyRows.forEach((row, rowIndex) =>{
                

                if(row.classList.contains("ignoreRow")){
                    let expandContent = row.querySelector(".expandedRow");
                  
                      if(expandContent){
                        
                        let expandContentJSON = [];      
                        let expandContentRows = expandContent.querySelectorAll('.linePn');

                        if(expandContentRows){

                            expandContentRows.forEach((expandContentRow, columnIndex) => {
                                let nodeJSON = getExpandRowJSON(expandContentRow);
                                if(nodeJSON){
                                    expandContentJSON.push(nodeJSON);    
                                }                                
                            });
                        }

                        if(expandContentJSON.length > 0){
                            tableJSON.body.rows[tableJSON.body.rows.length-1].expand = {
                                "contents" : [
                                    {
                                        "type": "row",
                                        "template": "grid",
                                        "contents": expandContentJSON
                                    }
                                ]
                            };
                        }
                    }
                }
                else{
                    let htmlBodyColumns = row.querySelectorAll('td');
                    let rowJSON = {"columns":[]};

                    let emptyColumns = 0;

                    if(row.classList && row.classList.contains('section-header')){
                        if(rowJSON.style){
                            rowJSON.style += ", header";
                        }
                        else{
                            rowJSON.style = "header";
                        }
                    }

                    htmlBodyColumns.forEach((column, columnIndex)=>{

                        let columnChildren = column.childNodes;
                        let columnClasses = column.classList;

                        let columnJSON = {"contents":[], "attributes":{}};

                        if(tableOptions.selectable && columnIndex == 0){
                            //Set selectable options here using body row and then skip the rest of the column rendering

                            rowJSON.selection = {};

                            //check for input selected.
                            let checked = column.querySelector('input[type="checkbox"]:checked');
                            if(checked){
                                rowJSON.selection.checked = true;

                                //get key if exists.
                                if(checked.id){
                                    rowJSON.key = checked.id;
                                }
                            }

                            let selectedRadio = column.querySelector('input[type="radio"]:checked');
                            if(selectedRadio){
                                rowJSON.selection.checked = true;

                                //get key if exists.
                                if(selectedRadio.id){
                                    rowJSON.key = selectedRadio.id;
                                }
                            }

                            //Empty
                            let checkExists = column.querySelector('input[type="checkbox"]');

                            let radioExists = column.querySelector('input[type="radio"]');
                            //get key if exists.
                            if(checkExists && checkExists.id){
                                rowJSON.key = checkExists.id;
                            }
                            if(radioExists && radioExists.id){
                                rowJSON.key = radioExists.id;
                            }

                            if(!checkExists && !radioExists){
                                rowJSON.selection.empty = true;
                            }

                            return;
                        }

                        //Apply general column attributes
                        columnJSON.attributes = pullAttributes(column, "class");                    

                        columnJSON.style = styleFromClass(column.className);                   
                        
                        if(columnClasses.contains('hidden')){
                            if(columnJSON.attributes.className){
                                columnJSON.attributes.className += ' cui-hidden';
                            }else{
                                columnJSON.attributes.className = 'cui-hidden';    
                            }                    
                        }

                        //Apply special attributes such as currency.
                        if((tableStyleIndexes.currency.indexOf(columnIndex) >= 0) && (columnJSON.style.indexOf("currency")<0)){
                            columnJSON.style = (columnJSON.style) ? columnJSON.style + ", currency" : "currency";
                        }

                        columnChildren.forEach((childNode, childNodeIndex)=>{

                            // TODO: Call function to recursivly parse node here.
                            let nodeJSON = getNodeJSON(childNode);

                            if(!objectIsEmpty(nodeJSON)){
                                columnJSON.contents.push(nodeJSON);
                            }

                        });

                        //Check if it is a pure text node
                        if(columnJSON.contents && columnJSON.contents.length == 1){
                        	let keys = Object.keys(columnJSON.contents[0]);

                        	if(keys.length == 1 && keys[0] == "text"){
                        		columnJSON.text = columnJSON.contents[0].text;
                        		delete columnJSON.contents;
                        	}
                        }

                        if(columnJSON.contents && columnJSON.contents.length == 0){
                            emptyColumns++;
                        }

                        rowJSON.columns.push(columnJSON);
                    });

                    if(rowJSON.columns.length == emptyColumns){
                        emptyRowCount++;
                    }



                    tableJSON.body.rows.push(rowJSON);
                }
            });

            if(tableJSON.body.rows.length == 1 && emptyRowCount == 1){
                tableJSON.body.rows = [];
            }

        }

        //Process Table Attributes:
        //Check for primary;

        if(!tablePrimarySet){
            if(tableJSON.head.rows[0].columns[0].responsive){
                tableJSON.head.rows[0].columns[0].responsive.type = "primary";
            }
            else{
                tableJSON.head.rows[0].columns[0].responsive = {
                    "type":"primary"                    
                };
            }
        }

        //Table title
        let htmlTitle = htmlElement.querySelector(".rset_title");

        if(htmlTitle){
            tableJSON.title = (htmlTitle.innerHTML) ? htmlTitle.innerHTML : '';    
        }        

        //Additional table styles
        let htmlTableDataStyles = htmlTable.dataset.ifctStyle;
        if(htmlTableDataStyles){
            if(tableJSON.style){
                tableJSON.style += "," + htmlTable.dataset.ifctStyle;
            }
            else{
                tableJSON.style = htmlTable.dataset.ifctStyle;
            }
        }

        //Remove original table source
        htmlRset.parentNode.removeChild(htmlRset);

        //Create new table
        let containerDiv = document.createElement('div');

        containerDiv.id = containerID;
        containerDiv.classList.add("feta-converted-table");
        htmlElement.appendChild(containerDiv);     

        if(fwData.tables){
            fwData.tables.push(tableJSON);            
        }
               
        table.init(tableJSON);
    };

    /**
     * @public
     *
     * Public API
    */
    return {
        init,
        htmlToJSON,
    };
});
