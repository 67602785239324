/**
 * feta module
 *
 * @description          Main module for Feta ({F}ramework for {E}xternal {T}ax {A}pplications)
 * @version              0.1.0
 *
 * @return {object}      Public API for module
 */
/*jshint esversion: 6 */
/*global feta */
define(['jquery', 'cui', 'ols', 'iflow', 'dynamicBlock', 'datepicker', 'analytics', 'navigation', 'tooltip', 'popover', 'table', 'tree', 'renderer', 'guid', 'userMessage', 'fetch', 'functionCall', 'form', 'requestMap', 'inbox', 'reactdom', 'inputmask', 'universalNav', 'buttonMenu', 'clickBlocker', 'validation', 'progressBar', 'listBox'], function ($, cui, ols, iflow, dynamicBlock, datepicker, analytics, navigation, tooltip, popover, table, tree, renderer, guid, userMessage, fetch, functionCall, form, requestMap, inbox, ReactDOM, inputmask, universalNav, buttonMenu, clickBlocker, validation, progressBar, listBox) {
    // Private API
    const _priv = {};

    // App and page information
    const pageInfo = {
        appPlatform: '',
        appCode: '',
        appTitle: '',
        pageCode: '',
        pageTitle: '',
    };

    // Browser/device support and info
    // Note: only the necessary properties are filled in as needed, so you cannot count on the information being populated (e.g. `name` will not always be defined)
    const browser = {
        name: '',
        os: '',
        supports: {},
    };

    // Cached element references
    let $uNavBanner;
    let $bannerWrapper;
    let agencyBannerUserPopover;
    let agencyBannerPrefsPopover;

    ////////////////////
    // Public methods //
    ////////////////////

    /**
     * @private
     * Initializes module
     */
    const _init = () => {
        // console.log('[init] ' + MODULE_NAME + ' version ' + VERSION + ' initialization');

        // Enable development logging
        if (document.domain === 'localhost' || document.location.protocol === 'file:') {
            journal.print();
            journal.live();
        }

        if (typeof fwData !== 'object') {

            if(typeof fetaData == 'object'){
                fwData = fetaData;
                journal.log({type: 'warn', owner: 'UI', module: 'feta', submodule: 'init'}, 'Depreciated fetaData object used, update to fwData');
        }
            else{
                fwData = {};
            }
        }

        if (typeof uiData !== 'object') {
            uiData = {};
        }

        // Gather app & page information
        _priv.pageInfoSetup();

        //Set page scripts
        var scripts = require.s.contexts._.config.paths;

        //Clear out any existing pageScripts
        feta.pageScripts = false;
        if (fwData.page && fwData.page.id && scripts[fwData.page.id]) {

            cui.load(fwData.page.id, function (script) {
                let pageScript;

                // Expose all the scripts
                feta.pageScripts = script;
                pageScript = script;

                if (pageScript.init) {

                    pageScript.init();

                    journal.log({ type: 'info', owner: 'UI', module: 'feta', func: 'init' }, 'Page script for ' +fwData.page.id+ ' was executed!');
                }

                if(pageScript.uiData){
                    _priv.applyUiData(pageScript.uiData);
                }

                _priv.initalizePage();

            });
        }
        else{
            _priv.initalizePage();
        }
    };

    /////////////////////
    // Private methods //
    /////////////////////

    _priv.applyUiData = (uiData) =>{
        let updateTables = (tablesArray) =>{
            let fwTables = fwData.tables;

            tablesArray.forEach((table)=>{
                for(let i = 0; i < fwTables.length; i++){
                    if(table.id === fwTables[i].id){
                        fwTables[i] = Object.assign(fwTables[i], table);
                    }
                }
            });
        };

        //Update table data if present in both fwData and uiData
        if(fwData.tables && Array.isArray(fwData.tables) && fwData.tables.length > 0){
            if(uiData.tables && Array.isArray(uiData.tables) && uiData.tables.length > 0){
                updateTables(uiData.tables);
            }
        }

    };

    _priv.initalizePage = () =>{
        // Render page elements
        renderer.init(fwData);

        ols.init();
        iflow.init();

        // Setup header and banners
        _priv.headerSetup();


        // Insert uNav
        // if (!$uNavBanner && document.location.protocol !== 'file:') {
            $uNavBanner = $('#nygov-universal-navigation');

            //Only initalize banner once.
            if( $uNavBanner.length && $uNavBanner.html().trim().length === 0){
                universalNav.init();
            }

            // This NY.gov-provided script will automatically load both the banner and the footer
            // Make sure this doesn't get run more than once (e.g. if `feta.init` is called multiple times) otherwise we'll end up with multiple banners and footers
            // if (!window._NY && $uNavBanner.length && $uNavBanner.html().trim().length === 0) {
            //     window._NY = {
            //         HOST: 'static-assets.ny.gov',
            //         BASE_HOST: 'www.ny.gov',
            //         hideSettings: false,
            //         hideSearch: false
            //     };

            //     (function (document, bundle, head) {
            //         head = document.getElementsByTagName('head')[0];
            //         bundle = document.createElement('script');
            //         bundle.type = 'text/javascript';
            //         bundle.async = true;
            //         bundle.src = 'https://static-assets.ny.gov/sites/all/widgets/universal-navigation/js/dist/global-nav-bundle.js';
            //         head.appendChild(bundle);
            //     }(document));
            // }
        // }

        // Remove the temporary styles that are in place while main parts of the page (header, etc) are being loaded
        document.documentElement.classList.add('feta-page-loaded');

        // Setup Dynamic Toggle fields
        $('.feta-dynamic-toggle').each(function () {
            // Selection-dependent radio buttons with highlighting
            if (this.classList.contains('feta-dynamic-toggle-highlight')) {
                $(this).dynamicBlock({
                    onMatch: (blockElem, toggleElem/*, blockConfig*/) => {
                        blockElem.classList.add('feta-dynamicblock-matched-block');
                        $(toggleElem).closest('.cui-row').addClass('feta-dynamicblock-matched-toggle');
                    },
                    onUnmatch: (blockElem, toggleElem/*, blockConfig*/) => {
                        blockElem.classList.remove('feta-dynamicblock-matched-block');
                        $(toggleElem).closest('.cui-row').removeClass('feta-dynamicblock-matched-toggle');
                    },
                });
            }
            // Standard field
            else {
                $(this).dynamicBlock();
            }
        });

        // Date pickers
        $('.cui-date').datepicker();

        // Inputmasks
        $('.cui-input-mask [type="password"]').inputmask();

        // Analytics
        analytics.init();

        navigation.init();

        progressBar.init();

        // Tables
        if (fwData.tables) {
            table.init(fwData.tables);
        }

         // Message list
        if (fwData.inboxes && fwData.inboxes.length) {
            fwData.inboxes.forEach((list) => {
                inbox.init(list);
            });
        }

        // User Messages
        //Initilize the userMessages component.
        userMessage.init();

        //Perform any project specific setup for messages.
        _priv.userMessageSetup();

        if (fwData.userMessages) {
            //Perform any project specific processinging before passing the messages to the userMessages component for creation.
            _priv.processUserMessages(fwData.userMessages);
        }

        // Tooltips
        // Putting `section` in the selector prevents the icon in the page legend from being included
        $(document.body).on('click', 'section .feta-icon-help:not(.feta-tooltip-has-been-setup)', function () {
            $(this).tooltip({showOnCreate: true});
        });


        // Drop menus
        if (fwData.buttonMenus && fwData.buttonMenus.length) {
            fwData.buttonMenus.forEach((menu) => {
                buttonMenu.init(menu);
            });
        }

        // Confirmation dialogs
        _priv.confirm.init();

        _priv.clickBlocker.init();

        _priv.legendSetup();

        _priv.validate.init();

        _priv.fileInputs.init();

        _priv.uploadModal.init();

        // listBox.init();

        //Espots
        _priv.espotSetup();
    };

    _priv.pageInfoSetup = () => {
        const locHref = document.location.href;
        const iflowAppCodeRegex = /\.gov\/([A-Z]{4}(?:\d{2})?)\//;

        // Iflow page
        if (locHref.indexOf('/iflow/') !== -1) {
            pageInfo.appPlatform = 'iflow';

            // App code
            if (iflowAppCodeRegex.test(locHref)) {
                pageInfo.appCode = iflowAppCodeRegex.exec(locHref)[1];
            }

            // Page code
            // Extracted from a comment near the top of the source code
            // Example: <!-- INDIVIDUAL - IEPC_PCAL - IEPC_PCAL01 - IEPC_PCAL01_PAGE_INSTRUCTIONS [...]
            // Example: <!-- MAIN - FWSHSCUI - FWSHSCES - FWSHSCES_SECURITY_CHECK_PAGE_INSTRUCTIONS [...]
            // Example with mistake: <!-- MAIN - MMWF_FINF - MMWF_FINF010 - MMWF_FINF010_PAGE_INSTRUCTIONS [...] -->

            // We're doing many cautious checks in this function because the code is running so early in the life of the page
            if (document && document.documentElement) {
                // Start with the first child element on the page
                let elem = document.documentElement.firstChild;

                // Loop through siblings but don't bother going past the `<head>`
                while (elem && !/head|body/.test(elem.nodeName)) {
                    // Check if it's a comment node
                    if (elem.nodeType === 8) {
                        break;
                    }

                    elem = elem.nextSibling;
                }

                // Make sure we found something
                if (elem) {
                    // Get the comment's content
                    const text = (typeof elem.textContent === 'string') ? elem.textContent : elem.innerText;
                    const pageCodeRegex = /^\s*\w+\s+\-\s+\w+\s+\-\s+(\w+)\s+/;

                    // Extract page code
                    if (text && pageCodeRegex.test(text)) {
                        pageInfo.pageCode = pageCodeRegex.exec(text)[1];
                        analytics.trackEvent('app', 'Page view', pageInfo.appCode + '|' + pageInfo.pageCode, null, true);
                    }
                }
            }
        }
        else if (document.location.protocol.indexOf('file') !== -1 || document.location.hostname === 'localhost') {
            pageInfo.appPlatform = 'local';
        }
        else {
            pageInfo.appPlatform = 'iflow';
        }

        // Other parameters:

        if (fwData.app && fwData.app.title) {
            pageInfo.appTitle = fwData.app.title;
        }
        else {
            let appTitleContent = $('.feta-app-title').html();
            pageInfo.appTitle = (appTitleContent) ? appTitleContent : "";
        }

        if (fwData.page) {
            if (fwData.page.title) {
                pageInfo.pageTitle = fwData.page.title;
                $('.feta-page-title').html(pageInfo.pageTitle);
            }
            else {
                pageInfo.pageTitle = $('.feta-page-title').html();
            }

            if (fwData.page.type) {
                document.documentElement.classList.add('feta-page-type-' + fwData.page.type);
            }
        }

        // Make the info available globally
        feta.pageInfo = pageInfo;

        // Default window title for mockups
        if (document.domain === 'localhost' || document.location.protocol === 'file:') {
            _priv.pageTitleSetup();
        }
    };

    // Updates window title
    _priv.pageTitleSetup = (customTitle) => {
        const titleElems = document.documentElement.getElementsByTagName('title');
        let titleText;

        // Add a `<title>` element if necessary
        if (!titleElems.length) {
            document.documentElement.appendChild(document.createElement('title'));
        }

        // Determine the window's title text
        if (customTitle) {
            titleText = customTitle;
        }
        // Both page and app title are available
        else if (pageInfo.pageTitle && pageInfo.appTitle) {
            titleText = pageInfo.pageTitle + ' - ' + pageInfo.appTitle;
        }
        // Only page title
        else if (pageInfo.pageTitle) {
            titleText = pageInfo.pageTitle;
        }
        // Only app title
        else if (pageInfo.appTitle) {
            titleText = pageInfo.appTitle;
        }

        // Update the window title if we have something to display
        if (titleText) {
            if (!window.title) {
                window.title = titleText;
            }

            if (titleElems.length && !titleElems[0].innerHTML) {
                titleElems[0].innerHTML = titleText;
            }
        }
    };

    // Populates the app header
    _priv.headerSetup = () => {
        $bannerWrapper = $('.feta-header');

        $('.feta-app-title').html(feta.pageInfo.appTitle);

        if ($bannerWrapper.length) {
            // The popovers can wait while the rest of `_init` runs so we wrap them in `setTimeout`

            if (fwData.userAcct) {
                setTimeout(_priv.agencyBannerUserInfoPopoverSetup, 0);

                if(fwData.userAcct.userName){
                    $('.feta-useracct-name').text(fwData.userAcct.userName);
                }
                else if (fwData.userAcct.name) {
                    $('.feta-useracct-name').text(fwData.userAcct.name);
                }
            }

            if (fwData.helpMenu) {

                if(fwData.helpMenu.disable == true){
                    let helpToggle = document.querySelector(".feta-toggle-help");

                    if(helpToggle){
                        helpToggle.parentNode.removeChild(helpToggle);
                    }
                }
                else{
                    setTimeout(_priv.agencyBannerHelpPopoverSetup, 0);    
                }               
            }
        }
        else {
            journal.log({type: 'warn', owner: 'UI', module: 'feta', submodule: 'headerSetup'}, 'No agency banner element');
        }
    };

    _priv.agencyBannerUserInfoPopoverSetup = () => {
        const $userIcon = $bannerWrapper.find('.feta-toggle-user').find('a');
        const acctParams = fwData.userAcct;
        let html = '';

        // Make sure the toggle icon exists
        if (!$userIcon.length) {
            // Not sure whether to log an error/warning here. It means `fwData.userAcct` is defined but there's no way to display its contents.
            // journal.log({type: 'warn', owner: 'UI', module: 'feta', submodule: 'agencyBannerUserInfoPopoverSetup'}, 'No user icon in the banner');

            return false;
        }

        // Destroy existing popover
        if (agencyBannerUserPopover) {

            //Remove any react components from banner since it is being destroyed.
            unmountComponents(agencyBannerUserPopover.$popover);

            agencyBannerUserPopover.destroy();
        }

        // Prevent link from being followed
        $userIcon.on('click', (evt) => {
            evt.preventDefault();
        });

        // Build the popover's content HTML:

        // Name
        if (acctParams.name) {
            html += '<p class="feta-profile-popover-name">' + acctParams.name + '</p>';
        }

        // Legal name for businesses
        if (acctParams.legalName) {
            html += '<p class="feta-profile-popover-ssn">' + acctParams.legalName + '</p>';
        }

        // Taxpayer ID
        if (acctParams.id) {
            html += '<p class="feta-profile-popover-id">' + acctParams.id + '</p>';
        }

        // Add a divider if either of the above items were present
        if (acctParams.name || acctParams.legalName) {
            html += '<div class="feta-popover-divider">&nbsp;</div>';
        }

        // Account type
        if (acctParams.type) {
            html += '<p class="feta-profile-popover-tp-role"><span>Account:</span> ' + acctParams.type + '</p>';
        }

        // Role
        if (acctParams.role) {
            html += '<p class="feta-profile-popover-tp-role"><span>Role:</span> ' + acctParams.role + '</p>';
        }

        // Add a divider if either of the above items were present
        if (acctParams.type || acctParams.role) {
            html += '<div class="feta-popover-divider">&nbsp;</div>';
        }

        if (fwData.preferences) {
            html += '<div id="feta-profile-popover-prefs"></div>' +
                    '<div class="feta-popover-divider">&nbsp;</div>';
        }

        // Logout link, if present
        if (fwData.banners && fwData.banners.urls && fwData.banners.urls.logout && fwData.banners.urls.logout.href) {
            html += '<ul>' +
                        '<li><a href="' + fwData.banners.urls.logout.href + '">Log out</a></li>' +
                    '</ul>';
        }

        // Instantiate the popover
        agencyBannerUserPopover = $.popover(
            $userIcon,
            {
                html: html,
                display: {
                    className: 'feta-profile-popover',
                },
                location: 'below-center',
            }
        );

        if (fwData.preferences) {
            // Fill in optional settings
            if (typeof fwData.preferences.isOpen !== 'boolean') {
                fwData.preferences.isOpen = false;
            }

            if (!fwData.preferences.text) {
                fwData.preferences.text = 'Preferences';
            }

            tree.init(fwData.preferences, agencyBannerUserPopover.$popover.find('#feta-profile-popover-prefs').get(0));
        }

        $bannerWrapper.find('.feta-agency-header').addClass('feta-agency-header-has-user-toggle');
    };

    _priv.agencyBannerHelpPopoverSetup = () => {
        const $helpIcon = $bannerWrapper.find('.feta-toggle-help').find('a');
        let html = '';

        if (!$helpIcon.length) {
            journal.log({type: 'warn', owner: 'UI', module: 'feta', submodule: 'agencyBannerHelpPopoverSetup'}, 'No help icon in the banner');

            return false;
        }

        $helpIcon.find('span').text(fwData.helpMenu.text || 'Help');

        // Destroy existing popover
        if (agencyBannerPrefsPopover) {

            //Remove any react components from banner since it is being destroyed.
            unmountComponents(agencyBannerPrefsPopover.$popover);

            agencyBannerPrefsPopover.destroy();
        }

        // Prevent link from being followed
        $helpIcon.on('click', (evt) => {
            evt.preventDefault();
        });

        // Build the popover's content HTML
        html = '<ul>';

        fwData.helpMenu.items.forEach((item) => {
            if (item.type && item.type === 'divider') {
                html += '<div class="feta-popover-divider">&nbsp;</div>';
            }
            else {
                html += '<li><a href="' + item.href + '"';

                if (item.attributes) {
                    if (item.attributes.className) {
                        html += ' class="' + item.attributes.className + '"';
                    }

                    if (item.attributes.target) {
                        html += ' target="' + item.attributes.target + '"';
                    }
                }
                else {
                    if (item.target) {
                        html += ' target="' + item.target + '"';
                    }

                    if (item.className) {
                        html += ' class="' + item.className + '"';
                    }
                }

                html += '>' + item.text + '</a></li>';
            }
        });

        html += '</ul>';

        agencyBannerPrefsPopover = $.popover(
            $helpIcon,
            {
                html: html,
                display: {
                    className: 'feta-profile-popover feta-prefs-popover',
                },
                location: 'below-center',
                resizeMobile: false
            }
        );
    };

    _priv.legendSetup = () => {
        const ANIMATION = {
            duration: '150',
            easing: 'easeInOutCubic',
        };

        const CLASSES = {
            hidden:"cui-hidden"
        };

        let showElement = function _showElement (elem) {
            if (!elem.classList.contains(CLASSES.hidden)) {
                return false;
            }

            // Make it visible but with zero height so we can expand it
            elem.style.height = '0px';
            elem.classList.remove(CLASSES.hidden);

            // Expansion animation
            $(elem).animate(
                {
                    height: elem.scrollHeight,
                },
                {
                    easing: ANIMATION.easing,
                    duration: ANIMATION.duration,
                    done: function () {
                        this.style.height = 'auto';
                    },
                }
            );
        };

        let hideElement = function _hideElement (elem) {
            // Check if it's already hidden
            if (elem.classList.contains(CLASSES.hidden)) {
                return false;
            }

            // Collapse animation
            $(elem).animate(
                {
                    height: '0px',
                },
                {
                    easing: ANIMATION.easing,
                    duration: ANIMATION.duration,
                    done: function () {
                        // Even though the element is effectively invisible at this point, we'll use this class later use this class as a quick test of whether it's hidden or not
                        elem.classList.add(CLASSES.hidden);
                    },
                }
            );
        };

        let legendToggle = function _legendToggle (evt) {
            var targ = evt.target;
            var fieldIds;

            // if (targ.nodeName === 'A') {
            //     evt.preventDefault();
            // }

            // // Get list of related IDs
            // fieldIds = targ.getAttribute(ATTRIBUTES.idList);

            // // Selected an radio in the same group as another option that has a dependent block
            // if (fieldIds && fieldIds.length) {
            //     // Evaluate each ID stored on the selected option
            //     fieldIds.split(',').forEach(function (id) {
            //         priv.evaluateField(id);
            //     });

            //     // This option might also have its own block
            //     priv.evaluateField(targ.id);
            // }
            // else {
            //     // This option only affects one block
            //     priv.evaluateField(targ.id);
            // }
        };

        //Legend init
        if(fwData.legend && $.isArray(fwData.legend) ){
            let $legend = $(".feta-page-legend");

            if($legend.length <= 0){
                //Find main

                $legend = $('<div/>', {
                            'class': "feta-page-legend",
                        });

                let $main = $("#main");

                //Find form;
                let $form = $main.children('form');

                //Find messages.
                let $messages = $main.children('.cui-messages');

                if(false && $messages.length > 0){

                    $messages.after($legend);
                }
                else if($form.length > 0){

                    $form.before($legend);
                }
            }

            //Handle case where legend is not present on the page.
           // add before form, before messages.

            let legendHasContent = false;
            $legend.html("");

            fwData.legend.forEach((legendItem) => {
                if(legendItem.html){
                    let $item = $('<div/>', {
                            'class': "feta-page-legend-item",
                        });
                    $item.append(legendItem.html);
                    $legend.append($item);
                    legendHasContent = true;
                }
                else if(legendItem.text){
                    let $item = $('<div/>', {
                            'class': "feta-page-legend-item",
                        });
                    $item.append(legendItem.text);

                    $legend.append($item);
                    legendHasContent = true;
                }
            });

            if(legendHasContent){
                $legend.removeClass('cui-hidden');
            }
            else{
                $legend.addClass('cui-hidden');
            }
        }

        //Check if responsive trigger is in place.

        let responsiveLegendTrigger = $('.feta-page-instructions-toggle');

        responsiveLegendTrigger.on('click', () => {
            $('.feta-page-instructions-wrapper').toggleClass('feta-page-instructions-open');
        });

        // if(responsiveLegendTrigger.length)


        //Find fieldInstructions wrapper.
        let fieldInstructionWrapper = $('.feta-field-instructions');

        if(fieldInstructionWrapper.length > 0){
        // Show page instructions when clicking on the icon in the legend
        $('.feta-page-legend').find('.feta-icon-help').on('click', () => {
                fieldInstructionWrapper
                .css({
                    display: 'block',
                    margin: '1em',
                })
                .find(' > div > header')
                    .css({
                        display: 'block',
                        fontWeight: 'bold',
                        marginTop: '1em',
                    })
                .end()
                .get(0)
                    .scrollIntoView();
        });
        }
        else{
            //Look for legacy instructions.
            let legacyInstructionWrapper = $('#instructionsFooter');

            if(legacyInstructionWrapper.length > 0){
                // Show page instructions when clicking on the icon in the legend
                $('.feta-page-legend').find('.feta-icon-help').on('click', () => {
                    legacyInstructionWrapper
                        .css({
                            display: 'block',
                            margin: '1em',
                        })
                        .find(' > div > h4')
                            .css({
                                display: 'block',
                                fontWeight: 'bold',
                                marginTop: '1em',
                                fontSize:'16px',
                                marginBottom:'0',
                            })
                        .end()
                        .get(0)
                            .scrollIntoView();
                });
            }
        }

    };

    _priv.validate = {};

    _priv.validate.validatedFields = {};

    _priv.validate.init = () => {

    };

    //Method from emp2
    _priv.validate.field = (field) => {
        var results = validation.field(field);
        // _priv.validate.processValidation(results);

        if (results){
            if(results.result || results.endResult) {
                return true;
            }
        }

        return false;
    };

    _priv.validate.getFieldValidationResults = (field) =>{
        return validation.field(field);
    };


    //Method from emp2
    // _priv.validate.form = (form) => {
    //       var results = validation.form(form);

    //     // _priv.processValidation(results);

    //     if (results.endResult) {
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // };


    _priv.validate.form = (settings = {}) => {

        //Get fields to validate.
        let form = (settings.form) ? settings.form : undefined;

        if(!form){
            journal.log({type: 'error', owner: 'app', module: 'feta', submodule: '_priv.validate.form'}, 'Invalid form');
            return false;
        }

        var formValidation = validation.form(form);

        if(formValidation.endResult === true){

            _priv.validate.removeValidationErrorsFromForm(formValidation);

            //All tests passed, continue to function defined by user.
            if(settings.callback){
                let callbackArgs = (settings.callbackArgs)? settings.callbackArgs : null;

                if (typeof(window[settings.callback]) === 'function') {
                    return window[settings.callback].apply(this, callbackArgs);
                }
                else {
                    journal.log({type: 'error', owner: 'app', module: 'feta', submodule: '_priv.validate.form'}, 'window[' + settings.callback + '] is not a function');
                }
            }

            return true;
        }
        else{
            _priv.validate.processValidation(formValidation);
            return false;
        }
    };

    _priv.validate.processValidation = (validationObj) =>{
        let validationFields = validationObj.fields;

        validationFields.forEach((fieldObj) => {
            _priv.validate.processField(fieldObj);
        });
    };

    _priv.validate.removeValidationErrorsFromForm = (validationObj) =>{
        let validationFields = validationObj.fields;

        validationFields.forEach((fieldObj) => {
            let fieldReference = fieldObj.$reference[0];
            let fieldId = fieldReference.id;

            if(fieldObj.tests){
                for(const testName in fieldObj.tests){
                    // skip loop if the property is from prototype
                    if (!fieldObj.tests.hasOwnProperty(testName)) continue;
                    _priv.validate.clearTestMessageFromValidationReference( fieldId, testName);
                }
            }
        });
    };

    _priv.validate.processField = (fieldObj) => {
        let fieldReference = fieldObj.$reference[0];
        let fieldId = fieldReference.id;
        let newReference = true;

        if(fieldObj.tests){
            for(const testName in fieldObj.tests){
                // skip loop if the property is from prototype
                if (!fieldObj.tests.hasOwnProperty(testName)) continue;

                let test  = fieldObj.tests[testName];

                if(test.result !== true){
                    let newMessage = _priv.processUserMessages({
                        "template": "userMessages",
                        "parameters": {
                            "field": [{
                                "elem": fieldReference,
                                "messages": [{
                                    "template": "message",
                                    "type": "error",
                                    "text": test.message
                                }]
                            }]
                        }
                    });

                    if(newMessage.length > 0){

                        _priv.validate.updateTestMessageFromValidationReference(fieldId, testName, fieldReference, newMessage);
                    }
                }
                else{
                     _priv.validate.clearTestMessageFromValidationReference(fieldId, testName);
                }
            }
        }
    };

    _priv.validate.updateTestMessageFromValidationReference = (fieldId, testName, fieldReference, message) =>{
        //Check if field reference exists.
        if(_priv.validate.validatedFields[fieldId]){
            _priv.validate.validatedFields[fieldId].testMessages[testName] = message;
        }else{
            _priv.validate.validatedFields[fieldId] = {
                field:fieldReference,
                testMessages:{}
            };
            _priv.validate.validatedFields[fieldId].testMessages[testName] = message;
        }
    };

    _priv.validate.clearTestMessageFromValidationReference = (fieldId, testName) =>{
        if(_priv.validate.validatedFields[fieldId]){
            if(_priv.validate.validatedFields[fieldId].testMessages){
                if(_priv.validate.validatedFields[fieldId].testMessages[testName]){
                    userMessage.removeMessage(_priv.validate.validatedFields[fieldId].testMessages[testName]);
                    delete _priv.validate.validatedFields[fieldId].testMessages[testName];
                }
            }
        }
    };

    _priv.userMessageFieldsInTables = [];

    _priv.userMessageSetup = () => {
        const $pageLocationParent = $("#main");
        let $pageMessageLocation;

        if ($pageLocationParent.children('ul.cui-messages').length <= 0) {
            const $messageLoc = $('<ul/>', {
                'class': 'cui-messages cui-hidden',
            });

            let $form = $pageLocationParent.children('form');

            let $legend = $pageLocationParent.children('.feta-page-legend');

            if($legend.length > 0){
                $legend.after($messageLoc);
            }
            else{
                $form.before($messageLoc);
            }

            $pageMessageLocation = $pageLocationParent.children('ul.cui-messages');
        }
        else {
            $pageMessageLocation = $pageLocationParent.children('ul.cui-messages');
        }

        userMessage.setPageMessageLocation($pageMessageLocation);
        userMessage.setPageNotifierMessage(
            {
                "message":'Please review the highlighted messages shown below before continuing.',
                "errorMessage":'Please correct the highlighted errors shown below before continuing.',
                "warningMessage":'Please review the highlighted messages shown below before continuing.'
            }
        );
    };

    _priv.processUserMessages = (message) => {
        let $messageLoc;

        if (message.parameters) {
            // Processing for field level messages
            if (message.parameters.field) {
                for (let i = 0; i < message.parameters.field.length; i++) {
                    if (message.parameters.field[i].elem) {

                        // const $field = $(message.parameters.field[i].elem);
                        const field = getElementByString(message.parameters.field[i].elem);
                        const $field = $(field);

                        //If a valid field elem was found.
                        if($field.length && $field.length > 0){
                            const nodeType = $field.get(0).nodeName;

                            // Stub out parameters object if missing.
                            if(!message.parameters.field[i].parameters){
                                message.parameters.field[i].parameters = {};
                            }

                            /**
                             * Handle messages for special node cases.
                             *
                             * Most of the time field messages will be tied to an input or other form element.
                             * In casses where the message elem is a DIV or other special wrapping element, we need to determine how to handle the message.
                             */
                            switch (nodeType) {
                                case 'DIV': {
                                    /*== Table within a wrapper div==*/
                                    const $tableWrapper = $field.children('.feta-table-wrapper');

                                    if ($tableWrapper.length) {
                                        const $tableMessageWrapper = $tableWrapper.children('.feta-table-messages');

                                        if ($tableMessageWrapper.length) {
                                            // Update the element to the react root class of the table.
                                            message.parameters.field[i].elem = $tableMessageWrapper;

                                            if (!message.parameters.field[i].parameters) {
                                                message.parameters.field[i].parameters = {};
                                            }

                                            // Set field message location for fields within tables.
                                            message.parameters.field[i].parameters.messageLocation = '.feta-table-messages';
                                        }
                                    }

                                    /*== Inbox within a wrapper div==*/
                                    const $inboxWrapper = $field.children('.feta-inbox-list');

                                    if ($inboxWrapper.length) {
                                        const $inboxMessageWrapper = $inboxWrapper.children('.feta-inbox-messages');

                                        if ($inboxMessageWrapper.length) {
                                            // Update the element to the react root class of the inbox.
                                            message.parameters.field[i].elem = $inboxMessageWrapper;

                                            if (!message.parameters.field[i].parameters) {
                                                message.parameters.field[i].parameters = {};
                                            }

                                            // Set field message location for fields within inboxs.
                                            message.parameters.field[i].parameters.messageLocation = '.feta-inbox-messages';
                                        }
                                    }

                                    break;
                                }

                                case 'SECTION':{
                                    const $header = $field.children("header");

                                    if (!$field.children('.cui-messages').length) {
                                        $messageLoc = $('<ul/>', {
                                            'class': 'cui-messages cui-hidden cui-field-message cui-section-message'
                                        });

                                        if($header.length > 0){
                                            $field.children($header).eq(0).after($messageLoc);
                                        }
                                        else{
                                            $field.children().eq(0).before($messageLoc);
                                        }
                                    }

                                    // Set field message location for fields within sections.
                                    message.parameters.field[i].parameters.messageLocation = message.parameters.field[i].elem;
                                    break;
                                }

                                default: {
                                    /*== Fields within tables - Update message location ==*/
                                    const $tableParents = $field.parents('.feta-table-elem-table');

                                    if ($tableParents.length) {

                                        if(!_priv.userMessageFieldsInTables.includes($field[0].id)){
                                            _priv.userMessageFieldsInTables.push($field[0].id);
                                        }

                                        let $expandParents = $field.parents('.feta-table-row-expand-child');
                                        
                                        //Clear messages if they exist
                                        _priv.removeUserMessagesFromElement("#"+$field[0].id);
                                        
                                        //Message is in an expand row. 
                                        if(!$expandParents.length){

                                            // Make sure parameters object is defined
                                            if (!message.parameters.field[i].parameters) {
                                                message.parameters.field[i].parameters = {};
                                            }

                                            // Set field message location for fields within tables.
                                            message.parameters.field[i].parameters.messageLocation = '.feta-table-col-data';
                                        }

                                        else{
                                            const expandRow = $expandParents[0].parentNode;
                                           
                                            if(expandRow && $(expandRow).css('display') == "none"){
                                                const $previousRow = $(expandRow).prev();
                                                const expandToggle = $previousRow[0].querySelector('.feta-table-cell-expand-indicator');
                                                
                                                if(expandToggle){
                                                    expandToggle.click();
                                                }
                                            }                                          
                                        }
                                    }

                                    /*== Dynamic Block Triggers ==*/
                                    if (($field.closest('.feta-dynamic-option').length) && (!$field.closest('.feta-dynamic-toggle').length)) {

                                        //Make sure parameters object is defined
                                        if (!message.parameters.field[i].parameters) {
                                            message.parameters.field[i].parameters = {};
                                        }

                                        const $pageLocationParent = $field.closest('.feta-dynamic-wrapper');

                                        if (!$pageLocationParent.children('.cui-messages').length) {
                                            $messageLoc = $('<ul/>', {
                                                'class': 'cui-messages cui-hidden cui-field-message'
                                            });

                                            $pageLocationParent.children('.feta-dynamic-option').eq(0).before($messageLoc);
                                        }

                                        message.parameters.field[i].parameters.messageLocation = '.feta-dynamic-wrapper';
                                    }

                                    /*== Radio Button list. Fieldset containing direct cui-row children. ==*/
                                    if (($field.closest('fieldset > .cui-row').length) && (!$field.closest('.feta-dynamic-toggle').length) && (!$field.closest('.feta-dynamic-option').length)) {
                                        //Make sure parameters object is defined
                                        if (!message.parameters.field[i].parameters) {
                                            message.parameters.field[i].parameters = {};
                                        }

                                        const $fieldset = $field.closest('fieldset');
                                        const $legend = $fieldset.children('.cui-row').eq(0).find('legend');
                                        const hasLegend = ($legend.length) ? true : false;

                                        if(!$fieldset.find('.cui-messages').length){

                                            $messageLoc = $('<ul/>', {
                                                'class': 'cui-messages cui-hidden cui-field-message'
                                            });

                                            if(hasLegend){
                                                $fieldset.children('.cui-row').eq(0).after($messageLoc);
                                            }
                                            else{
                                                $fieldset.children('.cui-row').eq(0).before($messageLoc);
                                            }
                                        }

                                        message.parameters.field[i].parameters.messageLocation = 'fieldset';
                                    }
                                }
                            }
                        }
                    }
                }
            }

           return userMessage.create(message.parameters);
        }

    };

    _priv.removeUserMessage = (message) => {

        userMessage.removeMessage(message);
    };

    _priv.removeUserMessageById = (messageId) => {

        if(userMessage.messageStore && userMessage.messageStore.length){
            userMessage.messageStore.forEach((message) => {
                if(message.id === messageId){
                    if(message.ref){
                        userMessage.removeMessage(message.ref);
                    }
                }
            });
        }
    };

    _priv.removeUserMessagesFromElement = (elem) => {
        let elementToRemoveMessages =  null;

        if(elem && elem.nodeName && elem.nodeType){
            elementToRemoveMessages = elem;
        }
        else if(typeof elem ===  'string'){
            //A selector was used so find the first instance of the element.
            elementToRemoveMessages = document.querySelector(elem);
        }
        else{
            journal.log({type: 'error', owner: 'app', module: 'feta', submodule: 'removeUserMessagesFromElement'}, 'Invalid element passed to function');
            return;
        }

        let messageStore = userMessage.messageStore;
        let messagesToRemove = [];
        for(let i = 0; i < messageStore.length; i++){
            let message = messageStore[i];
            if(message.element){
                let messageElement;
                if (message.element instanceof jQuery) {
                    messageElement = message.element.get(0);
                }
                else {
                    messageElement = document.querySelector(message.element);
                }

                if(messageElement == elementToRemoveMessages){
                    messagesToRemove.push(message);
                }
            }
        }

        if(messagesToRemove.length>0){
            for(let i = 0; i < messagesToRemove.length; i++){
                _priv.removeUserMessage(messagesToRemove[i].ref);
            }
        }
    };

    _priv.espot = {
        CLASSES:{
            espot: "ols-espot",
            hidden: "cui-hidden",
        }
    };

    _priv.espotSetup = () => {
        //Return if no espot object was set in fwData
        if(!fwData.espots){
            return;
        }

        //get espot list
        let espotList = $('.'+_priv.espot.CLASSES.espot);

        // Return if there are no espots on the page
        if(espotList.length < 1){
            return;
        }

        if(typeof fwData.espots.display === "boolean" && fwData.espots.display === false){
            for(let i=0;i<espotList.length;i++){
                hideEspot(espotList[i]);
            }
        }
        else{

            //If allowed types isnt set or is an empty value, show all espots.
            if(!fwData.espots.allowedTypes ||
                (typeof fwData.espots.allowedTypes === "object" && fwData.espots.allowedTypes.length < 1) ||
                (typeof fwData.espots.allowedTypes === "object" && fwData.espots.allowedTypes.length == 1 && fwData.espots.allowedTypes[0] === "") ||
                (typeof fwData.espots.allowedTypes === "string" && fwData.espots.allowedTypes === "")){
                    for(let i=0;i<espotList.length;i++){
                        showEspot(espotList[i]);
                    }
            }

            let allowedTypes = [];

            if(typeof fwData.espots.allowedTypes === "object"){
                allowedTypes = fwData.espots.allowedTypes;
            }
            else if(typeof fwData.espots.allowedTypes === "string"){
                allowedTypes = [fwData.espots.allowedTypes];
            }

            //Didn't receive a valid type list, show all and return.
            if(allowedTypes.length<1){
                for(let i=0;i<espotList.length;i++){
                    showEspot(espotList[i]);
                }
                return;
            }

            //Check display types against each espot. If it matches set to showen, otherwise set to hidden.
            for(let i=0;i<espotList.length;i++){

                let displayEspot = false;

                //Get data-espot-types
                let espotTypes =espotList[i].getAttribute('data-espot-type');

                for(let t = 0; t < allowedTypes.length; t++){
                    if(espotTypes.indexOf(allowedTypes[t]) >= 0){
                        displayEspot = true;
                        break;
                    }
                }

                if(displayEspot){
                    showEspot(espotList[i]);
                }
                else{
                    hideEspot(espotList[i]);
                }
            }
        }
    };

    const hideEspot = (elem) => {
        if (elem instanceof Node) {
            elem = $(elem);
        }
        elem.addClass(_priv.espot.CLASSES.hidden);
    };

    const showEspot = (elem) => {
        if (elem instanceof Node) {
            elem = $(elem);
        }
        elem.removeClass(_priv.espot.CLASSES.hidden);
    };


    _priv.confirm = {
        hasInitialized: false,
    };

    _priv.confirm.configs = {};

    _priv.confirm.init = () => {
        if (!_priv.confirm.hasInitialized) {
            _priv.confirm.hasInitialized = true;

            // Event delegation for clicks because togglers may be dynamically added to the DOM
            $(document.body).on('click', '.feta-confirm:not(.feta-confirm-has-been-setup)', function (evt) {
                evt.preventDefault();
                _priv.confirm.setup(this, {}, true);
            });

            // Pre-load the module so it's ready when the user first clicks on a toggler
            if (!require.defined('modal')) {
                cui.load('modal');
            }
        }
    };

    //setup function run on all delegated onclick calls. Saves modal and settings pulled from data-feta-confirm attribute.
    _priv.confirm.setup = (elem, settings = {}, doOpenNow = false) => {
        const fallbackMessage = 'Are you sure you want to perform this action?';
        const removeMessageParts = ['Are you sure you want to remove this ', '?'];
        const defaultSettings = {
            msg: undefined,
            customMsg: undefined,
            callback: undefined,
            args: undefined,
        };
        const $toggler = $(elem);
        let togglerId = $toggler.attr('id');
        let wasAlreadySetup = false;

        // Ensure the toggler has an ID
        if (!togglerId) {
            togglerId = 'feta-confirm-' + guid();
            $toggler.attr('id', togglerId);
        }

        // Read options from the `data` attribute and apply defaults
        if (_priv.confirm.configs[togglerId]) {
            settings = _priv.confirm.configs[togglerId];
            wasAlreadySetup = true;
        }
        else {

            let dataSettings = $toggler.data('feta-confirm');

            if(dataSettings && typeof dataSettings == 'object'){
                //Remap settings.
                if(!dataSettings.msg && dataSettings.confirmMessage){
                    dataSettings.msg = dataSettings.confirmMessage;
        }
                if(!dataSettings.msgRemove && dataSettings.removeTerm){
                    dataSettings.msgRemove = dataSettings.removeTerm;
                }
                if(!dataSettings.args && dataSettings.callbackArgs){
                    dataSettings.args = dataSettings.callbackArgs;
                }
            }

            settings = $.extend(true, {}, settings, dataSettings, defaultSettings);
        }

        // Store the settings
        _priv.confirm.configs[togglerId] = settings;

        // Prepare the toggler, as long as it hasn't already been setup
        if (!wasAlreadySetup) {
            /**
             * Sets up a toggler, if necessary, and opens the modal
             *
             * @param   {event}  evt  Click event
             */
            const _onConfirmClick = (evt) => {
                // Retrieve stored settings
                settings = _priv.confirm.configs[togglerId];

                // Cancel this event. We'll trigger another click once we're done setting up the toggler.
                if (evt) {
                    evt.preventDefault();
                }

                // We're storing the event handler in the settings object so that each toggler gets its own instance
                settings.onTogglerClick = (origEvt) => {
                    // Private methods for this particular modal instance:

                    // Set up event handlers once the modal has been displayed
                    const _setup = (modal) => {
                        modal.$self
                            .find('.feta-confirm-button-yes')
                                .on('click', {modal: modal}, _onYesClick);

                        modal.$self
                            .find('.feta-confirm-button-no')
                                .on('click', {modal: modal}, _onNoClick);
                    };

                    const _destroy = (modal) => {
                        modal.hide();
                        modal.destroy();
                    };

                    // Event handlers for this particular modal instance
                    const _onNoClick = (evt) => {
                        const modal = evt.data.modal;

                        _destroy(modal);
                    };

                    const _onYesClick = (evt) => {
                        const modal = evt.data.modal;

                        _destroy(modal);

                        if (typeof settings.callback === 'function') {
                            if (settings.args) {
                                settings.callback(origEvt, ...settings.args);
                            }
                            else {
                                settings.callback(origEvt);
                            }
                        }
                        else {
                            // Simulate a click on the element

                            // First we need to remove the event handler for the confirmation dialog, otherwise it will just appear again
                            //FIXME: This should only remove `click.confirm`, not all click handlers, but it wasn't properly clearing the listener (CP 6/16/17)
                            $toggler.off('click');

                            // Then we simulate the click
                            $toggler.get(0).click();

                            // And finally we re-add our click handler so the confirmation dialog appears the next time the element is clicked
                            $toggler.on('click.confirm', settings.onTogglerClick);
                        }
                    };

                    // Create and display the modal
                    const _createModal = () => {
                        const $confirmModal = $.modal({
                            html: '<p>' + settings.text + '</p>',
                            footer: {
                                html:   '<button type="button" class="feta-confirm-button-yes">Yes</button>' +
                                        '<button type="button" class="feta-confirm-button-no">No</button>',
                                className: 'cui-align-right',
                            },
                            modalClass: 'feta-confirm-modal',
                            onCreate: (modal) => {
                                _setup(modal);
                            },
                            hideDestroy: true,
                        });

                        $confirmModal.show();
                    };

                    // Check to see if the module has been loaded yet
                    if (require.defined('modal')) {
                        // Create the modal immediately
                        _createModal();
                    }
                    else {
                        // Load the module and then create the modal
                        cui.load('modal', () => {
                            _createModal();
                        });
                    }

                    origEvt.preventDefault();
                };

                // If the callback is a string, check if it's the name of a global function
                if (typeof settings.callback === 'string' && typeof window[settings.callback] === 'function') {
                    settings.callback = window[settings.callback];
                }

                // Create message text:

                // Fully-custom message
                if (settings.msg && typeof settings.msg === 'string') {
                    settings.text = settings.msg;
                }
                // Template message for removing some type of item
                else if (settings.msgRemove && typeof settings.msgRemove === 'string') {
                    settings.text = removeMessageParts[0] + settings.msgRemove + removeMessageParts[1];
                }
                // Default message
                else {
                    settings.text = fallbackMessage;
                }

                // Add the click event handler
                $toggler.one('click.confirm', settings.onTogglerClick);

                // Open it now
                $toggler.trigger('click.confirm');
            };

            // Listen for clicks
            $toggler.on('click', _onConfirmClick);

            // Pre-load the module so it's ready when the user first clicks on a toggler
            if (!require.defined('modal')) {
                cui.load('modal');
            }

            if (doOpenNow) {
                _onConfirmClick();
            }
        }

        // Prevent event delegation from setting up this toggler again
        $toggler.addClass('feta-confirm-has-been-setup');
    };

    //Creates a new modal
    _priv.confirm.create = (settings = {}) => {
        //   // Shift variables just in case the event object is not included
        // if (typeof evt === 'string' && (Array.isArray(funcName) || funcName === undefined) ) {
        //     // Shift all the variables
        //     // console.log('Shift all the variables');
        //     settings = evt;
        //     evt = false;
        // }

        const fallbackMessage = 'Are you sure you want to perform this action?';
        const removeMessageParts = ['Are you sure you want to remove this ', '?'];
        const defaultSettings = {
            confirmMessage: undefined,
            removeTerm: undefined,
            callback: undefined,
            callbackArgs: undefined,
        };

        const confirmSettings = {};
        Object.assign(confirmSettings, defaultSettings, settings);

        // If the callback is a string, check if it's the name of a global function
        if (typeof confirmSettings.callback === 'string' && typeof window[confirmSettings.callback] === 'function') {
            confirmSettings.callback = window[confirmSettings.callback];
        }

        // Create message text
        // ====================

        // Fully-custom message
        if (confirmSettings.confirmMessage && typeof confirmSettings.confirmMessage === 'string') {
            confirmSettings.text = confirmSettings.confirmMessage;
        }
        // Template message for removing some type of item
        else if (confirmSettings.removeTerm && typeof confirmSettings.removeTerm === 'string') {
            confirmSettings.text = removeMessageParts[0] + confirmSettings.removeTerm + removeMessageParts[1];
        }
        // Default message
        else {
            confirmSettings.text = fallbackMessage;
        }

        // Set up event handlers once the modal has been displayed
        const _setup = (modal) => {
            modal.$self
                .find('.feta-confirm-button-yes')
                    .on('click', {modal: modal}, _onYesClick);

            modal.$self
                .find('.feta-confirm-button-no')
                    .on('click', {modal: modal}, _onNoClick);
        };

        const _destroy = (modal) => {
            modal.hide();
            modal.destroy();
        };

        // Event handlers for this particular modal instance
        const _onNoClick = (evt) => {
            const modal = evt.data.modal;

            _destroy(modal);
        };

        const _onYesClick = (evt) => {
            const modal = evt.data.modal;

            _destroy(modal);

            const callFunction = (funcName, args) => {

                // Check to see if the function is in a namespace
                if (funcName.indexOf('.') === -1) {
                    // No namespace assume this is a global (window) function
                    // Check to make sure we are calling a real function first.
                    if (typeof(window[funcName]) === 'function') {
                        return window[funcName].apply(this, args);
                    }
                    else {
                        console.error('window[' + funcName + '] is not a function');
                    }
                }
                else {
                    let context = window;
                    const namespace = funcName.split('.');
                    const firstPart = namespace.shift();

                    if (context[firstPart]) {
                        let textContext = firstPart;

                        context = context[firstPart];

                        // Loop through remaining spaces
                        for (let i = 0, len = namespace.length; i < len; i++) {
                            const testSpace = context[namespace[i]];

                            if (testSpace) {
                                // Update context and text name
                                context = testSpace;
                                textContext += '.' + namespace[i];
                            }
                            else {
                                journal.log({type: 'error', owner: 'UI', module: 'feta', submodule: 'callFunction'}, 'Namespace breaks down at depth: "', textContext + '.' + namespace[i], '"');

                                return false;
                            }
                        }

                        // We reached the end make sure the namespace is a function
                        if (typeof context === 'function') {
                            switch (funcName) {

                                case "feta.confirm.create":

                                    // add on the original event object
                                    // args.unshift(evt);
                                    // args.push(remainingSteps);

                                    break;
                            }

                            if (typeof args == "string") {
                                journal.log({type: 'error', owner: 'UI', module: 'feta', submodule: 'callFunction'}, 'args should be passed as an array');
                                args = [args];
                            }

                            return context.apply(this, args);
                        }
                        else {
                            journal.log({type: 'error', owner: 'UI', module: 'feta', submodule: 'callFunction'}, 'Window namespace is not a function: ', textContext);
                        }
                    }
                    else {
                        journal.log({type: 'error', owner: 'UI', module: 'feta', submodule: 'callFunction'}, 'Window namespace does not exist');
                    }
                }
            };

            if (typeof confirmSettings.callback === 'function') {

                if (confirmSettings.callbackArgs) {
                    confirmSettings.callback(confirmSettings.callbackArgs);
                }
                else {
                    confirmSettings.callback();
                }
            }
            else if(confirmSettings.callback && confirmSettings.callback!== undefined){
                callFunction(confirmSettings.callback, confirmSettings.callbackArgs);
            }
        };

        // Create and display the modal
        const _createModal = () => {
            const $confirmModal = $.modal({
                html: '<p>' + confirmSettings.text + '</p>',
                footer: {
                    html:   '<button type="button" class="feta-confirm-button-yes">Yes</button>' +
                            '<button type="button" class="feta-confirm-button-no">No</button>',
                    className: 'cui-align-right',
                },
                modalClass: 'feta-confirm-modal',
                onCreate: (modal) => {
                    _setup(modal);
                },
                hideDestroy: true,
            });

            $confirmModal.show();
        };

        // Check to see if the module has been loaded yet
        if (require.defined('modal')) {
            // Create the modal immediately
            _createModal();
        }
        else {
            // Load the module and then create the modal
            cui.load('modal', () => {
                _createModal();
            });
        }

    };


    _priv.fileInputs = {
        CLASSES : {
            "fileInputSetup":"feta-file-input-setup",            
            "displayWrapper":"feta-file-input-display-wrapper",            
            "labelWrapper":"feta-file-input-label-wrapper",            
            "buttonWrapper":"feta-file-input-button-wrapper",            
            "fileLabel":"feta-file-input-file-label",            
            "browseButton":"feta-file-input-browse-button",            
            "clearButton":"feta-file-input-clear-button",            
            "hidden":"cui-hidden"
        }
    };

    _priv.fileInputs.init = (container) =>{
        let fileInputs;

        if(container && (container instanceof Element || container instanceof HTMLDocument)){
            fileInputs = container.querySelectorAll('input[type="file"]');
        }
        else{
            fileInputs = document.querySelectorAll('input[type="file"]');
        }

        for(let i = 0; i < fileInputs.length; i++){
            // fileInputs[i].parentNode.removeChild(fileInputs[i]);
            _priv.fileInputs.create(fileInputs[i]); 
        }
    };
    
    //Takes dom refernce to an element to create a file input. 
    _priv.fileInputs.create = (fileElem) =>{

            if(!fileElem || !(fileElem instanceof Element)){
                return;
            }

            if(fileElem.classList.contains(_priv.fileInputs.CLASSES.fileInputSetup)){
                return;
            }

            const fileInputOnChange = (evt) =>{
                if(evt.target.value != ""){                    
                    let value = evt.target.value;
                    let pieces = value.split('\\');

                    label.textContent = pieces[pieces.length - 1];                 

                    _showClear();
                }                
            };      

            const _onClearClick = (evt) =>{
                //remove filename and retrigger modal
                fileElem.value = "";                
                label.textContent = defaultLabel;
                clearButton.classList.add(_priv.fileInputs.CLASSES.hidden);
                browseButton.classList.remove(_priv.fileInputs.CLASSES.hidden);
                browseButton.focus();
            };

            const _onBrowseClick = (evt) =>{
                evt.stopPropagation();
                evt.preventDefault();
                
                fileElem.click();
            };      

            const _showClear = () =>{
                browseButton.classList.add(_priv.fileInputs.CLASSES.hidden);
                clearButton.classList.remove(_priv.fileInputs.CLASSES.hidden);
            };

            let parentElem = fileElem.parentNode;
            let defaultLabel =  "Select a file";
            let initialValueSet = (fileElem.value && fieldElem.value !== "") ? true:false;

            let inputID;
            if(fileElem.id){
                inputID = fileElem.id;
            }
            else{
                inputID = guid();
                fileElem.id = inputID;
            }

            let label = document.createElement('span');
            label.textContent = (initialValueSet) ? fileElem.value : defaultLabel;
            label.id = _priv.fileInputs.CLASSES.fileLabel + "_" + inputID;
            label.classList.add(_priv.fileInputs.CLASSES.fileLabel);
            label.classList.add('cui-value');            

            let labelWrapper = document.createElement('div');
            labelWrapper.classList.add(_priv.fileInputs.CLASSES.labelWrapper);
            labelWrapper.classList.add('cui-field-collection-item');
            labelWrapper.appendChild(label);

            let browseButton = document.createElement('button');
            browseButton.type = "button";
            browseButton.textContent = "Browse";
            browseButton.onclick = _onBrowseClick;

            let clearButton = document.createElement('button');
            clearButton.type = "button";
            clearButton.textContent = "Clear";
            clearButton.onclick = _onClearClick;

            if(initialValueSet){
                browseButton.classList.add(_priv.fileInputs.CLASSES.hidden);                
            }
            else{
                clearButton.classList.add(_priv.fileInputs.CLASSES.hidden);
            }

            let buttonWrapper = document.createElement('div');
            buttonWrapper.classList.add(_priv.fileInputs.CLASSES.buttonWrapper);
            buttonWrapper.classList.add('cui-field-collection-item');
            buttonWrapper.appendChild(browseButton);
            buttonWrapper.appendChild(clearButton);

            let displayWrapperElem = document.createElement('div');
            displayWrapperElem.classList.add('feta-file-input-wrapper');
            displayWrapperElem.classList.add('cui-field-collection');
            displayWrapperElem.appendChild(labelWrapper);
            displayWrapperElem.appendChild(buttonWrapper);
            
            fileElem.classList.add('cui-hide-from-screen');
            fileElem.onchange = fileInputOnChange;
            
            parentElem.appendChild(displayWrapperElem);
    };

    _priv.uploadModal = {
        CLASSES : {
            "input":"feta-upload-modal-input",
            "modalOpen": "feta-upload-modal-open"
        }
    };

    _priv.uploadModal.init = () =>{
    };

    _priv.uploadModal.create = (evt, options) => {
        const defaultOptions = {
            "callback":"",
            "callbackArgs":"",
            "fileInputId":"feta-dynamic-file-upload-" + guid(),
            "validFileExtensions": "",
            "modalButtonText":"Upload File"
        };

        let fileInputLocation;
        let fileInput;
        let trigger;
        let fileUploadModal;

        //If event didn't come across shift the position of the options.
        if (!(evt instanceof Event) &&  (typeof evt !== 'object' ||  (typeof evt === 'object' && !evt.hasOwnProperty('target')))) {
            options = evt;
            evt = undefined;
        }

        options = Object.assign(defaultOptions, options);

        if(evt && evt.target){
            fileInputLocation = evt.target.parentNode;
            trigger = evt.target;
        }
        else{
            fileInputLocation = document.body;
        }

        fileInputId = options.fileInputId;

        const fileInputOnChange = (evt) =>{
            if(evt.target.value != ""){
                if(fileUploadModal){
                    fileUploadModal.hide();
                    fileUploadModal.destroy();
                }

                createFileModal();
            }
        };

        const createFileInput = (target, id) =>{
            //Create file input.
            let fileInput = document.createElement("input");
            fileInput.type = 'file';
            fileInput.id =  id;
            fileInput.name = id;
            fileInput.classList.add('cui-hide-from-screen');
            fileInput.classList.add(_priv.uploadModal.CLASSES.input);

            if(options.validFileExtensions){
                //Process filetype list.
                let fileString = "";

                if(typeof options.validFileExtensions === "string"){
                    fileString = options.validFileExtensions;
                }
                else if(Array.isArray(options.validFileExtensions)){
                    if(options.validFileExtensions.length>0){
                        for(let i=0; i<options.validFileExtensions.length;i++){
                            if(i >= 1){
                                fileString += ",";
                            }
                            fileString += options.validFileExtensions[i];
                        }
                    }
                }

                fileInput.dataset.validation = "hasFileExtension,validateFileExtension";
                fileInput.dataset.fileTypes = fileString;
            }
            else{
                fileInput.dataset.validation="hasFileExtension,validateFileExtension";
            }

            fileInput.setAttribute("aria-required", "true");
            fileInput.onchange = fileInputOnChange;

            target.appendChild(fileInput);

            return fileInput;
        };

        const createFileModal = () =>{

            let fileName = (fileInput.files[0]) ? fileInput.files[0].name: "";

            // Set up event handlers once the modal has been displayed
            const _setup = (modal) => {
                modal.$self
                    .find('.feta-upload-button-upload')
                        .on('click', {modal: modal}, _onUploadClick);

                modal.$self
                    .find('.feta-upload-button-clear')
                        .on('click', {modal: modal}, _onClearClick);

                modal.$self
                    .find('.feta-upload-button-browse')
                        .on('click', {modal: modal}, _onBrowseClick);

            };

            const _destroy = (modal) => {
                modal.hide();
                modal.destroy();
            };

            const _onUploadClick = (evt) =>{
                let fieldValidationResults = _priv.validate.getFieldValidationResults(fileInputId);

                _priv.removeUserMessagesFromElement("#"+fileInput.id+'_display_wrapper');

                if(fieldValidationResults.result){
                    const modal = evt.data.modal;
                    feta.functionCall(evt, options.callback, [options.callbackArgs]);
                    _destroy(modal);
                }
                else{
                    let fieldValidationMessages = [];

                    //Get error messages from validation results
                    if(fieldValidationResults.tests){
                        for(const testKey in fieldValidationResults.tests){
                            let test = fieldValidationResults.tests[testKey];
                            if(test.result !== true){
                                fieldValidationMessages.push(test.message);
                            }
                        }
                    }

                    if(fieldValidationMessages.length>0){
                        let fieldValidationMessagesJSON = [];

                        fieldValidationMessages.forEach((message)=>{
                            fieldValidationMessagesJSON.push({
                                "template": "message",
                                "type": 'error',
                                "text": message
                            });
                        });

                        _priv.processUserMessages({
                            "template": "userMessages",
                            "parameters": {
                                "field": [
                                    {
                                        "elem": "#"+fileInput.id+'_display_wrapper',
                                        "parameters": {
                                            "pageNotifier": false,
                                            "scroll": false,
                                        },
                                        "messages": fieldValidationMessagesJSON
                                    },
                                ]
                            }
                        });

                        fileUploadModal.adjustHeight();
                    }
                }
            };

            const _onClearClick = (evt) =>{
                const modal = evt.data.modal;
                _destroy(modal);

                //remove filename and retrigger modal
                fileInput.value = "";
                createFileModal();
            };

            const _onBrowseClick = (evt) =>{
                fileInput.click();
            };

            // Create and display the modal
            const _createModal = () => {
                const contentHTML = ' <div class="cui-row cui-inline-field"><div class="cui-col"><div class="cui-field cui-required feta-label-width-s"><div class="cui-field-main"><div class="cui-label"><span class="cui-desc">File name:</span></div><div class="cui-data"><span class="cui-value feta-upload-file-name" id="'+fileInput.id+'_display_wrapper">'+fileName+'</span><button type="button" class="cui-button feta-upload-button-clear">Clear</button></div></div></div></div></div>';

                const browseHTML = '<div class="cui-row cui-inline-field"><div class="cui-col"><div class="cui-field cui-required feta-label-width-s"><div class="cui-field-main"><div class="cui-label"><span class="cui-desc">File name:</span></div><div class="cui-data"><span class="cui-value feta-upload-file-name" id="'+fileInput.id+'_display_wrapper">Select a file</span><button type="button" class="cui-button feta-upload-button-browse">Browse</button></div></div></div></div></div>';

                const uploadHTML = '<div class="cui-row"><div class="feta-button-group"><button type="button" class="cui-button-primary feta-upload-button-upload">'+options.modalButtonText+'</button></div></div>';

                let modalHTML;

                if(fileInput.value){
                    modalHTML = contentHTML + uploadHTML;
                }
                else{
                    modalHTML = browseHTML + uploadHTML;
                }

                const $uploadModal = $.modal({
                    html: modalHTML,
                    modalClass: 'feta-upload-modal',
                    onCreate: (modal) => {
                        _setup(modal);
                    },
                    onHide: (modal) => {
                        _priv.removeUserMessagesFromElement("#"+fileInput.id+'_display_wrapper');
                    },
                    hideDestroy: true,
                    alwaysCentr:true,
                });

                $uploadModal.show();
                fileUploadModal = $uploadModal;
            };

            // Check to see if the module has been loaded yet
            if (require.defined('modal')) {
                // Create the modal immediately
                _createModal();
            }
            else {
                // Load the module and then create the modal
                cui.load('modal', () => {
                    _createModal();
                });
            }
        };

        //Check if the file input has been created.
        fileInput = document.querySelector("#"+fileInputId);

        // If th file input does not exist, create one
        if(!fileInput){
            fileInput = createFileInput(fileInputLocation, fileInputId);
        }

        //Clear the value if one already exists.
        fileInput.value = "";

        //Trigger click on fileinput to prompt file upload.
        fileInput.click();
    };

    _priv.clickBlocker = {
        hasInitialized: false,
        blockedElements: [],
        elementTimers:[],
        elementBlockDuration: 5000,
        timeoutMessage: "There was an issue with your request.",
        blockerClass : "feta-blocker-initalized",
        spinnerClass : "feta-click-blocker-spinner"
    };

    _priv.clickBlocker.init = () => {
        if(_priv.clickBlocker.hasInitialized){
            return;
        }

        // Event delegation for clicks because togglers may be dynamically added to the DOM
        $(document.body).on('click', '.feta-click-blocker', function (evt) {
            let element = evt.target;

            if(element){
                _priv.clickBlocker.addToElement(element);
            }
        });

        _priv.hasInitialized = true;
    };

    _priv.clickBlocker.displayTimeoutMessage = () => {
        _priv.processUserMessages({
            "template": "userMessages",
            "parameters": {
                "page": [
                    {
                        "template": "message",
                        "type": "error",
                        "text": _priv.clickBlocker.timeoutMessage,
                        "parameters":{

                        }
                    }
                ]
            }
        });
    };

    _priv.clickBlocker.addToElement = (element) => {
        if(element.classList.contains(_priv.clickBlocker.blockerClass) || element.classList.contains(_priv.clickBlocker.spinnerClass)){
            return;
        }

        _priv.clickBlocker.blockedElements.push(element);
        element.classList.add(_priv.clickBlocker.blockerClass);
        clickBlocker.addClickBlocker(element);
        _priv.clickBlocker.addToPage();

        let timerId = setTimeout(
            function(){
                _priv.clickBlocker.removeFromElement(element);
                _priv.clickBlocker.displayTimeoutMessage();
                _priv.clickBlocker.removeFromPage();

                //Remove timer from list of timers.
                let timerIndex = _priv.clickBlocker.elementTimers.indexOf(timerId);
                if(timerIndex !== -1){
                    _priv.clickBlocker.elementTimers.splice(timerIndex, 1);
                }
            },
            _priv.clickBlocker.elementBlockDuration
        );

        _priv.clickBlocker.elementTimers.push(timerId);
    };

    _priv.clickBlocker.removeFromElement = (element) => {
        let elementIndex =  _priv.clickBlocker.blockedElements.indexOf(element);

        if(elementIndex !== -1){
            _priv.clickBlocker.blockedElements.splice(elementIndex, 1);
        }

        element.classList.remove(_priv.clickBlocker.blockerClass);

        clickBlocker.removeClickBlocker(element);
    };

    _priv.clickBlocker.addToPage = () => {
        clickBlocker.blockPage();
    };

    _priv.clickBlocker.removeFromPage = () => {
        clickBlocker.removeFromPage();
    };

    _priv.clickBlocker.removeFromAll = () => {
        for(let i=0; i < _priv.clickBlocker.elementTimers.length; i++){
            clearTimeout(_priv.clickBlocker.elementTimers[i]);
        }
        _priv.clickBlocker.elementTimers = [];

        for(let i=0; i < _priv.clickBlocker.blockedElements.length; i++){
            let element = _priv.clickBlocker.blockedElements[i];
            element.classList.remove(_priv.clickBlocker.blockerClass);
            clickBlocker.removeClickBlocker(element);
        }
        _priv.clickBlocker.blockedElements = [];

        _priv.clickBlocker.removeFromPage();
    };


    _priv.toggleFieldInstructions = () => {
        const $instructions = $('.feta-field-instructions');
    };

    const getFunctionFromName = function _getFunctionFromName (str) {
        var func = window[str];
        var pieces;
        var i;

        if (typeof func === 'function') {
            return func;
        }

        pieces = str.split('.');

        func = window[pieces[0]];

        i = 1;
        while (func && i < pieces.length) {
            func = func[pieces[i]];

            i++;
        }

        if (typeof func !== 'function') {
            journal.log({type: 'error', owner: 'app', module: 'feta', submodule: 'getFunctionFromName'}, 'Could not get function from the name: ', str);
        }

        return func;
    };

    /**
     * Renders HTML for a data object containing contents, tables, and/or messages
     *
     * @param {object} data
     * @param {object} settings
     */
    const render = (data = {}, settings = {}) => {



        let $container;

        if (!data) {
            journal.log({type: 'error', owner: 'app', module: 'feta', submodule: 'render'}, 'No data provided');

            return false;
        }
        else if (typeof data === 'string') {
            try {
                data = JSON.stringify(data.trim());
            }
            catch (e) {
                journal.log({type: 'error', owner: 'app', module: 'feta', submodule: 'render'}, 'Could not parse data string: "' + data + '"');

                return false;
            }
        }

        // Apply new `fwData`
        if (data.fwData) {

            //Some parts of fwData are page specific and should not be carried over.
            //If a legend is provided, completely replace legend object present in window.
            if(data.fwData.legend){
                window.fwData.legend = data.fwData.legend;
            }

            window.fwData = $.extend(true, {}, window.fwData, data.fwData);
            feta.init();
        }

        // Get the container element
        if (settings && settings.$container && settings.$container.length) {
            $container = settings.$container;
        }
        else if (settings && settings.container) {
            $container = $(settings.container);
        }
        else if (data.container) {
            $container = $(data.container);
        }

        if (!$container || !$container.length) {
            journal.log({type: 'error', owner: 'app', module: 'feta', submodule: 'render'}, 'No container provided. Settings: ', settings);

            return false;
        }

        // Data structure (all properties are optional):
        //
        // {
        //     contents: {}, // JSON representation of UI, to be passed to the rendered
        //     tables: [],   // Table JSON object
        //     messages: {}, // Messages to be displayed
        // }

        // Render HTML
        if (data.contents) {
            // Unmount any React components in the container

            unmountComponents($container);

            $container.html(renderer.getHTML(data));

            // Setup datepickers
            $container.find('.cui-date').datepicker();
        }

        // Render tables
        if (data.tables && data.tables.length) {
            table.init(data.tables);
        }

        // Render message list
        if (data.inboxes && data.inboxes.length) {
            data.inboxes.forEach((list) => {
                inbox.init(list);
            });
        }

        //TODO: Display messages
        if (data.messages) {
            //Perform any project specific processinging before passing the messages to the userMessages component for creation.
            _priv.processUserMessages(data.messages);
        }
    };


    ///////////
    // Other //
    ///////////

    const getElementByString = function getElementByString(str){
        // First check if passed str uses css sleector, if so do lookup and return element.

        try {
            let queryElement = document.querySelector(str);
            if(queryElement){
                return queryElement;
            }
        } catch (e) {/*Invalid query selector*/}

        if(str instanceof Node){
        	return str;
        }

        let strippedString = str.replace("#", "").replace(".", "").trim();
        if(strippedString != ""){

            // If does not contain a css selector first lookup element by ID,
            let idElement = document.getElementById(strippedString);
            if(idElement){
                return idElement;
            }

            try{
                // If element is not found search for element by name
                let nameElement = document.querySelector('[name='+strippedString+']');
                if(nameElement){
                    return nameElement;
                }
            }catch(e){/*Invalid name selector*/}

            try{
                // If element cann't be found try looking by class.
                let classElement = document.querySelector('.'+ strippedString);
                if(classElement){
                    return classElement;
                }
            }catch(e){/*Invalid class selector*/}
        }

        // element could not be found, throw error.
        journal.log({type: 'error', owner: 'app', module: 'feta', submodule: 'getElementByString'}, 'No element matching "'+ str +'" found');

        return false;
    };


    // Opens a new window and loads a URL or submits a form into it
    const openInNewWindow = function openInNewWindow (dest) {
        // URL was provided
        if (typeof dest === 'string') {
            window.open(dest/* , winName, 'scrollbars=yes,menubar=yes,resizable=yes,toolbar=no,width=900,height=700' */);
        }
        // Form was provided
        else {
            // Create a name for a window so we can target it
            const winName = 'NewWindow_' + guid();

            // Open a blank window
            window.open('', winName/* , 'scrollbars=yes,menubar=yes,resizable=yes,toolbar=no,width=900,height=700' */);

            // Set the target to the blank window
            dest.target = winName;

            // Submit
            dest.submit();
        }
    };

    //Unmounts react components within a given container.
    const unmountComponents = function unmountComponents(elem){
        if (elem instanceof Node) {
            elem = $(elem);
        }

        elem.find('[data-reactroot]').each(function () {
            //Check for parentNode since it can be removed when a sibling react component is unmounted.
            if (this.parentNode && !ReactDOM.unmountComponentAtNode(this.parentNode)) {
                journal.log({type: 'error', owner: 'app', module: 'feta', submodule: 'render'}, 'React component was not successfully unmounted. Parent element: ', this.parentNode);
            }
        });
    };

    // Detect browser support immediately
    (function _detectBrowserSupport () {
        // iOS detection
        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && /AppleWebKit/.test(navigator.userAgent) && /Safari/.test(navigator.userAgent) && !window.MSStream && !/Android/.test(navigator.userAgent)) {
            // In the conditional above, the first three conditions look for the typical strings in the UA. However Windows phones also include those strings (see https://msdn.microsoft.com/en-us/library/hh869301(v=vs.85).aspx) which is why the fourth token tests for `window.MSStream`. Lastly, I added a check for `Android` in case MS drops the `MSStream` global in the future because I can't imagine an iOS device having 'Android' in its UA. (CP 12/1/16)
            // See also: http://stackoverflow.com/a/9039885/348995

            document.documentElement.classList.add('ios');

            browser.os = 'iOS';
            browser.name = 'Safari';
        }

        // Sticky positioning
        // Adapted from http://kangax.github.io/cft/#IS_POSITION_FIXED_SUPPORTED
        browser.supports.positionSticky = (function () {
            const container = document.body;
            let el;
            let originalHeight;
            let originalScrollTop;
            let elementTop;
            let isSupported;

            if (document.createElement && container && container.appendChild && container.removeChild) {
                el = document.createElement('div');

                if (!el.getBoundingClientRect) {
                    return null;
                }

                el.innerHTML = 'x';
                el.style.cssText = 'position:-webkit-sticky;position:sticky;top:100px;';
                container.appendChild(el);

                originalHeight = container.style.height;

                originalScrollTop = container.scrollTop;
                container.style.height = '3000px';
                container.scrollTop = 498;

                elementTop = el.getBoundingClientRect().top;

                container.style.height = originalHeight;

                isSupported = (elementTop === 200);
                // if (!isSupported) { console.warn('elementTop should be 200 but instead it is ', elementTop); }

                container.removeChild(el);
                container.scrollTop = originalScrollTop;

                return isSupported;
            }

            return null;
        })();
    })();

    /**
     * @public
     *
     * Public API
    */
    return {
        init: _init,
        pageInfo,
        render,
        fetch,
        functionCall,
        form,
        requestMap,
        browser,
        confirm: {
            setup: _priv.confirm.setup,
            create: _priv.confirm.create,
        },
        processUserMessages: _priv.processUserMessages,
        userMessage: {
            addMessage:  _priv.processUserMessages,
            removeMessage:  _priv.removeUserMessage,
            removeMessageById: _priv.removeUserMessageById,
            messageStore: userMessage.messageStore,
            removeMessagesFromElement: _priv.removeUserMessagesFromElement,
            userMessagesFieldsInTables: _priv.userMessageFieldsInTables
        },
        getFunctionFromName,
        openInNewWindow,
        validate: {
            field: _priv.validate.field,
            form: _priv.validate.form,
            formWithCallback: _priv.validate.formWithCallback,
        },
        uploadModal:{
            create: _priv.uploadModal.create
        },
        getElementByString,
        iflowFieldMethods: iflow.fieldMethods
    };
});
