define('animation',['jquery', 'kind'], function ($, kind) {
    // var NAMESPACE = 'animation';
    // var VERSION = '1.0.0';

    var CLASSES = {
            hidden: 'cui-hidden',
        };

    var TRANSITION = {
            height: {
                value: 'height',
            }
        };

    var DEFAULT_SETTINGS = {
            finalValue: -1,
            duration: '150ms',
            timingFunction: 'ease-in-out',
            done: null,
        };

    var isFirefox = (navigator.userAgent.indexOf('Firefox') !== -1);

    // Private method namespaces
    var height = {};
    var events = {};

    ////////////////////
    // Public methods //
    ////////////////////

    /**
     * Defines properties for a block and adds a toggler
     * @param   {object}  options  Settings object (or an array of them) to be applied
     */
    var _collapseHeight = function _collapseHeight (elem, options) {
        var settings = $.extend(true, {}, DEFAULT_SETTINGS, options);

        // Verify argument
        if (!elem || kind(elem) !== 'element') {
            return false;
        }

        height.collapseElement(elem, settings);
    };

    var _expandHeight = function _expandHeight (elem, options) {
        var settings = $.extend(true, {}, DEFAULT_SETTINGS, options);

        // Verify argument
        if (!elem || kind(elem) !== 'element') {
            return false;
        }

        height.expandElement(elem, settings);
    };

    var _toggleHeight = function _toggleHeight (elem, options) {
        var settings = $.extend(true, {}, DEFAULT_SETTINGS, options);

        // Verify argument
        if (!elem || kind(elem) !== 'element') {
            return false;
        }

        if (elem.classList.contains(CLASSES.hidden)) {
            height.expandElement(elem, settings);
        }
        else {
            height.collapseElement(elem, settings);
        }
    };

    var _getDefaultSettings = function _getDefaultSettings () {
        return $.extend(true, {}, DEFAULT_SETTINGS);
    };

    /////////////////////
    // General methods //
    /////////////////////

    /**
     * Make an element appear, using animation if available
     * @param  {element} elem  DOM element
     */
    height.expandElement = function _expandElement (elem, settings) {
        var parent;
        var startHeight;
        var endHeight;
        var evtData = {};

        if (!elem.classList.contains(CLASSES.hidden) && settings.finalValue === -1) {
            return false;
        }

        elem.classList.remove(CLASSES.hidden);

        evtData.settings = settings;

        // Store current overflow values
        evtData.overflowY = elem.style.overflowY;
        evtData.overflowX = elem.style.overflowX;

        // Transition height from 0 to `auto`
        // Since you cannot transition to/from `auto`, instead get the content's
        //     absolute height, X, and transition from 0 to X
        // http://n12v.com/css-transition-to-from-auto/

        // Momentarily expand the content to allow it to reach its full height
        if (settings.finalValue !== -1) {
            endHeight = settings.finalValue;
            evtData.finalValue = settings.finalValue;

            startHeight = getComputedStyle(elem).height;

            // The first time an element is animated its natural height may match the intended end height, resulting in no transition, just a snap into place
            if (startHeight === endHeight) {
                startHeight = '0px';
            }
        }
        else {
            elem.style.height = 'auto';
            // Measure the full height (`jQuery.height()` rounds this value)
            endHeight = getComputedStyle(elem).height;
            startHeight = '0px';
        }

        // Re-collapse the contents
        elem.style.height = startHeight;

        // It's actually the containing element that will appear to animate, so we need to clip the contents to prevent it from spilling out of the container during the transition
        if (settings.finalValue !== -1) {
            elem.style.overflowY = 'auto';
            elem.style.overflowX = 'hidden';
        }
        else {
            elem.style.overflowY = 'hidden';

            // Prevent content from spilling out of the parent element
            // Note that a bug in Firefox prevents the `transitionend` event from firing consistently, see https://stackoverflow.com/q/16222805/348995
            if (!isFirefox &&  elem.parentNode.nodeName !== 'BODY') {
                parent = elem.parentNode;

                // Store current overflow value
                evtData.parentOverflowY = parent.style.overflowY;

                // Set to `hidden` for the transition
                parent.style.overflowY = 'hidden';
            }
        }

        // Force the browser to repaint by reading `offsetHeight`
        elem.offsetHeight; // jshint ignore:line

        // Pull transition details from the CSS
        elem.style.transition = TRANSITION.height.value + ' ' + settings.duration + ' ' + settings.timingFunction;

        // Watch for the transition to end
        $(elem).one('transitionend', evtData, events.height.onShowTransitionEnd);

        // Finally, begin expanding the contents, and add some padding
        elem.style.height = endHeight;
    };

    /**
     * Make an element disappear, using animation if possible
     * @param  {element} elem  DOM element
     */
    height.collapseElement = function _collapseElement (elem, settings) {
        var evtData = {};
        var parent;

        // Check if it's already hidden
        if (elem.classList.contains(CLASSES.hidden)) {
            return false;
        }

        evtData.settings = settings;

        // Store current overflow values
        evtData.overflowY = elem.style.overflowY;
        evtData.overflowX = elem.style.overflowX;

        // Transition the contents's height from `auto` to 0

        // Give the contents an absolute (non-`auto`) height
        elem.style.height = getComputedStyle(elem).height;

        // Force the browser to repaint by reading `offsetHeight`
        elem.offsetHeight; // jshint ignore:line

        // Clip the contents from view while the container is collapsing
        if (settings.finalValue !== -1) {
            elem.style.overflowY = 'auto';
            elem.style.overflowX = 'hidden';
        }
        else {
            elem.style.overflowY = 'hidden';

            // Prevent content from spilling out of the parent element
            // Note that a bug in Firefox prevents the `transitionend` event from firing consistently, see https://stackoverflow.com/q/16222805/348995
            if (!isFirefox && elem.parentNode.nodeName !== 'BODY') {
                parent = elem.parentNode;

                // Store current overflow value
                evtData.parentOverflowY = parent.style.overflowY;

                // Set to `hidden`
                parent.style.overflowY = 'hidden';
            }
        }

        // Setup transition
        elem.style.transition = TRANSITION.height.value + ' ' + settings.duration + ' ' + settings.timingFunction;

        // Watch for the transition to end
        $(elem).one('transitionend', evtData, events.height.onHideTransitionEnd);

        // Start transition
        if (settings.finalValue !== -1) {
            evtData.finalValue = settings.finalValue;
            elem.style.height = settings.finalValue;
        }
        else {
            elem.style.height = '0px';
        }
    };


    ////////////
    // Events //
    ////////////

    events.height = {};

    /**
     * Sets the appropriate styles and classes when an element has finished displaying
     * @param  {event} evt  Transitionend event
     */
    events.height.onShowTransitionEnd = function _onShowTransitionEnd (evt) {
        var elem = evt.target;

        // Restore the element
        elem.style.transition = '';

        elem.setAttribute('aria-expanded', 'true');
        elem.setAttribute('aria-hidden', 'false');

        // Reinstate an automatic height (in case the layout changes, etc)
        if (evt.data.finalValue) {
            elem.style.height = evt.data.finalValue;
        }
        else {
            elem.style.height = 'auto';
        }

        // Restore overflow values
        if (evt.data.overflowY) {
            elem.style.overflowY = evt.data.overflowY;
        }
        else if (evt.data.finalValue) {
            elem.style.overflowY = 'auto';
        }
        else {
            elem.style.overflowY = 'visible';
        }

        if (evt.data.overflowX) {
            elem.style.overflowX = evt.data.overflowX;
        }
        else {
            elem.style.overflowX = 'visible';
        }

        // Restore the parent element
        if (evt.data.parentOverflowY) {
            elem.parentNode.style.overflowY = evt.data.parentOverflowY;
        }

        if (typeof evt.data.settings.done === 'function') {
            evt.data.settings.done(elem, evt.data.settings, evt);
        }
    };

    /**
     * Sets the appropriate styles and classes when an element has finished hiding
     * @param  {event} evt  Transitionend event
     */
    events.height.onHideTransitionEnd = function _onHideTransitionEnd (evt) {
        var elem = evt.target;

        // Restore the element
        elem.style.transition = '';

        // // Reinstate an automatic height (in case the layout changes, etc)
        if (evt.data.finalValue) {
            elem.style.height = evt.data.finalValue;
        }
        else {
            elem.style.height = 'auto';
        }

        // Hide if it's now completely collapsed
        if (!evt.data.finalValue || parseInt(evt.data.finalValue, 10) === 0) {
            elem.classList.add(CLASSES.hidden);
            elem.setAttribute('aria-expanded', 'false');
            elem.setAttribute('aria-hidden', 'true');
        }

        // Restore overflow values
        if (evt.data.overflowY) {
            elem.style.overflowY = evt.data.overflowY;
        }
        else if (evt.data.finalValue) {
            elem.style.overflowY = 'auto';
        }
        else {
            elem.style.overflowY = 'visible';
        }

        if (evt.data.overflowX) {
            elem.style.overflowX = evt.data.overflowX;
        }
        else {
            elem.style.overflowX = 'visible';
        }

        // Restore the parent element
        if (evt.data.parentOverflowY) {
            elem.parentNode.style.overflowY = evt.data.parentOverflowY;
        }

        if (typeof evt.data.settings.done === 'function') {
            evt.data.settings.done(elem, evt.data.settings, evt);
        }
    };

    //////////////////////////////////////////
    // Expose public properties and methods //
    //////////////////////////////////////////

    return {
        getDefaultSettings: _getDefaultSettings,
        height: {
            expand: _expandHeight,
            collapse: _collapseHeight,
            toggle: _toggleHeight
        }
    };
});

