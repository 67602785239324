define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    // SVG source file: magnifying-glass.svg (in the table component's images folder)
    function RenderIconSearch (props) {
        return (
            <svg
                className={props.className}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
            >
                <path
                    d="M20.916 23.878l-5.68-5.68c-1.557 1.018-3.418 1.616-5.425 1.616-5.474 0-9.902-4.438-9.902-9.912C-.092 4.437 4.335 0 9.81 0c5.466 0 9.902 4.437 9.902 9.902 0 1.91-.538 3.69-1.47 5.2l5.722 5.72c.302.294.048 1.03-.56 1.635l-.85.854c-.61.607-1.344.86-1.638.568zM16.42 9.902c0-3.654-2.957-6.612-6.61-6.612-3.653 0-6.62 2.958-6.62 6.612 0 3.653 2.967 6.62 6.62 6.62s6.61-2.966 6.61-6.62z"
                    fill="#bababb"
                />
            </svg>
        );
    }

    RenderIconSearch.propTypes = {
        className: ReactPropTypes.string,
    };

    RenderIconSearch.defaultProps = {
        className: '',
    };

    return RenderIconSearch;
});
