define(['react', 'reactproptypes', 'jquery', 'popover', 'kind', 'TableSortIcon', 'renderer', 'RenderItem', 'RenderIconMessage', 'guid'], function (React, ReactPropTypes, $, popover, kind, TableSortIcon, renderer, RenderItem, RenderIconMessage, guid) {
    class TablePaging extends React.Component {
        constructor (props) {
            super(props);
        }
    

        ////////////
        // Render //
        ////////////
        render(){
        	
            const sourceTable = this.props.source;
            
            //Need to modify the IDs of the paging controls, so create a deep copy of paging to work with from here out since it is shared between instances of <TablePaging>
            const paging = JSON.parse(JSON.stringify( sourceTable.paging));
            
            const pagingLocation = (this.props.location) ? this.props.location : "";

            let isSmallSize = this.props.isSmallSize;
            let pagingElems = [];

            let pagingViewLinks = [];


            if(paging.first && paging.first !== null){
                let firstSource = paging.first;
                firstSource.input.icon = "first";
                firstSource.input.attributes.id = firstSource.input.attributes.id + "_" +  pagingLocation;
                let firstControl = (
                    <RenderItem
                        source={firstSource}
                    />
                );
                pagingElems.push(firstControl);
            }

            if(paging.previous && paging.previous !== null){
                let previousSource = paging.previous;
                previousSource.input.icon = "previous";
                previousSource.input.attributes.id = previousSource.input.attributes.id + "_" +  pagingLocation;
                let previousControl = (
                    <RenderItem
                        source={previousSource}
                    />
                );
                pagingElems.push(previousControl);
            }

            if(paging.text && paging.text !== ""){
                pagingTextControl = (
                    <div className="feta-table-paging-text-wrapper">
                        {paging.text}
                    </div>
                );
                pagingElems.push(pagingTextControl);
            }

            if(paging.next && paging.next !== null){
                let nextSource = paging.next;
                nextSource.input.icon = "next";
                nextSource.input.attributes.id = nextSource.input.attributes.id + "_" +  pagingLocation;
                let nextControl = (
                    <RenderItem
                        source={nextSource}
                    />
                );
                pagingElems.push(nextControl);
            }

            if(paging.last && paging.last !== null){
                let lastSource = paging.last;
                lastSource.input.icon = "last";
                lastSource.input.attributes.id = lastSource.input.attributes.id + "_" +  pagingLocation;
                let lastControl = (
                    <RenderItem
                        source={lastSource}
                    />
                );
                pagingElems.push(lastControl);
            }

            // if(paging.size && paging.size.length > 0){
            //     paging.size.forEach((size) => {
            //         if(size == paging.currentSize){
            //             pagingViewLinks.push(<span className="feta-table-paging-view-link-current">{size}</span>);
            //         }
            //         else{
            //             pagingViewLinks.push(<a href="#" className="feta-table-paging-view-link">{size}</a>);
            //         }

            //     });
            // }
            // else{
            //     pagingViewLinks.push(
            //         <a href="#" className="feta-table-paging-view-link">25</a>
            //     );
            //     pagingViewLinks.push(
            //         <a href="#" className="feta-table-paging-view-link">50</a>
            //     );
            //     pagingViewLinks.push(
            //         <a href="#" className="feta-table-paging-view-link">All</a>
            //     );
            // }

            // // Only show the footer controls element if there are elements to show.
            // if(pagingElems.length && pagingElems.length > 0){
            //     return (
            //         <div className="feta-table-paging-wrapper">
            //             <div className="feta-table-paging-pane-left"></div>
            //             <div className="feta-table-paging-pane-center">
            //                 <div className="feta-table-paging-controls">
            //                     {pagingElems}
            //                 </div>
            //             </div>
            //             <div className="feta-table-paging-pane-right">
            //                 <div className="feta-table-paging-view-links">
            //                     View: {pagingViewLinks}                                
            //                 </div>
            //             </div>
            //         </div>
            //     );
            // }

            if(pagingElems.length && pagingElems.length > 0){
                return (
                    <div className="feta-table-paging-wrapper">
                        <div className="feta-table-paging-pane-left"></div>
                        <div className="feta-table-paging-pane-center">
                            <div className="feta-table-paging-controls">
                                {pagingElems}
                            </div>
                        </div>
                        <div className="feta-table-paging-pane-right"></div>
                    </div>
                );
            }

            return null;
        }
    }

    TablePaging.propTypes = {
        source: ReactPropTypes.shape({}).isRequired,
        isSmallSize: ReactPropTypes.bool,        
    };

    TablePaging.defaultProps = {
    	isSmallSize: false,        
    };

    return TablePaging;
});
