define(['react', 'reactproptypes', 'TableRow'], function (React, ReactPropTypes, TableRow) {
    class TableFoot extends React.Component {
        constructor (props) {
            super(props);
        }


        render () {
            const sourceTable = this.props.source;

            // Ensure a footer exists for this table
            if (sourceTable.foot && sourceTable.foot.rows) {
                const rows = sourceTable.foot.rows;

                if(this.props.isSmallSize && sourceTable.renderStyle && sourceTable.renderStyle.hideFooter && sourceTable.renderStyle.hideFooter === true){
                    return null;
                }

                return (
                    <this.props.tagNames.tfoot className="feta-table-elem-tfoot">
                        {rows.map((row) => {
                            return (
                                <TableRow
                                    row={row}
                                    tagNames={this.props.tagNames}
                                    isSmallSize={this.props.isSmallSize}
                                    key={row.key}
                                    rowIndex={row.index}
                                    isHeader={false}
                                    source={sourceTable}
                                    getTableCell={this.props.getTableCell}
                                    isFooter
                                />
                            );
                        })}
                    </this.props.tagNames.tfoot>
                );
            }
            // No footer
            else {
                return null;
            }
        }
    }

    TableFoot.propTypes = {
        source: ReactPropTypes.shape({}),
        tagNames: ReactPropTypes.shape({}),
        isSmallSize: ReactPropTypes.bool,
        getTableCell: ReactPropTypes.func,
    };

    TableFoot.defaultProps = {
        source: {},
        tagNames: {},
        isSmallSize: false,
        getTableCell: () => {},
    };

    return TableFoot;
});
