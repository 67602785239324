define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    // SVG source file: feta-envelope-open.svg
    const RenderIconEnvelopeOpen = (props) => {
        return (
            <svg
                className={(props && props.className) ? props.className : ''}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-949 951 24 24"
                width="24"
                height="24"
            >
                <switch
                    transform="matrix(.245 0 0 .245 -716.85 717.857)"
                    fill="#555"
                >
                    <g>
                        <path
                            d="M-899 952l-41.3 41.3v56.7h82.7v-56.7L-899 952zm0 4.9l36.4 36.4h-72.8l36.4-36.4zm35.2 39.9l-22.5 22.5-6.6-6.6h-12.3l-6.6 6.6-22.5-22.5h70.5zm-73 2.4l22.6 22.6-22.6 22.6v-45.2zm2.8 47.3l30.3-30.3h9.4l30.3 30.3h-70zm72.8-2.1l-22.6-22.6 22.6-22.6v45.2z"
                        />
                    </g>
                </switch>
            </svg>
        );
    };

    RenderIconEnvelopeOpen.propTypes = {
        className: ReactPropTypes.string,
    };

    RenderIconEnvelopeOpen.defaultProps = {
        className: '',
    };

    return RenderIconEnvelopeOpen;
});
