define(['guid'], function(guid) {

	const NAMESPACE = 'listbox';
	const VERSION = '1.0.0';

	const SUFFIX = "listbox";
	const CLASSES = {
		wrapper: SUFFIX + "-wrapper",
		select: SUFFIX + "-select",
		setup: SUFFIX + "-setup",

		toggleWrapper: SUFFIX + "-toggle-wrapper",
		toggle: SUFFIX + "-toggle",
		toggleOpen: SUFFIX + "-toggle-open",
		contentList: SUFFIX + "-toggle-list",
	};

	const DEFAULT_CONFIG = {
		wrapperClass: CLASSES.wrapper		
	};

	let userConfig = {};

	let listBoxes = [];

	///////////////////////
	// Class definitions //
	///////////////////////
	
	// Takes reference of HTML Select node, and replaces with custom 'lisBox' component
	class listBox {

		// Private methods
		constructor(selectElem) {
			const _createToggleWrapper = () => {
				let toggleWrapper = document.createElement('div');
				toggleWrapper.classList.add(CLASSES.toggleWrapper);

				return toggleWrapper;
			}

			const _createToggle = () => {
				let toggle = document.createElement("button");
				toggle.classList.add(CLASSES.toggle);
				toggle.id = this._toggleID;
				toggle.setAttribute("type", "button");
				toggle.setAttribute("aria-haspopup", "listbox");

				toggle.setAttribute("data-toggles-list", this._contentListID);
				toggle.textContent = "";

				toggle.onclick = this._toggleOnClick.bind(this);

				return toggle;
			}

			const _createContentList = () => {
				let contentList = document.createElement('ul');
				contentList.classList.add(CLASSES.contentList);
				contentList.id = this._contentListID;
				contentList.setAttribute('tabindex', "-1");
				contentList.setAttribute('size', "1");
				contentList.setAttribute('role', "listbox");
				contentList.setAttribute('aria-labelledby', "");
				contentList.style.display = "none";

				contentList.onkeydown = this._contentListOnKeyDown.bind(this);
				contentList.onblur = this._contentListOnBlur.bind(this);

				let options = selectElem.querySelectorAll('option');

				// populate content list
				if (options.length > 0) {
					options.forEach((option, index) => {
						let item = document.createElement('li');
						let textContent = option.text;
						item.setAttribute('role', 'option');
						item.textContent = textContent;
						item.setAttribute('data-value', option.value);

						option.id = (option.id) ? option.id : this._instanceID + "_" + guid(option);
						item.setAttribute('id', option.id);
						item.setAttribute('tabindex', '-1');

						// set the first option as default
						if (index == 0) {
							toggle.textContent = textContent;
							contentList.setAttribute('aria-activedescendant', item.id);
						}

						// update toggle text if option is selected.
						if (option.getAttribute("selected") == "selected") {
							item.setAttribute('data-selected', "selected");
							toggle.textContent = textContent;

							// set active descendant
							contentList.setAttribute('aria-activedescendant', item.id);
						}

						// track max content length.
						if (textContent.length > maxContentLength) {
							maxContentLength = textContent.length;
						}

						item.onclick = this._listItemClick.bind(this);
						
						contentList.appendChild(item);
					});
				}
				return contentList;
			}

			// set instance variables
			this._selectElem = selectElem;
			this._instanceID = (selectElem.id) ? selectElem.id : SUFFIX + "_" + guid(selectElem);
			this._toggleID = this._instanceID + "_toggle";
			this._contentListID = this._instanceID + "_contentList";
			this._isOpen = false;
			this._typeAheadSequence = "";			
			this._outsideCloseEventSet = false;	

			let maxContentLength = 0;
			let widthFactor = 14;
			let maxToggleWidth = 500;
			let parent = selectElem.parentElement;

			// create listbox pieces
			let toggleWrapper = _createToggleWrapper();
			let toggle = _createToggle();
			let contentList = _createContentList();

			let calculatedWidth = maxContentLength * widthFactor;
			let toggleWidth = (calculatedWidth < maxToggleWidth) ? calculatedWidth : maxToggleWidth;

			toggle.style.width = toggleWidth + "px";

			toggleWrapper.appendChild(toggle);
			toggleWrapper.appendChild(contentList);
			parent.insertBefore(toggleWrapper, selectElem.nextSibling);

			// save dom references of listbox pieces 
			this._toggleWrapper = toggleWrapper;
			this._contentList = contentList;
			this._toggle = toggle;
		}

		_toggleOnClick(evt) {
			if (this._isOpen) {
				_hideListBoxes();
			} else {
				_hideListBoxes();
				this.open();
			}
		}

		_listItemClick(evt) {
			let selectedItem = evt.target;

			if(selectedItem){
				this._selectListItem(selectedItem);	
			}			

			// close content list
			this.close();
		}

		_highlightSelectedItem() {
			let itemCurrentSelected = this._contentList.querySelector('[data-selected="selected"');

			if (itemCurrentSelected) {
				itemCurrentSelected.focus();
			} else {
				this._contentList.focus();
			}
		}

		_highlightFirstItem() {
			this._contentList.firstChild.focus();
		}

		_highlightLastItem() {
			this._contentList.lastChild.focus();
		}

		_highlightPreviousItem(loopSelection) {
			// update selected index in contentlist
			let itemCurrentSelected = this._contentList.querySelector(":focus");
			if (itemCurrentSelected) {

				let nextSelected = itemCurrentSelected.previousSibling;

				if (nextSelected) {
					nextSelected.focus();
				} else if(loopSelection) {
					// set last item as focused
					this._contentList.lastChild.focus();
				} else{
					this.close();
				}
			} else {
				this._contentList.firstChild.focus();
			}
		}

		_highlightNextItem() {
			let itemCurrentSelected = this._contentList.querySelector(":focus");
			if (itemCurrentSelected) {

				let nextSelected = itemCurrentSelected.nextSibling;
				if (nextSelected) {
					nextSelected.focus();
				} else {
					// set first item as focused					
					this._contentList.firstChild.focus();
				}
			} else {
				this._contentList.firstChild.focus();
			}
		}

		_selectHighlightedItem() {
			let focusedItem = this._contentList.querySelector(":focus");

			if (focusedItem) {
				this._selectListItem(focusedItem);
			}

			this.close();
		}

		_selectListItem(listItem) {
			let selectedValue = listItem.getAttribute('data-value');
			let selectedText = listItem.textContent;

			// update select element
			this._selectElem.options[this._selectElem.selectedIndex].value = selectedValue;
			this._selectElem.options[this._selectElem.selectedIndex].textContent = selectedText;

			// update toggle text
			this._toggle.textContent = selectedText;

			// deselect any selected items. 
			let itemCurrentSelected = this._contentList.querySelector('[data-selected="selected"');
			
			if (itemCurrentSelected) {
				itemCurrentSelected.removeAttribute('data-selected');
				itemCurrentSelected.removeAttribute('aria-selected');
			}

			listItem.setAttribute('data-selected', "selected");
			listItem.setAttribute('aria-selected', "true");

			this._toggle.setAttribute('aria-activedescendant', listItem.id);
		}

		_typeAhead() {
			let optList = Array.prototype.slice.call(this._contentList.childNodes);
			let testSequence = this._typeAheadSequence.toLowerCase();
			let matchedBefore = false;
			let matchedAfter = false;
			let pastFocused = false;
			
			optList.some(function (opt, optIndex) {
				let text = opt.textContent.substr(0, testSequence.length).toLowerCase();
				
				if(opt == document.activeElement){
					pastFocused = true;					
				}
				else{
					if(pastFocused){
						if(text === testSequence){
							
							matchedAfter = opt;
							return true;
						}
					}
					else{
						if(text === testSequence){
							if(!matchedBefore){
								matchedBefore = opt;
							}
						}	
					}
				}				
			});

			if(matchedAfter){
				matchedAfter.focus();
			}
			else if(matchedBefore){
				matchedBefore.focus();
			}
		}

		_contentListOnKeyDown(evt) {
			let keyCode = evt.keyCode;

			if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105)) {
				
				// single letter matching
				this._typeAheadSequence = String.fromCharCode(keyCode);
				
				// letter group matching
				// this._typeAheadSequence += String.fromCharCode(keyCode);

				this._typeAhead();
			} else {
				
				// reset matching sequence since a letter wasn't pressed. 
				this._typeAheadSequence = "";

				if (keyCode) {

					switch (keyCode) {
						case 38: // up
							this._highlightPreviousItem();
							evt.preventDefault();
							break;

						case 40: // down
							this._highlightNextItem();
							evt.preventDefault();
							break;

						case 32: // space	
						case 13: // enter
							this._selectHighlightedItem();
							evt.preventDefault();
							break;

						case 36: // home
							this._highlightFirstItem();
							evt.preventDefault();
							break;

						case 35: // end
							this._highlightLastItem();
							evt.preventDefault();
							break;

						case 9: // tab
							if (event.shiftKey) {
								this._highlightPreviousItem(true);
							} else {
								this._highlightNextItem();
							}
							evt.preventDefault();
							break;

						case 27: // escape
							this.close();
							evt.preventDefault();
							break;
						
						case 39: // right
						case 37: // left
							evt.preventDefault();
							break;

						default:
					}
				}
			}
		}

		_contentListOnBlur(evt) {
			if (evt.srcElement.contains(evt.target)) {
				evt.preventDefault();
			}
		}

		_onClickOutside(element, callback) {
			const outsideClickListener = (evt) => {
				if (!element.contains(event.srcElement) && (event.srcElement !== this._toggle)) {
					removeClickListener()
					callback();
				}				
			}

			const removeClickListener = () => {
				document.removeEventListener('click', outsideClickListener);
			}

			if(!this._outsideCloseEventSet){
				document.addEventListener('click', outsideClickListener);	
				this._outsideCloseEventSet = true;
			}			
		}

		_onClickOutsideCallback(){
			this._outsideCloseEventSet = false;
			this.close();
		}

		// Public methods
		getOpenState() {
			return this._isOpen;
		}

		getInstanceID() {
			return this._instanceID;
		}

		getSelectElem(){
			return this._selectElem;
		}

		open() {
			this._contentList.classList.add(CLASSES.toggleOpen);
			this._contentList.style.display = "block";
			this._isOpen = true;

			//set focus to content list.
			this._highlightSelectedItem();

			this._onClickOutside(this._contentList, this._onClickOutsideCallback.bind(this));
		}

		close() {
			if (this._contentList.classList.contains(CLASSES.toggleOpen)) {
				this._toggle.focus();
			}

			this._contentList.classList.remove(CLASSES.toggleOpen);
			this._contentList.style.display = "none";
			this._isOpen = false;
			this._typeAheadSequence = "";
		}

		delete(){
			//delete toggle-wrapper
			this._toggleWrapper.parentNode.removeChild(this._toggleWrapper);
			delete this._toggleWrapper;
	
			//Remove all added classes from select. 
			this._selectElem.classList.remove(CLASSES.setup);
		}
	};

	////////////////////
	// Public methods //
	////////////////////
	const _init = (config) => {
		userConfig = Object.assign(DEFAULT_CONFIG, config);
		_setupWrappers();
	};

	const _setupWrappers = () => {
		let wrappers = document.querySelectorAll('.' + userConfig.wrapperClass);

		if (wrappers.length > 0) {
			for (let i = 0; i < wrappers.length; i++) {

				wrappers[i].classList.add(CLASSES.wrapper);

				let selectList = wrappers[i].querySelectorAll('select');

				if (selectList.length > 0) {
					selectList.forEach((selectElem) => {
						if (!selectElem.classList.contains(CLASSES.setup)) {
							let box = new listBox(selectElem);
							listBoxes.push(box);
							selectElem.classList.add(CLASSES.setup);
						}
					});
				}
			}
		}
	};

	const _createListBox = (selectElem) => {
		if(selectElem){
			if (!selectElem.classList.contains(CLASSES.setup)) {
				let wrapper = selectElem.closest('.' + userConfig.wrapperClass);
				wrapper.classList.add(CLASSES.wrapper);				

				let box = new listBox(selectElem);
				listBoxes.push(box);
				selectElem.classList.add(CLASSES.setup);
			}
		}		
	}	

	const _removeListBox = (selectElem) => {
		if(selectElem){
			if (selectElem.classList.contains(CLASSES.setup)) {

				// since we are splicing the array to remove items, traverse the array in reverse to maintain proper index.
				for(let i=listBoxes.length -1;  i >= 0; i--){
					let listBox = listBoxes[i];

					if(listBox.getSelectElem() == selectElem){
						listBox.delete();

						let wrapper = selectElem.closest("."+CLASSES.wrapper);
						
						// remove component wrapper class
						if(wrapper){
							wrapper.classList.remove(CLASSES.wrapper);
						}
						
						// remove listbox from list of list boxes.
						listBoxes.splice(i, 1);
					}
				}
			}
		}	
	}

	const _getListBoxes = () => {
		return listBoxes;
	}

	const _hideListBoxes = () => {
		listBoxes.forEach((box) => {
			if (box.getOpenState() == true) {
				box.close();
			}
		})
	}

	//////////////////////////////////////////
	// Expose public properties and methods //
	//////////////////////////////////////////

	return {
		init: _init,
		getListBoxes: _getListBoxes,
		createListBox: _createListBox,
		removeListBox: _removeListBox,
	};
});


/*
NOTES:

Keyboard Interaction§
For a vertically oriented listbox:

When a single-select listbox receives focus:
    If none of the options are selected before the listbox receives focus, the first option receives focus. Optionally, the first option may be automatically selected.
    If an option is selected before the listbox receives focus, focus is set on the selected option.

When a multi-select listbox receives focus:
    If none of the options are selected before the listbox receives focus, focus is set on the first option and there is no automatic change in the selection state.
    If one or more options are selected before the listbox receives focus, focus is set on the first option in the list that is selected.

Down Arrow: Moves focus to the next option. Optionally, in a single-select listbox, selection may also move with focus.

Up Arrow: Moves focus to the previous option. Optionally, in a single-select listbox, selection may also move with focus.

Home (Optional): Moves focus to first option. Optionally, in a single-select listbox, selection may also move with focus. Supporting this key is strongly recommended for lists with more than five options.

End (Optional): Moves focus to last option. Optionally, in a single-select listbox, selection may also move with focus. Supporting this key is strongly recommended for lists with more than five options.

Type-ahead is recommended for all listboxes, especially those with more than seven options:
    Type a character: focus moves to the next item with a name that starts with the typed character.
    Type multiple characters in rapid succession: focus moves to the next item with a name that starts with the string of characters typed.
*/
