/* eslint react/no-danger: 0 */
define(['react', 'reactproptypes', 'guid', 'RenderItem'], function (React, ReactPropTypes, guid, RenderItem) {
    const InboxModalBody = (props) => {
        const message = props.listOfMessages[props.currentIndex];
        const wrapperAttributes = {};
        let messageContent;

        if (!message || message.key === 'empty-message') {
            return null;
        }

        // Message content was provided as HTML
        if (typeof message.body === 'string') {
            wrapperAttributes.dangerouslySetInnerHTML = {__html: message.body};
        }
        // Message content was provided as JSON that needs to be rendered into HTML
        else {
            messageContent = message.body.map((source) => {
                const key = source.key;

                return (
                    <div
                        key={key + '_row'}
                        className="cui-row"
                    >
                        <div className="cui-col">
                            {RenderItem({
                                key,
                                source,
                            })}
                        </div>
                    </div>
                );
            });
        }

        return (
            <div className="cui-modal-body cui-uiBox-body">
                <div
                    className="cui-modal-body-content"
                    tabIndex="0"
                    {...wrapperAttributes}
                >
                    {messageContent}
                </div>
            </div>
        );
    };

    InboxModalBody.propTypes = {
        listOfMessages: ReactPropTypes.array,
        currentIndex: ReactPropTypes.number,
    };

    InboxModalBody.defaultProps = {
        listOfMessages: [],
        currentIndex: 0,
    };

    return InboxModalBody;
});
