define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    class TreeFilter extends React.Component {
        constructor (/* props */) {
            super();

            // Private method binding
            this._onCancelButtonClick = this._onCancelButtonClick.bind(this);
            this._onFilterChange = this._onFilterChange.bind(this);
            this._onKeyDown = this._onKeyDown.bind(this);

            ///////////////
            // Constants //
            ///////////////

            this.defaultState = {
                query: '',
                showCloseButton: true,
            };

            // Initial state
            this.state = this.defaultState;
        }


        ///////////////////
        // React methods //
        ///////////////////

        componentDidMount () {
            // Listen for when the tree is closed by some external component
            $(this._treeFilter).on('treeclose', function () {
                // Reset the Search section
                this.setState(this.defaultState);
            }.bind(this));

            // Specify whether to show the Close button
            if (this.props.source.filter.closeButton !== this.state.showCloseButton) {
                // Make sure it's a boolean
                if (this.props.source.filter.closeButton) {
                    this.setState({
                        showCloseButton: true,
                    });
                }
                else {
                    this.setState({
                        showCloseButton: false,
                    });
                }
            }
        }

        /////////////////////
        // Private methods //
        /////////////////////

        _onCancelButtonClick (/*evt*/) {
            // Clear query
            this.setState({
                query: '',
            }, this.props.onFilterChange);
        }

        _onFilterChange (evt) {
            // Update displayed items in the parent component
            this.setState({
                query: evt.target.value,
            }, function () {
                this.props.onFilterChange(this.state.query);
            }.bind(this));
        }

        // Handles key presses on input field
        _onKeyDown (evt){
            // Prevent default of "Enter" key
            if (evt.keyCode === 13) {
                evt.preventDefault();
            }
        }

        ////////////
        // Render //
        ////////////

        render () {
            const buttons = [];
            const wrapperClassNames = ['feta-tree-header'];
            const controlBarClassNames = ['feta-tree-control-bar'];

            if (this.state.showCloseButton) {
                controlBarClassNames.push('feta-tree-has-close-button');

                buttons.push(
                    <div key="feta-tree-menu-control" className="feta-tree-menu-control">
                        <button
                            type="button"
                            className="feta-tree-menu-close"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="feta-tree-menu-cancel"
                            onClick={this._onCancelButtonClick}
                        >
                            Cancel
                        </button>
                    </div>
                );
            }

            // Note that the arrow function on the first line below is used to pass a reference to the element to `componentDidMount` so we don't have to crawl the DOM
            return (
                <div
                    className={wrapperClassNames.join(' ')}
                    ref={(refToThisElem) => this._treeFilter = refToThisElem}
                >
                    <div className={controlBarClassNames.join(' ')}>
                        <div className="feta-tree-search-input">
                            <label
                            className="cui-hide-from-screen"
                            htmlFor={this.props.source.id + "_filter"}
                            >
                                Filter
                            </label>

                            <input
                                type="text"
                                value={this.state.query}
                                onKeyDown={this._onKeyDown}
                                onChange={this._onFilterChange}
                                placeholder="Filter"
                                id={this.props.source.id + "_filter"}
                            />
                        </div>
                        {buttons}
                    </div>
                </div>
            );
        }
    }

    TreeFilter.propTypes = {
        source: ReactPropTypes.any, //TODO: This should be `shape()`, but I'm having trouble with console errors when I use it (CP 1/30/17)
        onFilterChange: ReactPropTypes.func,
    };

    TreeFilter.defaultProps = {
        source: {
            filter: {},
        },
        onFilterChange: () => {},
    };

    return TreeFilter;
});
