define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    const InboxHeader = (props) => {
        let titleSection;

        // Title section
        if (props.title) {
            titleSection = (
                <h4>{props.title}</h4>
            );
        }

        return (
            <div className="feta-inbox-list-header">
                {titleSection}
            </div>
        );
    };

    InboxHeader.propTypes = {
        title: ReactPropTypes.string,
    };

    InboxHeader.defaultProps = {
        title: null,
    };

    return InboxHeader;
});
