define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    // SVG source file: feta-trash-can.svg
    const RenderIconTrashCan = (props) => {
        return (
            <svg
                className={(props && props.className) ? props.className : ''}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
            >
                <g fill="#555">
                    <path
                        d="M20.764 3.506h-4.202l-.838-2.012c-.197-.46-.527-.808-.908-1.066C14.43.175 13.984.004 13.483 0h-3.236c-.505.003-.954.18-1.334.444-.377.267-.698.63-.873 1.102L7.33 3.5l.018.006H3.236c-.596 0-1.08.483-1.08 1.078 0 .596.484 1.08 1.08 1.08h1.08V22.11c-.002.52.213.996.552 1.334.34.34.815.554 1.334.554h11.596c.52 0 .995-.214 1.334-.554.34-.338.554-.814.553-1.334V5.662h1.08c.595 0 1.078-.482 1.078-1.078 0-.595-.483-1.078-1.08-1.078zM9.56 2.1c.027-.085.133-.23.28-.33.146-.105.32-.155.407-.152h3.236c.092-.004.278.05.435.157.16.103.28.254.312.34l.58 1.39H9.05L9.56 2.1zm8.507 20.012c0 .074-.028.138-.08.19-.052.052-.116.08-.19.08H6.203c-.073 0-.137-.028-.19-.08-.05-.052-.08-.116-.08-.19V5.662h12.135v16.45z"
                    />
                    <path
                        d="M8.36 20.494c.446 0 .81-.362.81-.81V8.09c0-.447-.364-.81-.81-.81-.447 0-.81.363-.81.81v11.595c0 .447.363.81.81.81zm7.01 0c.448 0 .81-.362.81-.81V8.09c0-.447-.362-.81-.81-.81-.446 0-.808.363-.808.81v11.595c0 .447.362.81.81.81zm-3.505 0c.447 0 .81-.362.81-.81V8.09c0-.447-.363-.81-.81-.81-.447 0-.81.363-.81.81v11.595c0 .447.363.81.81.81z"
                    />
                </g>
            </svg>
        );
    };

    RenderIconTrashCan.propTypes = {
        className: ReactPropTypes.string,
    };

    RenderIconTrashCan.defaultProps = {
        className: '',
    };

    return RenderIconTrashCan;
});
