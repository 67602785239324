define(['react', 'reactproptypes', 'TableRow', 'TableRowExpand'], function (React, ReactPropTypes, TableRow, TableRowExpand) {
    const TableBody = (props) => {
        const sourceTable = props.source;
        const rows = props.rows;
        let rowsToRender = []; // Which row objects should be rendered
        const rowElems = []; // The `<TableRow>` components that React will render
        let i;
        let firstRow;
        let lastRow;
        let rowIndexOffset = 0;
        let stripeFlag = false;

        // Gather rows that need to be rendered
        if (props.isScrollable) {
            // console.log('[TableBody] Rendering rows ' + (props.displayStart + 1) + ' to ' + (props.displayEnd + 1) + ' (' + (props.displayEnd - props.displayStart + 1) + ' out of ' + props.total + ' total rows)');
            // Top spacer row
            if (!props.isSmallSize) {
                firstRow = (
                    <props.tagNames.tr
                        key={'line_top'}
                        style={{height: props.displayStart * props.defaultRowHeight}}
                    >
                        <td
                            colSpan="999"
                            style={{display: 'none'}}
                        />
                    </props.tagNames.tr>
                );
            }

            // Rendered rows
            for (i = props.displayStart; i < props.displayEnd + 1; i++) {
                rowsToRender[i + 1] = rows[i];
            }

            // Bottom spacer row (only for large screens)
            if (!props.isSmallSize) {
                let height;

                // User has scrolled to the bottom of the table (or close to it), so make sure the dummy row has zero height otherwise it will leave a white gap
                if (rows.length === props.displayEnd + 1) {
                    height = 0;
                }
                // User isn't yet near the bottom
                else {
                    height = (rows.length - props.displayEnd) * props.defaultRowHeight;
                }

                lastRow = (
                    <props.tagNames.tr
                        key={'row_' + rowsToRender.length}
                        style={{height: height}}
                    >
                        <td
                            colSpan="999"
                            style={{display: 'none'}}
                        />
                    </props.tagNames.tr>
                );
            }
            // "Show more" button (for small screens)
            else if (rows.length > props.displayEnd) {
                const rowKeyPrefix = sourceTable.id + '_bodyRow_' + rowsToRender.length;

                lastRow = (
                    <TableRow
                        row={{
                            columns: [
                                {
                                    text: 'Show More',
                                    attributes: {
                                        className: 'cui-align-center',
                                        colSpan: '999',
                                    },
                                    key: rowKeyPrefix + '_col0_showMore',
                                },
                            ],
                        }}
                        tagNames={props.tagNames}
                        isSmallSize={props.isSmallSize}
                        key={rowKeyPrefix + '_showMore'}
                        rowIndex={rowsToRender.length}
                        isHeader={false}
                        source={sourceTable}
                        height={props.defaultRowHeight * 2}
                        onClick={props.onShowMoreClick}
                        getTableCell={props.getTableCell}
                    />
                );
            }
            // "No more records" message (for small screens)
            else {
                lastRow = (
                    <TableRow
                        row={{
                            columns: [
                                {
                                    text: 'There is no more data to display',
                                    attributes: {
                                        className: 'cui-align-center',
                                        colSpan: '999',
                                    },
                                },
                            ],
                        }}
                        tagNames={props.tagNames}
                        isSmallSize={props.isSmallSize}
                        key={'row_' + rowsToRender.length}
                        rowIndex={'row_' + rowsToRender.length}
                        isHeader={false}
                        source={sourceTable}
                        height={props.defaultRowHeight * 2}
                        inlineStyle={{fontStyle: 'italic'}}
                        getTableCell={props.getTableCell}
                    />
                );
            }

            // Account for the dummy row at the top when rendering the actual content rows
            rowIndexOffset++;
        }
        else {
            // console.log('[TableBody] Rendering all ' + rows.length + ' rows');
            rowsToRender = rows;
        }

        // Gather the `<TableRow/>` and `<TableRowExpand/>` components to be inserted in the `<tbody>`
        rowsToRender.forEach((row, r) => {
            if (!row) {
                return;
            }

            const parentRowIndex = r + rowIndexOffset;
            const childRowIndex = parentRowIndex + 1;

            // Event handler for parent row so the child row can be expanded
            let onExpandableRowClick = row.expand ? props.onExpandableRowClick : null;

            // Event handler that checks the row's box
            const onClickSelectableRow = (!row.expand && sourceTable.selectionType) ? props.onClickSelectableRow : null;

            //Handle any necessary code changes for rendering special ajax expandable rows.
            if(row.expand && row.expand.isLoading){
                onExpandableRowClick = null;
            }
            else if(row.expand && !row.expand.contents){
                onExpandableRowClick = props.fetchExpandContent;
            }

            // Create the standard/parent row
            rowElems.push(
                <TableRow
                    row={row}
                    tagNames={props.tagNames}
                    isSmallSize={props.isSmallSize}
                    key={row.key}
                    rowIndex={parentRowIndex}
                    isHeader={false}
                    expand={row.expand}
                    onClickSelectableRow={onClickSelectableRow}
                    onExpandableRowClick={onExpandableRowClick}
                    source={sourceTable}
                    height={row.height}
                    onSelectRowChange={props.onSelectRowChange}
                    onInputChange={props.onInputChange}
                    onCheckChange={props.onCheckChange}
                    getTableCell={props.getTableCell}
                    isStriped={stripeFlag}
                />
            );

            // Create expanded row, if applicable
            if (row.expand && row.expand.contents && !props.isSmallSize) {
                rowElems.push(
                    <TableRowExpand
                        row={row.expand}
                        tagNames={props.tagNames}
                        isSmallSize={props.isSmallSize}
                        key={row.key + '_expand'}
                        rowIndex={childRowIndex}
                        isHeader={false}
                        parent={row}
                        isVisible={row.expand.settings.isVisible}
                        source={sourceTable}
                        height={row.height}
                        onSelectRowChange={props.onSelectRowChange}
                        onInputChange={props.onInputChange}
                        onCheckChange={props.onCheckChange}
                        getTableCell={props.getTableCell}
                    />
                );

                rowIndexOffset++;
            }

            stripeFlag = !stripeFlag;
        });

        return (
            <props.tagNames.tbody className="feta-table-elem-tbody">
                {firstRow}
                {rowElems}
                {lastRow}
            </props.tagNames.tbody>
        );
    };

    TableBody.propTypes = {
        source: ReactPropTypes.shape({}),
        rows: ReactPropTypes.array,
        tagNames: ReactPropTypes.shape({}),
        isSmallSize: ReactPropTypes.bool,
        isScrollable: ReactPropTypes.bool,
        onSelectRowChange: ReactPropTypes.func,
        onInputChange: ReactPropTypes.func,
        onCheckChange: ReactPropTypes.func,
        onExpandableRowClick: ReactPropTypes.func,
        fetchExpandContent: ReactPropTypes.func,
        onClickSelectableRow: ReactPropTypes.func,
        onShowMoreClick: ReactPropTypes.func,
        getTableCell: ReactPropTypes.func,
        displayStart: ReactPropTypes.number,
        displayEnd: ReactPropTypes.number,
        defaultRowHeight: ReactPropTypes.number,
    };

    TableBody.defaultProps = {
        source: {},
        rows: [],
        tagNames: {},
        isSmallSize: false,
        isScrollable: false,
        onSelectRowChange: () => {},
        onInputChange: () => {},
        onCheckChange: () => {},
        onExpandableRowClick: () => {},
        fetchExpandContent: () => {},
        onClickSelectableRow: () => {},
        onShowMoreClick: () => {},
        getTableCell: () => {},
        displayStart: 0,
        displayEnd: 1,
        defaultRowHeight: 40, //FIXME: What should this be?
    };

    return TableBody;
});
