define(['react', 'reactproptypes', 'popover', 'kind', 'TableSortIcon', 'renderer', 'RenderItem'], function (React, ReactPropTypes, popover, kind, TableSortIcon, renderer, RenderItem) {
    class TableColumnHeader extends React.Component {
        constructor (props) {
            super(props);

            // Private method binding
            this._onSortClick = this._onSortClick.bind(this);
        }

        componentDidMount () {
            // Track dimensions of header cells
            // Since this function is called for every single cell, we need to check and make sure the refs we want are actually defined
            if (this._headerCol && this._headerElem && this.props.getColDimensions) {
                this.props.getColDimensions(this._headerCol.col, this._headerCol.colIndex, this._headerElem);
            }
        }


        /////////////////////
        // Private methods //
        /////////////////////

        _onSortClick (...eventArgs) {
            // Don't let the event bubble up to the row
            eventArgs[0].stopPropagation();

            // Ignore button clicks
            if (eventArgs[0].target.nodeName !== 'BUTTON') {
                this.props.sortFunc(this._headerCol);
            }
        }


        ////////////
        // Render //
        ////////////

        render () {
            const sourceTable = this.props.source;
            let col = this.props.col;
            let onClickFunc = null;
            let elems = [];
            const styles = {};
            const cellCassNames = col.cellProps.className ? col.cellProps.className.split(' ') : [];
            const colIndex = this.props.colIndex;
            const isSmallSizeAndIsSelection = (this.props.isSmallSize && colIndex === 0 && sourceTable.selectionType);
            let isHelpColumn = false;

            // Use the alternate, small-screen version of a column if one was provided
            if (this.props.isSmallSize && col.hasOwnProperty('smallSize')) {
                journal.log({type: 'error', owner: 'UI', module: 'TableColumnHeader'}, 'Column should not have the deprecated `smallSize` property: ', col);
                col = col.smallSize;
            }

            // Specific width
            if (col.width) {
                col.cellProps.width = col.width + 'px';
            }

            // Height for sticky headers
            if (sourceTable.head.stickyHeight) {
                col.cellProps.height = sourceTable.head.stickyHeight + 'px';
            }

            cellCassNames.push('feta-table-elem-th');

            // Sorting classes
            if (col.attributes && col.attributes['data-sort']) {
                cellCassNames.push('feta-table-sort-col');

                elems.push(
                    <TableSortIcon
                        key={col.key + 'sort-icon'}
                    />
                );
            }

            if (col.sortDir) {
                cellCassNames.push('feta-table-sort-col-sorted-' + col.sortDir);
                styles.cursor = 'pointer';
            }

            // Field-type cell
            if (col.contents) {

                if(col.text && col.text != ""){
                    elems.push(
                        <span class="feta-data">{col.text}</span>
                    )
                }

                col.contents.forEach((item) => {
                    let onChange;

                    if (item.template === 'field') {
                        // Make sure it's a supported type for the table header                        
                        if (!/checkbox/.test(item.type) && !(item.input && item.input.icon == "copy-value")) {
                            journal.log({type: 'error', owner: 'UI', module: 'TableColumnHeader'}, 'Field type is not supported in the table header and will not be rendered: "' + item.type + '" ', item, col);

                            return false;
                        }

                        if (isSmallSizeAndIsSelection) {
                            // Tell `<RenderField/>` not to hide the `<label>` element like it normally would in a table
                            item.doNotHideLabel = true;
                            item.label.attributes.className = (item.label.attributes.className || '').replace('cui-hide-from-screen', '');
                        }
                        else {
                            item.doNotHideLabel = false;
                        }

                        // 'Select all' check box
                        if (item.type === 'checkbox' && col.selectionType === 'all-rows') {
                            onChange = this.props.onSelectAllChange;
                        }
                        else if (item.type === 'checkbox' || item.type === 'radio') {
                            onChange = this.props.onCheckChange;
                        }
                        else {
                            onChange = this.props.onInputChange;
                        }
                    }


                    else if(item.template === "helpIcon"){
                        isHelpColumn = true;
                    }

                    // if(item.label){
                    //     // Modify the label object so the renderer can handle it properly
                    //     const label = item.label;

                    //     label.type = 'label';

                    //     if(label.attributes && label.attributes.className){
                    //         if(label.attributes.className.indexOf('cui-hide-from-screen') == -1){
                    //             label.attributes.className += " cui-hide-from-screen";
                    //         }
                    //     }
                    //     else{
                    //         label.attributes.className = " cui-hide-from-screen";
                    //     }

                    //     elems.push(
                    //         <RenderItem
                    //             source={label}
                    //             className="cui-hide-from-screen"
                    //             key={item.key + '_label'}
                    //             colIndex={colIndex}
                    //             rowIndex={this.props.rowIndex}
                    //             isSmallSize={this.props.isSmallSize}
                    //         />
                    //     );
                    // }

                    // Create the element(s)
                    elems.push(
                        <RenderItem
                            source={item}
                            // inputOnly
                            key={item.key}
                            onChange={onChange}
                            colIndex={colIndex}
                            rowIndex={this.props.rowIndex}
                            isSmallSize={this.props.isSmallSize}
                        />
                    );
                });

                // This is the "Select All" cell...
                if (!this.props.isSmallSize && colIndex === 0 && sourceTable.selectionType) {
                    // Fix for IE11. Doesn't seem to harm other browsers to have this. (CP 12/19/16)
                    col.cellProps.style.verticalAlign = 'middle';
                }
            }
            // Icon-type cell
            else if (col.type !== 'icon') {
                if (col.texts) {
                    elems.push(col.texts);
                }
                else {
                    elems.push(col.text);
                }
            }

            // Sorting event handler
            if (col.attributes && col.attributes['data-sort']) {
                onClickFunc = this._onSortClick;
            }

            // Sticky header -- wrap all elements in a `<div>` that we can position
            if (this.props.scrollTop) {
                const cellWrapper = [];
                const cellWrapperStyle = {
                    top: (this.props.scrollTop - 1) + 'px', // Not sure why we need to drop 1px from the scrollTop value... (CP 12/19/16)
                };

                // Specific height
                if (sourceTable.head.stickyHeight) {
                    cellWrapperStyle.height = sourceTable.head.stickyHeight + 'px';
                }

                cellWrapper.push(
                    <div
                        key={col.key + colIndex + '-sticky'}
                        className="feta-table-elem-thead-sticky-cell"
                        style={cellWrapperStyle}
                    >
                        {elems}
                    </div>
                );

                // Replace `elems` with our new, wrapped version
                elems = cellWrapper;
            }

             if(isHelpColumn){
                cellCassNames.push('table-help-column');


                let helpWrapper = (
                    <div className="feta-table-help-wrapper">
                        {elems}
                    </div>
                );

                elems = helpWrapper;

            }

            return (
                <this.props.tagNames.th
                    {...col.cellProps}
                    className={cellCassNames.join(' ')}
                    onClick={onClickFunc}
                    style={styles}
                    ref={(refToThisElem) => {
                            this._headerCol = {col: col, colIndex: colIndex};
                            this._headerElem = refToThisElem;
                        }}
                >
                    {elems}
                </this.props.tagNames.th>
            );
        }
    }

    TableColumnHeader.propTypes = {
        source: ReactPropTypes.shape({}).isRequired,
        col: ReactPropTypes.shape({}).isRequired,
        getColDimensions: ReactPropTypes.func,
        sortFunc: ReactPropTypes.func,
        isSmallSize: ReactPropTypes.bool,
        colIndex: ReactPropTypes.number,
        onSelectAllChange: ReactPropTypes.func,
        onCheckChange: ReactPropTypes.func,
        onInputChange: ReactPropTypes.func,
        rowIndex: ReactPropTypes.number,
        scrollTop: ReactPropTypes.number,
    };

    TableColumnHeader.defaultProps = {
        getColDimensions: () => {},
        sortFunc: () => {},
        isSmallSize: false,
        colIndex: 0,
        onSelectAllChange: () => {},
        onCheckChange: () => {},
        onInputChange: () => {},
        rowIndex: 0,
        scrollTop: 0,
    };

    return TableColumnHeader;
});
