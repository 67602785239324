/*global feta */
define(['react', 'reactproptypes', 'reactmodal', 'InboxHeader', 'InboxFooter', 'InboxList', 'InboxModalHeader', 'InboxModalBody', 'InboxModalFooter', 'dateutil', 'jquery', 'modal', 'uiBox'], function (React, ReactPropTypes, ReactModal, InboxHeader, InboxFooter, InboxList, InboxModalHeader, InboxModalBody, InboxModalFooter, dateutil, $) {
    class Inbox extends React.Component {
        constructor (props) {
            super(props);

            // Private method binding
            this._setMessageActionState = this._setMessageActionState.bind(this);
            this._onSetMessageActionStateClick = this._onSetMessageActionStateClick.bind(this);
            this._onItemClick = this._onItemClick.bind(this);
            this._onAfterModalOpen = this._onAfterModalOpen.bind(this);
            this._onRequestModalClose = this._onRequestModalClose.bind(this);
            this._navigateMessages = this._navigateMessages.bind(this);
            this._onKeyDown = this._onKeyDown.bind(this);
            this._onNavLinkClick = this._onNavLinkClick.bind(this);
            this._setModalBodyHeight = this._setModalBodyHeight.bind(this);
            this._onViewFormClick = this._onViewFormClick.bind(this);
            this._onDeleteClick = this._onDeleteClick.bind(this);
            this._ajaxAction = this._ajaxAction.bind(this);
            this._stopScrollEvent = this._stopScrollEvent.bind(this);
            this._stopScrollEventForKeys = this._stopScrollEventForKeys.bind(this);

            this.$window = $(window);
            this.$document = $(document);

            this.source = props.source;

            // Initial state
            this.state = {
                currentIndex: 0, // Position of the message currently being displayed in the message list
                listOfMessages: props.source.messages,
                setHeaderFocus:false,
            };
        }


        /////////////////////
        // Private methods //
        /////////////////////

        _onItemClick (messageIndex /*, ...eventArgs*/) {
            // Mark the message as read and open the modal
            this.setState({
                listOfMessages: this._setMessageActionState(messageIndex, 'read', true),
                currentIndex: messageIndex,
                isModalOpen: true,
            });
        }

        /**
         * Moves to the next/previous message in the list
         * @param {object} message Message object
         * @param {string} direction Which direction to navigate through the list ('prev' or 'next')
         */
        _navigateMessages (message, direction = 'next' /*, ...eventArgs*/) {
            let nextIndex;

            // Go to next message
            if (direction !== 'previous') { // This condition implies that `direction` is optional and the function will default to moving to the next message
                nextIndex = this.state.currentIndex + 1;

                // Do nothing if we were already on the last message
                if (nextIndex > this.state.listOfMessages.length - 1) {
                    return false;
                }
            }
            // Go to previous message
            else {
                nextIndex = this.state.currentIndex - 1;

                // Do nothing if we were already on the first message
                if (nextIndex < 0) {
                    return false;
                }
            }

            this.setState({
                currentIndex: nextIndex,
                listOfMessages: this._setMessageActionState(nextIndex, 'read', true),
            }, this._setModalBodyHeight);
        }

        _onKeyDown (evt /*...otherEventArgs*/) {
            if (!/button|input|select|textarea/i.test(evt.target.nodeName)) {
                // Right arrow
                if (evt.keyCode === 39) {
                    this._navigateMessages(this.state.listOfMessages[this.state.currentIndex], 'next');
                }
                // Left arrow
                else if (evt.keyCode === 37) {
                    // Prev
                    this._navigateMessages(this.state.listOfMessages[this.state.currentIndex], 'previous');
                }
            }
        }

        /**
         * Runs after the modal has opened
         *
         * Does not need to update `isModalOpen` in state (it's already `true`)
         */
        _onAfterModalOpen (/*...eventArgs*/) {
            this._setModalBodyHeight();

            // Watch for navigation keystrokes
            document.body.addEventListener('keydown', this._onKeyDown);
            window.addEventListener('resize', this._setModalBodyHeight);

            // Disable scrolling on the page
            this.$window.on('wheel.cui.inbox', this._stopScrollEvent); // modern standard
            this.$window.on('touchmove.cui.inbox', this._stopScrollEvent); // mobile
            this.$document.on('keydown.cui.inbox', this._stopScrollEventForKeys);
        }

        _setModalBodyHeight () {
            const outerNode = this.modalOuterElem.node.firstChild.firstChild;
            const outerHeight = outerNode.offsetHeight;
            const headerHeight = outerNode.children[0].offsetHeight;
            const footerHeight = outerNode.children[2].offsetHeight;

            // Set max-height for scrollable body
            outerNode.children[1].style.maxHeight = (outerHeight - headerHeight - footerHeight - 3) + 'px';
        }

        /**
         * Closes the modal
         *
         * Updates the state
         */
        _onRequestModalClose (/*...eventArgs*/) {
            this.setState({
                isModalOpen: false,
            });

            document.body.removeEventListener('keydown', this._onKeyDown);
            window.removeEventListener('resize', this._setModalBodyHeight);

            // Enable scrolling on the page
            this.$window.off('wheel.cui.inbox');
            this.$window.off('touchmove.cui.inbox');
            this.$document.off('keydown.cui.inbox');
        }

        /**
         * Sets the value of a message's action state (i.e. mark as read, etc)
         *
         * Does not update state
         *
         * @param   {number}  messageIndex  Index of the message
         * @return  {array}                 Updated message list
         */
        _setMessageActionState (messageIndex, actionName, stateValue) {
            const listOfMessages = this.state.listOfMessages;
            const message = listOfMessages[messageIndex];

            //TODO: Comment out these two lines during testing
            // Store the existing date/state in case we need to undo it if the ajax request fails
            const previousDate = message[actionName].date;
            const previousState = message[actionName].state;

            message[actionName].date = dateutil.getLocalTimestamp();
            message[actionName].state = stateValue;

            // Send ajax request if possible
            if (message[actionName].url) {
                this._ajaxAction(message, actionName, {
                    //TODO: Comment this out during testing
                    onError: (/*...args*/) => {
                        // console.warn(actionName + ' failed\n', ...args);
                        const listOfMessages = this.state.listOfMessages;

                        listOfMessages[messageIndex][actionName].date = previousDate;
                        listOfMessages[messageIndex][actionName].state = previousState;

                        this.setState({
                            listOfMessages,
                        });
                    }
                });
            }

            return listOfMessages;
        }

        /**
         * Sets the value of a message's action state and updates the message list
         *
         * Updates the state
         *
         * @param   {number}  messageIndex  Index of the message
         */
        _onSetMessageActionStateClick (messageIndex, actionName, stateValue /*, ...eventArgs*/) {
            this.setState({
                listOfMessages: this._setMessageActionState(messageIndex, actionName, stateValue),
            });
        }

        _onViewFormClick (message /*, ...eventArgs*/) {
            //use a virtual form submit instead of new window.
            const url = message.attachments.length ? message.attachments[0].url : message.attachments.url;
            const data = message.attachments.length ? message.attachments[0].data : message.attachments.data;

            feta.form.virtual(
                {action: url},
                data,
                true,
                true,
                'strict'
            );
        }

        /**
         * Handles clicks on a delete button
         *
         * Updates the state
         *
         * @param {number} messageIndex Index of the message in the list
         */
        _onDeleteClick (messageIndex) {
            const listOfMessages = this.state.listOfMessages;
            const message = listOfMessages[messageIndex];

            // Send ajax request
            this._ajaxAction(message, 'delete', {
                //TODO: Comment this out during testing
                onError: (/*...args*/) => {
                    // console.warn('Delete failed\n', ...args);
                    // Restore message
                    listOfMessages[messageIndex].isDeleted = false;

                    this.setState({
                        listOfMessages,
                    });
                }
            });

            listOfMessages[messageIndex].isDeleted = true;

            this.setState({
                listOfMessages,
            });
        }

        /**
         * Performs an ajax request
         *
         * @param {object} message
         * @param {string} actionName
         * @param {object} ajaxSettings
         */
        _ajaxAction (message, actionName, ajaxSettings) {
            const actionSettings = message[actionName];

            if (!actionSettings) {
                return false;
            }

            if (!actionSettings.url) {
                journal.log({type: 'error', owner: 'app', module: 'Inbox', submodule: '_ajaxAction'}, 'Cannot perform the action "' + actionName + '" because no URL was given. ', JSON.parse(JSON.stringify(message)));

                return false;
            }

            if (!actionSettings.params) {
                actionSettings.params = {
                    id: message.id,
                };
            }
            // Add state and message ID to params
            else if (typeof actionSettings.params === 'string') {
                actionSettings.params += '&id=' + message.id + '&state=' + actionSettings.state;
            }
            else {
                actionSettings.params.id = message.id;
                actionSettings.params.state = actionSettings.state;
            }

            $.ajax({
                    url: actionSettings.url,
                    data: actionSettings.params,
                    method: 'GET', //FIXME: for testing only,
                    cache: false
                })
                .done((data, textStatus, jqXHR) => {
                    if (typeof ajaxSettings.onSuccess === 'function') {
                        ajaxSettings.onSuccess(ajaxSettings, data, textStatus, jqXHR);
                    }
                })
                .fail((jqXHR, textStatus, errorThrown) => {
                    if (typeof ajaxSettings.onError === 'function') {
                        ajaxSettings.onError(ajaxSettings, jqXHR, textStatus, errorThrown);
                    }
                })
                .always((arg1, arg2, arg3) => { // Args will match either `done` or `fail`, whichever applies
                    if (typeof ajaxSettings.onComplete === 'function') {
                        ajaxSettings.onComplete(ajaxSettings, arg1, arg2, arg3);
                    }
                });
        }

        _onNavLinkClick (...eventArgs) {
            eventArgs[0].preventDefault();

            // Simple URL
            if (typeof this.source.navLink === 'string') {
                window.location.href = this.source.navLink;
            }
            // Ajax request
            else if (this.source.navLink) {
                feta.fetch.request(this.source.navLink);
            }
        }

        /**
         * Prevents the page from scrolling when the scrollbar or mousewheel is used
         *
         * See: http://output.jsbin.com/xatidu/4/
         * via http://stackoverflow.com/a/4770179/348995
         *
         * @param {event} evt Scroll event
         * @param {jquery} $modalOuter Modal's outer container
         * @param {element} modalBody Modal's body element
         */
        _stopScrollEvent (evt, $modalOuter, modalBody) {
            $modalOuter = $modalOuter || $(evt.target).closest('.cui-modal');

            // Scroll took place outside of a modal element
            if (!$modalOuter.length) {
                evt.preventDefault();
            }
            else {
                modalBody = modalBody || $modalOuter.find('.cui-modal-body').get(0);

                // Check for plausible scroll actions on the modal body
                if (modalBody) {
                    // Upward scroll attempt when the modal body has already been scrolled to the top
                    if (evt.originalEvent.deltaY < 0 && modalBody.scrollTop === 0) {
                        evt.preventDefault();
                    }
                    // Downward scroll attempt when the modal body has already been scrolled to the bottom
                    else if (evt.originalEvent.deltaY > 0 && (modalBody.scrollHeight - modalBody.scrollTop === modalBody.clientHeight)) {
                        evt.preventDefault();
                    }
                }
            }
        }

        /**
         * Prevents the page from scrolling when certain keys are pressed
         *
         * @param {event} evt Keydown event
         */
        _stopScrollEventForKeys (evt) {
            const $target = $(evt.target);
            const $modalOuter = $target.closest('.cui-modal');

            // `1` means the key causes downward movement
            // `2` means the key causes upward movement
            const scrollKeyCodes = {
                37: 1, // left arrow
                38: 2, // up arrow
                39: 1, // right arrow
                40: 1, // down arrow
                32: 1, // spacebar
                33: 2, // pageup
                34: 1, // pagedown
                35: 1, // end
                36: 2, // home
            };

            // Scroll took place outside of a modal element
            if (!$modalOuter.length) {
                if (scrollKeyCodes[evt.keyCode]) {
                    this._stopScrollEvent(evt, $modalOuter);

                    return false;
                }
            }
            else {
                const modalBody = $modalOuter.find('.cui-modal-body').get(0);

                // Check for plausible scroll actions on the modal body
                if (modalBody) {
                    // Upward attempt
                    if (scrollKeyCodes[evt.keyCode] === 2 && modalBody.scrollTop === 0) {
                        this._stopScrollEvent(evt, $modalOuter, modalBody);

                        return false;
                    }
                    // Downward attempt
                    else if (scrollKeyCodes[evt.keyCode] === 1 && (modalBody.scrollHeight - modalBody.scrollTop === modalBody.clientHeight)) {
                        // Make sure the user didn't press the spacebar on a button, link, etc, which is allowed
                        if (evt.keyCode !== 32 || !$target.is('button, input, a')) {
                            this._stopScrollEvent(evt, $modalOuter, modalBody);

                            return false;
                        }
                    }
                    // Not an event we need to worry about, but make sure focus is set on the body. Ignore the tab key since tabbing is handled elsewhere.
                    // Sometimes the first keystroke will affect the window, but this call will ensure the next one only affects the modal's body, which is better than nothing. (CP 12/27/16)
                    else if (evt.keyCode !== 9) {
                        modalBody.focus();
                    }
                }
            }
        }


        ////////////
        // Render //
        ////////////

        render () {
            const source = this.props.source;
            let headerSection;
            let footerSection;

            //Create location for inbox user messages
            const inboxMessages = (
                <div
                    className="feta-inbox-messages"
                    key="messages"
                />
            );

            // Title
            if (source.title) {
                headerSection = (
                    InboxHeader({
                        title: source.title,
                    })
                );
            }

            // Nav link
            if (this.source.navLink) {
                footerSection = (
                    InboxFooter({
                        onNavLinkClick: this._onNavLinkClick,
                        navLink: this.source.navLink,
                    })
                );
            }



            return (
                <div className="feta-inbox-list">
                    {inboxMessages}
                    {headerSection}
                    {InboxList({
                        currentIndex: this.state.currentIndex,
                        listOfMessages: this.state.listOfMessages,
                        onItemClick: this._onItemClick,
                        onDeleteClick: this._onDeleteClick,
                    })}
                    {footerSection}
                    <ReactModal
                        /*
                          See docs at https://github.com/reactjs/react-modal
                        */
                        isOpen={this.state.isModalOpen}
                        onAfterOpen={this._onAfterModalOpen}
                        onRequestClose={this._onRequestModalClose}
                        contentLabel="Selected message"
                        portalClassName="ReactModalPortal"
                        overlayClassName="feta-inbox-list-modal-overlay"
                        className="feta-inbox-list-modal cui-modal cui-uiBox cui-modal-use-header cui-modal-use-footer"
                        // ariaHideApp
                        appElement={document.getElementById("cui-body-wrapper")}
                        shouldCloseOnOverlayClick
                        role="dialog"
                        // parentSelector={() => document.body}
                        ref={(el) => this.modalOuterElem = el}
                    >
                        {InboxModalHeader({
                            currentIndex: this.state.currentIndex,
                            listOfMessages: this.state.listOfMessages,
                            onCloseModal: this._onRequestModalClose,
                            onViewFormClick: this._onViewFormClick,
                        })}
                        {InboxModalBody({
                            currentIndex: this.state.currentIndex,
                            listOfMessages: this.state.listOfMessages,
                        })}
                        {InboxModalFooter({
                            currentIndex: this.state.currentIndex,
                            listOfMessages: this.state.listOfMessages,
                            onCloseModal: this._onRequestModalClose,
                            onNavClick: this._navigateMessages,
                            onDeleteClick: this._onDeleteClick,
                            setActionState: this._onSetMessageActionStateClick,
                        })}
                    </ReactModal>
                </div>
            );
        }
    }

    Inbox.propTypes = {
        source: ReactPropTypes.shape({
            messages: ReactPropTypes.array,
        }),
    };

    Inbox.defaultProps = {
        source: {
            messages: [],
        },
    };

    return Inbox;
});
