define(['react', 'reactproptypes', 'TableFilterBasic', 'TableFilterAdvanced', 'TableSortIcon'], function (React, ReactPropTypes, TableFilterBasic, TableFilterAdvanced, TableSortIcon) {
    const ReactCSSTransitionGroup = React.addons.CSSTransitionGroup;

    class TableFilter extends React.Component {
        constructor (props) {
            super(props);

            // Private method binding
            this._onSortClick = this._onSortClick.bind(this);
            this._toggleAdvancedFilterVisibility = this._toggleAdvancedFilterVisibility.bind(this);

            // Initial state
            this.state = {
                showAdvanced: false, // Tracks whether the user wants the advanced filters to be visible
            };
        }

        // Toggles whether the advanced controls are shown
        _toggleAdvancedFilterVisibility () {
            this.setState({
                // Swap the property to its opposite value
                showAdvanced: !this.state.showAdvanced,
            });
        }

        _onSortClick (...eventArgs) {
            // Don't let the event bubble up to the row on small screens
            eventArgs[0].stopPropagation();

            this.props.sortFunc(this._sortClickParams);
        }

        render () {
            const sourceTable = this.props.source;
            const advancedSectionsToRender = [];
            const sortSectionsToRender = [];

            if (!this.props.filters) {
                return null;
            }

            

            // Add element for advanced filters if applicable
            if (this.state.showAdvanced && this.props.filters.advanced) {
                // Animations only work correctly (e.g. upon both entering and leaving) if the `<ReactCSSTransitionGroup/>` is always rendered. Therefore we need to populate `<ReactCSSTransitionGroup/>` with an array of items. We are adding `<TableFilterAdvanced/>` to that array only when it should be available to the user so that we are not needlessly rendering DOM for tables without advanced filters.
                // Normally, without animation, we would conditionally render the elemenr directly in the `return` statement much like we're doing below with the basic filters.
                advancedSectionsToRender.push(
                    <TableFilterAdvanced
                        key="filters-advanced"
                        source={sourceTable}
                        updateFilterAdvCheckbox={this.props.updateFilterAdvCheckbox}
                        isSmallSize={this.props.isSmallSize}
                    />
                );
            }
            
            if (this.props.isSmallSize) {
                // Add sorting for small screens:

                // Note: to move the sort links into the expandable section, move `<div className="feta-table-filter-advanced-wrapper">` from <TableFilterAdvanced/> to the render function further down in this component.

                // Add a legend
                sortSectionsToRender.push(
                    <legend key="smallSize-sort-label" className="feta-table-sort-label">
                        Sort by:
                    </legend>
                );

                // Collect the sort links
                sourceTable.head.rows[0].columns.map((col, colIndex) => {
                    const classNames = ['feta-table-sort-col'];

                    if (col.sort) {
                        journal.log({type: 'error', owner: 'UI', module: 'TableFilter', submodule: '_renderTableFilter'}, 'Column has deprecated `sort` property rather than `attributes["data-sort"]`: ', col);
                    }

                    if (col.attributes && col.attributes['data-sort']) {
                        if (col.sortDir) {
                            classNames.push('feta-table-sort-col-sorted-' + col.sortDir);
                        }

                        sortSectionsToRender.push(
                            <a
                                key={'smallSize-sort-' + col.key}
                                onClick={this._onSortClick}
                                ref={() => {
                                    this._sortClickParams = {col: col, colIndex: colIndex};
                                }}
                                className={classNames.join(' ')}
                            >
                                <TableSortIcon />
                                {col.text}
                            </a>
                        );
                    }
                });

                // Only render the section if we found some columns with sorting
                if (sortSectionsToRender.length > 1) { // The `<legend>` gives it a length of 1
                    // Wrap the sort pieces and add them to what we're going to render
                    advancedSectionsToRender.push(
                        <div
                            key="feta-table-sort-wrapper"
                            className="feta-table-sort-wrapper"
                        >
                            {sortSectionsToRender}
                        </div>
                    );
                }
            }
            let basicSection = null;

            if (this.props.filters.basic) {
                basicSection = (
                    <TableFilterBasic
                        source={sourceTable}
                        value={this.props.filters.basic.value}
                        updateFilterBasicValue={this.props.updateFilterBasicValue}
                        onToggleAdvancedClick={this._toggleAdvancedFilterVisibility}
                        totalRowsAvailable={this.props.totalRowsAvailable}
                        numDisplayRows={this.props.numDisplayRows}
                        isSmallSize={this.props.isSmallSize}
                    />
                );
            }

            return (
                <div className="feta-table-filter-wrapper">
                    {basicSection}
                    <ReactCSSTransitionGroup
                        transitionName="feta-table-filter-advanced-wrapper"
                        transitionEnter
                        transitionEnterTimeout={200}
                        transitionLeave
                        transitionLeaveTimeout={200}
                    >
                        {advancedSectionsToRender}
                    </ReactCSSTransitionGroup>
                </div>
            );
        }
    }

    TableFilter.propTypes = {
        // source: ReactPropTypes.shape({}),
        source: ReactPropTypes.any,
        // filters: ReactPropTypes.shape({
        //     advanced: {},
        //     basic: {
        //         value: '',
        //     },
        // }),
        filters: ReactPropTypes.any,
        sortFunc: ReactPropTypes.func,
        updateFilterBasicValue: ReactPropTypes.func,
        updateFilterAdvCheckbox: ReactPropTypes.func,
        isSmallSize: ReactPropTypes.bool,
        totalRowsAvailable: ReactPropTypes.number,
        numDisplayRows: ReactPropTypes.number,
    };

    TableFilter.defaultProps = {
        source: {},
        filters: {
            // advanced: {},
            // basic: {
            //     value: '',
            // },
        },
        sortFunc: () => {},
        updateFilterBasicValue: () => {},
        updateFilterAdvCheckbox: () => {},
        isSmallSize: false,
        totalRowsAvailable: 0,
        numDisplayRows: 0,
    };

    return TableFilter;
});
