define(['react', 'reactproptypes'], function (React, ReactPropTypes) {
    // Note: both `<TreeSection>` and `<TreeItem>` are defined in here, rather than in their own files, because there is a two-way dependency between them which RequireJS cannot properly resolve. When we replace Require we can split them up.

    const ReactCSSTransitionGroup = React.addons.CSSTransitionGroup;

    /**
     * Creates a `<section>` for a single list, containing a `<header>` and `<ul>`
     *
     * This is recursively called to create sub-sections
     */
    class TreeSection extends React.Component {
        constructor (props) {
            super(props);

            const collection = props.collection;

            // Private method binding
            this._createSection = this._createSection.bind(this);
            this._onHeaderClick = this._onHeaderClick.bind(this);
            this._setHeight = this._setHeight.bind(this);
            this._getHeight = this._getHeight.bind(this);

            this.toggleList = props.toggleList;
            this.collectionId = collection.id;
            this.lastKnownHeight = 0;

            this.isOpen = (props.isOpen || collection.isOpen || collection.isHeader || !collection.text);
            this.state = {};
        }

        componentWillReceiveProps (nextProps) {
            const theProps = nextProps;

            // Update state if the `isOpen` flag has changed
            if (theProps.collection.isOpen !== this.isOpen) {
                this.isOpen = (theProps.collection.isOpen || theProps.collection.isHeader || !theProps.collection.text);
            }
        }


        /////////////////////
        // Private methods //
        /////////////////////

        _createSection (collection, level) {
            const classNames = [];
            let header;
            let headerOnClick;
            let headerOnKeyPressed;

            if (this.isOpen || collection.isHeader || !collection.text) {
                if (!this.isOpen) {
                    this.isOpen = true; // Make sure this is set for the items without text
                }

                classNames.push('feta-tree-branch-is-open');
            }

            if (collection.items && collection.items.length) {
                classNames.push('feta-tree-branch-has-children');
            }

            // Fill in the default level number if none was set
            if (isNaN(level)) {
                level = 1;
            }
            // Convert to number
            else if (typeof level !== 'number') {
                level = parseInt(level, 10);
            }

            classNames.push('feta-tree-level-' + level);

            // Section header
            if (collection.text) {
                // Header is clickable (i.e. it's not the main header for the entire tree)
                if (!collection.isHeader) {
                    headerOnClick = this._onHeaderClick.bind(this, {id: this.collectionId, collection: collection});
                    classNames.push('feta-tree-branch-is-collapsible');
                }

                header = (
                    <button
                        key={'feta-tree-section-' + level + '_header'}
                        onClick={headerOnClick}
                        type="button"
                        aria-controls={this.collectionId + "_toggleSection"}
                        aria-expanded={this.isOpen}
                    >
                        {collection.text}
                    </button>
                );
            }

            let subItems = null;
            let emptyMessage = false;

            //Determine if level 1 and if empty display empty message.
            if(level === 1){
               if(this.props.isFiltered && collection.childMatch == false) {
                    emptyMessage = true;
                    subItems = (
                        <ul className={"feta-tree-children feta-tree-empty-search"}>
                            <li>
                                <em>No matches found</em>
                            </li>
                        </ul>
                    );
               }
            }

            level++;

            if (this.isOpen && !emptyMessage) {
                subItems = collection.items.map((item) => {
                                return (
                                    <TreeItem
                                        item={item}
                                        key={item.id}
                                        level={level}
                                        collection={collection}
                                        setHeight={this._setHeight}
                                        getHeight={this._getHeight}
                                        toggleList={this.toggleList}
                                        isFiltered={this.props.isFiltered}
                                    />
                                );
                            });

                subItems = (
                    <ul className={"feta-tree-children"}>
                    {subItems}
                    </ul>
                );
            }

            return (
                <section
                    key={'feta-tree-section-' + level}
                    className={classNames.join(' ')}
                >
                    {header}
                    <ReactCSSTransitionGroup
                        transitionName='anim'
                        component='div'
                        transitionEnter
                        transitionEnterTimeout={500}
                        transitionLeave
                        transitionLeaveTimeout={250}
                        id={this.collectionId + "_toggleSection"}
                    >
                        {subItems}
                    </ReactCSSTransitionGroup>
                </section>
            );
        }

        _setHeight (newHeight) {
            if (newHeight !== 'auto' && this.lastKnownHeight !== newHeight) {
                this.lastKnownHeight = newHeight;
            }
        }

        _getHeight () {
            return this.lastKnownHeight;
        }

        _onHeaderClick (data, evt) {
            this.isOpen = !this.isOpen;
            this.toggleList(data.id, evt.target);
        }


        ////////////
        // Render //
        ////////////

        render () {
            return (
                this._createSection(this.props.collection, this.props.level)
            );
        }
    }

    TreeSection.propTypes = {
        collection: ReactPropTypes.shape({}),
        level: ReactPropTypes.number,
        toggleList: ReactPropTypes.func,
        isOpen: ReactPropTypes.bool,
        isFiltered: ReactPropTypes.bool,
    };

    TreeSection.defaultProps = {
        collection: {},
        level: 1,
        toggleList: () => {},
        isOpen: false,
        isFiltered: false,
    };





    /**
     * Creates a single `<li>` item
     */
    class TreeItem extends React.Component {
        constructor (props) {
            super();

            // Private method binding
            this._createListItems = this._createListItems.bind(this);

            this.collectionId = props.collection.id;
            this.lastKnownHeight = 0;

            this.state = {};
        }

        componentWillEnter () {
            const node = this.listItemElem.parentNode;
            const computedStyle = window.getComputedStyle(node, null);
            let height = computedStyle.height;

            if (height === 'auto') {
                if (this.lastKnownHeight) {
                    height = this.lastKnownHeight;
                }
                else {
                    this.lastKnownHeight = this.props.getHeight();

                    if (this.lastKnownHeight) {
                        height = this.lastKnownHeight;
                    }
                }
            }
            else if (height !== this.lastKnownHeight) {
                this.lastKnownHeight = height;
                this.props.setHeight(height);
            }
        }


        /////////////////////
        // Private methods //
        /////////////////////

        _createListItems (item, level) {
            const itemClassNames = [];

            if (this.props.isFiltered && item.isHighlighted === false && (!item.parentMatch) && (!item.childMatch)) {
                itemClassNames.push('cui-hidden');
            }

            // Nested list
            if (item.items) {
                itemClassNames.push('feta-tree-item'); //FIXME - not the same class that's used in Sass, I believe a different one was used before? (CP 10/5/16)

                return (
                    <li
                        key={item.id}
                        id={item.id}
                        className={itemClassNames.join(' ')}
                        ref={(_elem) => this.listItemElem = _elem}
                    >
                        <TreeSection
                            collection={item}
                            key={item.id}
                            level={level}
                            toggleList={this.props.toggleList}
                            isOpen={item.isOpen}
                            isFiltered={this.props.isFiltered}
                        />
                    </li>
                );
            }
            else {
                if (item.className) {
                    itemClassNames.push(item.className);
                }

                if (!item.attributes) {
                    item.attributes = {};
                }

                return (
                    <li
                        key={item.id}
                        id={item.id}
                        className={itemClassNames.join(' ')}
                        ref={(_elem) => this.listItemElem = _elem}
                    >
                        {
                            item.href
                                ?
                                    <a
                                        href={item.href}
                                        target={item.target}
                                        className={item.attributes.className}
                                        data-feta-confirm={item.attributes['data-feta-confirm']}
                                    >
                                        {item.text}
                                    </a>
                                :
                                    <span>{item.text}</span>
                        }
                    </li>
                );
            }
        }


        ////////////
        // Render //
        ////////////

        render () {
            const result = this._createListItems(this.props.item, this.props.level);

            return (
                result
            );
        }
    }

    TreeItem.propTypes = {
        item: ReactPropTypes.shape({}),
        collection: ReactPropTypes.shape({
            id: ReactPropTypes.string,
        }),
        level: ReactPropTypes.number,
        isFiltered: ReactPropTypes.bool,
        getHeight: ReactPropTypes.func,
        setHeight: ReactPropTypes.func,
        toggleList: ReactPropTypes.func,
    };

    TreeItem.defaultProps = {
        item: {},
        collection: {
            id: '',
        },
        level: 1,
        isFiltered: false,
        getHeight: () => {},
        setHeight: () => {},
        toggleList: () => {},
    };








    /**
     * Creates a tree's contents, including all of its sections/lists
     *
     * @param  {object}  props  React props from `<Tree>`
     *
     * @return {react}
     */
    function TreeBody (props) {
        const collection = props.itemList;

        return (
            <TreeSection
                collection={collection}
                key={collection.id}
                isFiltered={props.isFiltered}
                toggleList={props.toggleList}
                level={1}
                isOpen={collection.isOpen}
            />
        );
    }

    TreeBody.propTypes = {
        itemList: ReactPropTypes.shape({}),
        isFiltered: ReactPropTypes.bool,
        toggleList: ReactPropTypes.func,
    };

    TreeBody.defaultProps = {
        itemList: {},
        isFiltered: false,
        toggleList: () => {},
    };

    return TreeBody;
});
