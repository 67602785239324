/*jshint esversion: 6 */
/*global feta */
define(['react', 'reactproptypes', 'RenderIcon', 'guid', 'popover'], function (React, ReactPropTypes, RenderIcon, guid, popover) {
    // Attributes that will be displayed if availiable on read-only elements.
    const READ_ONLY_ATTRIBUTES = [
        'id',
        'name',
    ];
    const EVENT_HANDLER_NAMES = ['onClick', 'onChange', 'onFocus', 'onBlur', 'onKeyUp', 'onKeyDown'];
    const INDEX_TYPES = ['col', 'row', 'contents'];

    const getReadOnlyAttributes = function (elementAttributes) {
        const attributes = {};

        Object.keys(elementAttributes).forEach((name) => {
            if (READ_ONLY_ATTRIBUTES.includes(name)) {
                attributes[name] = elementAttributes[name];
            }
        });

        return attributes;
    };

    /**
     * Sets appropriate attributes, particularly for form fields
     *
     * @param {object} elem
     * @param {boolean} isControlledInput
     *
     * @return {object}
     */
    const normalizeElementAttrs = function (elem, isControlledInput = false, isCheckable = false) {
        if (!elem.attributes) {
            journal.log({type: 'warn', owner: 'UI', module: 'RenderItem', submodule: 'normalizeElementAttrs'}, 'Element does not have an `attributes` property: ', JSON.parse(JSON.stringify(elem)));

            return elem;
        }        

        // Copy `name` to `id` and vice versa
        if (elem.attributes.id && !elem.attributes.name) {
            elem.attributes.name = elem.attributes.id;
        }
        else if (elem.attributes.name && !elem.attributes.id) {
            elem.attributes.id = elem.attributes.name;
        }

        if(elem.attributes.onclick || elem.attributes.onClick){
            elem.attributes = normalizeOnClick(elem.attributes);
        }

        if(elem.attributes.onchange || elem.attributes.onChange){
            elem.attributes = normalizeOnChange(elem.attributes);
        }

        //TODO: Do we need to log a warning when there's neither a `name` nor `id`? e.g. it happens on buttons, which is fine, but we can't tell that the element in question is a button
        // else if (!elem.attributes.name && !elem.attributes.id) {
        //     journal.log({type: 'warn', owner: 'UI', module: 'Render', submodule: 'normalizeElementAttrs'}, 'Element is missing both the `name` and `id` attributes: ', JSON.parse(JSON.stringify(elem)));
        // }

        // Radio buttons must have names
        if (elem.attributes.type === 'radio' && !elem.attributes.name) {
            journal.log({type: 'warn', owner: 'UI', module: 'RenderItem', submodule: 'normalizeElementAttrs'}, 'Radio button is missing a `name` attribute: ', JSON.parse(JSON.stringify(elem)));
        }

        // Controlled input (i.e. has event handlers)
        // https://facebook.github.io/react/docs/forms.html#controlled-components
        if (isControlledInput) {
            if (isCheckable) {
                // Controlled inputs must have `checked` defined
                if (!elem.attributes.hasOwnProperty('checked')) {
                    if (elem.attributes.hasOwnProperty('defaultChecked')) {
                        elem.attributes.checked = elem.attributes.defaultChecked;
                    }
                    else {
                        elem.attributes.checked = false;
                    }
                }

                // Make sure `checked` is boolean
                if (typeof elem.attributes.checked !== 'boolean') {
                    elem.attributes.checked = (elem.attributes.checked === 'checked');
                }

                // We need to make sure the `defaultChecked` attribute does not exist, otherwise it will be rendered via `{...elem.attributes}`
                if (elem.attributes.hasOwnProperty('defaultChecked')) {
                    delete elem.attributes.defaultChecked;
                }

            }
            // Non-checkable field
            else {
        	   // This is needed for `<select>` where the value is one level up
                if (elem.hasOwnProperty('value') && !elem.attributes.defaultValue) {
                    elem.attributes.defaultValue = elem.value;
                }

                // Has neither property defined, so just give it a default
                else if (!elem.attributes.hasOwnProperty('defaultValue') && !elem.attributes.hasOwnProperty('value')) {
                    elem.attributes.value = '';
                }
                else {
                    // Controlled components should use `value` and may not use `defaultValue`
                    if (elem.attributes.hasOwnProperty('defaultValue') && !elem.attributes.hasOwnProperty('value')) {
                        elem.attributes.value = elem.attributes.defaultValue;
                    }

                    // We need to make sure the `defaultValue` attribute does not exist, otherwise it will be rendered via `{...elem.attributes}`
                    if (elem.attributes.hasOwnProperty('defaultValue')) {
                        delete elem.attributes.defaultValue;
                    }
                }
            }
        }
        else {
            if (isCheckable) {
                // Uncontrolled components must use `defaultChecked` instead of `checked`
                if (elem.attributes.hasOwnProperty('checked') && !elem.attributes.hasOwnProperty('defaultChecked')) {
                    elem.attributes.defaultChecked = !!elem.attributes.checked;
                }

                // We need to make sure the `checked` attribute does not exist, otherwise it will be rendered via `{...elem.attributes}`
                if (elem.attributes.hasOwnProperty('checked')) {
                    delete elem.attributes.checked;
                }

                // We need to make sure the `defaultValue` attribute does not exist, otherwise it will be rendered via `{...elem.attributes}`
                if (elem.attributes.hasOwnProperty('defaultValue')) {
                    delete elem.attributes.defaultValue;
                }

                // Finally, make sure `defaultChecked` is a boolean
                if (typeof elem.attributes.defaultChecked !== 'boolean') {
                    elem.attributes.defaultChecked = false;
                }
            }
            // Non-checkable field
            else {
                // Has neither property defined, so just give it a default
                if (!elem.attributes.hasOwnProperty('defaultValue') && !elem.attributes.hasOwnProperty('value') && !elem.hasOwnProperty('value')) {
                    elem.attributes.defaultValue = '';
                }
                else {
                    // Uncontrolled components must use `defaultValue` instead of `value`
                    if (elem.attributes.hasOwnProperty('value') && !elem.attributes.defaultValue) {
                        elem.attributes.defaultValue = elem.attributes.value;
                    }
                    // This is needed for `<select>` where the value is one level up
                    else if (elem.hasOwnProperty('value') && !elem.attributes.defaultValue) {
                        elem.attributes.defaultValue = elem.value;
                    }

                    // We need to make sure the `value` attribute does not exist, otherwise it will be rendered via `{...elem.attributes}`
                    if (elem.attributes.hasOwnProperty('value')) {
                        delete elem.attributes.value;
                    }

                    // Finally, make sure `defaultValue` is a string
                    // We'll allow numbers and booleans since they can be converted to strings in the next conditional block
                    if (!/string|number|boolean/.test(typeof elem.attributes.defaultValue)) {
                        elem.attributes.defaultValue = '';
                    }
                    else if (typeof elem.attributes.defaultValue !== 'string') {
                        elem.attributes.defaultValue = '' + elem.attributes.defaultValue;
                    }
                }
            }
        }

        // Ensure there's a `tabIndex` on all inputs and buttons
        if (!elem.attributes.hasOwnProperty('tabIndex')) {
            let value = 0;

            // Look in a few places to see if a tabindex was provided:

            // A value was provided in `x.attributes` (i.e. from the original JSON)
            if (!isNaN(elem.attributes.tabIndex)) {
                value = elem.attributes.tabIndex;
            }
            // Use the lower case `i` value if it exists, although the attribute name must be camel case
            else if (!isNaN(elem.attributes.tabindex)) {
                value = elem.attributes.tabindex;
            }

            // Make sure the value is an integer
            if (typeof value !== 'number') {
                value = parseInt(value, 10);
            }

            elem.attributes.tabIndex = value;
        }

        // Remove the lower case `tabindex` property since React will throw a warning
        if (elem.attributes.hasOwnProperty('tabindex')) {
            // Copy the value to the camelCase property name to preserve it
            if (!elem.attributes.tabIndex) {
                elem.attributes.tabIndex = elem.attributes.tabindex;
            }

            delete elem.attributes.tabindex;
        }


        if (elem.attributes.hasOwnProperty("maxlength")){
            elem.attributes.maxLength = elem.attributes["maxlength"];
        }
       
        return elem;
    };


    const getStyleClasses = function (elem){
        let classList = "";

        function addClass(list, className){

            if(list.length <= 0){
                list = className;
            }
            else {
                list = list + " " + className;
            }

            return list;
        }

        styles = elem.style;

        if (styles !== undefined) {

            if (styles.indexOf(',') !== -1) {
                styles = styles.split(',');
            }
            else {
                styles = [styles];
            }

            for (var i = 0, len = styles.length; i < len; i++) {
                switch (styles[i].trim()) {

                    case 'currency':
                        classList = addClass(classList, "cui-currency");
                        break;

                    case 'align-right':
                        classList = addClass(classList, 'cui-align-right');
                        break;

                    case 'align-center':
                        classList = addClass(classList, 'cui-align-center');
                        break;

                    case 'no-wrap':
                        classList = addClass(classList, 'cui-no-wrap');
                        break;

                    case 'icon':
                        classList = addClass(classList, 'cui-width-icon');
                        break;

                    case 'not-applicable':
                        classList = addClass(classList, 'feta-not-applicable');
                        break;

                    case 'min-width':
                        classList = addClass(classList, 'feta-min-width');
                        break;

                    case 'link':
                        classList = addClass(classList, 'feta-style-link');
                        break;

                    case 'new-line':
                        classList = addClass(classList, 'feta-new-line');
                        break;

                    case 'clear-field':
                        classList = addClass(classList, 'clearField');
                        break;
                }
            }
        }
        return classList;
    };

    /**
     * Cleans up certain props
     *
     * @param {object} dest Props to be updated
     * @param {object} src Optional source props to copy value from
     *
     * @return {object}
     */
    const normalizeProps = function (dest, src = {colIndex: -1, rowIndex: -1, contentsIndex: -1}) {
        // Matches the format `feta.functionCall({0: {function: "nameOfFunction", args: [array,of,args]}})`
        const funcCallObjectRegex = /^feta\.functionCall\((?:event\,)?\s*(\{.+\})\);?$/;

        // Matches the format `feta.functionCall("nameOfFunction", [array,of,arguments])`
        const funcCallStringRegex = /^feta\.functionCall\((?:event\,)?\s*\"([\w.]+)\"\,\s*(\[.+\])\);?$/;

        dest.refData = src.refData;

        if(dest.onclick || dest.onClick){
            dest = normalizeOnClick(dest);
        }

        // Only include row/col index if they were actually defined
        INDEX_TYPES.forEach((type) => {
            if (src[type + 'Index'] !== -1) {
                if (dest.className && dest.className.includes('cui-')) {
                    // console.info('keeping ' + type + 'Index ', dest);
                }
                dest['data-feta-elem-index-' + type] = src[type + 'Index'];
            }
            else if (dest.hasOwnProperty('data-feta-elem-index-' + type)) {
                if (dest.className && dest.className.includes('cui-')) {
                    console.warn('removing ' + type + 'Index ', dest);
                }
                delete dest['data-feta-elem-index-' + type];
            }
        });
      
        EVENT_HANDLER_NAMES.forEach((type) => {
            const callback = dest[type] || src[type];
      
            funcCallObjectRegex.lastIndex = 0; // We need this to ensure `.exec()` always starts its search from the beginning; see https://stackoverflow.com/a/11477448/348995

            if (typeof callback === 'string') {
                if (funcCallObjectRegex.test(callback)) {
                    // console.info('funcCallObjectRegex matched! ' , callback);
                    const parts = funcCallObjectRegex.exec(callback);
                    const funcNames = JSON.parse(parts[1]);

                    dest[type] = () => {
                        Object.keys(funcNames).forEach((key) => {
                            feta.functionCall('', funcNames[key]);
                        });
                    };
                }
                else if (funcCallStringRegex.test(callback)) {
                    const parts = funcCallStringRegex.exec(callback);
                    const funcName = parts[1];
                    const funcArgArray = JSON.parse(parts[2]);

                    dest[type] = (evt) => {
                        feta.functionCall(evt, funcName, funcArgArray);
                    };
                }
            }
            else if (typeof callback === 'function') {
                dest[type] = callback;
            }
        });

        return dest;
    };

    const normalizeOnClick = function (dest) {
        // Matches the format `feta.functionCall({0: {function: "nameOfFunction", args: [array,of,args]}})`
        const funcCallObjectRegex = /^feta\.functionCall\((?:event\,)?\s*(\{.+\})\);?$/;

        // Matches the format `feta.functionCall("nameOfFunction", [array,of,arguments])`
        const funcCallStringRegex = /^feta\.functionCall\((?:event\,)?\s*\"([\w.]+)\"\,\s*(\[.+\])\);?$/;

        const funcCallStringSingleQuotesRegex = /^feta\.functionCall\((?:event\,)?\s*\'([\w.]+)\'\,\s*(\[.+\])\);?$/;

        if(dest.onclick){
            if(!dest.onClick){
                dest.onClick = dest.onclick;
                delete dest.onclick;
            }
            else{
                delete dest.onclick;
            }
        }

        const eventTypes = ["onClick"];

        eventTypes.forEach((type) => {
            let callback = dest[type];

            funcCallObjectRegex.lastIndex = 0; // We need this to ensure `.exec()` always starts its search from the beginning; see https://stackoverflow.com/a/11477448/348995

            if (typeof callback === 'string') {
                const reverseString = function (str) {
                    var splitString = str.split("");
                    var reverseArray = splitString.reverse();
                    var joinArray = reverseArray.join("");

                    return joinArray;
                }

                //Replaces single quotes with double quotes to properly parse as JSON
                const replaceSingleQuotes = function(str){
                    // Uses lookahead to detect single quotes when used as a wrapper in json.
                    const singleQuoteConversion = /\'(?=({|\s{))|\'(?=(\[|\s\[))|\'(?=(\(|\s\())|\'(?=(:|\s:))|\'(?=(,|\s,))|\'(?=(}|\s}))|\'(?=(]|\s]))|\'(?=(\)|\s\)))/g;

                    //Get the callback as a reversed string.
                    let replacedStr = reverseString(str);

                    //Run the singleQuoteConversion regex and replace single quotes with double quotes
                    replacedStr = replacedStr.replace(singleQuoteConversion, '"');

                    //Reverse the string again to set to initial direction
                    replacedStr = reverseString(replacedStr);

                    //Run the singleQuoteConversion regex to replace the rest of the single quotes
                    replacedStr = replacedStr.replace(singleQuoteConversion, '"');
                    return replacedStr
                }

                //Wraps number indexes, eg 0:, in quotes to properly parse as JSON
                const wrapIndexes = function(str){
                    const wrapIndexesSelector = /[0-9](?=(:|\s:))/g;
                    let replacedStr = str.replace(wrapIndexesSelector, "\"$&\"");
                    return replacedStr;
                }

                //Wraps args: parameter in quotes to properly parse as JSON
                const wrapArgs = function(str){
                    let replacedStr = str.replace('args:', "\"args\":");

                    return replacedStr;
                }

                //Replace all single quotes to properly parse JSON
                if(callback.indexOf("'") > -1){
                    callback = replaceSingleQuotes(callback);
                }

                //Wrap all number indexes in quotes to properly parse as json
                callback = wrapIndexes(callback);

                //If present, wrap args parameter in quotes to properly parse as JSON
                if(callback.indexOf("args:")){
                    callback = wrapArgs(callback);
                }

                if (funcCallObjectRegex.test(callback)) {
                    const parts = funcCallObjectRegex.exec(callback);
                    const funcNames = JSON.parse(parts[1]);

                    dest[type] = () => {
                        Object.keys(funcNames).forEach((key) => {
                            feta.functionCall('', funcNames[key]);
                        });
                    };
                }
                else if (funcCallStringRegex.test(callback)) {
                    const parts = funcCallStringRegex.exec(callback);
                    const funcName = parts[1];
                    const funcArgArray = JSON.parse(parts[2]);

                    dest[type] = (evt) => {
                        feta.functionCall(evt, funcName, funcArgArray);
                    };
                }
                else {
                    //Unknown callback.
                    journal.log({type: 'error', owner: 'UI', module: 'normalizeOnClick'}, 'Unknown callback type:', callback);
                    dest[type] = null;
                }
            }
            else if (typeof callback === 'function') {
                dest[type] = callback;
            }
        });

        return dest;
    };

    const normalizeOnChange = function (dest) {
        // Matches the format `feta.functionCall({0: {function: "nameOfFunction", args: [array,of,args]}})`
        const funcCallObjectRegex = /^feta\.functionCall\((?:event\,)?\s*(\{.+\})\);?$/;

        // Matches the format `feta.functionCall("nameOfFunction", [array,of,arguments])`
        const funcCallStringRegex = /^feta\.functionCall\((?:event\,)?\s*\"([\w.]+)\"\,\s*(\[.+\])\);?$/;

        const funcCallStringSingleQuotesRegex = /^feta\.functionCall\((?:event\,)?\s*\'([\w.]+)\'\,\s*(\[.+\])\);?$/;

        if(dest.onchange){
            if(!dest.onChange){
                dest.onChange = dest.onchange;
                delete dest.onchange;
            }
            else{
                delete dest.onchange;
            }
        }

        const eventTypes = ["onChange"];

        eventTypes.forEach((type) => {
            let callback = dest[type];

            funcCallObjectRegex.lastIndex = 0; // We need this to ensure `.exec()` always starts its search from the beginning; see https://stackoverflow.com/a/11477448/348995

            if (typeof callback === 'string') {
                const reverseString = function (str) {
                    var splitString = str.split("");
                    var reverseArray = splitString.reverse();
                    var joinArray = reverseArray.join("");

                    return joinArray;
                }

                //Replaces single quotes with double quotes to properly parse as JSON
                const replaceSingleQuotes = function(str){
                    // Uses lookahead to detect single quotes when used as a wrapper in json.
                    const singleQuoteConversion = /\'(?=({|\s{))|\'(?=(\[|\s\[))|\'(?=(\(|\s\())|\'(?=(:|\s:))|\'(?=(,|\s,))|\'(?=(}|\s}))|\'(?=(]|\s]))|\'(?=(\)|\s\)))/g;

                    //Get the callback as a reversed string.
                    let replacedStr = reverseString(str);

                    //Run the singleQuoteConversion regex and replace single quotes with double quotes
                    replacedStr = replacedStr.replace(singleQuoteConversion, '"');

                    //Reverse the string again to set to initial direction
                    replacedStr = reverseString(replacedStr);

                    //Run the singleQuoteConversion regex to replace the rest of the single quotes
                    replacedStr = replacedStr.replace(singleQuoteConversion, '"');
                    return replacedStr
                }

                //Wraps number indexes, eg 0:, in quotes to properly parse as JSON
                const wrapIndexes = function(str){
                    const wrapIndexesSelector = /[0-9](?=(:|\s:))/g;
                    let replacedStr = str.replace(wrapIndexesSelector, "\"$&\"");
                    return replacedStr;
                }

                //Wraps args: parameter in quotes to properly parse as JSON
                const wrapArgs = function(str){
                    let replacedStr = str.replace('args:', "\"args\":");

                    return replacedStr;
                }

                //Replace all single quotes to properly parse JSON
                if(callback.indexOf("'") > -1){
                    callback = replaceSingleQuotes(callback);
                }

                //Wrap all number indexes in quotes to properly parse as json
                callback = wrapIndexes(callback);

                //If present, wrap args parameter in quotes to properly parse as JSON
                if(callback.indexOf("args:")){
                    callback = wrapArgs(callback);
                }

                if (funcCallObjectRegex.test(callback)) {
                    const parts = funcCallObjectRegex.exec(callback);
                    const funcNames = JSON.parse(parts[1]);

                    dest[type] = () => {
                        Object.keys(funcNames).forEach((key) => {
                            feta.functionCall('', funcNames[key]);
                        });
                    };
                }
                else if (funcCallStringRegex.test(callback)) {
                    const parts = funcCallStringRegex.exec(callback);
                    const funcName = parts[1];
                    const funcArgArray = JSON.parse(parts[2]);

                    dest[type] = (evt) => {
                        feta.functionCall(evt, funcName, funcArgArray);
                    };
                }
                else {
                    //Unknown callback.
                    journal.log({type: 'error', owner: 'UI', module: 'normalizeOnChange'}, 'Unknown callback type:', callback);
                    dest[type] = null;
                }
            }
            else if (typeof callback === 'function') {
                dest[type] = callback;
            }
        });

        return dest;
    };

    /**
     * Renders grid elements (rows, columns, etc)
     *
     * Use `<RenderItem/>` for other elements
     *
     * @param {object}  props  Component props
     *
     * @return {react}
     */
    const GridElem = function (props) {
        const elem = props.source ? props.source : {};
        const type = props.type;
        const attrs = props.attrs;
        const inputOnly = props.inputOnly; // Only declaring this because of an ESLint bug with `react/no-unused-prop-types`
        const classNames = [];
        let wrapperProps = {
            onClick: props.onClick,
        };

        // Make sure it's either a row or column and add the appropriate class
        if (type === 'column') {
            // Only add the standard class if a special one wasn't provided
            if ((!attrs || !attrs.className || !attrs.className.includes('-col-')) && !elem.width) {
                classNames.push('cui-col');
            }
            else if(elem.width){
                classNames.push('cui-col-small-12 cui-col-large-'+elem.width);
            }
        }
        else if (type === 'row') {
            classNames.push('cui-row');
        }
        else {
            journal.log({type: 'error', owner: 'UI', module: 'GridElem'}, 'Unsupported grid element type: "' + type + '" ', JSON.parse(JSON.stringify(props.source)));

            return null;
        }

        // Only include row/col index if they were actually defined
        wrapperProps = normalizeProps(wrapperProps, props);

        const childProps = normalizeProps(wrapperProps, props);

        if (elem.attributes && elem.attributes.className) {
            elem.attributes.className.split(' ').forEach((name) => {
                if (!classNames.includes(name)) {
                    classNames.push(name);
                }
            });
        }

        if (attrs && attrs.className) {
            attrs.className.split(' ').forEach((name) => {
                if (!classNames.includes(name)) {
                    classNames.push(name);
                }
            });
        }

        wrapperProps.className = classNames.join(' ');
        wrapperProps.onClick = props.onClick;

        INDEX_TYPES.forEach((type) => {
            if (wrapperProps.hasOwnProperty(type + 'Index')) {
                // console.log('removing index from wrapperProps');
                delete wrapperProps[type + 'Index'];
            }
        });

        delete wrapperProps.refData;

        // Item has inner `contents`
        if (elem.contents) {
            return (
                <div {...wrapperProps}>
                    {elem.contents.map((childElem) => {
                        if (!childElem.key) {
                            console.error('[RenderItem => GridElem] No childElem.key: ', childElem); // Added 1/31/17, remove when it hasn't reported any problems for a while. The key should be added in the table.js normalize function, assuming this element comes from a table.
                            childElem.key = guid();
                        }

                        return (
                            <RenderItem
                                {...childProps}
                                key={childElem.key}
                                source={childElem}
                                inputOnly={inputOnly}
                                colIndex={wrapperProps.colIndex}
                                rowIndex={wrapperProps.rowIndex}
                                contentsIndex={wrapperProps.contentsIndex}
                            />
                        );
                    })}
                </div>
            );
        }
        // Item has children (but not an empty plain object)
        else if (props.children && !(typeof props.children === 'object' && !Object.keys(props.children).length)) {
            return (
                <div {...wrapperProps}>
                    {props.children || ''}
                </div>
            );
        }
        // Item has only plain text, or it's empty
        else {
            return (
                <div {...wrapperProps}>
                    {elem.text || ''}
                </div>
            );
        }
    };

    GridElem.propTypes = {
        source: ReactPropTypes.shape({}),
        type: ReactPropTypes.string,
        children: ReactPropTypes.any,
        attrs: ReactPropTypes.shape({}),
        inputOnly: ReactPropTypes.bool,
        onClick: ReactPropTypes.func,
        onChange: ReactPropTypes.func,
        onKeyUp: ReactPropTypes.func,
        onKeyDown: ReactPropTypes.func,
        onFocus: ReactPropTypes.func,
        onBlur: ReactPropTypes.func,
    };

    GridElem.defaultProps = {
        source: {},
        type: '',
        children: {},
        attrs: {},
        inputOnly: false,
        onClick: null,
        onChange: null,
        onKeyUp: null,
        onKeyDown: null,
        onFocus: null,
        onBlur: null,
    };


    /**
     * Creates a text field
     *
     * @param {object}  props  Component props
     *
     * @return {react}
     */
    const TextField = function (props) {
        const isControlled = (props.onChange || props.onKeyUp || props.onKeyDown);
        const elem = normalizeElementAttrs(props.source, isControlled);
        let elemProps = null;

        if(isControlled){
            elemProps = normalizeProps(
                            {
                                onClick: props.onClick,
                                onChange: props.onChange,
                                onFocus: props.onFocus,
                                onBlur: props.onBlur,
                                onKeyUp: props.onKeyUp,
                                onKeyDown: props.onKeyDown,
                            },
                            props
                        );
        }
        else{
            elemProps = normalizeProps({},props);
        }       

        delete elemProps.refData;

        // Add the element's `attributes` to the parameters that are passed to each event handler
        EVENT_HANDLER_NAMES.forEach((evtType) => {
            if (typeof elemProps[evtType] === 'function') {
                elemProps[evtType] = (...args) => {
                    props[evtType](...args, elem.attributes);
                };
            }
        });

        return (
            <input
                {...elem.attributes}
                {...elemProps}
            />
        );
    };

    TextField.propTypes = {
        source: ReactPropTypes.shape({}),
        onClick: ReactPropTypes.func,
        onChange: ReactPropTypes.func,
        onFocus: ReactPropTypes.func,
        onBlur: ReactPropTypes.func,
        onKeyUp: ReactPropTypes.func,
        onKeyDown: ReactPropTypes.func,
    };

    TextField.defaultProps = {
        source: {},
        onClick: null,
        onChange: null,
        onFocus: null,
        onBlur: null,
        onKeyUp: null,
        onKeyDown: null,
    };


    /**
     * Creates a date field
     *
     * @param {object}  props  Component props
     *
     * @return {react}
     */
    const DateField = function (props) {
        const propsCopy = props; // Make a copy to avoid having to define every single `proptype` and `defaultProp`; defining them causes a "checker is not a function" React warning that I can't resolve (CP 5/30/17)

        // Point directly to the input element if `source` contains the whole field (input and label)
        if (propsCopy.source.input) {
            propsCopy.source = propsCopy.source.input;
        }

        // Add a date class for non-table form fields
        if (!propsCopy.inputOnly && !propsCopy.source.readOnly) {
        	if(!propsCopy.source.attributes.className || propsCopy.source.attributes.className == ""){
				propsCopy.source.attributes.className = 'cui-date cui-value';
        }
        	else{
        		if(propsCopy.source.attributes.className.indexOf("cui-date") == -1){
        			propsCopy.source.attributes.className += " cui-date";
        		}
        		if(propsCopy.source.attributes.className.indexOf("cui-value") == -1){
        			propsCopy.source.attributes.className += " cui-value";
        		}
        	}

        	//Set default date field size attributes
        	propsCopy.source.attributes.size = (propsCopy.source.attributes.size) ? propsCopy.source.attributes.size : 10;
        	propsCopy.source.attributes.maxLength = (propsCopy.source.attributes.maxLength) ? propsCopy.source.attributes.maxLength : 10;
        }

        // Use the standard text field renderer
        return (
            <TextField {...propsCopy} />
        );
    };

    DateField.propTypes = {
        source: ReactPropTypes.shape({}),
        onClick: ReactPropTypes.func,
        onChange: ReactPropTypes.func,
        onKeyUp: ReactPropTypes.func,
        onKeyDown: ReactPropTypes.func,
        onFocus: ReactPropTypes.func,
        onBlur: ReactPropTypes.func,
        rowIndex: ReactPropTypes.number,
        colIndex: ReactPropTypes.number,
        contentsIndex: ReactPropTypes.number,
    };

    DateField.defaultProps = {
        source: {},
        onClick: null,
        onChange: null,
        onKeyUp: null,
        onKeyDown: null,
        onFocus: null,
        onBlur: null,
        rowIndex: -1,
        colIndex: -1,
        contentsIndex: -1,
    };


    /**
     * Creates a dropdown field
     *
     * @param {object}  props  Component props
     *
     * @return {react}
     */
    const SelectField = (props) => {

		const elem = props.source;
        const isControlled = (props.onChange || props.onKeyUp || props.onKeyDown);
        const input = normalizeElementAttrs(elem, isControlled);
        const elemProps = normalizeProps(
                                {
                                    onClick: props.onClick,
                                    onChange: props.onChange,
                                    onFocus: props.onFocus,
                                    onBlur: props.onBlur,
                                    onKeyUp: props.onKeyUp,
                                    onKeyDown: props.onKeyDown,
                                },
                                props
                            );

        if (input.readOnly) {
            const selectKey = input.value;
            let readOnlyValue;
            let readOnlyAttributes;

            if (input.attributes) {
                readOnlyAttributes = getReadOnlyAttributes(input.attributes);
            }

            if (selectKey) {
                readOnlyValue = (input.options[selectKey]) ? input.options[selectKey].text : null;
            }

            return (
                <span
                    {...readOnlyAttributes}
                >
                    {readOnlyValue}
                </span>
            );

        }
        else {
            delete elemProps.refData;

            // Add the element's `attributes` to the parameters that are passed to each event handler
            EVENT_HANDLER_NAMES.forEach((evtType) => {
                if (typeof elemProps[evtType] === 'function') {
                    elemProps[evtType] = (...args) => {
                        props[evtType](...args, elem.attributes);
                    };
                }
            });

            return (
                <select
                    {...input.attributes}
                    {...elemProps}
                >
                    {Object.keys(input.options).map((key) => {
                        const opt = input.options[key];

                        return (
                            <option
                                key={key}
                                value={opt.value}
                            >
                                {opt.text}
                            </option>
                        );
                    })}
                </select>
            );
        }

    };

    SelectField.propTypes = {
        source: ReactPropTypes.shape({}),
        onClick: ReactPropTypes.func,
        onChange: ReactPropTypes.func,
        onKeyUp: ReactPropTypes.func,
        onKeyDown: ReactPropTypes.func,
        onFocus: ReactPropTypes.func,
        onBlur: ReactPropTypes.func,
    };

    SelectField.defaultProps = {
        source: {},
        onClick: null,
        onChange: null,
        onKeyUp: null,
        onKeyDown: null,
        onFocus: null,
        onBlur: null,
    };


    /**
     * Creates a check box or radio button element
     *
     * @param {object}  props  Component props
     *
     * @return {react}
     */
    const CheckableField = function (props) {
        const isControlled = (!props.source.readOnly && (props.onChange || props.onKeyUp || props.onKeyDown));
        const elem = normalizeElementAttrs(props.source, isControlled, true);
        
        let elemProps = null;

        if(isControlled){
            elemProps = normalizeProps(
                            {
                                onClick: props.onClick,
                                onChange: props.onChange,
                                onFocus: props.onFocus,
                                onBlur: props.onBlur,
                                onKeyUp: props.onKeyUp,
                                onKeyDown: props.onKeyDown,
                            },
                            props
                        );
        }
        else{
            elemProps = normalizeProps({},props);
        }
       
        // `type` is required
        if (elem.attributes.type !== 'checkbox' && elem.attributes.type !== 'radio') {
            journal.log({type: 'warn', owner: 'UI', module: 'CheckableField'}, 'CheckableField input has a `type` value of "' + elem.attributes.type + '". It should be either `checkbox` or `radio`. Defaulting to checkbox.', JSON.parse(JSON.stringify(props.source)));

            elem.attributes.type = 'checkbox';
        }

        if (elem.readOnly) {
            let readOnlyAttributes;
            const typeValue = 'check' + ((elem.attributes.checked || elem.attributes.defaultChecked) ? '' : '-off');

            if (elem.attributes) {
                readOnlyAttributes = getReadOnlyAttributes(elem.attributes);
            }

            // Render readonly icon
            return (
                <RenderIcon
                    {...readOnlyAttributes}
                    type={typeValue}
                />
            );
        }
        else {
            delete elemProps.refData;

            // Add the element's `attributes` to the parameters that are passed to each event handler
            EVENT_HANDLER_NAMES.forEach((evtType) => {
                if (typeof elemProps[evtType] === 'function') {
                    elemProps[evtType] = (...args) => {
                        props[evtType](...args, elem.attributes);
                    };
                }
            });

            return (
                <input
                    {...elem.attributes}
                    {...elemProps}
                />
            );
        }
    };

    CheckableField.propTypes = {
        source: ReactPropTypes.shape({
            readOnly: ReactPropTypes.bool,
        }),
        onClick: ReactPropTypes.func,
        onChange: ReactPropTypes.func,
        onKeyUp: ReactPropTypes.func,
        onKeyDown: ReactPropTypes.func,
        onFocus: ReactPropTypes.func,
        onBlur: ReactPropTypes.func,
    };

    CheckableField.defaultProps = {
        source: {
            readOnly: false,
        },
        onClick: null,
        onChange: null,
        onKeyUp: null,
        onKeyDown: null,
        onFocus: null,
        onBlur: null,
    };


    /**
     * <Hidden/> creates an `<input type=hidden>` element
     *
     * @param {object}  props  Component props
     *
     * @return {react}
     */
    const Hidden = function (props) {
        const elem = normalizeElementAttrs(props.source, false);
        const elemProps = normalizeProps({}, props);

        delete elemProps.refData;

        return (
            <input
                {...elem.attributes}
                {...elemProps}
                type="hidden"
            />
        );
    };

    Hidden.propTypes = {
        source: ReactPropTypes.shape({}),
    };

    Hidden.defaultProps = {
        source: {},
    };


    /**
     * <Button/> creates a `<button>` element
     *
     * @param {object}  props  Component props
     *
     * @return {react}
     */
    const Button = function (props) {
        const elem = normalizeElementAttrs(props.source, false);
        const elemProps = normalizeProps({}, props);

        let buttonStyleClasses = getStyleClasses(elem);

        if(buttonStyleClasses){
            //Add any additonal classes determined through style.
            if(elem.attributes.className && typeof elem.attributes.className  === 'string'){
                elem.attributes.className = elem.attributes.className + " " + buttonStyleClasses;
            }
            else{
                elem.attributes.className = buttonStyleClasses;
            }
        }

        // `type` is required
        if (!elem.attributes.type) {
            elem.attributes.type = 'button';
        }

        if(elem.primary && elem.primary === true){
            if(elem.attributes.className && typeof elem.attributes.className  === 'string'){
                elem.attributes.className = elem.attributes.className + " cui-button-primary";
            }
            else{
                elem.attributes.className = "cui-button-primary";
            }
        }

        delete elemProps.refData;

        // Add the element's `attributes` to the parameters that are passed to each event handler
        EVENT_HANDLER_NAMES.forEach((evtType) => {
            if (typeof elemProps[evtType] === 'function') {
                elemProps[evtType] = (...args) => {
                    props[evtType](...args, elem.attributes);
                };
            }
        });
     
        return (
            <button
                {...elem.attributes}
                {...elemProps}
            >
                {elem.text}
            </button>
        );
    };

    Button.propTypes = {
        source: ReactPropTypes.shape({}),
    };

    Button.defaultProps = {
        source: {},
    };


    /**
     * Renders arbitrary (non-grid) elements
     *
     * Note: all items should be routed through `<RenderItem/>` rather than calling this component directly
     * Note: this component is not ideal for grid elements; use `<RenderItem/>` to route items appropriately
     *
     * @param {object}  props  Component props
     */
    const Element = function (props) {
        const isControlled = (props.onChange || props.onKeyUp || props.onKeyDown);
        const elem = normalizeElementAttrs(props.source, isControlled);
        const elemProps = normalizeProps(
                                {
                                    onClick: props.onClick,
                                    onChange: props.onChange,
                                    onFocus: props.onFocus,
                                    onBlur: props.onBlur,
                                    onKeyUp: props.onKeyUp,
                                    onKeyDown: props.onKeyDown,
                                },
                                props
                            );
        const TagName = elem.type; // This variable name must be capitalized per React requirements
        const voidElements = ['img', 'input', 'br', 'hr', 'link', 'area', 'base', 'col', 'command', 'embed', 'meta', 'param', 'source']; // Elements with no inner content or closing tag, e.g. you would use `<img/>` but not `<img>content</img>`

        // Special handling for labels
        if (elem.type === 'label') {
            // Make sure we're using the React-compliant attribute name `htmlFor` instead of `for`
            if (elem.attributes.for) {
                if (!elem.attributes.htmlFor) {
                    elem.attributes.htmlFor = elem.attributes.for;
                }

                // Remove the unsupported attributes so React won't throw a warning
                delete elem.attributes.for;
            }
            else if (!elem.attributes.htmlFor) {
                journal.log({type: 'warn', owner: 'UI', module: 'Element'}, 'Label should have either the `for` or `htmlFor` attributes: ', JSON.parse(JSON.stringify(props.source)));
            }
        }

        // The only different between the two blocks below should be whether or not they contain inner content (`elem.text`) and a closing tag
        // Note: if this throws "unknown prop type" warnings, it's because `TagName` is not an actual DOM element name. If we need to render custom component names, see the comments in `RenderItem.jsx` about dynamically-named React components.

        // Elements with no inner content or closing tag
        if (voidElements.includes(TagName)) {
            // Log error if the element has content that we won't be rendering
            if (elem.contents || elem.text) {
                journal.log({type: 'error', owner: 'UI', module: 'Element'}, 'Element has `contents` or `text` which will not be rendered because <' + TagName + '> tags cannot contain content. The element itself will still be rendered, but without any content.', JSON.parse(JSON.stringify(props.source)));
            }

            delete elem.attributes.refData;
            delete elemProps.refData;

            // Add the element's `attributes` to the parameters that are passed to each event handler
            EVENT_HANDLER_NAMES.forEach((evtType) => {
                if (typeof elemProps[evtType] === 'function') {
                    elemProps[evtType] = (...args) => {
                        props[evtType](...args, elem.attributes);
                    };
                }
            });

            return (
                <TagName
                    {...elem.attributes}
                    {...elemProps}
                />
            );
        }
        // Standard element with closing tag
        else {
            delete elem.attributes.refData;
            delete elemProps.refData;

            // Add the element's `attributes` to the parameters that are passed to each event handler
            EVENT_HANDLER_NAMES.forEach((evtType) => {
                if (typeof elemProps[evtType] === 'function') {
                    elemProps[evtType] = (...args) => {
                        props[evtType](...args, elem.attributes);
                    };
                }
            });

            // Item has inner contents
            if (elem.contents) {
                return (
                    <TagName
                        {...elem.attributes}
                        {...elemProps}
                    >
                        {elem.contents.map((childElem) => {
                            return (
                                <RenderItem
                                    key={childElem.key}
                                    source={childElem}
                                    inputOnly={props.inputOnly}
                                    colIndex={elemProps.colIndex}
                                    rowIndex={elemProps.rowIndex}
                                    contentsIndex={elemProps.contentsIndex}
                                />
                            );
                        })}
                    </TagName>
                );
            }
            // Item has only plain text
            else {
                return (
                    <TagName
                        {...elem.attributes}
                        {...elemProps}
                    >
                        {elem.text || ''}
                    </TagName>
                );
            }
        }
    };

    Element.propTypes = {
        source: ReactPropTypes.shape({}),
        onClick: ReactPropTypes.func,
        onChange: ReactPropTypes.func,
        onFocus: ReactPropTypes.func,
        onBlur: ReactPropTypes.func,
        onKeyUp: ReactPropTypes.func,
        onKeyDown: ReactPropTypes.func,
        inputOnly: ReactPropTypes.bool,
    };

    Element.defaultProps = {
        source: {},
        onClick: null,
        onChange: null,
        onFocus: null,
        onBlur: null,
        onKeyUp: null,
        onKeyDown: null,
        inputOnly: false,
    };


    /**
     * Creates a field for any input type or read-only values
     *
     * @param {object}  props  Component props
     *
     * @return {react}
     */
    const RenderField = function (props) {
        const item = props.source;
        const itemType = props.type || item.type;
        const componentName = (itemType) ? itemType[0].toUpperCase() + itemType.substr(1) : '';
        const inputType = item.button ? 'button' : 'input';
        const wrapperClassNames = ['cui-field'];
        let dataSection;
        let controlsSection;
        let suffixElem = null;
        let prefixElem = null;

        const renderFieldAddClass = (fieldClassName) => {
            if( !commonProps.source.attributes.className || !commonProps.source.attributes.className.includes(fieldClassName)) {
                commonProps.source.attributes.className = commonProps.source.attributes.className ? commonProps.source.attributes.className + " " + fieldClassName : fieldClassName;
            }
        }

        // Reuse the same props object for all components
        const commonProps = normalizeProps(
                                {
                                    source: item,
                                    onClick: props.onClick,
                                    onChange: props.onChange,
                                    onFocus: props.onFocus,
                                    onBlur: props.onBlur,
                                    onKeyUp: props.onKeyUp,
                                    onKeyDown: props.onKeyDown,
                                    colIndex: props.colIndex,
                                    rowIndex: props.rowIndex,
                                    contentsIndex: props.contentsIndex,
                                },
                                props
                            );

        if (item.label && (!item.label.attributes.className || !item.label.attributes.className.includes('cui-desc'))) {
            item.label.attributes.className = item.label.attributes.className ? item.label.attributes.className + ' cui-desc' : 'cui-desc';
        }

       	if(item.label && item.label.visibility === "hidden"){
			if(!item.label.attributes.className || item.label.attributes.className == ""){
				item.label.attributes.className = 'cui-hide-from-screen';
			}
			else{
				if(item.label.attributes.className.indexOf('cui-hide-from-screen') == -1){
					item.label.attributes.className += ' cui-hide-from-screen';
				}
			}
        }

        if(item.prefix){
            if(item.prefix.text && typeof item.prefix.text === "string" && item.prefix.text != ""){
                prefixElem = (
                    <span className="cui-input-prepend">{item.prefix.text}</span>
                    );
            }
        }

        if(item.suffix){
            if(item.suffix.text && typeof item.suffix.text === "string" && item.suffix.text != ""){
                suffixElem = (
                    <span className="cui-input-append">{item.suffix.text}</span>
                    );
            }
        }

        if (item[inputType]) {

            let inputStyleClasses = getStyleClasses(item[inputType]);

            // Point directly to the input element
            commonProps.source = commonProps.source[inputType];

            if (itemType != 'button' && !props.inputOnly && (!commonProps.source.attributes.className || !commonProps.source.attributes.className.includes('cui-value'))) {
                commonProps.source.attributes.className = commonProps.source.attributes.className ? commonProps.source.attributes.className + ' cui-value' : 'cui-value';
            }

            // Required field
            if (item[inputType].required) {
                wrapperClassNames.push('cui-required');
                commonProps.source.attributes['aria-required'] = 'true';
            }


            if(itemType === 'button' && item[inputType].icon && item[inputType].icon != ""){
                let buttonIcon = item[inputType].icon;
                switch (buttonIcon){
                    case "trash-can":
                        renderFieldAddClass("feta-icon-delete");
                    break;

                    case "copy-value":
                        renderFieldAddClass("feta-icon-copy-value copyValue");
                    break;

                    case "next":
                        renderFieldAddClass("feta-icon-next");
                    break;

                    case "previous":
                        renderFieldAddClass("feta-icon-previous");
                    break;

                    case "first":
                        renderFieldAddClass("feta-icon-first");
                    break;

                    case "last":
                        renderFieldAddClass("feta-icon-last");
                    break;

                    default:
                }



            }


            // Read-only field (except radios, check boxes, and selects, which handle read-only-ness within their sub-components)
            if (item[inputType].readOnly && !/Checkbox|Radio|Select/.test(componentName)) {
                let readOnlyAttributes;

                if (item[inputType].attributes) {
                    readOnlyAttributes = getReadOnlyAttributes(item[inputType].attributes);
                }

                const span = (
                    <span
                        className="cui-value"
                        {...readOnlyAttributes}
                    >
                        {item[inputType].attributes.value}
                    </span>
                );

                // Input only (e.g. for tables)
                if (props.inputOnly || !item.label) {
                    return span;
                }
                // Standard form field
                else {
                    item.label.readOnly = true;

                    let dataWrapperClasses = "cui-data "+ inputStyleClasses;

                    if(prefixElem || suffixElem){
                        dataSection = (
                            <div className={dataWrapperClasses}>
                                <div className="cui-input-wrapper">
                                    {prefixElem}
                                    {span}
                                    {suffixElem}
                                </div>
                            </div>
                        );
                    }
                    else{
                        dataSection = (
                            <div className={dataWrapperClasses} >
                                {span}
                            </div>
                        );
                    }
                }
            }
            else {
                // Seemed to be duplicating input elements within json structure.
                //TODO: try passing `commonProps.source.input` to the function
                // commonProps.source.input = normalizeElementAttrs(commonProps.source);

                const elem = React.createElement(componentList[componentName], commonProps);

                // Input only (e.g. for tables)
                // Disable inputOnly for now.
                if (false && props.inputOnly || !item.label) {

                    return elem;
                }
                // Standard form field
                else {

                    let dataWrapperClasses = "cui-data "+ inputStyleClasses;

                    if(prefixElem || suffixElem){
                        dataSection = (
                            <div className={dataWrapperClasses} >
                                <div className="cui-input-wrapper">
                                    {prefixElem}
                                    {elem}
                                    {suffixElem}
                                </div>
                            </div>
                        );
                    }
                    else{
                        dataSection = (
                            <div className={dataWrapperClasses} >
                                {elem}
                            </div>
                        );
                    }


                }
            }
        }

        // Field controls
        if (item.controls) {
            controlsSection = (
                <div className="cui-controls">
                    {item.controls.map((control) => {
                        const key = guid(); //FIXME, this isn't very efficient and there's no need to generate a new one on every re-render

                        return (
                            <RenderItem
                                source={control}
                                key={key}
                            />
                        )
                    })}
                </div>
            );
        }

        const label = item.label;

        label.type = 'label';

        if(label.hasLayout === false){
            return (
                <div className={wrapperClassNames.join(' ')}>
                    <div className="cui-field-main">
                        <Element
                            source={label}
                            key="label"
                        />
                        {dataSection}
                        {controlsSection}
                    </div>
                </div>
            );
        }

        return (
            <div className={wrapperClassNames.join(' ')}>
                <div className="cui-field-main">
                    <div className="cui-label">
                        <Element
                            source={label}
                            key="label"
                        />
                    </div>
                    {dataSection}
                    {controlsSection}
                </div>
            </div>
        );
    };

    RenderField.propTypes = {
        source: ReactPropTypes.shape({}),
        type: ReactPropTypes.string,
        inputOnly: ReactPropTypes.bool,
        rowIndex: ReactPropTypes.number,
        colIndex: ReactPropTypes.number,
        contentsIndex: ReactPropTypes.number,
        onClick: ReactPropTypes.func,
        onChange: ReactPropTypes.func,
        onFocus: ReactPropTypes.func,
        onBlur: ReactPropTypes.func,
        onKeyUp: ReactPropTypes.func,
        onKeyDown: ReactPropTypes.func,
    };

    RenderField.defaultProps = {
        source: {},
        template: '',
        type: '',
        inputOnly: false,
        rowIndex: -1,
        colIndex: -1,
        contentsIndex: -1,
        onClick: null,
        onChange: null,
        onFocus: null,
        onBlur: null,
        onKeyUp: null,
        onKeyDown: null,
    };


    /**
     * Creates an input group field
     *
     * @param {object}  props  Component props
     *
     * @return {react}
     */
    const InputGroup = function (props) {
        const group = props.source;
        const wrapperClassNames = ['cui-field'];
        const itemType = props.type || group.type;
        const componentName = itemType[0].toUpperCase() + itemType.substr(1);
        let dataSection;
        let controlsSection;

        if (group.attributes && group.attributes.className) {
            group.attributes.className.split(' ').forEach((name) => {
                wrapperClassNames.push(name);
            });
        }

        //TODO: How to determine if it's a required field? Where do we look to find the flag?
        // if (field.input.required) {
        //     wrapperClassNames.push('cui-required');
        // }

        //TODO: Read-only field — same problem as above
        // if (field.input.readOnly) {
        //     field.label.readOnly = true;
        // }

        // Collect label and input elements
        if (group.options) {
            const options = [];

            group.options.forEach((field) => {
                let key;

                if (field.input && field.input.attributes) {
                    key = field.input.attributes.id;
                }
                else if (field.button && field.button.attributes) {
                    key = field.button.attributes.id;
                }

                if (!key) {
                    key = guid();
                }

                // Get the input element (buttons won't have the `.input` sub-property)
                const source = field.input ? field.input : field;

                options.push(
                    React.createElement(componentList[componentName], {source, key})
                );

                const label = field.label;

                if (label) {
                    label.type = 'label';

                    options.push(
                        <Element
                            source={label}
                            key={key + '_label'}
                        />
                    );
                }
            });

            dataSection = (
                <div className="cui-data">
                    {options}
                </div>
            );
        }

        // Field controls
        if (group.controls) {
            controlsSection = (
                <div className="cui-controls">
                    {group.controls.map((control) => {
                        const key = guid(); //FIXME

                        return (
                            <RenderItem
                                source={control}
                                key={key}
                            />
                        );
                    })}
                </div>
            );
        }

        return (
            <fieldset>
                <div className={wrapperClassNames.join(' ')}>
                    <div className="cui-field-main">
                        <div className="cui-label">
                            <legend className="cui-desc">{group.legend}</legend>
                        </div>
                        {dataSection}
                        {controlsSection}
                    </div>
                </div>
            </fieldset>
        );
    };

    InputGroup.propTypes = {
        source: ReactPropTypes.shape({}),
        type: ReactPropTypes.string,
    };

    InputGroup.defaultProps = {
        source: {},
        type: 'checkbox',
    };


    /**
     * Creates a button menu
     *
     * @param {object}  props  Component props
     *
     * @return {react}
     */
    const ButtonMenu = function (props) {
        const elem = normalizeElementAttrs(props.source, false);
        const elemProps = normalizeProps({}, props);
        let menuObject = null;
        let menuInitalizedClass = 'feta-table-button-menu-setup';
        let menuListWrapperClass = 'feta-button-menu-hidden-list';
        let menuListClass = 'feta-render-button-menu';


        const toggleMenu = function (evt){

            let triggerElement = evt.target;

            if(!triggerElement.classList.contains(menuInitalizedClass)){

                let menuListHTML = '';

                let parent = triggerElement.parentNode;
                let menuList = parent.querySelector('.'+menuListClass);

                let buttonMenuPopover = $.popover(
                    triggerElement,
                    {
                        html: menuList,

                        display: {
                            className: 'feta-button-menu-popover',
                        },
                        resizeMobile:false,
                    }
                );
                buttonMenuPopover.show();

                triggerElement.classList.add(menuInitalizedClass);
            }
        };

        // `type` is required
        if (!elem.attributes.type) {
            elem.attributes.type = 'button';
        }

        if(!elem.attributes.className){
            elem.attributes.className = "feta-button-menu-toggle";
        }
        else{
            elem.attributes.className += " feta-button-menu-toggle";
        }

        //Override onclick of menu button.
        elem.attributes.onClick = toggleMenu;

        delete elemProps.refData;

        // Add the element's `attributes` to the parameters that are passed to each event handler
        EVENT_HANDLER_NAMES.forEach((evtType) => {
            if (typeof elemProps[evtType] === 'function') {
                elemProps[evtType] = (...args) => {
                    props[evtType](...args, elem.attributes);
                };
            }
        });

        //Render items in hidden div to display later.
        if(elem.items && elem.items.length > 0){
             menuObject = elem.items.map((item) => {

                return (

                    <li>
                    <RenderItem
                    source={item}
                    />
                    </li>
                );

            });
        }

        return (
            <div className="feta-button-menu">
                <button
                    {...elem.attributes}
                    {...elemProps}
                >
                    {elem.text}
                </button>

                <div className={menuListWrapperClass}>
                    <div className={menuListClass}>
                        <ul>
                            {menuObject}
                        </ul>
                    </div>
                </div>

            </div>
        );
    };

    ButtonMenu.propTypes = {
        source: ReactPropTypes.shape({}),
    };

    ButtonMenu.defaultProps = {
        source: {},
    };


    /**
     * Creates a composite
     *
     * @param {object}  props  Component props
     *
     * @return {react}
     */
    const Composite = function (props) {
        const composite = props.source;
        const wrapperClassNames = ['cui-field feta-composite'];
        const itemType = props.type || composite.type;
        let compositeElement = null;

        if (composite.attributes && composite.attributes.className) {
            composite.attributes.className.split(' ').forEach((name) => {
                wrapperClassNames.push(name);
            });
        }

        const _composite_DateRange = function(){
            let toElement;
            let fromElement;
            let legend;
            let compositeRequired;

            const _hideLabel = function(source){
                if(source.label){
                    if(source.label.attributes){
                        if(source.label.attributes.className){
                            source.label.attributes.className = source.label.attributes.className + " cui-hide-from-screen";
                        }
                        else{
                            source.label.attributes.className = 'cui-hide-from-screen';
                        }
                    }
                }

                return source;
            }

            const _addRequired = function(source){
                if(source.input){
                    if(!source.input.required && source.input.required !== true){
                        source.input.required = true;
                    }
                }
                return source;
            }

            if(composite.properties){
                if(composite.properties.legend){
                    legend = (
                        <legend className="cui-desc">{composite.properties.legend}</legend>
                    );
                }

                if(composite.properties.required && composite.properties.required === true){
                    wrapperClassNames.push('cui-required');
                    compositeRequired = true;
                }
            }

            //Get from date
            if(composite.parts){
                if(composite.parts.from){
                    let source = composite.parts.from;

                    source = _hideLabel(source);

                    if(compositeRequired){
                        source = _addRequired(source);
                    }

                    fromElement = (
                        <RenderItem
                            source={source}
                            inputOnly
                        />
                    );
                }
                if(composite.parts.to){
                    let source = composite.parts.to;

                    source = _hideLabel(source);

                    if(compositeRequired){
                        source = _addRequired(source);
                    }

                    toElement = (
                        <RenderItem
                            source={source}
                            inputOnly
                        />
                    );
                }
            }

            compositeElement = (
                <fieldset>
                    <div className={wrapperClassNames.join(' ')}>
                        <div className="cui-field-main">
                            <div className="cui-label">
                                {legend}
                            </div>
                            <div className="cui-data">
                                <div className="cui-field-collection">
                                    <div className="cui-field-collection-item">
                                        {fromElement}
                                    </div>
                                    <div className="cui-field-collection-item">
                                        <span className="cui-sub-label">to</span>
                                    </div>
                                    <div className="cui-field-collection-item">
                                        {toElement}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            );
        }

        const _composite_Search = function(){
            let labelElement = null;
            let textElement = null;
            let buttonElement = null;
            let controlsSection = null;
            let compositeAttributes = [];

            const _hideLabel = function(source){
                if(source.label){
                    if(source.label.attributes){
                        if(source.label.attributes.className){
                            source.label.attributes.className = source.label.attributes.className + " cui-hide-from-screen";
                        }
                        else{
                            source.label.attributes.className = 'cui-hide-from-screen';
                        }
                    }
                }
                return source;
            }

            if(composite.attributes){
                if (composite.attributes.id){
                    compositeAttributes.id = composite.attributes.id;
                }
            }

            if(composite.parts){
                if(composite.parts.from){
                    let source = composite.parts.from;

                    source = _hideLabel(source);

                    if(compositeRequired){
                        source = _addRequired(source);
                    }

                    fromElement = (
                        <RenderItem
                            source={source}
                            inputOnly
                        />
                    );
                }

                if(composite.parts.label){
                    let source = composite.parts.label.label;
                    if (source.template){
                        source.template = "";
                    }

                    source.type="label";

                    labelElement = (
                        <RenderItem
                            source={source}
                            inputOnly
                        />
                    );
                }

                 // if(composite.parts.text){
                 //    let source = composite.parts.text;
                 //    let textElements = [];
                 //    if(source.length>0){
                 //        source.forEach((col, c) => {
                 //            const controlItemSource = source[c];

                 //            // TODO: Look into updating key and removing row/col index.
                 //            textElements.push(
                 //                <RenderItem
                 //                    source={controlItemSource}
                 //                />
                 //            );
                 //        });
                 //    }


                if(composite.parts.text){
                    let source = composite.parts.text;

                    source = _hideLabel(source);

                    textElement = (
                        <RenderItem
                            source={source}
                        />
                    );
                }

                // source = _hideLabel(source);
                //     textElement = (
                //         <span>{textElements}</span>
                //     );
                // }

                if(composite.parts.button){
                    let source = composite.parts.button;

                    source = _hideLabel(source);

                    buttonElement = (
                        <RenderItem
                            source={source}
                        />
                    );
                }

                // composite controls
                if (composite.parts.controls) {
                    controlsSection = (
                        <div className="cui-controls">
                            {composite.parts.controls.map((control) => {
                                const key = guid(); //FIXME

                                return (
                                    <RenderItem
                                        source={control}
                                        key={key}
                                    />
                                );
                            })}
                        </div>
                    );
                }
            }

            compositeElement = (
                <div className={wrapperClassNames.join(' ')} {...compositeAttributes}>
                    <div className="cui-field-main">
                        <div className="cui-label">
                            {labelElement}
                        </div>
                        <div className="cui-data">
                            {textElement}
                            {buttonElement}
                        </div>
                        {controlsSection}
                    </div>
                </div>
            );

        }

        if(itemType){
            switch(itemType){
                case 'dateRange':
                _composite_DateRange();
                break;

                case 'search':
                _composite_Search();
                break;

                case 'default':
                default:

            }
        }

        if(compositeElement === null){
         journal.log({type: 'error', owner: 'UI', module: 'RenderItem'}, 'Unsupported composite type: "' + itemType + '" ', JSON.parse(JSON.stringify(props.source)));
        }

        return compositeElement;
    };

    Composite.propTypes = {
        source: ReactPropTypes.shape({}),
    };

    Composite.defaultProps = {
        source: {},
    };

    /**
     * Renders any type of field, template, or element
     *
     * @param {object}  props  Component props
     *
     * @return {react}
     */
    const RenderItem = function (props) {
        const item = props.source;
        const itemTemplate = props.template || item.template;
        const itemType = props.type || item.type;
        const attrs = props.attrs || item.attributes;
        let inputType;

        // Make sure the `attributes` object is defined so we can examine its properties in various places throughout our code without having to check for its existence
        if (!item.attributes) {
            item.attributes = {};
        }

        // Fields (e.g. input + label)
        if (itemTemplate === 'field') {
            // Make sure we actually have a React component for this field type
            // if (typeof itemType !== 'string' || !/text|checkbox|radio|button|hidden|password|date|select/.test(itemType)) {
            //     journal.log({type: 'error', owner: 'UI', module: 'RenderItem'}, 'Unsupported field type: "' + itemType + '" ', JSON.parse(JSON.stringify(props.source)));

            //     return null;
            // }

            // Determine which subproperty to look for in the object
            if (item[inputType]) {
                inputType = 'input';
            }
            else if (item.button) {
                inputType = 'button';
            }

            // Make sure the sub-component `attributes` objects are defined
            if (item.label && !item.label.attributes) {
                item.label.attributes = {};
            }

            if (item[inputType] && !item[inputType].attributes) {
                item[inputType].attributes = {};
            }

            if (item.button && !item.button.attributes) {
                item.button.attributes = {};
            }

            // Reuse the same props object for all components
            const commonProps = normalizeProps(
                                    {
                                        source: item,
                                        inputOnly: props.inputOnly,
                                        onClick: props.onClick,
                                        onChange: props.onChange,
                                        onFocus: props.onFocus,
                                        onBlur: props.onBlur,
                                        onKeyUp: props.onKeyUp,
                                        onKeyDown: props.onKeyDown,
                                        colIndex: props.colIndex,
                                        rowIndex: props.rowIndex,
                                        contentsIndex: props.contentsIndex,
                                    },
                                    props
                                );

            return (
                <RenderField
                    {...commonProps}
                />
            );
        }
        // Field group
        else if (itemTemplate === 'inputGroup') {
            return (
                <InputGroup
                    source={item}
                    type={itemType}
                    attrs={attrs}
                    onClick={props.onClick}
                    inputOnly={props.inputOnly}
                    colIndex={props.colIndex}
                    rowIndex={props.rowIndex}
                    contentsIndex={props.contentsIndex}
                />
            );
        }
        else if (itemTemplate === 'buttonMenu') {
            return (
                <ButtonMenu
                    source={item}
                    type={itemType}
                    attrs={attrs}
                    onClick={props.onClick}
                    inputOnly={props.inputOnly}
                    colIndex={props.colIndex}
                    rowIndex={props.rowIndex}
                    contentsIndex={props.contentsIndex}
                />
            );
        }
        // Grid elements
        else if (itemTemplate === 'grid') {
            return (
                <GridElem
                    {...props}
                    source={item}
                    type={itemType}
                    attrs={attrs}
                >
                    {props.children}
                </GridElem>
            );
        }
        // Composite group
        else if (itemTemplate === 'composite') {
            return (
                <Composite
                    source={item}
                    type={itemType}
                    attrs={attrs}
                    onClick={props.onClick}
                    inputOnly={props.inputOnly}
                    colIndex={props.colIndex}
                    rowIndex={props.rowIndex}
                    contentsIndex={props.contentsIndex}
                />
            );
        }
        // Help icon
        else if (itemTemplate === 'helpIcon') {
            item.type = 'button';
            item.text = item.text || 'More information';
            item.attributes['data-tooltip-source'] = item.source;
            item.attributes.type = 'button';
            item.attributes.className = 'cui-icon feta-icon-help';
            item.attributes.title = item.title || item.text || 'More information';

            // Be sure to call `.tooltip()` on the icon once this has rendered, or use event delegation on a parent
            return (
                <Element
                    source={item}
                    colIndex={props.colIndex}
                    rowIndex={props.rowIndex}
                    contentsIndex={props.contentsIndex}
                >
                    {props.children}
                </Element>
            );
        }
        //Icon
        else if(itemTemplate === 'icon'){
            return(
                <RenderIcon
                    {...item.attributes}
                    type={item.icon}
                />
            );
        }
        // Link
        else if (itemTemplate === 'link') {
            const link = item;
            const linkClassNames = [];

            const linkProps = normalizeProps({}, item);

            linkClassNames.push('feta-link');

            if(link.attributes.onclick || link.attributes.onClick){
                link.attributes = normalizeOnClick(link.attributes);
                linkClassNames.push('feta-link-click');
            }

            if(link.type){
                switch(itemType){
                    case 'button':
                        linkClassNames.push('feta-link-button');
                    break;

                    case 'default':
                    default:
                }
            }

            //Store any class names that came across in attributes
            if(link.attributes.className){
                link.attributes.className.split(' ').forEach((name) => {
                    if (!linkClassNames.includes(name)) {
                        linkClassNames.push(name);
                    }
                });
            }

            delete linkProps.refData;

            // Add the element's `attributes` to the parameters that are passed to each event handler
            EVENT_HANDLER_NAMES.forEach((evtType) => {
                if (typeof linkProps[evtType] === 'function') {
                    linkProps[evtType] = (...args) => {
                        props[evtType](...args, link.attributes);
                    };
                }
            });

            //Override styles that may have been passed
            //TODO: Add support for potential accepted styles.
            if(link.attributes.style){
                link.attributes.style = null;
            }

            //Add all classes for link element
            link.attributes.className = linkClassNames.join(' ');

            return (
                <a
                    {...link.attributes}
                    {...linkProps}
                >
                    {link.text}
                </a>
            );
        }
        // HTML Output
        else if (itemTemplate === 'output') {
            if(!item.text){
                return null;
            }

            return (
                <span dangerouslySetInnerHTML={{__html: item.text}} />
            );
        }
        // Arbitrary element or plain text
        else if (item.text || item.type) {
            //TODO: Make sure `item.type` is a valid HTML tag?
            const attrs = item.attributes || {};

            // Make sure there's a tag name
            if (!item.type) {
                item.type = 'span';
            }

            if(item.type === 'span'){
                if(!attrs.tabIndex && !attrs.tabindex){
                    //Prevents a tab index from being added to the element.
                    attrs.tabIndex = null;
                }
            }




            const itemProps = normalizeProps(attrs, props);

            let itemStyleClasses = getStyleClasses(item);

            if(itemStyleClasses){
                //Add any additonal classes determined through style.
                if(item.attributes.className && typeof item.attributes.className  === 'string'){
                    item.attributes.className = item.attributes.className + " " + itemStyleClasses;
                }
                else{
                    item.attributes.className = itemStyleClasses;
                }
            }


            return (
                <Element
                    source={item}
                    {...itemProps}
                >
                    {props.children}
                </Element>
            );
        }
        else if(item.contents){

            const attrs = item.attributes || {};
            const itemProps = normalizeProps(attrs, props);
            let elems = [];
            item.contents.forEach((col, c) => {
                const itemContent = item.contents[c];
                elems.push(
                    <RenderItem
                        source={itemContent}
                    />
                );
            });

            if(elems.length>0){
                return (
                    <span className="feta-render-contents" {...itemProps}>
                        {elems}
                    </span>
                );
            }

            return null;
        }
        // Unsupported template
        else if (itemTemplate) {
            journal.log({type: 'error', owner: 'UI', module: 'RenderItem'}, 'Unsupported template: "' + itemTemplate + '"', JSON.parse(JSON.stringify(props.source)));

            return null;
        }
        // Unsupported object
        else {
            journal.log({type: 'error', owner: 'UI', module: 'RenderItem'}, 'Item is missing both `type` and `text` properties', JSON.parse(JSON.stringify(props.source)));

            return null;
        }
    };

    RenderItem.propTypes = {
        // Must provide either `source` or both `template` and `type`, and in the latter case you must likely want `children` as well
        source: ReactPropTypes.shape({}),
        template: ReactPropTypes.string,
        type: ReactPropTypes.string,

        children: ReactPropTypes.any,
        attrs: ReactPropTypes.any,
        rowIndex: ReactPropTypes.number,
        colIndex: ReactPropTypes.number,
        contentsIndex: ReactPropTypes.number,
        inputOnly: ReactPropTypes.bool,
        onClick: ReactPropTypes.func,
        onChange: ReactPropTypes.func,
        onFocus: ReactPropTypes.func,
        onBlur: ReactPropTypes.func,
        onKeyUp: ReactPropTypes.func,
        onKeyDown: ReactPropTypes.func,
    };

    RenderItem.defaultProps = {
        source: {},
        template: '',
        type: '',

        children: {},
        attrs: '',
        rowIndex: -1,
        colIndex: -1,
        contentsIndex: -1,
        inputOnly: false,
        onClick: null,
        onChange: null,
        onFocus: null,
        onBlur: null,
        onKeyUp: null,
        onKeyDown: null,
    };


    // For dynamically-named React components, we cannot use a string for the name and call `<MyString/>`. Instead we must build a hash of the component classes and call `React.createElement()`. Otherwise it will think `MyString` is a DOM element, and since elements don't have attributes with names like `source` or `inputOnly` React will throw "unknown prop type" warnings and will skip rendering the item. More info: http://stackoverflow.com/q/40371886/348995
    const componentList = {
        Text: TextField,
        Checkbox: CheckableField,
        Radio: CheckableField,
        Button: Button,
        Hidden: Hidden,
        Date: DateField,
        Select: SelectField,
        Composite:Composite
    };


    return RenderItem;
});
