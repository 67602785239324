define(['react', 'reactdom', 'Tree', 'guid'], function (React, ReactDOM, Tree, guid) {
    ////////////////////
    // Public methods //
    ////////////////////

    var _init = function _init (sourceObject, mountNode) {
        var MyTree;

        if (!sourceObject) {
            journal.log({type: 'error', owner: 'UI', module: 'tree', submodule: '_init'}, 'No source list was provided');

            return false;
        }

        if (!mountNode && sourceObject.container) {
            mountNode = document.querySelector(sourceObject.container);
        }

        if (!mountNode) {
            journal.log({type: 'error', owner: 'UI', module: 'tree', submodule: '_init'}, 'Could not get mount node');

            return false;
        }

        sourceObject = _normalizeItemList(sourceObject);
        // if (sourceObject.id === 'alpha_alpha') { window.DEBUG = true; } else { window.DEBUG = false; }

        MyTree = React.createFactory(Tree);

        // Mount the JSX component
        ReactDOM.render(
            MyTree({source: sourceObject}),
            mountNode
        );

        return MyTree;
    };


    /////////////////////
    // Private methods //
    /////////////////////

    /**
     * Ensures that every list and sub-list object has `id` and `isOpen` properties
     *
     * @param   {object}  originalList  List object to be normalized
     *
     * @return  {object}                Updated list object
     */
    var _normalizeItemList = function _normalizeItemList (originalList) {
        var newList = {};
        var __normalizeList = function __normalizeList (list, parentId) {
            // Ensure it has an ID
            if (!list.id) {
                if (!parentId) {
                    parentId = 'item';
                }

                list.id = parentId + '_' + guid();
            }

            // Set default `isOpen` state
            if (typeof list.isOpen !== 'boolean') {
                list.isOpen = false;
            }

            // Recursively check sub-lists
            if (list.items) {
                list.items.map(function (item) {
                    return __normalizeList(item, list.id);
                });
            }

            return list;
        };

        // Make the root level opened by default unless it was explicitly set to closed
        if (originalList.isOpen !== false) {
            originalList.isOpen = true;
        }

        newList = __normalizeList(originalList);

        return newList;
    };


    //////////////////////////////////////////
    // Expose public properties and methods //
    //////////////////////////////////////////

    return {
        init: _init,
    };
});
