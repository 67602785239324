/*jshint esversion: 6*/
define(['jquery'], function ($) {
    return (inputMap) => {
        if (!inputMap || typeof inputMap !== 'object') {
            console.error('bad map ', inputMap);

            return null;
        }

        const getValue = (id) => {
            let elem = document.getElementById(id);
            
			if (!elem) {

				// Determine if this is a name group. 
                const nameGroup = document.getElementsByName(id);

                if(nameGroup.length <= 0){
                	return undefined;
                }

                let nameGroupValues = "";
                
                for(let i=0; i < nameGroup.length; i++){
                	let item = nameGroup[i];

                	if(/radio|checkbox/i.test(item.type)){
                		if(item.checked === true){
		                	if(nameGroupValues === ""){
		                		nameGroupValues = item.value;
		                	}
		                	else{
		                		nameGroupValues += ","+item.value;	
		                	}	                	
		                }	
                	}                	
                }

                return nameGroupValues;
            }

            // Get DOM element from jQuery collection
            if (elem instanceof $) {
                elem = elem.get(0);
            }

            // Input field
            if (/input|select|textarea/i.test(elem.nodeName)) {

                return elem.value;
            }
            // Non-input
            else {
                return elem.textContent;
            }
        };

        // Array of element IDs
        if (Array.isArray(inputMap)) {
            const outputMap = {};

            inputMap.forEach((item) => {
                outputMap[item] = getValue(item);
            });

            return outputMap;
        }
        else {
            Object.keys(inputMap).forEach((keyName) => {

                // Try to get its value
                const val = getValue(keyName);

                // Only store the new value if it actually was an element ID
                if (val !== undefined) {
                    inputMap[keyName] = val;
                }
                // Otherwise the value is some other type of data, so just pass it through without processing
            });

            return inputMap;
        }
    };
});
