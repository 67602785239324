define(['react', 'reactproptypes', 'jquery', 'popover', 'kind', 'TableSortIcon', 'renderer', 'RenderItem', 'RenderIconMessage', 'guid', 'TableColumnHeader'], function (React, ReactPropTypes, $, popover, kind, TableSortIcon, renderer, RenderItem, RenderIconMessage, guid, TableColumnHeader) {
    class TableFooterControls extends React.Component {
        constructor (props) {
            super(props);

            // Private method binding
            this._onRemoveClick = this._onRemoveClick.bind(this);
            this._onRemoveAllClick = this._onRemoveAllClick.bind(this);
        }

        /////////////////////
        // Private methods //
        /////////////////////
        _onRemoveClick(evt){
            this.props.onFooterControlRemoveClick();

            // Don't let the event bubble up
            // evt.stopPropagation();
        }

        _onRemoveAllClick(evt){
            this.props.onFooterControlRemoveAllClick();

            // Don't let the event bubble up
            // evt.stopPropagation();
        }

        ////////////
        // Render //
        ////////////
        render(){
            const sourceTable = this.props.source;
            const footerControls = sourceTable.footerControls;
            const selectionType = sourceTable.selectionType;

            let isSmallSize = this.props.isSmallSize;
            let elems = [];

            // Build special controls like remove here.
            // if(isSmallSize){
            //     if(footerControls.remove && selectionType === "multiple"){
            //         elems.push(

            //             <button
            //                 className="feta-table-footer-control-remove-all"
            //                 onClick={this._onRemoveAllClick}
            //                 type="button"
            //             >Remove All</button>

            //         );
            //     }
            // }
            // else{
                if(footerControls.remove){
                    elems.push(

                        <button
                            className="feta-table-footer-control-remove"
                            onClick={this._onRemoveClick}
                            type="button"
                        >Remove</button>
                    );
                }
            // }

            //Render rest of footer control contents. This is a straight rendering of JSON elements
            if(footerControls && footerControls.contents && footerControls.contents.length > 0){
                footerControls.contents.forEach((col, c) => {
                    const controlItemSource = footerControls.contents[c];

                    // TODO: Look into updating key and removing row/col index.
                    elems.push(
                        <RenderItem
                            source={controlItemSource}
                            key={footerControls.key + this.props.rowIndex  + '-' + this.props.colIndex + '-' + c}
                            colIndex={this.props.colIndex}
                            rowIndex={this.props.rowIndex}
                        />
                    );
                });
            }

            // Only show the footer controls element if there are elements to show.
            if(elems.length && elems.length > 0){
                return (
                    <div className="feta-table-footer-controls">
                        {elems}
                    </div>
                );
            }

            return null;
        }
    }

    TableFooterControls.propTypes = {
        source: ReactPropTypes.shape({}).isRequired,
        isSmallSize: ReactPropTypes.bool,
        onFooterControlRemoveClick: ReactPropTypes.func,
        onFooterControlRemoveAllClick: ReactPropTypes.func,
    };

    TableFooterControls.defaultProps = {
        isSmallSize: false,
        onFooterControlRemoveClick: () => {},
        onFooterControlRemoveAllClick: () => {}
    };

    return TableFooterControls;
});
